html.maker_page.tip_box_man &
  #header
    z-index 1
  #body
    z-index 2
  .mp_maker
    z-index 3 !important
  #maker
    z-index 3
    .maker_menu
      z-index 1
    .board
      z-index 2
html.maker_page[lang="jp"] &
  .tip_box
    .description
      line-height 17px
      font-size 12px
  .tip_1.tip_box
    >.body
      .description
        width 270px
      width 337px
      height 136px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14202_1542854812586_m4wZKhhLu4i4t3bPJ8iG.png) no-repeat 0 0
      background-size 337px
  .tip_2.tip_box
    >.body
      .description
        width 244px
      width 320px
      height 148px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14203_1542854830453_M7FPW4E2iqLVD2bRTVK.png) no-repeat 0 0
      background-size 320px
  .tip_2_1.tip_box
    .body
      width 337px
      height 175px
      background transparent url(//s3.marpple.co/files/u_1187078/2024/5/original/ec71674947071e7322c9710e5df5b8bb82d6844b1.png) no-repeat 0 0
      background-size 337px
      .description
        width 270px
  .tip_3.tip_box
    >.body
      width 380px
      height 152px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14205_1542854847397_nAbdoHWxUZh0tb5A7b.png) no-repeat 0 0
      background-size 380px
  .tip_4.tip_box
    >.body
      width 318px
      height 162px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14206_1542854875769_O5VzZwO7q5IFe8sMNe4Q.png) no-repeat 0 0
      background-size 318px
      .description
        width 250px
html.maker_page[lang="en"] &
  .tip_box
    .description
      line-height 17px
      font-size 12px
  .tip_1.tip_box
    >.body
      width 337px
      height 132px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14202_1542854812586_m4wZKhhLu4i4t3bPJ8iG.png) no-repeat 0 0
      background-size 337px
  .tip_2.tip_box
    >.body
      width 320px
      height 144px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14203_1542854830453_M7FPW4E2iqLVD2bRTVK.png) no-repeat 0 0
      background-size 320px
  .tip_2_1.tip_box
    .body
      width 337px
      height 175px
      background transparent url(//s3.marpple.co/files/u_1187078/2024/5/original/ec71674947071e7322c9710e5df5b8bb82d6844b1.png) no-repeat 0 0
      background-size 337px
      .description
        width 270px
  .tip_3.tip_box
    >.body
      width 380px
      height 152px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14205_1542854847397_nAbdoHWxUZh0tb5A7b.png) no-repeat 0 0
      background-size 380px
  .tip_4.tip_box
    >.body
      width 318px
      height 162px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14206_1542854875769_O5VzZwO7q5IFe8sMNe4Q.png) no-repeat 0 0
      background-size 318px
      .description
        width 253px
html.maker_page[lang="kr"] &
  .tip_box
    .description
      line-height 22px
      font-size 14px
  .tip_1.tip_box
    >.body
      width 301px
      height 154px
      background transparent url(//s3.marpple.co/files/u_193535/2019/1/original/f_28248_1548658236585_U9W7PJ0edT7M9TGXgysT.png) no-repeat 0 0
      background-size 301px
      .description
        width 240px
  .tip_2.tip_box
    .body
      width 312px
      height 174px
      background transparent url(//s3.marpple.co/files/u_193535/2019/1/original/f_28245_1548658236585_p2Z7e0H5ZPsPf0VNq1O1y0l.png) no-repeat 0 0
      background-size 312px
      .description
        width 230px
  .tip_2_1.tip_box
    .body
      width 312px
      height 174px
      background transparent url(//s3.marpple.co/files/u_1187078/2024/5/original/f5f09936133fe3ee78c2991318bb7c7458bd47d81.png) no-repeat 0 0
      background-size 312px
      .description
        width 230px
  .tip_3.tip_box
    >.body
      width 415px
      height 158px
      background transparent url(//s3.marpple.co/files/u_193535/2019/1/original/f_28246_1548658236585_oK5rfr3sO9NA0CppWx2Q.png) no-repeat 0 0
      background-size 415px
  .tip_4.tip_box
    >.body
      width 352px
      height 152px
      background transparent url(//s3.marpple.co/files/u_193535/2019/1/original/f_28249_1548658236585_u8OeTweR3nvr1eynJI.png) no-repeat 0 0
      background-size 352px
      .description
        width 300px
  .tip_shared.tip_box
    .body
      width 246px
      height 128px
      background transparent url(//s3.marpple.co/files/u_2798351/2024/7/original/04b700c04e34755f2178ba9dbdef6aa94dfa39021.png) no-repeat 0 0
      background-size 269px
      padding 16px
.tip_box
  top 0
  left 0
  .tippy-box
    display none
  .cancel, .next
    cursor pointer
    &:active
      opacity 0.8
  .circle_icon
    z-index 2
    position fixed
    list-style none
    display flex
    transform translateY(0) !important
    .text
      display none
.tip_box
  z-index 5
  .block
    z-index 2
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background #000
    opacity 0.3
  .body
    z-index 2
    position fixed
    > div
      position absolute
    .cancel
      width 8px
      height 8px
      padding 10px
      background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_14164_1542800035949_nkBJeDKGRJ5GMBnv.png) no-repeat 50% 50%
      background-size 8px
    .next
      background-color #000
      color #fff
      width 48px
      height 22px
      font-size 12px
      line-height 22px
      text-align center
    .title
      font-size 14px
      font-weight bold
    .dot
      width 6px
      height 6px
      background #d5dbe0
      border-radius 50%
      margin-right 6px
      &.selected
        background #000
.tip_1.tip_box
  position absolute
  .body
    opacity 0
    .title
      top 24px
      left 35px
    .description
      top 48px
      left 35px
    .pagination
      bottom 36px
      left 35px
      display flex
    .cancel
      top 14px
      right 16px
    .next
      right 26px
      bottom 28px
.tip_2.tip_box
  position absolute
  .block
    opacity 0
  .body
    opacity 0
    .title
      top 30px
      left 40px
    .description
      top 54px
      left 40px
    .pagination
      bottom 42px
      left 40px
      display flex
    .cancel
      top 20px
      right 22px
    .next
      right 32px
      bottom 34px
.tip_2_1.tip_box
  position absolute
  .block
    opacity 0
  .body
    opacity 0
    .title
      top 30px
      left 40px
    .description
      top 54px
      left 40px
    .pagination
      bottom 42px
      left 40px
      display flex
    .cancel
      top 20px
      right 22px
    .next
      right 32px
      bottom 34px
.tip_3.tip_box
  position absolute
  .block._top
    bottom initial
    position fixed
  .tr_block
    position fixed
    top 0
    right 0
    bottom 0
    left 0
  .body
    opacity 0
    .title
      top 38px
      left 32px
    .description
      top 62px
      left 32px
    .pagination
      bottom 42px
      left 32px
      display flex
    .cancel
      top 28px
      right 22px
    .next
      right 32px
      bottom 34px
.tip_4.tip_box
  position absolute
  .block
    opacity 0.3
  >.body
    right 543px
    opacity 0
    .title
      top 38px
      left 32px
    .description
      top 62px
      left 32px
    .pagination
      bottom 42px
      left 32px
      display flex
    .cancel
      top 28px
      right 22px
    .next
      right 32px
      bottom 34px
.tip_shared.tip_box
  position absolute
  .block
    opacity 0
  .body
    opacity 0
    > div
      position relative
    .title_wrap
      display: flex
      justify-content space-between
      margin-bottom 8px
      .cancel
        margin-right 16px
        padding: 0
    .description
      margin-bottom 20px
    .pagination_wrap
      display flex
      justify-content space-between
      align-items center
      .pagination
        bottom 21px
        left 16px
        display flex
      .next
        margin-right 16px
  #to_save_changes
    position fixed
    z-index 2
    border-radius 100px
    border: 1px solid BK
    font-size 16px
    padding: 14px 0
  #to_option
    position fixed
    z-index 2
    border-radius 100px
    border 1px solid BK
    font-size 16px
    padding 14px 0
    color WHT
    background-color BK
  #to_save_changes_vector
    position fixed
    z-index 2
    background-color WHT
    color BK
    border 1px solid BK
    padding ptr(6) ptr(16)
    border-radius 999px
    font-size ptr(16)
  html[marpplizer_mode="short"] &
    #to_save_changes_vector
      &:after
        content '변경사항 저장'
  html[marpplizer_mode="basic"] &
    #to_save_changes_vector
      &:after
        content '변경사항 저장하기'


