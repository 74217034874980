.infi_item
  .omp-cell
    &__review__description-wrapper
      display flex
      flex 1
      flex-direction column
      justify-content flex-start
      gap ptr(6)
    &__review__read-more
      width fit-content
      display none
      color GY
      {font_14_m_100}
    &__review__description
      display -webkit-box
      -webkit-box-orient vertical
      overflow hidden
      max-height ptr(48)
      {font_16_r_150}

.omp-cell
  &__review
    position relative
    display flex
    flex-direction column
    gap ptr(10)
    width 100%
    &[data-can_open="true"]
      cursor pointer
    &[data-read_more="true"]
      cursor pointer
      .omp-cell__review__description
        -webkit-line-clamp 2
      .omp-cell__review__read-more
        display block
    &[data-read_more="false"]
      .omp-cell__review__description
        max-height fit-content
  &__review-delete
    position absolute
    display flex
    right ptr(18)
    top ptr(18)
    svg
      width ptr(16)
      height ptr(16)
  &__review-image
    flex-grow 0
    flex-shrink 0
    position relative
    display inline-flex
    width 100%
    aspect-ratio 1/1
    border-radius ptr(20)
    overflow hidden
    text-align center

  &__review-info
    width 100%
    display flex
    flex-direction column
    gap ptr(10)
  &__review-score
    display flex
  &__review-size_info
    font-size ptr(14)
    color #858585
    margin-left ptr(8)
    font-weight 500
  &__review-star
    background url(//s3.marpple.co/files/u_2232571/2023/6/original/057cf321a6fc3ac6853730c9b0c585a0aa02686f1.png) no-repeat
    background-size 100%
    width ptr(18)
    height ptr(18)
    &[data-off="true"]
      background url(//s3.marpple.co/files/u_2232571/2023/6/original/90058fabf27297e7b30f23f6326a21855682c71b1.png) no-repeat
      background-size 100%
  &__review-badges
    display flex

  // meta
  &__review-meta
    display flex
    gap ptr(8)
    align-items center
    font-weight 500
    .omp-atom__badge
      padding ptr(4) ptr(8)
  &__review__user-id
    {font_14_m_140}
  &__review__created-at
    {font_12_m_140}

  // description
  &__review__description-wrapper
    display flex
    flex 1
    flex-direction column
    justify-content flex-start
    gap ptr(6)
  &__review__read-more
    width fit-content
    display none
    color GY
    {font_14_m_100}
  &__review__description
    overflow hidden
    max-height ptr(48)
    {font_16_r_150}
    limitLine(2)
  // product info
  &__review__product-info
    display flex
    gap ptr(10)
    {font_14_m_100}
    color GY !important
    &.review_page
      background #F6F6F6
      padding ptr(16)
      border-radius ptr(20)
      display flex
      justify-content space-between
      align-items flex-end
      .review_page__product-info
        display flex
        gap ptr(16)
      .go_to_custom
        white-space nowrap
        border-radius 999px
        background BK
        color WHT
        padding 0 ptr(16)
        height ptr(40)
        border none
        display flex
        gap ptr(4)
        align-items center
        cursor pointer
      .omp-cell__product-text__price
        color BK


  &__review__product-thumbnail
    border 1px solid GY_50
    border-radius ptr(8)
    background-size ptr(60)
    overflow hidden
    width ptr(60)
    height ptr(60)
    display flex
    align-items center
    justify-content center
    background BG_PRODUCT
    box-sizing border-box
    &>img
      width 90%
      height 90%
      object-fit cover
    &.review_page
      width ptr(80)
      height ptr(80)
      padding ptr(9)
      border none
  &__review__product-meta
    display flex
    flex-direction column
    justify-content center
    gap ptr(8)
    color GY !important
    & > a
      color GY !important
    & > a.omp-cell__review__product-category
      color OG !important
    &.review_page
      >.omp-cell__review__product-name
        color BK
        font-size ptr(16)
        line-height 150%
  &__review__product-category > a
    color OG !important


// vertical
.omp-cell__review[data-type="vertical"]
  position relative
  .omp-cell__review
    &-badges
      position absolute
      top ptr(20)
      left ptr(20)
    &-image
      &>img
        width 100%
        object-position center
        object-fit cover
    &__description
      margin-right ptr(20)
    &__product-category > a
      color OG !important

// horizontal
.omp-cell__review[data-type="horizontal"]
  box-sizing border-box
  display flex
  flex-direction row
  align-items flex-start
  justify-content space-between
  width 100%
  padding ptr(24)
  border 1px solid BG_MODEL
  border-radius ptr(20)
  gap ptr(24)
  .omp-cell
    &__review-image
      flex-shrink 0
      position relative
      display flex
      align-items flex-start
      width ptr(216)
      height ptr(216)
      border-radius ptr(12)
      overflow hidden
      text-align center
      &>img
        object-position center
        object-fit cover
        -webkit-user-drag none
        height 100%
        width 100%
      .omp-atom__icon-multiple
        position absolute
        top ptr(8)
        right ptr(8)
        width ptr(20)
        height ptr(20)
    &__review-badges
      gap ptr(8)
    &__review-meta
      margin-top ptr(8)
      align-items center
    &__review__body
      display flex
      flex-direction column
      justify-content space-between
      min-height ptr(216)
      gap ptr(14)
    &__review__product-category > a
      color OG !important


// TODO @kjj atom으로 빼기
.omp-atom__icon-multiple
  content ' '
  background url(//s3.marpple.co/files/u_2232571/2023/6/original/03cd1ef8a75166aed344b34f01e202ca8ed4fe971.png) no-repeat
  background-size ptr(20)
