.don_frame.has_up_style
  .up_c_item
    padding-bottom 16px
    &:not(:first-child)
      padding-top 16px
      border-top 1px solid #d5dbe0
    .item_header
      margin-bottom 16px
      font-size 14px
      color GY_50
      .no
        &:before
          content ''
          font-size 1px
          margin 0 8px
          border .4px solid #dee2e6
    .item_body
      .item_body_wrap1
        display flex
        gap ptr(16)
    .thumb
      max-width ptr(100)
      flex 0 0 ptr(100)
      height ptr(100)
      .thumbnail_wrapper
        overflow hidden
        border-radius ptr(8)
    .up_c_info
      min-width 0
      .title
        font-size ptr(16)
        line-height ptr(24)
        color #212529
      .sub_title
        font-size ptr(14)
        color GY_50
        margin-right 3px
        &:after
          content ' : '
      .faces, .sizes
        display flex
      .options
        display flex
        flex-direction column
        gap ptr(4)
        margin-top ptr(4)
        .product_file
          display none
      .qty
        color GY_50
      .up_c_s_list
        flex 1
      .up_c_s_item
        {flex-center-between}
        position relative
        .size
          margin-right 6px
        &.empty
          text-decoration underline
          cursor pointer
        &:not(:last-child)
          margin-bottom 8px
      html:not(.creator_store) &
        .pc_name
          display none
      html.creator_store &
        .bp_name
          margin-top 4px
        .faces
          display none
    .product_price
      font-size 14px
      display flex
      justify-content flex-end
      align-items center
      gap ptr(32)
      .product_price_content
        {flex-center-between}
        flex-shrink 0
        font-size ptr(16)
        line-height ptr(24)
      .origin
        text-decoration line-through
        font-size ptr(14)
        font-weight 300
        line-height ptr(1.17)
        margin-right 8px
        color GY_50
      .product_file_wrapper
        min-width 0
        .product_file
          display flex
          align-items center
          gap ptr(8)
          min-width 0
        .product_file_name
          display flex
          align-items center
          min-width 0
          text-decoration underline
          color BK
          font-weight 500
          font-size ptr(14)
          line-height 140%
          cursor pointer
          &.disabled
            color GY_80
            text-decoration none
            cursor text
        .product_file_title
          text-overflow ellipsis
          white-space nowrap
          overflow hidden
        .product_file_mimetype
          flex-shrink 0
        .product_file_date
          color GY
          flex-shrink 0
          {font_12_r_140}
    .product_price:has(.product_file_wrapper)
      justify-content space-between
.don_frame.has_up_modal_style
  >.don_wrapper
    width 900px !important
  .don_page >.don_wrapper >.header
    width calc(100% - 624px) !important
@media screen and (min-width: 1500px)
  .don_frame.has_up_modal_style
    >.don_wrapper
      width 1036px !important
    .don_page >.don_wrapper >.header
      width calc(100% - 624px) !important
.don_frame.has_up_modal_style
  .don_page >.don_wrapper >.header
    .title
      font-size 20px !important
      font-weight normal !important
      html[lang="en"] &
        font-family CircularStd
  .modal
    background-color white
    .list_body
      width 100%
      box-sizing border-box
      overflow-y auto
      .product_title
        font-size 26px
        margin-right 24px
        margin-bottom 16px
        .pc_name
        .price
          display none !important
      .item
        box-sizing border-box
        position relative
        border-bottom 1px solid #d5dbe0
        padding 16px 0
        .size_info__no_stock
          display none
        &.is_not_stock
          .info
            color GY_50 !important
          .size_info
            span
              position relative
              display flex
              gap ptr(10)
              flex-wrap wrap
              align-items center
              margin-right ptr(10)
              word-break keep-all
              word-wrap break-word
              &:after
                content "Sold Out"
                color GY_50 !important
                font-size 10px
                word-break keep-all
                word-wrap break-word
                html[lang="kr"] &
                  content "일시품절"
        &.is_overflowed
          padding-bottom 30px
          &:before
            content "The image size is too large."
            html[lang="kr"] &
              content "인쇄크기 넘침"
            html[lang="jp"] &
              content "印刷可能な大きさを超えました。"
            position absolute
            bottom 6px
            right 16px
            color #fea022 !important
        &:last-child
          border-bottom none
        .info_wrap
          {flex-center-between}
          .info
            font-size 14px
            color color_black
            display flex
            width 50%
            &.qty_info
              display flex
              flex-direction column
          span.size, span.qty
            margin-right 8px
          .qty_wrap
            display flex
            justify-content end
            width 100%
          .mp-select-product-quantity
            width 100%
            select
              width 100%
              maker-option-select-style1(8px)
          .input_wrap:not(.mp-select-product-quantity)
            width 106px
            height 24px
            background-color #ffffff
            border solid 1px #e9ecef
            border-radius 4px
            box-sizing border-box
            {flex-center-between}
            input.quantity
              font-size 12px
              line-height 1.17
              text-align center
              color #212529
              outline none
              border none
              width 80px
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button
                -webkit-appearance none
                margin 0
            .plus, .minus
              height 24px
              width 40px
              padding 10px
              cursor pointer
            .plus
              background svg-plus no-repeat center
              &:active
                background svg-plus-active no-repeat center
            .minus
              background svg-minus no-repeat center
              &:active
                background svg-minus-active no-repeat center
        .product_price_wrap
          display flex
          justify-content flex-end
        .product_price
          display flex
          bottom 14px
          font-size 14px
          &[is_discounted="true"]
            .discounted
              display block
            .original
              text-decoration line-through
              color GY_50
          &.hide
            .discounted, .original
              display none
          .discounted
            display none
            margin-top 12px
            color OG
          .original
            font-size 13px
            margin-right 8px
            margin-top 13px
            color OG
    .modal_footer
      position absolute
      bottom 0
      left 0
      right 0
      padding 16px 24px
      background-color #ffffff
      .summary
        display flex
        justify-content space-between
        align-items end
        .title
          font-size 14px
          font-weight bold
          color color_black
        .total_price
          display flex
          align-items end
          font-size 12px
          font-style normal
          font-stretch normal
          line-height 1.17
          letter-spacing normal
          .prices
            display flex
            gap 4px
            flex-direction column
          .percent
            margin-right 5px
            font-weight 500
            color #ff6b6b
          .origin
            text-decoration line-through
            font-weight 300
            text-align right
            color GY_50
          .sign
            line-height 1.07
            font-size 14px
            font-weight bold
            margin-right 1px
          .discounted
            font-size 14px
            font-weight bold
            line-height 1
      .msg
        margin-top 8px
        font-size 9px
        color GY_50
        html[lang="kr"] &
        html[lang="jp"] &
          display none
      .buttons
        margin-top 22px
        button
          width 50%
          height 40px
          outline none
          font-size 14px
          border none
          border-radius 100px
          &:active, &:focus
            outline none
        #confirm
          width 100%
          background-color #000
          color #fff


.don_frame.has_up_modal_style
  .don_wrapper
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    min-height 640px
  >.don_wrapper >.header
    display none
  .don_page >.don_wrapper >.header
    background-color transparent
    width 100%
    z-index 20
    position absolute
    right 0
    top 0
    box-sizing border-box
    padding 40px 32px 16px !important
    .don_hide_frame
      top 28px
      right 24px
  .modal
    display flex
    height 100%
    >.product_preview
      position relative
      background-color #f8f9fa
      width 617px
      height 100%
      >.product_face_container
        position relative
        width 100%
        height 100%
      .product_face_list.top
        z-index 1
        .product_face
          display flex
          justify-content center
          padding-top 6%
          height 80%
        .img.canvas_600
          width auto
          height 100%
          margin auto
          text-align center
          > canvas
            display inline-block
            transform scale(1)
      .product_face_list.bottom
        {flex-center}
        position absolute
        bottom ptr(20)
        left 0
        right 0
        z-index 2
        .product_face.preview_thumb
          margin 0 8px
          cursor pointer
          display flex
          flex-direction column
          gap ptr(8)
          .img.canvas_60
            opacity 0.5
            width ptr(80)
            height ptr(80)
          .face_name
            font-size 10px
            text-align center
            line-height 0.5
          &.active
            .img.canvas_60
              opacity 1
            .face_name
              color OG
          &:hover
            .img.canvas_60
              opacity .8
      .swiper_control
        width ptr(40)
        height ptr(40)
        border-radius ptr(999)
        background WHT
        position absolute
        top 0
        bottom 0
        margin auto 0
        z-index 20
        cursor pointer
      .swiper-button-disabled
        &.preview_prev
          background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 18 16' fill='none'%3E%3Cpath d='M8 1L1 8L8 15' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 8H12H7' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
        &.preview_next
          background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3E%3Cpath d='M10 1.5L17 8.5L10 15.5' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 8.5H6H11' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
          cursor default
      .preview_prev
        bottom ptr(138)
        left ptr(32)
        background-size ptr(18) ptr(16)
        background-position center
        background-repeat no-repeat
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 18 16' fill='none'%3E%3Cpath d='M8 1L1 8L8 15' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 8H12H7' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
      .preview_next
        bottom ptr(138)
        right ptr(32)
        background-size ptr(18) ptr(16)
        background-position center
        background-repeat no-repeat
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 18 17' fill='none'%3E%3Cpath d='M10 1.5L17 8.5L10 15.5' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 8.5H6H11' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")

    >.for_desktop
      flex 1
      position relative
      padding 65px 32px 24px 32px
      .list_body
        position absolute
        top 28px
        right 0
        bottom 125px
        left 0
        padding 0 32px

@media screen and (max-height: 850px)
  .shrink-modal
    .don_wrapper
      height: 640px !important


