.don_frame[frame_name="/modules/OMP/Review/Detail/S/Mui/frame.js"]
  .review_detail_page_wrap
    .omp-cell__product
      .omp-atom__badge[data-type="custom_badge"]
      .omp-atom__badge[data-type="bp_first"]
      .omp-atom__badge[data-type="sale"]
      .omp-atom__badge[data-type="new"]
      .omp-atom__badge[data-type="moq"]
        display flex
    .review_detail
      &__nav
        display flex
        gap ptr(16)
        align-items center
        .review_all
          font-size ptr(16)
          font-weight 500
          color GY
        .review_currnet
          font-size ptr(16)
          font-weight 700
          color BK
      &__content
        display flex
        align-items center
        flex-direction column
        .omp-review-detail
          padding ptr(24)
          border-radius ptr(40)
          border 1px solid #E8E8E8
          max-width ptr(1200)
          height ptr(560)
          margin-top ptr(32)
          &__image-slider
            width ptr(560)
            height ptr(560)
            display flex
            align-items center
            justify-content flex-start
          &__image-wrapper
            width ptr(560)
            height ptr(560)
            border-radius ptr(20)
            overflow hidden
            display flex
            align-items center
            justify-content center
            background-color #A5A5A5
            > img
              width 100%
              height 100%
              object-fit contain
              object-position center
    .review_more
      border-radius 999px
      border 1px solid BK
      width ptr(450)
      height ptr(50)
      font-size ptr(16)
      font-weight 500
      background WHT
      margin auto
      margin-top ptr(24)
      margin-bottom ptr(60)
      cursor pointer
      display flex
      align-items center
      justify-content center

    .review_product_list
      font-size ptr(26)
      font-weight 500
      margin 0
      margin-bottom ptr(16)

    .cate_list_review_count
      color OG


  .omp-review-detail
    display flex
    gap ptr(30)
    margin-top ptr(16)
    height ptr(500)
    max-width ptr(880)
    min-width ptr(542)
    &__body
      min-width ptr(350)
      max-width ptr(542)
      display flex
      justify-content space-between
      flex-direction column
      gap ptr(8)
    &__body-info-wrapper
      height auto
      display flex
      flex-direction column
      gap ptr(8)
    &__body-info
      display flex
      flex-direction column
      gap ptr(8)
    &__body-content
      max-height ptr(300)
      overflow-y auto
      display flex
      position relative
    &__body-description
      height fit-content
      {font_16_r_150}
    &__image-slider
      flex-shrink 0
      position relative
      &[data-index="0"]
        .omp-review-detail__image-slider-prev
          display none
      &[data-last="true"]
        .omp-review-detail__image-slider-next
          display none
      &[data-length="1"]
        .omp-review-detail__image-slider-prev
          display none
        .omp-review-detail__image-slider-next
          display none
    &__image-slider
      width ptr(500)
      height ptr(500)
      display flex
      align-items center
      justify-content flex-start
    &__image-slider-nav
      cursor pointer
      top ptr(250)
    &__image-wrapper
      width ptr(500)
      height ptr(500)
      border-radius ptr(20)
      overflow hidden
      display flex
      align-items center
      justify-content center
      background-color #A5A5A5
      > img
        width 100%
        height 100%
        object-fit contain
        object-position center
