tooltip_common_style =
  background-color white
  box-sizing border-box

tooltip_shadow =
  box-shadow 1px 1px 5px 0 rgba(0, 0, 0, 0.2)

.don_tooltip
  {tooltip_common_style}
  {tooltip_shadow}
  position absolute
  opacity 0
  top 16px
  font-size 14px
  z-index 101
  >.wrapper
    {tooltip_common_style}
    width 100%
    height 100%
    padding 16px
    z-index 4
