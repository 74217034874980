body.contact_us
  .contact-us
    width 992px
    margin 0 auto
    padding-bottom 120px
    h1, h2, h3, h4, p
      margin 0
      padding 0
    >.header
      font-size 32px

    .mobile
      display none

    .section
      font-size 20px
      margin-top 40px
      >.header
        margin-bottom 16px
      .description
        font-size 14px
        line-height 24px
    .section.get_in_touch
      .description
        margin-bottom 40px
      >.body
        display flex
        padding 40px 0
        border-top 1px solid #000
        border-bottom 1px solid #d5dbe0
        .item
          flex-grow 1
          display flex
          >.icon
            width 88px
            height 88px
          >.body
            padding-top 4px
            margin-left 24px
            >.header
              margin-bottom 16px
              font-size 14px
              font-weight bold
            >.body
              line-height 24px
              font-size 14px
              a
                color color_active
                margin-left 5px
    .section.quick_assists
      .description
        margin-bottom 24px
      a
        display inline-block
        border 1px solid #d5dbe0
        width 488px
        height 40px
        text-align center
        font-size 14px
        font-weight normal
        color color_black
        line-height 40px
      >.body
        display flex
        justify-content space-between