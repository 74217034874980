.mp_maker.cv_object_selected.from_library
  #marpplizer
    .image_color.section
      display none
    .request_bg_removal.section
      display block
.cv_image_editor
  button
  .operator
    cursor pointer
