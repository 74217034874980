.omp-cell
  &__dropdown
    display flex
    flex-direction  column
    position relative
    width ptr(147)
    height ptr(48)
    border-radius 999px
    border 1px solid GY_50
    overflow hidden
    cursor pointer
    > *
      box-sizing border-box

    input
      opacity 0
      position absolute
      left -99999px

    input:checked + label
      order -1
      background WHT
      position relative
      {font_16_b_100}
      color BK

    input:checked + label:after
      content ''
      width 0
      height 0
      border-left ptr(4.5) solid transparent
      border-right ptr(4.5) solid transparent
      border-top ptr(7) solid BK
      position absolute
      right ptr(20)
      pointer-events none

    label
      padding ptr(24)
      display flex
      align-items center
      background WHT
      width  100%
      pointer-events none
      overflow hidden
      white-space nowrap
      {font_16_m_100}
      color GY
      .icon
        margin-right ptr(8)
      &:hover
        cursor pointer
    &:focus
      overflow visible
      border none
      input:checked + label
        order 0
      input:checked + label:after
        content none
    &:focus
      label
        position relative
        pointer-events all
        border-left 1px solid GY_50
        border-right 1px solid GY_50
        &:first-of-type
          border-radius ptr(16) ptr(16) 0 0
          border-top 1px solid GY_50
          padding-top ptr(28)
        &:last-of-type
          border-radius 0 0 ptr(16) ptr(16)
          border-bottom 1px solid GY_50
          padding-bottom ptr(28)


