.sticker_etc_share_style
  .colors_wrapper
    .title
      margin-bottom 20px
    .colors
      .row
        margin-bottom 12px
        &:last-child
          margin-bottom 0
      .color
        width 28px
        height 28px
        border-radius 28px
        span
          width 24px
          height 24px
          border-radius 24px
          background-size 24px 24px
        @media screen and (max-width vector-editor-short-version-width)
          width 24px
          height 24px
          border-radius 24px
          span
            width 20px
            height 20px
            border-radius 20px
            background-size 20px 20px
    .title
      text-align center
    .colors
      .color
        &[data-color_code="#ffffff"]
          span
            border 1px solid #ced4da
        border 1px solid transparent
        display flex
        justify-content center
        align-items center
        &.active
          border 1px solid OG
      .row
        display flex
        justify-content space-between
