.omp-atom
  &__wish-button
    position relative
    display flex
    flex-direction row
    align-items center
    justify-content center
    width 100%
    height 100%
    box-sizing border-box
    background-size 100% auto
    background-repeat no-repeat
    background-position center top
    &[data-active="false"]
      background-image empty-heart
    &[data-active="true"]
      background-image full-heart
  &__cart-button
    position relative
    display flex
    flex-direction row
    align-items center
    justify-content center
    width 100%
    height 100%
    box-sizing border-box
    background-size 100% auto
    background-repeat no-repeat
    background-position center top
    background-image cart
  &__button-badge
    position absolute
    box-sizing border-box
    top ptr(9)
    left ptr(9)
    margin 0
    padding ptr(2) ptr(4)
    min-height ptr(16)
    min-width ptr(16)
    background-color OG
    border-radius ptr(999)
    { font_12_m_100 }
    text-align center
    color WHT
  &__heart-button
    width 100%
    position relative
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap ptr(7)
    &[data-active="false"]
      color GY
      .omp-atom__heart-button-heart
        background-image empty-grey-heart
    &[data-active="true"]
      color OG
      .omp-atom__heart-button-heart
        background-image full-heart
  &__heart-button-heart
    width 100%
    aspect-ratio 1/1
    box-sizing border-box
    background-size 100% auto
    background-repeat no-repeat
    background-position center top
  &__heart-round-button
    width 100%
    border-radius 100%
    position relative
    display flex
    flex-direction column
    align-items center
    justify-content center
    box-sizing border-box
    padding ptr(3.75)
    background-color WHT
    &[data-active="false"]
      color GY
      .omp-atom__heart-round-button__heart
        background-image empty-grey-heart
    &[data-active="true"]
      color OG
      .omp-atom__heart-round-button__heart
        background-image full-heart
  &__heart-round-button__heart
    width 100%
    aspect-ratio 1/1
    box-sizing border-box
    background-size 100% auto
    background-repeat no-repeat
    background-position center top
  &__heart-button-count
    { font_12_m_100 }
    text-align center
  &__arrow-button
    display block
    width 100%
    height 100%
    background-size 100% auto
    background-repeat no-repeat
    background-position center top
    &[data-direction="left"]
      background-image left-arrow
    &[data-direction="right"]
      background-image right-arrow
  &__plus-button
    display block
    width 100%
    height 100%
    background-size 100% auto
    background-repeat no-repeat
    background-position center top
    background-image plus
