body.old_message
    position relative
    .don_tab
      #old_messages
        overflow hidden
        .body
          .date
            margin 0 0 24px
            overflow hidden
            .date_at
              text-align center
              color color_gray3
            .old_messages
              .old_message
                overflow hidden
                display block
                padding 8px 0
                .iv
                  display inline-block
                  vertical-align top
                .left
                  display none
                .right
                  margin-top 4px
                  .message_header
                    margin-bottom 8px
                    overflow hidden
                    .name
                      font-weight 600
                      margin-right 8px
                    .updated_at
                      color color_gray3
                      font-weight 100
                      font-size 12px
                      vertical-align bottom
                  .message_body
                    padding 8px 16px
                    border-radius 8px
                    background-color #eee
                    display inline-block
                    &.down
                      padding 8px 16px 8px 12px
                      &:before
                        content ''
                        display inline-block
                        vertical-align top
                        width 28px
                        height 28px
                        margin-right 8px
                        background-image url(//s3.marpple.co/files/u_29089/2019/3/original/f_23764_1552413955001_o2HM1sO8mLQB2MqKIqE.png)
                        background-repeat no-repeat
                      .comment
                        display inline-block
                        margin-top 5px
                    .comment
                      a
                        text-decoration underline

                &[is_user="true"]
                  .right
                    float right
                    .message_header
                      .updated_at
                        float right
                    .message_body
                      background-color #222
                      color #fff
                      float right
                      .comment
                          a
                            color #fff
                &[is_user="false"]
                  .left
                    display inline-block
                    margin-right 8px
                    .marpple_profile
                      width 24px
                      height 24px
                      display block
                      border-radius 24px
                      color #fff
                      background-color #000
                      text-align center
                      line-height 24px
                      font-size 12px

        .footer
          margin 40px 0
          html#dream_factory &
            display none
          .new_chat
            text-align center
            .live_chat
              border 0
              padding 12px 24px 12px 40px
              background #222
              color #fff
              border-radius 24px
              position relative
              &:before
                content '+'
                position absolute
                font-size 26px
                color #fff
                top 4px
                left 16px
                font-weight 200


        @media screen and (min-width 1025px)
          width 500px
          margin auto
          padding 40px 0 8px 0
          min-height 424px
          .header
            font-size 32px
            margin 40px 0
          .body
            width 500px
            margin 0 auto
            .old_message
              .right
                max-width 300px

        @media screen and (max-width 1024px)
          .header
            padding 16px
            font-size 20px
            margin 24px 0 0
          .body
            padding 16px
            .date
              .date_at
                font-size 12px
              .old_messages
                .old_message
                  padding 4px 0
                  .right
                    max-width 70%
                    .message_header
                      font-size 12px
                      margin-bottom 4px
                      .name
                        margin-right 4px
                      .updated_at
                        font-size 10px
                    .message_body
                      padding 8px
                      border-radius 4px
                      font-size 12px