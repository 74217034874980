// modules/Auth/LoginResult/F/Style/Pc/index.styl
.omp.login_result
  .omp-layout-body
    padding-top 0
    &__wrap
      flex-grow 1
  .login_result--wrapper
    height 100%
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap ptr(40)
    {font_16_m_150}
    .signup
      display flex
      flex-direction column
      align-items center
      .logo
        margin-bottom ptr(80)
        width ptr(170)
      .image
        margin-bottom ptr(24)
        width ptr(480)
      .title
        margin-top 0
        margin-bottom ptr(8)
        {font_20_b_140}
        color BK
      .description
        text-align center
        {font_16_m_150}
        color GY_80
    .login
      .title
        color #6B6B6B
        text-align center
        font-size ptr(24)
        font-weight 700
        line-height ptr(34)
        letter-spacing -0.2px
        margin 0
      .description
        margin-top ptr(8)
        color #A2A2A2
        text-align center
        font-size ptr(16)
        font-weight 500
        line-height ptr(24)
        letter-spacing -0.2px
    .spinner
      width ptr(72)
      height ptr(72)
