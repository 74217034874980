.vector-editor__path-editor
  position absolute
  right 0
  top 0
  width 0
  height 0
  .don_tab
    position absolute
    top 0
    width 0
    height 0
    right 2.5rem
    > .don_wrapper
      position relative
      width 100%
      height 100%
      .container
        position relative
        width 100%
        height 100%
        display flex
        flex-direction column
        box-sizing border-box
        padding 40px 0 32px 0
        background-color #FFFFFF
        overflow hidden
        border-radius 20px
        .header
          position relative
          width 100%
          height 22px
          display flex
          align-items center
          justify-content center
          .title
            font-size 19px
        .wrapper
          margin-top 40px
          width 100%
          height 0
          flex-grow 1
          display flex
          flex-direction column
          box-sizing border-box
          padding 0 40px
          .button_group
            width 100%
            display flex
            flex-direction column
            align-items flex-start
            &:not(:last-child)
              margin-bottom 34px
            .button_group_label
              font-size 14px
              margin-bottom 16px
            .button_list
              width 100%
              display flex
              flex-direction column
              button
                position relative
                box-sizing border-box
                width 100%
                height 40px
                vector-editor-border-radius-xs()
                background WHT
                padding 0
                margin 0
                display flex
                align-items center
                justify-content center
                &:not(:last-child)
                  margin-bottom 8px
                .button_icon
                  position absolute
                  width 28px
                  height 28px
                  left 12px
                  top 50%
                  transform translateY(-50%)
                  background-repeat no-repeat
                  background-size 28px 28px
                  background-position center center
                  &.active
                    display none
                  &.inactive
                    display block
                .button_text
                  font-size 14px
                &:active
                  border-color OG
                  .button_icon
                    &.active
                      display block
                    &.inactive
                      display none
                  .button_text
                    color OG
              button.add_l
                .button_icon
                  &.inactive
                    mp-editor-path-straight()
              button.add_c
                .button_icon
                  &.inactive
                    mp-editor-path-curved()
              button.remove
                .button_icon
                  &.inactive
                    mp-editor-path-delete()
              button.change_to_c
                .button_icon
                  &.inactive
                    mp-editor-path-changed-curved()
              button.change_to_l
                .button_icon
                  &.inactive
                    mp-editor-path-changed-straight()
              button.close_or_open
                &[data-is_closed="false"]
                  .button_text.open
                    display none
                &[data-is_closed="true"]
                  .button_text.close
                    display none
                .button_icon
                  &.inactive
                    mp-editor-path-closed()
        .button_container
          width 100%
          height 56px
          display flex
          justify-content center
          box-sizing border-box
          padding 0 40px
          button
            width 100%
            height 100%
            margin 0
            padding 0
            border 0 none
            vector-editor-border-radius-xs()
            &.done
              vector-editor-border-radius-l()
              background BK !important
              color WHT !important
