

@media screen and (min-width 1025px)
  .shipping_country_frame
    z-index 100 !important
    > .don_wrapper
      width 800px !important
    > .don_wrapper > .header
      height 48px
      .title
        display none
    .don_page
      > .don_wrapper > .header
        margin 76px auto 36px
        width 640px
        text-align center
        z-index 1
        .title
          font-size 32px
          font-weight normal
          color color_black
          html[lang="en"] &
            font-family CircularStd
      .don_tab
        width 640px
        margin 0 auto
        .cont
          font-size 14px
          line-height 1.71
          color color_black
          p
            margin 0 0 28px
            padding 0
        .country
          margin 38px 0 55px
          img
            width 100%
          .is_mobile
            display none


@media screen and (max-width 1024px)
  .shipping_country_frame
    background-color #fff
    > .don_wrapper > .header
      height 48px
      .title
        display none
    .don_page
      padding 0 6%
      box-sizing border-box
      > .don_wrapper > .header
        height 48px
        z-index 1
        background-color transparent !important
        .title
          font-size 20px
          font-weight normal
          color color_black
          text-align left
          html[lang="en"] &
            font-family CircularStd
      .don_tab
        background-color #fff
        .cont
          margin-top 10px
          font-size 14px
          line-height 1.71
          color color_black
          p
            margin 0 0 24px
            padding 0
        .country
          margin-top 38px
          img
            width 100%
          .is_pc
            display none


