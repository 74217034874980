
  .guideline_wrap
    position relative
    width 992px
    margin 0 auto
    h2
      margin 0
      padding 0
      font-size 32px
      font-weight normal
      line-height 1.19
      color color_black
      text-align center
      html[lang="en"] &
        font-family CircularStd
    .guideline_tab
      ul
        margin 12px 0 0
        padding 0
        list-style none
        display flex
        align-items stretch
        justify-content space-between
        li
          width 50%
          border-bottom 1px solid #d5dbe0
          &.on
            border-bottom-color #000
            a
              color color_black
          a
            display inline-block
            padding 22px 0
            width 100%
            text-align center
            font-size 20px
            color color_gray3
    .guideline_cont
      margin-top 74px
      .copy_description
        font-size 14px
        line-height 24px
        color color_black
        p
          margin 0 0 24px
      .qna
        margin-top 40px
        padding 40px
        background-color #fafafa
        h3
          margin 0
          padding 0
          font-size 14px
          font-weight bold
          line-height 1.71
          color color_black
        ul
          margin 0
          padding 0
          list-style none
          li
            font-size 14px
            line-height 1.71
            color color_black
            border-bottom 1px solid #d5dbe0
            &[selected="true"]
              .q
                background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_5968_1539181296743_L6VWH5tH7d7E5s2u2a0TR0mA.png)
              .a
                display block
            .q
              padding 20px 16px 19px
              background url(//s3.marpple.co/files/u_18053/2018/10/original/f_5967_1539181292187_BzObFQ8cH7bFS4X1vL6F.png) no-repeat
              background-size 8px 8px
              background-position 0 27px
              cursor pointer
            .a
              padding 0 16px 20px
              display none
      .guide
        margin-top 34px
        padding-top 34px
        border-top 1px solid #d5dbe0
        &:first-child
          margin-top 0
          padding-top 0
          border-top 0 none
        .q
          margin-bottom 16px
          font-size 14px
          font-weight bold
          line-height 24px
          color color_black
        .a
          font-size 14px
          line-height 24px
          color color_black
          .image
            margin 40px 0
            img
              max-width 100%
              &.is_mobile
                display none
          p
            margin 0 0 20px
          ul
            margin 0 0 20px 10px
            padding-left 10px
            li
              margin-bottom 5px
            b
              display block