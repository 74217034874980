.don_frame[frame_name="/modules/OMP/Auth/FindID/S/Mui/frame.js"]
  .don_back_page
    display none
  .omp-find-id-result
    display flex
    flex-direction column
    padding-top ptr(40)
    box-sizing border-box
    gap ptr(40)
    width ptr(440)
    &__emails
      display flex
      flex-direction column
      gap ptr(16)
    &__email
      padding ptr(5) 0
      border-bottom 1px solid BK
    &__sub-title
      {font_16_b_100}
    &__form-button
      flex-shrink 0
      width ptr(109)
    &__button
      width 50%
    &__button-wrapper
      display flex
      gap ptr(8)
