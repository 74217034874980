.phone-cert-wrapper
  display flex
  flex-direction column
  min-height 50vh
  .phone-cert
    display flex
    flex 1 1 auto
    flex-direction column
    align-items center
    justify-content center
    margin 0 auto
    width ptr(450)
    &__title
      {font_26_m_130}
      padding 0
      margin 0
    &__sub-title
      width 100%
      {font_16_b_100}
    &__data
      width 100%
      display flex
      flex-direction column
      gap ptr(16)
      &__title
        flex-shrink 0
        width ptr(42)
        {font_16_m_150}
      &__code
        width 100%
        &[data-requested="true"]
          display inline
        &[data-requested="false"]
          display none
      &__timer-wrapper
        flex-shrink 0
        color OG
        padding-right ptr(16)
        {font_14_m_100}
    &__form-button
      flex-shrink 0
      width ptr(125)
    &__buttons
      margin-top ptr(14)