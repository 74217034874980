
vector-editor-panel-border()
  border 1px solid GY_50
  border-radius 4px
@import "../MobileImageUpload/style.styl"
@import "../MobileTextInput/style.styl"
@import "../MobileUnlockItems/style.styl"
@import "../MobileText/style.styl"
@import "../colors.styl"
@import "../half_frame.styl"
@import "../range_slider_wrapper.styl"
@import "../right_panel.styl"
@import "../font.styl"
@import "../go_to_path_editor.styl"
@import "../strokeDash.styl"

@import "../loader.styl"
.svg_editor_stroke_dash_wrapper
  .control_box
    .operator
      height 40px
