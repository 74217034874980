.omp-need-verify-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  min-height 50vh
  .omp-need-verify
    display flex
    flex 1 1 0
    flex-direction column
    align-items center
    justify-content center
    width fit-content
    gap ptr(40)
    &__title
      align-self flex-start
      {font_26_m_130}
    &__desc
      display flex
      flex-direction column
      align-items center
      gap ptr(16)
      {font_16_m_150}
      .bold
        {font_16_b_100}
    &__buttons
      display flex
      align-items center
      gap ptr(8)
      & > span
        width ptr(221)
