.omp-layout-body__menus
  padding-bottom ptr(24)
  .omp-header__menu-buttons
    border-radius inherit
    .omp-header__menu-buttons-wrap
      gap ptr(24)
  .omp-cell__category-buttons
    display flex
    align-items center
    gap ptr(24)
  .omp-atom__header-menu-button
    {font_16_m_100}
    border none
    padding 0 0 ptr(12) 0
    border-bottom ptr(2) solid transparent
    border-radius inherit
    background-color transparent;
    white-space nowrap
  .omp-atom__header-menu-button
    &[data-active="false"]:hover
    &[data-active="false"]:active
    &[data-active="true"]
      background-color transparent
      border none
      color OG
      border-bottom ptr(2) solid OG
      font-weight 700
    &[data-active="false"]
      color BK
      border-bottom-color transparent
