#maker_frame
  &.group .maker_menu .group
  &.cv_group .maker_menu .ungroup
  &.has_lock_obj .maker_menu .unlock
    opacity 1
  &.cv_object_selected
    .maker_menu
      .lock
      .trash
      .forward
      .backward
      .flip_h
      .flip_v
      .group_left
      .group_center_h
      .group_right
      .group_top
      .group_center_v
      .group_bottom
        opacity 1
  &.has_embro
  &[data-maker_type="MASKING_TAPE"]
    .maker_menu
      .flip_h
      .flip_v
        opacity 0.3
#maker_frame
  .maker_menu
    z-index 2
    display flex
    background-color WHT
    border-radius 20px
    .list >div
      display flex
      flex-direction column
      align-items center
    .done
    .list
    .menu_down_list
      z-index 1
    .text
      text-align center
    .list
      display flex
      width 100%
    .menu_down_list
      display none
    &[data-down_list_show="true"]
      .menu_down_list
        display flex
    .list > div
    .menu_down_list > div
      span
        display block
        background-repeat no-repeat
    .done
    .list > div
    .menu_down_list > div
      &:active
        span
          background-color #ced4da !important
    .trash
    .forward
    .backward
    .flip_h
    .flip_v
    .ungroup
    .group
    .group_left
    .group_center_h
    .group_right
    .group_top
    .group_center_v
    .group_bottom
    .undo
    .reset
    .redo
    .lock
    .unlock
      opacity 0.3
    .reset span
      mp-editor-menu-refresh()
    .undo span
      mp-editor-menu-undo()
    .redo span
      mp-editor-menu-redo()
    .trash span
      mp-editor-menu-trash()
    .copy span
      mp-editor-tool-copy()
    .paste span
      background-image url(//s3.marpple.co/file/guest/2017/11/original/f_858_1510910984057_tlpwL9TKfzZKw4HlM.svg)
    .duplicate span
      mp-editor-menu-duplicate()
    .cut span
      background-image url(//s3.marpple.co/file/guest/2017/12/original/f_1465_1513843087595_gN1sxRm8RK3Tws9T7rzx.svg)
    .group span
      mp-editor-menu-grouping()
    .ungroup span
      mp-editor-menu-ungrouping()
    .flip_h span
      mp-editor-menu-flip-horizontal()
    .flip_v span
      mp-editor-menu-flip-vertical()
    .forward span
      mp-editor-menu-forward()
    .backward span
      mp-editor-menu-backward()
    .center_h span
      mp-editor-menu-align-center-horizontal()
    .center_v span
      mp-editor-menu-align-center-vertical()
    .group_left span
      mp-editor-menu-align-left()
    .group_center_h span
      mp-editor-menu-align-center-horizontal()
    .group_right span
      mp-editor-menu-align-right()
    .group_top span
      mp-editor-menu-align-top()
    .group_center_v span
      mp-editor-menu-align-center-vertical()
    .group_bottom span
      mp-editor-menu-align-bottom()
    .more_btn span
      background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6011_1539261895470_ldcN4kKeJLqN6cG3to.png)


.maker_menu
  position absolute
  left 0
  .lock
  .unlock
  .done
  .more_btn
    display none !important
  .list
    display flex
    justify-content center
    align-items center
    > div
      cursor pointer
    .pinch_btn
      display none
  //.undo
  //.trash
  //.forward
  //.group
  //.lock
  //.flip_h
  //.group_left
  //  margin-left 4%
  //.list >div
  //  &.undo
  //  &.forward
  //  &.group
  //  &.flip_h
  //  &.group_left
  //  &.group_center_h
  //  &.group_right
  //  &.group_top
  //  &.group_center_v
  //    margin-right 2%




#maker_frame
  html.all_faces_mode &
    .btn_tip
      display none
  &[redo_is_able="true"]
    .redo
      opacity 1
  &[undo_is_able="true"]
    .undo
    .reset
      opacity 1





