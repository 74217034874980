.section_header
  font-weight bold
  margin-bottom ptr(23)
.payment_type_container
  margin 0 auto
  input[type="text"]
  select
    font-size ptr(14)
    border solid ptr(1) #d5dbe0
    width 100%
    height ptr(40)
    padding ptr(8) ptr(16)
    box-sizing border-box
    display block
    background-color #ffffff
    outline none
    -webkit-appearance none
    border-radius 0
  select
    font-size ptr(14)
    -webkit-appearance none
    -moz-appearance none
    background svg-down-arrow2 no-repeat
    background-position right ptr(16) top ptr(16)
    cursor pointer
    &:active
    &:focus
      outline none
      border solid ptr(1) #000
    &:valid
      color #000
.options
  padding-top ptr(8)
  button
    text-align center
    height ptr(40)
    background-color #000
    font-size ptr(14)
    color #ffffff
    border solid ptr(1) #000
    font-weight 200
    cursor pointer
    box-sizing border-box
    padding 0
    width 100%
    border-radius ptr(40)
