// modules/Auth/LoginModal/F/Style/Pc/index.styl
.auth_login_modal
  > .don_wrapper
    width ptr(420) !important
    height fit-content !important
    padding ptr(30)
    > .body
      height fit-content !important
  .don_hide_frame
    top ptr(30) !important
  .don_wrapper
    .don_page
      .don_wrapper
        .header
          .auth_login_modal--header
            .title
              margin 0
              {font_26_m_130}
  &--header
    display flex
    justify-content space-between
    align-items center
    width 100%
  &--body
    margin-top ptr(24)
    .description
      {font_16_m_150}
    .images
      margin-top ptr(16)
      display flex
      flex-direction column
      gap ptr(12)
  &--footer
    margin-top ptr(24)
    display flex
    gap ptr(8)
    > button
      border-radius 999px
      padding ptr(12.5)
      {font_16_m_150}
      flex 1
  &--signup
    border 1px solid BK
    background-color WHT
    color BK
  &--signin
    background-color BK
    color WHT
    border none

