#maker_frame
  #marpplizer
    .shared_product_wrap
      display flex
      font-weight 500
      font-size ptr(14)
      padding ptr(16)
      margin-top ptr(32)
      gap ptr(16)
      border-radius ptr(8)
      background-color #F6F6F6
      &__expires_at
        color OG
        flex 1
      p
        margin 0
        width ptr(92)
    .selected
      border 1px solid OG
      color OG
    .fit_to_print_width
    .m_image_pattern
    .request_bg_removal
      >.head
        margin-bottom 13px !important
        cursor pointer
        .option.check_box
          display inline-block
          vertical-align middle
          width 18px
          height 18px
          margin-right 6px
          mp-editor-check-inactive()
          background-repeat no-repeat
          background-position 50% 50%
          background-size 18px
        &.checked
          .option.check_box
            mp-editor-check-active()
            background-size 18px
        .title
          vertical-align middle
          display inline-block
    .section.quantity
      .body
        display flex
        gap 8px
        height 40px
        .group_discount_guide
          button
            height 40px
            outline none
            font-size 14px
            font-weight 500
            width 136px
            border-radius 8px
            border 1px solid GY_50
            background-color #ffffff
        .qty_wrap
          display flex
          flex-grow 1
          .mp-select-product-quantity
            width 100%
            select
              width 100%
              maker-option-select-style1(11px)
          .input_wrap:not(.mp-select-product-quantity)
            width 100%
            background-color #ffffff
            border solid 1px GY_50
            border-radius 8px
            {flex-center-between}
            input.quantity
              font-size 14px
              font-weight 500
              background-color #ffffff
              text-align center
              outline none
              border none
              width 40px
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button
                -webkit-appearance none
                margin 0
            button
              outline none
              border none
              background-color #ffffff
              margin 0 10px
              cursor pointer
              width 20px
              height 20px
              &[name="plus"]
                background-position center
                background-size 12px 12px
                background-repeat no-repeat
                background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76563H6M6 5.76563H11M6 5.76563V10.7656M6 5.76563V0.765625' stroke='%23D8D8D8' stroke-linecap='round'/%3E%3C/svg%3E")
                &:active
                  background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76563H6M6 5.76563H11M6 5.76563V10.7656M6 5.76563V0.765625' stroke='%23000000' stroke-linecap='round'/%3E%3C/svg%3E")
              &[name="minus"]
                background-position center
                background-size 12px 12px
                background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76562H6H11' stroke='%23D8D8D8' stroke-linecap='round'/%3E%3C/svg%3E")
                background-repeat no-repeat
                &:active
                  background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76562H6H11' stroke='%23000000' stroke-linecap='round'/%3E%3C/svg%3E")
    .section.file-reference
      .body
        display flex
        gap 8px
        height 40px
        .input_wrap
          display flex
          align-items center
          width 100%
          background-color BG_PRODUCT
          border-radius 8px
          button[name="download"]
            outline none
            border none
            text-align left
            background-color transparent
            margin-left 16px
            width 100%
            color GY_80
            overflow hidden
            text-overflow ellipsis
            text-wrap nowrap
            &[url='']
              cursor default
            &:not([url=''])
              &:hover
                color BK
          button[name="upload"]
            outline none
            border none
            background-color transparent
            margin 0 12px
            cursor pointer
            width 20px
            height 20px
            background-position center
            background-size 12px 12px
            background-repeat no-repeat
            background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76563H6M6 5.76563H11M6 5.76563V10.7656M6 5.76563V0.765625' stroke='%23000000' stroke-linecap='round'/%3E%3C/svg%3E")
    .section.request-memo
      .body
        display flex
        gap 8px
        height 40px
        input
          border 1px solid GY_50
          outline none
          padding 0 16px
          width 100%
          border-radius 8px
          font-weight 500
          font-size 14px
          &::placeholder
            color GY_80
    .section .footer
      margin-top 8px
      span
        color GY
        font-weight 400
        font-size 12px
    .section.dosu_option_cards
      &__error
        .option_text__body
        .color_count
        .dosu_option_cards__footer-message
          color OG_ERROR
      .body
        display flex
        gap 8px
        flex-direction column
        width 100%
        .dosu_option_card
          position relative
          display flex
          height 50px
          padding 0 16px
          align-items center
          justify-content space-between
          background-color #F6F6F6
          border-radius 8px
          .description
            display flex
            align-items center
            &.option_summary
              width calc(100% - 73px)
            &.option_color
              position absolute
              right 16px
              .color-chips
                display flex
                position relative
                gap 3px
                .color-chip
                  border-radius 100%
                  border 1.5px solid #F6F6F6
                  margin-left -12px
                  width 24px
                  height 24px
                .color_count
                  display flex
                  justify-content center
                  align-items center
                //.color_count
                //  position absolute
                //  top -3px
                //  right -3px
                //  display flex
                //  justify-content center
                //  align-items center
                //  height 14px
                //  min-width 14px
                //  border-radius 999px
                //  background-color OG
                //  .count
                //    font-size 10px
                //    font-weight 500
                //    color white

    .dosu-colors
      margin-top 8px
      .color-pickers
        display flex
        flex-wrap wrap
        gap 13.5px
        .color-picker
          display flex
          justify-content center
          cursor pointer
          border-radius 100%
          span
            box-sizing border-box
            width 24px
            height 24px
            border-radius 100%
            margin 1px
            &.too_bright
              border 1px solid #CED4DA
          &.original_tag
            span
              background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
              border none
              border-radius 0
            &.selected
              box-shadow unset
          &.selected
            box-shadow: 0 0 0 1px #ff6b00
            border none
          &.used
            &::before
              content ""
              background-color #555
              width 4px
              height 4px
              border-radius 100%
              margin-top -8px
      html[marpplizer_mode="short"] &
        .color-pickers
          gap 9.5px
        .color-pickers
          .color-picker
            span
              width 22px
              height 22px
    .m_image_pattern
      .number_wrapper
      .control.number
      .repeat
      .grid_1
      .grid_2
        border-radius 4px !important
        *
          background-color transparent !important
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button
        -webkit-appearance none
        margin 0
      &.default_pattern:not(.ready)
        .controls.range_style
        .controls.type
          display none !important
      .controls
        &.number
          z-index 2
          position relative
        &.range_style
          z-index 1
        &.type
          z-index 1
      .controls
        > .head
          margin-bottom 8px
          font-size 14px
          color color_black
        > .body
          display flex
          height 40px
          justify-content space-between
          width 100%
        &:nth-last-child(1)
          margin-bottom 0
        &.number
          >.head
            display none
          .control.locking
            width 30px
            background url(//s3.marpple.co/file/guest/2018/1/original/f_1485_1515057400983_W3nG6bCHran9JilRFB.svg) no-repeat 50% 50%
            background-size 14px
            cursor pointer
            &[locked="false"]
              background-image url(//s3.marpple.co/file/guest/2018/1/original/f_1632_1516773453472_inw5hFI0qnM5f5tXi5X2H.svg)
          .control.number
            position relative
            width 43%
            border 1px solid #dee2e6
            box-sizing border-box
            height 100%
            input
              height 100%
              background transparent
              text-align center
              font-size 14px
              border none
              width 100%
              opacity 1
              margin 0
              padding 0
            .minus
            .plus
              position absolute
              width 40px
              height 38px
            .minus
              top 0
              left 0
              background url(//s3.marpple.co/files/u_193535/2018/10/original/f_12363_1540961884802_iyGL0ruH8od3s0efb7xq.png) no-repeat 50% 50%
              background-size 10px
            .plus
              top 0
              right 0
              background url(//s3.marpple.co/files/u_193535/2018/10/original/f_12364_1540961884802_t3nsH1aBxUTv3G9RI5bv.png) no-repeat 50% 50%
              background-size 10px
        &.range_style
          >.head
            margin-bottom -7px !important
          .control.number
            border 1px solid #dee2e6
            flex-basis 80px
            height 40px
          .value
            right 20px
            font-size 14px
            padding 0
            text-align center
            background-color #fff
            width 100%
            height 100%
            box-shadow none
            border none
          .control.range
            width 80%
            position relative
          .control.number
            margin-left 15px
        &.type
          .control.type.btn
            button
              width 31%
              padding 0
              font-size 14px
              outline none
              background-color #ffffff
              border solid 1px #d5dbe0
            button.selected
              color OG
  &[data-is_digital_product="true"]
    #marpplizer
      .product_editor
        .color.section
        .price
          display none
  &[data-is_vector="true"]
    #marpplizer
      #to_vector_editor
        display block !important
    .btn_tip
    .decoration_menu_for_pc
    .maker_menu
      display none
      html#dream_factory &
        display block
  &[data-is_vector="true"]
    #marpplizer
      #to_save_changes
        display none !important
      .shared_product_editing_buttons
        display flex
        justify-content space-between
        gap ptr(8)
        padding-bottom ptr(12)
        > button
          padding ptr(6) ptr(16)
          flex 1 0 0
          border-radius 999px
          height ptr(50)
          font-size ptr(16)
        #to_vector_editor
          border none !important
        #to_save_changes_vector
          border 1px solid #D8D8D8
          color #D8D8D8
          background WHT
          &[can_save="false"]
            pointer-events none
            border 1px solid #D8D8D8
            color #D8D8D8
          &[can_save="true"]
            pointer-events auto
            border 1px solid BK
            color BK
          &[is_saved="true"]
            color BK
            &:after
              content '' !important
        html[marpplizer_mode="short"] &
          #to_save_changes_vector
            &:after
              content '변경사항 저장'
        html[marpplizer_mode="basic"] &
          #to_save_changes_vector
            &:after
              content '변경사항 저장하기'

  &[data-is_vector="false"]
    #marpplizer
      .shared_product_editing_buttons
        display none

  #marpplizer
    color BK
    font-size 14px
    position absolute
    right 0
    top 0
    bottom 40px
    box-sizing border-box
    background WHT
    overflow hidden
    border-radius 20px
    .general_option_buttons
      #to_share
        //display none
        cursor pointer
        margin-bottom 12px
        background WHT
        //position relative
        //text-align center
        color BK
        border 1px solid BK
      #to_save_changes
        cursor pointer
        margin-bottom 12px
        background WHT
        display flex
        gap ptr(8)
        &[can_save="false"]
          pointer-events none
          border 1px solid #D8D8D8
          color #D8D8D8
        &[can_save="true"]
          pointer-events auto
          border 1px solid BK
          color BK
        &[is_saved="true"]
          color BK

    //.general_option_buttons
    //  position absolute
    //  bottom 0px
    //  left 50%
    //  transform translateX(-50%)
    //  > *:not(.condition_agree_text)
    //    height 50px
    //  > button
    //    border-radius 100px
    //    cursor pointer
    //    border none
    //    width 100%
    //    font-size 16px
    //    text-align center
    //    color WHT
    //    font-weight 500
    //    background-color BK
    //    display flex
    //    justify-content center
    //    align-items center
    //  #to_vector_editor
    //    display none
    //    cursor pointer
    //    margin-bottom 16px
    //    background OG
    //    position relative
    //    text-align center
    //    color #fff
    //  .border_box
    //    position absolute
    //    top 10px
    //    left 7px
    //    right 7px
    //    height 36px
    //    .border
    //      height 32px
    //      opacity 0.3
    //      border dashed 1px #ffffff
    //    >.deco
    //      width 3px
    //      height 3px
    //      background-color OG
    //      border solid 1px #ffffff
    //      position absolute
    //      opacity 0.6
    //      border-radius 20%
    //    .text
    //      position absolute
    //      height 17px
    //      top 50%
    //      left 50%
    //      transform translateX(-50%) translateY(-50%)
    //    .one
    //      top -1px
    //      left -1px
    //    .two
    //      top -1px
    //      right -1px
    //    .three
    //      bottom -1px
    //      left -1px
    //    .four
    //      bottom -1px
    //      right -1px
    //  html[marpplizer_mode="short"] &
    //    width 81%
    //    > *:not(.condition_agree_text)
    //      height 40px
    //    .border_box
    //      position absolute
    //      top 5px
    //      left 10px
    //      right 10px
    //      height 30px
    //      .border
    //        height 28px
    //      .one
    //        top -1px
    //        left -1px
    //      .two
    //        top -1px
    //        right -1px
    //      .three
    //        bottom -1px
    //        left -1px
    //      .four
    //        bottom -1px
    //        right -1px
    .activity
      overflow hidden
    .marpplizer_footer
      z-index 2
    .marpplizer_wrapper
      z-index 1
      .activity
        z-index 2
    .maker_range_wrapper
      >.body
        height 40px
        display flex
      .range_wrapper
        flex-grow 76
      .blank
        flex-grow 4
      .number_wrapper
        flex-grow 20
    .head
      font-size 14px
      margin-bottom 16px
      font-weight 500
    .maker_range_wrapper
      .head
        margin-bottom 10px
    .iscroll_wrapper
      box-sizing border-box
      overflow hidden
    >.iscroll_wrapper
      height 100%
    .marpplizer_back_btn_styl
      position absolute
      top 0
      left 0
      display block
      padding 0
      border none
      width 19px
      height 19px
      cursor pointer
      background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png) no-repeat
      background-size 19px auto
      background-position 50%
      &:focus
        outline none
      &:active
        background-color #ced4da
    html#dream_factory &
      padding-bottom 0
    .marpplizer_wrapper
      >.home
        background #fff
      .main_head
        height 24px
        font-size 20px
        >.option
          position relative
          top -23px
      .activity
        opacity 1
        height: 100%
        position absolute
        background WHT
        top 0
        border-radius 20px
        .section
          padding-bottom 32px
          &.size
            padding-bottom 32px
        .cv_text_editor
          .section
            padding-bottom 32px
          .controls
            padding-bottom 16px
            &.number
            &.translateX
              padding-bottom 32px
        .cv_image_editor
          .controls
            padding-bottom 32px
            width 100%
          .image_editor
          .marstagram_editor
            display block
            margin-top 30px
            .title
              font-weight bold
              height 17px
              font-size 14px
            .buttons
              display flex
              justify-content space-between
              align-content center
              margin 13px 0 8px 0
              button
                display flex
                padding 0
                align-items center
                width 116px
                height 40px
                background-color #fff
                border solid 1px #d5dbe0
              img
                width 16px
                height 16px
                margin-left 8px
                object-fit contain
              span
                flex-grow 1
                width 25px
                height 16px
            html[marpplizer_mode="short"] &
              .buttons
                flex-flow wrap
                button
                  width 80px
                  height 40px
                  margin-bottom 8px
                  &:nth-child(2n-1)
                    margin-right 8px
    .product_price_wrapper
      font-size 20px
    .activity >.product_editor
        position relative
        .color.section
          >.head
            span
              margin-left 7px
              font-weight normal
              &:before
                content "-"
                margin-right 5px
        .main_head
          display none
        .product.section
          >.body
            font-size 20px
      .activity >.cv_background
        .main_head
          margin-bottom 40px
      .activity >.cv_image_editor
        .main_head
          margin-bottom 40px
        > div:not(.main_head)
          >.body >.head
            margin-bottom 8px
        .change_img
          display none
          html[collabo_type="line"] &
            display block
          >button
            border 1px solid #d5dbe0
            background #fff
            padding 4px 9px
            &:focus
            &:hover
              background #000
              color #fff
        >.section
          &.image_color >.body
            >.head
              display flex
              .color_name
                font-weight normal
                margin-left 4px
                html[lang="kr"] &
                  &:before
                    content "-"
                    margin-right 4px
            .image_color_item
              display inline-block
              position relative
              cursor pointer
              width 40px
              margin-right 8px
              height 40px
              padding 2px
              box-sizing border-box
              background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_13771_1542338784965_Nb0oHE4U7Ox4GuPzLlk.png) no-repeat 50% 50%
              background-size 40px
              &:nth-of-type(8n)
                margin-right 0
              &.selected
                border 1px solid BK
              img
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                margin auto
                max-width 80%
                max-height 80%
      .activity >.cv_text_editor
        .controls
          width 100%
        .main_head
          margin-bottom 32px
        .press_color_list
          span.color_name
            font-weight 400
            width auto
            padding-left 7px
            &:before
              content "-"
              margin-right 5px
              font-weight 200
              width 5px
              height 3px
      .activity >.size_face
        .selected
          border 1px solid blue
        [data-checked]
          display none
        [data-checked = "true"]
          display block
        .main_head
          margin-bottom 32px
        .marpplizer_flex_v
          display flex
          justify-content space-around
          margin-bottom 16px
#maker_frame:not(.from_library)
  #marpplizer
    .marpplizer_wrapper
      .activity
        .cv_image_editor
          .marstagram_editor
            display none

.__group_discount_guide
  >.don_wrapper
    border-radius 16px
    width unset
    padding 0 24px
    display flex
    flex-direction column
    justify-content center
    height 423px
    html[lang="en"] &
      height 460px
    >.header
      position absolute
      top 0
      display flex
      align-items center
      height 72px
      border-bottom 1px solid BK
      justify-content space-between
      .title
        font-size 20px
        font-weight 500
      button.don_hide_frame
        position unset
    >.body
      .don_page
        height auto
      .don_tab
        margin-top 72px
        .price_range_guide_window
          display flex
          flex-direction column
          color BK
          .body
            display flex
            flex-direction column
            width 100%
            overflow scroll
            table
              width 100%
              border-collapse collapse
              font-size 16px
              th, td
                text-align center
                font-weight 500
                border-bottom 1px solid GY_50
                height 60px
                min-width 70px
                box-sizing border-box
                &.min
                  color OG
                  .best_tag
                    position absolute
                    background-color OG
                    border-radius 999px
                    top -12px
                    left calc(50% - 20px)
                    padding 4px 8px
                    color white
                    text-align center
                    font-size 10px
              th:first-child, td:first-child
                text-align left
                font-weight 500
                min-width 100px
                width 100px
                html[lang="en"] &
                  width 145px
                  white-space nowrap
          .notice
            font-size 14px
            table
              border-spacing 0 8px
            &__title
              line-height 140%
              height 20px
              width 88px
              font-weight 500
              vertical-align top
              html[lang="en"] &
                width 156px
              &__req-consulting__content
                > div
                  height 36px
                  background-color #E8E8E8
                  border-radius 999px
                  display flex
                  justify-content center
                  align-items center
                  gap 8px
                  margin-top 4px
                  cursor pointer
              &__consulting-circle
                width 104px
                html[lang="en"] &
                  width 124px
                html[lang="jp"] &
                  width 164px
              &__email-circle
                  width 175px
                  > a
                    display flex
                    gap 8px
                    align-items center
              &__delivery
              &__req-consulting
                vertical-align middle
                font-weight 500
                &__content
                  display flex
                  gap 8px
                  font-weight 500

