.omp-layout-body__footer
  position relative
  margin-top ptr(40)

#footer
  padding 0 ptr(40) ptr(40)
  background-color WHT
  a.ftc
  a.mail
    margin-left ptr(4)
    text-decoration underline
  .bar:after
    content ''
    margin 0 ptr(8)
  a:hover
    text-decoration underline
  .footer_wrap
    padding ptr(40) 0
    position relative
    >div
      vertical-align top
      display inline-block
    .logo
      position absolute
      top ptr(40)
      left 0
      height ptr(20)
      img
        height 100%

    .countries
      position absolute
      top ptr(80)
      left 0
      color BK
      &:hover
        .selected:after
          background ICON_DROP_DOWN
          background-repeat no-repeat
          background-size 100%
          width ptr(9)
          height ptr(9)
        ul
          display block
          position absolute
          top ptr(18)
          left 0
          right 0
          padding ptr(7) 0
          li
            padding ptr(7) 0
            a
              color BK
    .footer_menus
      padding-right ptr(32)
      display grid
      align-items flex-start
      justify-content flex-end
      grid-template-columns ptr(280) ptr(280) ptr(280) ptr(280)
      grid-gap ptr(20)

      .footer_menu
        h4
          margin 0 0 ptr(12) 0
          color BK
          { font_16_m_100 }
        ul
          padding 0
          margin 0
          list-style none
          li
            { font_14_m_100 }
            margin-bottom ptr(12)
            span
            a
              color BK
              &.mail
                text-decoration underline
            span
              cursor pointer
              &:hover
              &:active
                text-decoration underline

        &.contact_us
          .phone_number
            line-height 1
            margin ptr(16) 0 ptr(8)
            { font_26_m_100 }
            a
              color BK
          li
            color BK
        &.follow
          width ptr(100)
          ul
            margin 0
            padding 0
            list-style none
            li
              display inline-block
              vertical-align top
              margin 0 ptr(16) ptr(16) 0
              a
                display block
                width ptr(20)
                height ptr(20)
  .footer_wrap2
    position relative
    { font_14_m_100 }
    color BK
    &:after
      content ''
      display block
      clear both
    .policy
      float left
      >.header
        margin-bottom ptr(16)
        { font_12_m_140 }
      >.body
        >div
          margin ptr(8px) 0
      a
        { font_12_m_100 }
        color BK
        &.cs_tel
          margin-left ptr(3)
    &[lang="en"]
    &[lang="jp"]
      .policy
        float none
      .policy2
        float none
      .copy_right
        float none

    .policy2
      position relative
      margin-top ptr(14)
      float right
      height ptr(40)
      img
        height ptr(40)
      a.escrow
        position absolute
        top 0
        right ptr(54)
        width ptr(40)
        height ptr(40)
    .copy_right
      float right
    .logos
      position absolute
      bottom ptr(8)
      right 0
      display flex
      justify-content center
      img
        height ptr(40)
      .for_the_planet
        width ptr(96)
        height ptr(40)
        margin-right ptr(12)
      .iflogo
        width ptr(82)
        height ptr(40)
        img
          height 100%

/*
추가 사유
앱 연령 제한이 4+로 설정되어 있었는데, SNS 링크 이동이 있으면 12+로 강제해야 한다고 심사 거절 당함
iOS 앱에서만 숨김 처리
*/
html[is_marpple_app="true"][is_ios="true"]
  .omp-layout-body__footer
    .footer_menu.follow
      display none
