@import './search_result.styl'

.omp-search
  display flex
  flex-direction column

  /* 추천 키워드 */
  &__keyword
    margin-bottom ptr(60)
  &__keyword-title
    {font_16_m_100}
  &__keyword-btns
    display flex
    gap ptr(8)
    margin-top ptr(16)
    flex-wrap wrap
    .omp-atom__text-button
      height ptr(40)

  /* 카테고리 프리뷰 */
  &__category-preview
    display flex
    flex-direction column
    gap ptr(16)
  &__category-preview__title
    {font_26_m_100}
  &__category-preview__wrap
    display grid
    grid-template-columns repeat(3, minmax(0, 1fr))
    grid-column-gap ptr(8)
    grid-row-gap ptr(16)
    .omp-cell__category-list-thumbnail
      width 100%

  /* 카테고리 상세 */
  &__category-title
    {font_26_m_100}
    display inline-block
    margin-bottom ptr(16)
  &__categories-wrap
    display flex
    flex-direction column
    gap ptr(60)
  &__category-body
    width 100%
    display flex
    gap ptr(8)
  &__category-all
    width ptr(250)
    height ptr(250)
  &__category-subs
    display grid
    grid-template-columns repeat(6, minmax(0, 1fr))
    grid-column-gap ptr(8)
    grid-row-gap ptr(16)
    width 100%
    .omp-cell__category
      width 100%
      &-thumbnail
        width 100%
        height 100%
