.omp-atom
  &__warning-input-wrapper
    display flex
    flex-direction column
    gap ptr(8)
    background WHT
  &__warning-input
    color OG
    {font_14_m_140}
    &[data-visible="false"]
      display none
  &__input-wrapper
    display flex
    align-items center
    gap ptr(24)
    border-bottom 1px solid BK
  &__input
    &::placeholder
      color GY_80
    outline none !important
    padding 0
    width 100%
    height 100%
    min-width ptr(200)
    border 1px solid BK
    border-radius ptr(50)
    box-sizing border-box
    &[data-style_height="40"]
      min-height ptr(40)
    &[data-style_height="44"]
      min-height ptr(44)
    &[data-style_height="50"]
      min-height ptr(50)
    &[data-style_text_size="normal"]
      {font_16_m_100}
    &[data-style_text_size="small"]
      {font_14_r_140}
    &[data-style_border="false"]
      border-radius 0
      border none
    &[data-style_underline="true"]
      border-radius 0
      border-bottom 1px solid BK
      border-top none
      border-left none
      border-right none

  &__input-reset
    resetButton()
    icon()
    position absolute
    top 50%
    right ptr(16)
    width ptr(20)
    height ptr(20)
    transform translateY(-50%)
    background-color WHT
    background-image ICON_X
    padding-left ptr(16)
    cursor pointer
    &--hidden
      display none

  &__input-auto-complete
    &--active
      border-bottom none
      border-radius ptr(16) ptr(16) 0 0

  &__input-radio
    display flex
    align-items center
    cursor pointer
    &__icon-wrap
      display inline-block
      width ptr(20)
      height ptr(20)
      padding ptr(8)
      position relative
      margin-left ptr(-8)
    &__input
      width ptr(20)
      height ptr(20)
      margin 0
      padding ptr(8)
      transform translate(-50%, -50%)
      opacity 0
    &__input-label
      {font_16_r_150}
      width 100%
      text-overflow ellipsis
      text-wrap nowrap
      overflow hidden
