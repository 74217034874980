.vector-editor__shape-library
  > .don_wrapper
    display flex
    flex-direction column
    > .body
      order 2
      height 0
      flex-grow 1
    > .header
      order 1
      flex-shrink 0
      .frame_header
        position relative

        width 100%
        height 100%
        display flex
        align-items center
        justify-content center
        box-sizing border-box
        padding-top 16px
        border-bottom 1px solid #d5dbe0
        .title
          font-size 20px
        .close_frame
          cursor pointer
          position absolute
          top 24px
          right 24px
          width 25px
          height 25px
          background-color transparent
          background-repeat no-repeat
          background-image url(//s3.marpple.co/files/u_193535/2023/7/original/94711399c4ea8b2ee1e485d5298ccedcc3e03fd11.svg)
          background-size 25px 25px
          background-position center center
          margin 0
          padding 0
          border 0 none
          border-radius 8px 8px 0 0
  .don_page
    width 100%
    height 100%
    > .don_wrapper
      width 100%
      height 100%
      > .body
        width 100%
        height 100%
  .don_tab
    width 100%
    height 100%
    > .don_wrapper
      width 100%
      height 100%
      .infi_container
        width 100%
        height 100%
        padding 40px 48px
        box-sizing border-box
        .infi_item
          position relative
          background-color #FFFFFF
          &::after
            content ''
            display block
            padding-bottom 100%
          .infi_item_outer_container
            position absolute
            width 100%
            height 100%
            box-sizing border-box
            padding 20px
            .infi_item_inner_container
              position relative
              width 100%
              height 100%
              margin 0
              padding 0
              overflow hidden
              border-radius 4px
              border solid 1px #d5dbe0
              background-color transparent
              background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/img-layer-pattern@2x.png")
              background-repeat repeat
              background-size 8px 8px
              background-position center center
              cursor pointer
              &:active
                border-color OG
