.omp-atom__input-number-plus-minus-button
  display flex
  justify-content space-between
  width ptr(106)
  height ptr(28)
  border solid 1px GY_50
  border-radius ptr(4)
  box-sizing border-box
  input.quantity
    {font_14_r_140}
    width ptr(50)
    border none
    text-align center
    -moz-appearance textfield !important
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
      -webkit-appearance none
  button[data-operator]
    padding ptr(8)
    width ptr(28)
    height ptr(28)
    border none
    margin none
    background none
  button[data-operator="-"]
    background-position center
    background-size ptr(12) ptr(12)
    background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76562H6H11' stroke='%23D8D8D8' stroke-linecap='round'/%3E%3C/svg%3E")
    background-repeat no-repeat
    &:active
      background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76562H6H11' stroke='%23000000' stroke-linecap='round'/%3E%3C/svg%3E")
  button[data-operator="+"]
    background-position center
    background-size ptr(12) ptr(12)
    background-repeat no-repeat
    background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76563H6M6 5.76563H11M6 5.76563V10.7656M6 5.76563V0.765625' stroke='%23D8D8D8' stroke-linecap='round'/%3E%3C/svg%3E")
    &:active
      background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76563H6M6 5.76563H11M6 5.76563V10.7656M6 5.76563V0.765625' stroke='%23000000' stroke-linecap='round'/%3E%3C/svg%3E")
