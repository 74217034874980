
.omp-left
  &__wrap
    overflow clip
  &__menu
    margin 0 0 ptr(26)
    padding 0
    list-style none
    { font_20_m_100 }
  &__menu-li:not(:first-child)
    margin-top ptr(16)
  &__menu-li[data-active="true"]
    { font_20_b_100 }
  &__menu2
    margin 0 0 ptr(26)
    padding 0
    list-style none
    display flex
    flex-direction column
    gap ptr(12)
    { font_14_m_100 }
  &__menu2-li[data-active="true"]
    { font_14_b_100 }
  &__menu2-li[data-action]
    cursor pointer


.omp-layout-sidebar
  &[data-left_is_show="false"]
    .omp-layout-sidebar__open
      display block
  &[data-left_is_show="true"]
    .omp-layout-left
      display block
    .omp-layout-sidebar__close
      display block
  &__open
    display none
  &__btn-open
    position fixed
    top 50%
    left ptr(40px)
    width ptr(50px)
    height ptr(50px)
    cursor pointer
    z-index 1
    background-color BK
    border-radius ptr(999)
    border 0 none
  &__btn-open-img
    width ptr(20px)
    height ptr(20px)
    vertical-align top
  &__close
    display none
  &__btn-close
    position fixed
    top 50%
    left ptr(225px)
    width ptr(50px)
    height ptr(50px)
    cursor pointer
    z-index 1
    background-color BK
    border-radius ptr(999)
    border 0 none
  &__btn-close-img
    width ptr(18px)
    height ptr(18px)
    vertical-align top

.omp-layout-left
  display none
  position fixed
  top 0
  left 0
  bottom 0
  width ptr(250px)
  background-color #fff
  &__logo
    margin ptr(39px) ptr(40px) 0
  &__logo-h1
    margin 0
    padding 0
  &__logo-img
    height ptr(20px)
    vertical-align top
  &__menu
    margin ptr(20xp) ptr(40px)
