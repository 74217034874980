body.maker_canvas_editor_v1
  @import "../../../../F/Style/share.styl"

  @import "../../../../../Components/Atom/F/Style/Pc/index.styl";

  @import "../../../../../ConditionAgreeText/F/Style/Pc/index.styl";
  @import "../../../../../ConditionPopup/F/Style/Pc/index.styl";
  @import "../../../../../ConditionTooltip/F/Style/Pc/index.styl";
  @import "../../../../../../Maker/F/Marpplizer/marpplizer.styl"
  .btn_disabled
    background var(--GY-50, #D8D8D8) !important
    color var(--WHT, #fff) !important
    cursor not-allowed !important
