html.mp_maker_not_ready &
  .mp_maker
  .image_upload_desc
  .loc_opt_desc
  #for_detail_change
  .fake_mp_maker
  .footer_buttons
    opacity 0 !important
    transition opacity 0s !important
.don_page.__text_temp_transparent_bar
  .tab_button
    border-bottom 1px solid transparent !important

html.maker_editor_mode_for_canvas &
  .fake_mp_maker
    .left
    .right
    .dots
      display none !important
html.maker_editor_mode_for_canvas &
  user-select none
  -webkit-user-select none
.maker_page
  #to_direct_custom
    display none !important
.mp_maker
  .add_sticker_custom
    display none
  .fit_to_print_width
    display none !important
  &[data-need_fit_to_print_width="true"]
    #marpplizer
      .fit_to_print_width
        display block !important
    .btm_menus
      .fit_to_print_width
        display inline-block !important
  &[data-maker_type="MASKING_TAPE"]
    .main_icon.product_icon
    .open_chooser
    .request_bg_removal
      display none !important
  &[data-is_carved_phonecase_product="true"]
    .open_chooser
    .main_icon.product_icon
      display none
    .decoration_menu
      .add_img, .add_sticker2
        display none
    .decoration_menu_for_pc
      > *
        display none
      >.add_text
        display flex
    .maker_menu
      .list
      .menu_down_list
        > div
          opacity 0.3 !important
    #marpplizer
      .m_image_pattern
      .press_color_list
      .maker-color-picker
      .select_font_and_style .radio_box
      .cv_text_editor .input_number_row
        display none
  &[data-is_embroidery_app="true"]
    #marpple_layer
      .options
        display none
    .main_icon.product_icon
      display none
    .decoration_menu_for_pc
      > *
        display none
      >.add_text
      >.open_chooser
        display block
    #marpplizer
      .m_image_pattern
      .select_font_and_style .radio_box
      .select_font_and_style .style
      .cv_text_editor .input_number_row
        display none
    .maker_menu
      .list
      .menu_down_list
        > *:not(.trash)
          opacity 0.3 !important
    #marpplizer
      .m_image_pattern
      .cv_text_editor .input_number_row
        display none
  &[is_we_base_products="true"]
  &[data-is_no_request_bg_removal="true"]
    .request_bg_removal
      display none !important
  .canvas_zoom_wrapper
    will-change transform
  .canvas_container
    .canvas-container
    .canvas_zoom_wrapper
    canvas
      width 100% !important
      height 100% !important
  &.need_to_check_prev_cv_bpcf
    .click_for_prev_cv_bpcf
      .icon
        border 1px solid red
html.maker_page &
  button, div, span
    touch-action manipulation
  .tip_lock
    display none
  .fetch_warning
  .tip_zoom
  .tip_lock
    font-size 14px
    color OG
    text-decoration underline
    z-index 2
    text-align center
    position absolute
    white-space nowrap
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    border-radius 15px
    bottom 42px
    padding 5px 25px
    left 50%
    transform translateX(-50%)
  .fetch_warning
    span
      margin-left 2px
    display none
  .mp_maker.has_embro:not(.group)
    .fetch_warning
      display block
  //.rotation_info
  //  width 50px
  //  .width
  //    display none
  //  .rotate:after
  //    content '˚' !important
  //    margin-right 0
  #body
    opacity 1
    transition opacity 0.2s
  .footer_buttons
    z-index 2
  .mp_product_detail
    z-index 1
    > .body
      .fake_mp_maker
      .detail_wrapper
        z-index 2
      #maker_frame
        z-index 1
  &:not(.maker_editor_mode)
    #footer
      z-index 1
  .mp_maker
    >.buying
    >#maker
      z-index 1
  #maker
    >div
      z-index 1
  .board
    >.canvass
      z-index 1
      margin auto
    .special_message
    .fetch_warning
    .tip_lock
    .tip_zoom
      z-index 2
    >.prev_product_matcher >.bottom
      z-index 3
    >.decoration_menu_for_pc
    >#marpple_layer
      z-index 2
  .mp_maker[data-is_not_image_task="true"]
    span.creator_del_ai
      display none
  .mp_maker[is_shade="true"]
    .circle_icon.add_sticker_line
      display none !important
  .special_message
    display none
    text-align center
    position absolute
    white-space nowrap
    border-radius 15px
    top 42px
    padding 5px 25px
    left 50%
    transform translateX(-50%)
    color OG
    text-decoration underline
    font-size 14px
  .dosu_warning
    z-index 2 !important
    width 330px
    text-align center
    border-radius 12px
    background-color OG
    padding 12px
    color BK
    font-size 16px
    font-weight 500
    position absolute
    top 120px
    left calc(50% - 424px)
html[marpplizer_mode="short"] &
  .dosu_warning
    left calc(50% - 336px)

html.all_faces_mode.maker_page &
  .dosu_warning
    display none

.image_upload_desc
.loc_opt_desc
  display none
  position absolute
  min-height 224px
  top 650px
  z-index 3
  width 200px
  padding 18px
  color color_black
  background-color #fff
  box-sizing border-box
  box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.1)
  border-radius 12px
  .arrow
    position absolute
    top 34px
    right -23px
    width 37px
    height 17px
    -webkit-transform rotate(90deg)
    transform rotate(90deg)
    background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12429_1541148267479_wlBszQ4KTt0HO3lAg1U.png") no-repeat
  h3
    margin 0
    padding 0
    font-size 14px
    font-weight bold
  .txt1
    margin 9px 0 0
    padding 0
    font-size 12px
    font-weight 500
    line-height 1.67
    word-spacing -1px
  .txt2
    margin 6px 0 0
    padding 0
    font-size 12px
    line-height 1.67
    word-spacing -1px
    a
      color color_black
      text-decoration underline
.loc_opt_desc
  min-height unset
  white-space pre-line
  .arrow
    top 130px

#maker_frame[faces_length="1"]
  .select_face
    display none !important

.mp_maker
.tip_box
  .pinch_zoom
    position absolute
    top 0
    left 0
    right 0
    bottom 0
  .zoom_info
    position absolute
    top 0
    left 0
    right 0
    z-index 2
    bottom 0
    display none
    html.maker_zoomed &
      display block
    input
      position absolute
      top 50px
      left 50%
      transform translateX(-50%)
  .for_select_faces
    position absolute
    left 0
    top 0
    right 0
    //visibility hidden
    margin 0 auto
    .square
      position absolute
      .text
        display flex
        position absolute
        width 100px
        left 50%
        transform translateX(-50%)
        justify-content center
  .select_face
    position absolute
    border-radius 100px
    transition opacity 0.2s
    text-align center
    > .body
      display flex
      height 26px
      padding 8px
      background WHT
      border-radius 30px
      > div
        display inline-block
        height 26px
        text-align center
        line-height 28px
        font-size 12px
        vertical-align middle
        cursor default
        &:not(.selected)
          cursor pointer
      .selected_face
      .other_faces
        .wrapper
          position absolute
          width 100%
          height 100%
          top 0
          left 0
      .selected_face
        position relative
        margin-right -6px
        padding 0 12px
        display inline-block
      >.selected
        background-color BK
        border-radius 30px
      .selected_face.selected
        color WHT
      .other_faces
        position relative
        width 48px
        display inline-block
        .square
          width 4px
          height 4px
          background-color BK
          position absolute
        .square:nth-child(1)
          top 8px
          left 25px
        .square:nth-child(2)
          top 8px
          right 25px
        .square:nth-child(3)
          bottom 8px
          left 25px
        .square:nth-child(4)
          bottom 8px
          right 25px
      .other_faces.selected
        .square
          background-color WHT !important

.mp_maker
  .canvas_container
    position absolute
    left 0
    right 0
    top 0
    margin auto
    transform-origin 50% 50%
    .canvas_zoom_wrapper
      margin auto
    .cross
      position absolute
      margin auto auto
    .horizontal
      height 100%
      border-right 2px dotted #ccc
      opacity 0.6
      display none
    .vertical
      display none
      width 100%
      opacity 0.6
      border-top 2px dotted #ccc
  .canvass
    canvas
      transform-origin 0 0
  /*z-index 100*/
  #maker
    position absolute
    top 0
    right 0
    bottom 0
    left 0
  ul
    list-style-type none
  .board
    position absolute
    left 0
    top 0
    right 0
    bottom 0
    .canvas-container
      margin 0 auto
    .canvas_container
      .line
        position absolute
        border dashed 1px #b7c1cc
        z-index -1
        display none
        margin-left 0
        border-radius 40px
    .only_rotation_info
    .rotation_info
    .width_info
      position absolute
      opacity 0
      display inline-block
      z-index 10
      padding 3px 0 3px
      border-radius 4px
      background-color rgba(0, 0, 0, 0.7)
      font-size 10px
      color #ffffff
      html.maker_zoomed &
        position fixed !important
        top auto !important
        left 0 !important
        right 0 !important
        bottom 100px !important
        margin 0 auto !important
    .rotation_info
      .from_start
        display none
    .only_rotation_info
      width 50px
      text-align center
      .rotate
        &:after
          content '˚'
    .rotation_info
      width 111px
      text-align center
      .rotate
        position relative
        display inline-block
        &:after
          content '˚ /'
          margin-right 4px
      .width
        display inline-block
        &:after
          content ' cm'
    .width_info
      text-align center
      width 100px
      &:before
        display none
        content '크기 '
      &:after
        position relative
        right auto
        content ' cm'
    >.msg.lock
      display none


.don_frame[frame_name="mp.maker.prev_product_matcher"]
  display none !important



#maker_frame
  &.pc_canvas_change_ing
    .canvass
      canvas
        opacity 0
  .prev_product_matcher
    display none
    .new_bp_name
      left 0
      right 0
      height 40px
      line-height 42px
      font-size 14px
      text-align center
    .bottom
      bottom 40px
      left 0
      right 0
      box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.1)
      background #fff
      text-align center
      border-radius 20px
      .header
        height 20px
        font-size 14px
        font-weight 600
        line-height 20px
        display inline-block
        padding-left (18+8)px
        background url(//s3.marpple.co/files/u_18052/2018/9/original/f_5927_1538118982436_p9u1o8koPb6CLeu8bSA5Q.png) no-repeat 0 0
        background-size 18px 18px
      .tmp_designs
        display flex
        justify-content center
        .design
          display inline-block
          position relative
          margin 0 4px
          border 1px solid #fff
          border-radius 4px
          background #fff url(//s3.marpple.co/files/u_193535/2018/10/original/f_12365_1540966899216_E1asmUEv3r5f6q5v9LlDA.png) no-repeat top left
          img
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            margin auto
            max-width 90%
            max-height 90%
          button
            position absolute
            top -5px
            right -5px
          &.selected
            background #fff
            box-shadow 0 2px 10px 0 #b2d7ff
            border 1px solid OG
      .options
        display flex
        padding 0 32px
        button
          text-align center
          font-size 14px
          border 1px solid BK
          border-radius 100px
        .cancel
          margin-right 8px
          color BK
          background-color WHT
        .done
          background-color BK
          color #ffffff


.don_frame[frame_name="mp.maker.fake.mobile"]
  display none !important

.for_select_faces
  display none
  z-index -1
  html.front_blank_show &
    display block
  html.front_blank_zIndex &
    z-index 3
html.maker_page.all_faces_mode &
  #maker
    .canvas_container.selected
      z-index 2
    .for_select_faces
      margin 0 auto
      .text
        .face_name
          font-size 14px
          position relative
        img
          position absolute
          width 10px
          top 5px
          left -14px
          display none
      .square.selected
        .text
          .face_name
            color OG !important
            &:before
              content ''
              background-image url(//s3.marpple.co/files/u_193535/2023/7/original/27eac89279ba7e1fba8bf5fea293642b84495f391.svg)
              background-size 20px 20px
              width 20px
              height 20px
              position absolute
              left -22px
              top -1px
      &.front_blank
        .square
          cursor pointer
          background transparent !important
          .text
            bottom 0
          &.dragenter
            .text .face_name
              color OG
              img
                display block
.maker-color-picker
  display flex
  flex-direction column
  justify-content center
  &__title
    text-align center
  &__list
    display flex
    flex-wrap wrap
  &__item
    display flex
    justify-content center
    cursor pointer
    border-radius 100%
    border 1px solid transparent
    margin 0 12px 6px 0
    html[marpplizer_mode="short"] &
      margin 0 6px 4px 0
    span
      width 24px
      height 24px
      html[marpplizer_mode="short"] &
        width 22px
        height 22px
      box-sizing border-box
      border-radius 100%
      margin 1px
      border 1px solid transparent
      &.too_bright
        border 1px solid #CED4DA
    &.original_tag
      span
        background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
        border none
        border-radius 0
      &.selected
        box-shadow unset
    &.selected
      border 1px solid #ff6b00
