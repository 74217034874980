.don_page
  > .don_wrapper
    > .header
      .title
        padding ptr(25) 0
        font-size ptr(20)
        text-align center
        font-weight normal
        color #000
.page_body
  padding ptr(32)
  input
    border none
    padding 0
    margin 0
    border-bottom 1px solid BK
    {font_16_m_100}
    height ptr(32)
    margin-bottom ptr(20)
    &:last-child
      margin-bottom 0
  form
    display flex
    flex-direction column
  button
    cursor pointer
    width 100%
    height ptr(50)
    {font_14_m_100}
    font-size ptr(14)
    border-radius ptr(12)
    border 1px solid BK
    &.done
      background BK
      color WHT
    &.cancel
      color BK
      background WHT
  .option
    margin-top ptr(30)
    display grid
    grid-gap ptr(10)
    grid-template-columns 1fr 1fr
