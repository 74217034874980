// modules/OMP/Quotation/F/Style/Pc/index.styl
.don_frame[frame_name="/modules/OMP/Quotation/S/Mui/frame.js"]
  .don_wrapper
    display flex
    flex-direction column
    height ptr(1320)
    .body
      overflow unset
  .don_page
    .don_wrapper
      .header
        .title
          padding-top ptr(24)
          padding-left ptr(30)
          {font_20_m_140}
  .don_tab
    .don_wrapper
      display flex
      flex-direction column
  > *
    -webkit-text-size-adjust none
.omp_quotation
  margin-top ptr(40)
  margin-bottom auto
  overflow-y scroll
  overflow-x hidden
  height ptr(1092)
  padding 0 ptr(30) 0 ptr(30)
  box-sizing border-box
  -webkit-text-size-adjust none
  > hr
    margin-top ptr(24)
    margin-bottom 0
    border 0
    border-bottom 1px solid BK
  input
    outline none
    &:focus
      border-color BK
  &__header
    display flex
    align-items center
    justify-content space-between
  &__header-title
    {font_26_m_130}
  &__desc
    {font_14_m_140}
    margin-top ptr(16)
  &__logo
    width ptr(136)
    height ptr(16)
  &__body
    &-frame
      margin-top ptr(24)
    &-frame.horizontal
      display flex
      flex-direction row
      gap ptr(38)
      > *
        flex-basis ptr(468)
        flex-grow 1
        flex-shrink 0
      &.last
        padding-bottom ptr(13.25)
    &-subtitle
      {font_16_b_100}
      margin-bottom ptr(24)
    &-recipient-name
      display flex
      align-items baseline
      justify-content space-between

    &-recipient-input-wrapper
      display flex
      flex-direction column
      flex-grow 1
      > svg
        position absolute
        top ptr(6)
        right 0
        cursor pointer
    &-recipient-input
      width 100%
      border none
      border-bottom 1px solid GY_50
      padding 0 0 ptr(12) 0
      {font_20_m_140}
      &::placeholder
        color GY_80
      &.invalid
        color OG_ERROR
    &-dear
      {font_20_m_140}
      margin-left ptr(16)
    &-validation-message-wrapper
      display flex
      justify-content space-between
      margin-top ptr(8)
      span.len
        color BK
      > .omp_quotation__body-validation-message-text
        display flex
        align-items center
        visibility hidden
        color OG_ERROR
        {font_12_r_140}
        > svg
          margin-right ptr(4)
      &.invalid
        > .omp_quotation__body-validation-message-text
          visibility visible
        > .omp_quotation__body-validation-message
          > span.len
            color OG_ERROR
    &-validation-message
      {font_12_r_140}
      color GY
    &-contacts
      display flex
      flex-direction column
      gap ptr(12)
      margin-top ptr(31)
      &:has(input)
        gap ptr(24)
    &-contacts-row
      display flex
      align-items center
    &-contacts-label
      flex-basis ptr(160)
      {font_14_r_140}
    &-contacts-value
      {font_14_m_140}
      &:has(input)
        flex-grow 1
      > input
        width 100%
        border none
        border-bottom 1px solid GY_50
        padding-bottom ptr(8)
        {font_14_m_140}
    &-supplier-infos
      display flex
      flex-direction column
      gap ptr(12)
    &-supplier-infos-row
      display flex
      align-items flex-start
    &-supplier-infos-label
      display flex
      align-items center
      flex-basis ptr(128)
      flex-shrink 0
      {font_14_r_140}
    &-supplier-infos-value
      display flex
      align-items center
      {font_14_m_140}
      > img
        width ptr(19)
        height ptr(22)
        margin-left ptr(8)
    &-details-infos
      display flex
      flex-direction column
      gap ptr(12)
      hr
        width 100%
        border 0
        border-bottom 1px solid GY_50
        margin 0
    &-details-infos-row
      display flex
      justify-content space-between
      flex-basis ptr(20)
    &-details-infos-label
      display flex
      align-items center
      flex-basis ptr(160)
      flex-shrink 0
      {font_14_r_140}
    &-details-infos-value
      display flex
      align-items center
      {font_14_m_140}
    &-total-infos
      display flex
      flex-direction column
      gap ptr(12)
      hr
        width 100%
        border 0
        border-bottom 1px solid GY_50
        margin 0
    &-total-infos-row
      display flex
      justify-content space-between
      flex-basis ptr(20)
    &-total-infos-label
      display flex
      align-items center
      flex-basis ptr(160)
      flex-shrink 0
      {font_14_r_140}
    &-total-infos-value
      display flex
      align-items center
      {font_14_m_140}
      &.total_price
        {font_26_m_100}
    &-table
      width 100%
      border none
      border-collapse collapse
      > thead
        border-top 1px solid GY_50
        border-bottom 1px solid GY_50
        > tr
          background-color BG_PRODUCT
          > th
            width 33.3%
            {font_14_m_140}
            padding ptr(15) 0
      > tbody
        > tr
          page-break-inside avoid !important
          > td
            page-break-inside avoid !important
            //height ptr(66)
            padding ptr(23) 0
            box-sizing border-box
            border-bottom 1px solid GY_50
            text-align center
            &.em
              {font_26_m_130}
            .option_item + .option_item
              margin-top ptr(8)
      + .omp_quotation__body-desc
        text-align end
        margin-top ptr(8)
      &.products
        th:not(.product_title)
          width ptr(148)
        td
          padding ptr(17) 0
          {font_14_r_140}
          &.pb-0
            padding-bottom 0
          &.pb-0
            padding-top 0
          &.size:not(.last),
          &.quantity:not(.last)
            padding-bottom 0
            border-bottom none
          .discounted
            color GY
            font-weight 400
            font-size ptr(14)
            line-height ptr(19.6)
            text-decoration line-through
            margin-bottom ptr(4)
          .product
            display grid
            grid-template-columns ptr(60) auto
            align-items center
            gap ptr(20)
            page-break-inside avoid
            padding ptr(8) 0 ptr(8) ptr(16)
          .product_thumbnail
            border-radius ptr(8)
            border 1px solid GY_50
            width ptr(60)
            height ptr(60)
            flex-shrink 0
            overflow hidden
          .product_infos
            display flex
            flex-direction column
            gap ptr(4)
          .product_title
            {font_14_r_140}
            text-align left
          .product_options
            {font_12_r_140}
            text-align left
            color GY
          .option_items
            page-break-inside avoid !important
          .option_item
            page-break-inside avoid !important
          .only_one
            display grid
            min-height 3.75rem
            align-items center
            padding ptr(8)
    &-desc
      color GY
      {font_12_r_140}
  &__footer
    display flex
    align-items center
    justify-content center
    height ptr(134)
    &-print
      width ptr(400)
      height ptr(50)
      border-radius 999px
      padding ptr(6) ptr(16) ptr(7) ptr(16)
      background-color BK
      color WHT
      font-size ptr(16)
      font-weight 500
      line-height ptr(16)
      display flex
      align-items center
      justify-content center
      border none
      &.disabled
        background-color GY_50
        color WHT
        cursor default


@media print
  html, body
    font-size 11px
    -webkit-text-size-adjust none
    width 788px
  @page
    margin 15mm 10mm 10mm 10mm;
  body.omp.my_page,
  body.omp.cart
    visibility hidden
    position static !important
    max-height 0px
  .don_frame[frame_name="/modules/OMP/Cart/S/Mui/frame.js"],
  .don_frame[frame_name="/modules/OMP/MyPage/Order/Detail/S/Mui/frame.js"]
    .omp-layout-body
      display none
  .don_frame[frame_name="/modules/OMP/Quotation/S/Mui/frame.js"]
    position static !important
    overflow-y hidden
    .don_wrapper
      position static !important
      height 0px
  .omp_quotation
    visibility visible
    position absolute
    top 0
    left 0
    overflow visible !important
    width 100% !important
    margin 0
    padding 0
    max-height unset !important
    .remove-icon
      display none
    input
      &::placeholder
        color transparent
    &__footer
      &-print
        visibility hidden
    &__body-frame.horizontal
      > *
        flex-basis unset
  .omp_quotation__body-validation-message-wrapper
    display none
  #twc-chat-plugin
    display none
@media screen and (max-height 1320px)
  .don_frame[frame_name="/modules/OMP/Quotation/S/Mui/frame.js"]
    > .don_wrapper
        height unset !important
        min-height 100vh !important
  .omp_quotation
    height 70vh
