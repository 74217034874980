body.receipt
  .receipt_body
    position relative
    padding 0 0 ptr(60)
    .receipt_body__h2
      { font_26_m_130 }
      margin 0
      padding 0
    p
      margin ptr(8) 0
    .header_title
      position relative
      margin-top ptr(16)
      margin-bottom ptr(150)
      &.has_instruction_url
        margin-bottom ptr(100)
      box-sizing border-box
      font-size ptr(40)
      .status_bar
        font-size ptr(14)
        color color_gray3
        font-weight normal
        span
          &.active
            color color_black
          &.arrow
            margin auto ptr(8)
            height ptr(10)
            min-width ptr(10)
            background svg-right-arrow no-repeat center
    .success_msg
      text-align center
      {flex-center}
      flex-direction column
      background-color #ffffff
      padding ptr(40)
      .title
        { font_26_m_100 }
        color BK
      .body
        { font_20_m_100 }
        color BK
        margin-top ptr(30)
        a
          border 1px solid #000
          border-radius ptr(4)
          padding ptr(6)
        a:hover
          color OG !important
          border 1px solid OG !important
        > *
          margin-bottom ptr(28)
          &:last-child
            margin-bottom 0
    .buttons
      text-align center
      padding 0
      position relative
      display flex
      align-items center
      justify-content center
      a
        outline none
        display flex
        align-items center
        justify-content center
        height ptr(50)
        text-align center
        box-sizing border-box
        border-radius ptr(999)
        width ptr(220)
        color BK
        border solid 1px BK
        { font_16_m_150 }
      >#continue
        color WHT
        background-color BK
        margin-left ptr(16)
      >#view_orders
        background-color #ffffff

