// modules/OMP/ShareProduct/F/Style/Pc/index.styl

.don_tab[tab_name="/modules/OMP/ShareProduct/S/Mui/tab.js"]
  .don_wrapper
    .omp-layout-body
      align-items center
      justify-content center
      padding-top 0
    .check_share_product_wrap
      width ptr(440)
      display flex
      flex-direction column
      gap ptr(50)
      align-items center
      font-weight 500
      p
        text-align center
        font-size ptr(16)
        line-height 150%
      img
        width 100%

    .check_share_product_from
      width 100%
      &__title
        font-size ptr(14)
        line-height 140%
      &__input
        height ptr(50)
        display flex
        align-items center
        gap ptr(16)
        padding-right ptr(16)
        border-bottom 1px solid BK
        input
          width 100%
          outline none
          border none
          font-size ptr(16)
        .eye
          pointer cursor
          &[data-is_show="false"]
            .eye__close
              display none
          &[data-is_show="true"]
            .eye__open
              display none
      button
        margin auto
        display flex
        width ptr(160)
        height ptr(50)
        justify-content center
        align-items center
        border-radius 999px
        background #D8D8D8
        border none
        color WHT
        margin-top ptr(32)
        pointer-events none

      .error
        border-bottom 1px solid #FF6B00
      .error_message
        display none
        margin-top ptr(8)
        margin-bottom 0
        color #FF6B00
        font-size ptr(12)
        text-align left

      .active
        background BK
        pointer-events auto

