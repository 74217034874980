.omp-order-detail
  margin-top ptr(60)
  display flex
  flex-direction column
  gap ptr(60)
  &__head
    display flex
    flex-direction column
    gap ptr(16)
  &__status-wrapper
    display flex
    justify-content space-between
    min-height ptr(156)
  &__status
    display flex
    flex-direction column
    align-items center
    flex 1 1 0
    gap ptr(8)
    > svg
      visibility hidden
      width ptr(20.4)
      height ptr(20.4)
    &[data-active="false"]
      color GY_80
    &[data-active="true"]
      color BK
      .omp-order-detail__status-active
        background OG
      > svg
        visibility visible
  &__status-active
    width ptr(20)
    height ptr(20)
    border-radius 100%
    background transparent
    margin-bottom ptr(8)
  &__status-step
    text-align center
    {font_16_m_100}
  &__status-title
    text-align center
    {font_16_m_100}
  &__status-desc
    text-align center
    {font_14_r_140}

  &__status-detail-wrapper
    border-top 1px solid GY_50
    padding-top ptr(20)
    display flex
    justify-content space-between
    align-items center

    .underline
      text-decoration underline
  &__status-detail
    display flex
    align-items center
    gap ptr(8)
    {font_20_m_100}
  &__status-short-og
    color OG_ERROR
  &__status-buttons
    display flex
    align-items center
    gap ptr(16)
  &__status-button-wrapper
    min-width ptr(180)
    .omp-order-detail__status-button__change-pay-method
      padding 0 ptr(16)

  &__product_wrapper
    display flex
    flex-direction column
  &__product-header
    padding-bottom ptr(20)
  &__product-header-title
    {font_20_m_100}

  &__product-footer
    padding ptr(16) 0
    display flex
    gap ptr(16)

    button
      cursor pointer
      height ptr(34)
      padding ptr(6) ptr(16)
      background-color WHT
      color BK
      border 1px solid BK
      border-radius ptr(8)
      {font_14_m_100}

  &__order-info-wrapper
    gap ptr(60) ptr(20)
    display grid
    display -ms-grid
    grid-template-columns 1fr 1fr

    & > div
      width 100%

    [data-style_color="OG"]
      color OG
    [data-style_bold="true"]
      {font_16_b_100}
  &__order-info
    &__title
      width 100%
      {font_20_m_100}
      padding-bottom ptr(20)
      border-bottom 1px solid BK
      display flex
      align-items center
      justify-content space-between
    &__content
      display flex
      flex-direction column
      width 100%
      {font_16_m_100}
      padding-top ptr(20)
    &__shipping-info
      display flex
      flex-direction column
      gap ptr(10)
      {font_16_m_100}

    &__price-info-wrapper
      display flex
      flex-direction column
      gap ptr(8)

    &__payment-instruction
      border 1px solid #000
      border-radius ptr(4)
      margin-left ptr(8)
      padding ptr(4)
    &__price-info
      display flex
      align-items center
      justify-content space-between
      height ptr(18)

    &__payment-info-wrapper
      display flex
      flex-direction column
      gap ptr(8)

    &__payment-info
      display flex
      align-items center
      justify-content space-between
      height ptr(18)
    &__payment-value--copy
      text-decoration underline
      cursor pointer

.omp-order-detail
  &__table
    tr.omp-cell__table-body:has([data-file-error="true"])
      background-color BG_PRODUCT
    .underline
    text-decoration underline
    th
      box-sizing border-box
    th + th, td + td
      padding-left ptr(24)
    .td-wrapper
      display flex
      align-items center
      justify-content center
      min-height ptr(100)
      padding ptr(20) 0
      box-sizing border-box
      {font_16_r_150}
    [data-key="product_info"]
      {font_16_m_150}
      width 100%
      display flex
      align-items center
      justify-content flex-start !important
      gap ptr(20)
      padding-left ptr(20)

      .omp-order-product
        &__thumbnail
          position relative
          border 1px solid GY_50
          border-radius ptr(10)
          width ptr(120)
          height ptr(120)
          flex-shrink 0
          .thumbnail_wrapper
            overflow hidden
            border-radius ptr(8)
          .expiration
            display flex
            align-items center
            justify-content center
            background-color BG_PRODUCT
            color GY
            text-align center
            border-radius ptr(8)
            width 100%
            height 100%
            {font_14_r_140}
          svg
            width ptr(28)
            height ptr(28)
            position absolute
            top ptr(-8)
            left ptr(-8)
            z-index 1
        &__thumbnail:has(img)
          overflow hidden
        &__thumbnail-canvas-wrapper
          position relative
          width 100%
          padding-top 100%
          overflow hidden
          canvas
            position absolute
            width 200%
            height 200%
            top 0
            left 0
            transform scale(0.5)
            transform-origin 0 0
        &__info-wrapper
          display flex
          flex-direction column
          gap ptr(24)
        &__info
          display flex
          flex-direction column
          gap ptr(4)
        &__id
          {font_16_m_150}
        &__name
          {font_16_m_150}
          display flex
          align-items center
          gap ptr(8)
        &__resubmit-badge
          {font_10_m_100}
          {flex-center-center}
          color GY
          background-color BG_PRODUCT
          width ptr(62)
          height ptr(20)
          border-radius 999px
          flex 1 0 auto
        &__print
          color GY
          {font_14_r_140}
        &__buttons
          display flex
          gap ptr(20)
          align-items center
          justify-content flex-start
        &__button-review
        &__button-purchase
          text-align center
          cursor pointer
          text-decoration underline
        &__button-purchase
          color OG
    [data-key="option"]
      display flex
      justify-content center
      align-items center
      gap ptr(20)
      .omp-order-product__options
        display flex
        flex-direction column
        align-items center

    [data-key="sizes"]
      display flex
      flex-direction column
      gap ptr(8)
      .omp-order-product__size-set
        display flex
        align-items center
        gap ptr(16)
      .omp-order-product__size
        min-width ptr(150)
        text-align center
      .omp-order-product__quantity
        min-width ptr(80)
        text-align center
    [data-key="quantity"]
      .omp-order-product-status__name
        {font_16_m_150}

      .omp-order-product-status__info
        {font_14_r_140}
      display flex
      flex-direction column
      align-items center
      justify-content center
      gap ptr(16)
    [data-key="price"]
      display flex
      flex-direction column
      align-items center
      justify-content center
      word-break keep-all
      .discounted
        text-decoration line-through
        color GY_80
      .table-button
        &__purchase
        &__review
          min-width ptr(80)
          text-align center
          cursor pointer
          text-decoration underline
        &__purchase
          color OG
    [data-key="action"]
      .omp-order-product
        &__buttons
          display flex
          gap ptr(8)
          flex-direction column
          align-items center
          justify-content flex-start
        &__button-review
        &__button-purchase
        &__button-check_modifications
        &__button-resubmit
          box-sizing border-box
          display flex
          align-items center
          justify-content center
          padding ptr(10)
          width ptr(136)
          height ptr(34)
          cursor pointer
          background-color WHT
          color BK
          border 1px solid BK
          border-radius ptr(8)
          {font_14_m_100}
        &__button-purchase
        &__button-resubmit
          color OG
          border-color OG
          &[is_designed_by_worker="true"]
            color GY
            border-color GY
    [data-key="file"]
      .omp-order-product
        &__printing-file
          display flex
          flex-direction column
          width 100%
        &__printing-file-name
          display flex
          min-width 0
          justify-content center
          text-decoration underline
          font-weight 500
          cursor pointer
          &.disabled
            color GY_80
            text-decoration none
            cursor text
        &__printing-file-title
          text-overflow ellipsis
          white-space nowrap
          overflow hidden
          line-height ptr(24)
        &__printing-file-mimetype
          flex-shrink 0
        &__printing-file-date
          color GY
          align-self center
          {font_14_r_140}
