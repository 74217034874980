.vector-editor__cutting-line-manual
  position absolute
  width 100%
  > .don_wrapper
    position relative
    width 100%
    > .header
      display none
    > .body
      position relative
      width 100%
  .don_tab
    user-select none
    position relative
    width 100%
    > .don_wrapper
      position relative
      width 100%
      height 100%
      display flex

      .left_container
        position relative
        width 0
        flex-grow 1
        height 100%
        background-color #FFFFFF
        .editor_container
          position absolute
          top 0
          right 0
          bottom 0
          left 0
          background-color #F8F9FA
        .foreground_container
          position absolute
          top 0
          left 0
          width 0
          height 0
          overflow visible
          .size_guide_container
            position absolute
            top 0
            left 0
            width 0
            height 0
            overflow visible
            .size_guide_container_value
              visibility hidden
              position absolute
              top 0
              left 0
              display inline-flex
              width 200px
              height 20px
              align-items center
              justify-content center
              background-color #474748
              color #fff
              border-radius 16px
      .right_container
        vector-editor-right_container()
        height 100%
        overflow hidden
        .right_wrapper
          width 100%
          height 100%
          display flex
          flex-direction column
          box-sizing border-box
          padding 40px 0 32px 0
          background-color #FFFFFF
          overflow hidden
          .panel_container
            width 100%
            height 0
            flex-grow 1
            position relative
            .panel_wrapper
              position absolute
              width 100%
              height 100%
              overflow hidden
              display flex
              flex-direction column
              box-sizing border-box
              padding 0 40px 0 40px
              .wrapper_header
                display flex
                width 100%
                height 24px
                align-items center
                justify-content center
                margin-bottom 40px
                .wrapper_header_title
                  font-size 19px
              .wrapper_body
                width 100%
                height 0
                flex-grow 1
                display flex
                flex-direction column
                .button_group
                  width 100%
                  display flex
                  flex-direction column
                  align-items flex-start
                  &:not(:last-child)
                    margin-bottom 34px
                  .button_group_label
                    font-size 14px
                    margin-bottom 16px
                  .button_list
                    width 100%
                    display flex
                    flex-direction column
                    button
                      position relative
                      box-sizing border-box
                      width 100%
                      height 40px
                      border-width 1px
                      border-style solid
                      border-color #D5DBE0
                      background-color #FFFFFF
                      padding 0
                      margin 0
                      display flex
                      align-items center
                      justify-content center
                      &:not(:last-child)
                        margin-bottom 8px
                      .button_icon
                        position absolute
                        width 28px
                        height 28px
                        background-size 28px 28px
                        left 12px
                        top 50%
                        transform translateY(-50%)
                        background-repeat no-repeat
                        background-position center center
                        &.active
                          display none
                        &.inactive
                          display block
                      .button_text
                        font-size 14px
                      &:active
                        border-color OG
                        .button_icon
                          &.active
                            display block
                          &.inactive
                            display none
                        .button_text
                          color OG
                    button.add_l
                      .button_icon
                        &.inactive
                          mp-editor-path-straight()
                    button.add_c
                      .button_icon
                        &.inactive
                          mp-editor-path-curved()
                    button.remove
                      .button_icon
                        &.inactive
                          mp-editor-path-delete()
                    button.change_to_c
                      .button_icon
                        &.inactive
                          mp-editor-path-changed-curved()
                    button.change_to_l
                      .button_icon
                        &.inactive
                          mp-editor-path-changed-straight()
                    button.reset
                      .button_icon
                        &.inactive
                          mp-editor-path-new()
              .wrapper_footer
                display flex
                width 100%
                height 56px
                align-items center
                justify-content space-between
                button
                  border 0 none
                  padding 0
                  margin 0
                  width 0
                  height 100%
                  flex-grow 1
                  box-sizing border-box
                  background-color transparent
                .prev
                  margin-right 8px
                  background-color #FFFFFF
                  border 1px solid #D5DBE0
                .next
                  background-color #000000
                  color #FFFFFF


.vector-editor__cutting-line-manual-b
  position absolute
  width 100%
  top 91px
  > .don_wrapper
    position relative
    width 100%
    > .header
      display none
    > .body
      position relative
      width 100%
  .don_tab
    user-select none
    position relative
    width 100%
    display flex
    top 0 !important
    > .don_wrapper
      position relative
      width 100%
      height 100%
      display flex
      .left_container
        position relative
        width 0
        flex-grow 1
        height 100%
        background-color #FFFFFF
        .editor_container
          position absolute
          top 0
          right 0
          bottom 0
          left 0
          background-color #F8F9FA
        .top_menu_container
          position absolute
          top 0
          left 0
          right 2px
          height 66px
          background-color #FFFFFF
          top-menus(100%, 100%)
      .right_container
        vector-editor-right_container()
        height 100%
        overflow hidden
        .right_wrapper
          width 100%
          height 100%
          display flex
          flex-direction column
          box-sizing border-box
          padding 40px 0 32px 0
          background-color #FFFFFF
          overflow hidden
          .panel_container
            width 100%
            height 0
            flex-grow 1
            position relative
            .right_panel
              position absolute
              width 100%
              height 100%
              background-color #FFFFFF
              display flex
              flex-direction column
              box-sizing border-box
              padding 0 40px
              .header
                position relative
                width 100%
                height 22px
                display flex
                align-items center
                justify-content center
                .title
                  font-size 19px
              .wrapper
                margin-top 40px
                width 100%
                height 0
                flex-grow 1
                display flex
                flex-direction column
                .button_group
                  width 100%
                  display flex
                  flex-direction column
                  align-items flex-start
                  &:not(:last-child)
                    margin-bottom 34px
                  .button_group_label
                    font-size 14px
                    margin-bottom 16px
                  .button_list
                    width 100%
                    display flex
                    flex-direction column
                    button
                      position relative
                      box-sizing border-box
                      width 100%
                      height 40px
                      border-width 1px
                      border-style solid
                      border-color #D5DBE0
                      background-color #FFFFFF
                      padding 0
                      margin 0
                      display flex
                      align-items center
                      justify-content center
                      &:not(:last-child)
                        margin-bottom 8px
                      .button_icon
                        position absolute
                        width 28px
                        height 28px
                        background-size 28px 28px
                        left 12px
                        top 50%
                        transform translateY(-50%)
                        background-repeat no-repeat
                        background-position center center
                        &.active
                          display none
                        &.inactive
                          display block
                      .button_text
                        font-size 14px
                      &:active
                        border-color OG
                        .button_icon
                          &.active
                            display block
                          &.inactive
                            display none
                        .button_text
                          color OG
                    button.add_l
                      .button_icon
                        &.inactive
                          mp-editor-path-straight()
                    button.add_c
                      .button_icon
                        &.inactive
                          mp-editor-path-curved()
                    button.remove
                      .button_icon
                        &.inactive
                          mp-editor-path-delete()
                    button.change_to_c
                      .button_icon
                        &.inactive
                          mp-editor-path-changed-curved()
                    button.change_to_l
                      .button_icon
                        &.inactive
                          mp-editor-path-changed-straight()
                    button.reset
                      .button_icon
                        &.inactive
                          mp-editor-path-new()
          .button_container
            width 100%
            height 56px
            display flex
            justify-content space-between
            box-sizing border-box
            padding 0 40px 0 40px
            button
              height 100%
              margin 0
              padding 0
              border 0 none
              width 100%
              margin-right 8px
              &:last-child
                margin-right 0
              &.cancel
                background-color #FFFFFF
                box-sizing border-box
                border 1px solid #D5DBE0
                color #000000
              &.done
                background-color #000000
                color #FFFFFF
