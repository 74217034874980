.omp-atom
  &__category-button
    width fit-content
    display flex
    position relative
    flex-direction row
    align-items center
    justify-content center
    padding ptr(12) ptr(20)
    border-radius ptr(999)
    color black
    cursor pointer
    border none
    margin 0
    .omp-atom__category-button-text
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      display flex
      align-items center
      justify-content center
      { font_16_m_100 }
    .omp-atom__category-button-text-transparent
      color transparent
      opacity 0
      { font_16_b_100 }

    &[data-mag="s"]
      padding ptr(10) ptr(14)
    &[data-active="false"]
      background-color transparent
      border 1px solid GY_50
      .omp-atom__category-button-text
        { font_16_m_100 }
      .omp-atom__category-button-text-transparent
        { font_16_b_100 }
      .omp-atom__category-button-new-tag
        align-self flex-start
        margin-left ptr(2)
        width ptr(4)
        height ptr(4)
        background-color OG
        border-radius ptr(4)
      &[data-mag="s"]
        .omp-atom__category-button-text
          { font_12_m_100 }
        .omp-atom__category-button-text-transparent
          { font_12_b_100 }
        .omp-atom__category-button-new-tag
          margin-left ptr(4)
    &[data-active="false"]:hover
    &[data-active="false"]:active
    &[data-active="true"]
      background-color OG
      border 1px solid OG
      .omp-atom__category-button-text
        { font_16_b_100 }
      .omp-atom__category-button-text-transparent
        { font_16_b_100 }
      .omp-atom__category-button-new-tag
        align-self flex-start
        margin-left ptr(2)
        width ptr(4)
        height ptr(4)
        background-color WHT
        border-radius ptr(4)
      &[data-mag="s"]
        .omp-atom__category-button-text
          { font_12_b_100 }
        .omp-atom__category-button-text-transparent
          { font_12_b_100 }
        .omp-atom__category-button-new-tag
          margin-left ptr(4)

  &__header-menu-arrow
    width fit-content
    display flex
    position relative
    flex-direction row
    align-items center
    justify-content center
    padding ptr(6) ptr(14)
    border-radius ptr(999)
    height ptr(40)
    box-sizing border-box
    color black
    cursor pointer
    border none
    margin 0
    .omp-atom__header-menu-arrow-icon
      margin-left ptr(20)
      width ptr(16)
      height ptr(16)
      display flex
      align-items center
      justify-content center

    .omp-atom__header-menu-arrow-text
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      padding-right ptr(10)
      display flex
      align-items center
      justify-content center
      { font_16_m_100 }
    .omp-atom__header-menu-arrow-text-transparent
      color transparent
      opacity 0
      { font_16_b_100 }

    &[data-active="false"]
      background-color transparent
      border 1px solid GY_50
      .omp-atom__header-menu-arrow-text
        { font_16_m_100 }
      .omp-atom__header-menu-arrow-text-transparent
        { font_16_b_100 }
      .omp-atom__header-menu-arrow-new-tag
        align-self flex-start
        margin-left ptr(2)
        width ptr(4)
        height ptr(4)
        background-color OG
        border-radius ptr(4)
    &[data-active="false"]:hover
    &[data-active="false"]:active
    &[data-active="true"]
      background-color BK
      border 1px solid BK
      color WHT
      .omp-atom__icon-arrow
        transform rotate(90deg)
        path
          stroke WHT
      .omp-atom__header-menu-arrow-text
        { font_16_b_100 }
      .omp-atom__header-menu-arrow-text-transparent
        { font_16_b_100 }
      .omp-atom__header-menu-arrow-new-tag
        align-self flex-start
        margin-left ptr(2)
        width ptr(4)
        height ptr(4)
        background-color WHT
        border-radius ptr(4)

  &__header-menu-button
    width fit-content
    display flex
    position relative
    flex-direction row
    align-items center
    justify-content center
    padding ptr(11) ptr(20)
    border-radius ptr(999)
    color black
    cursor pointer
    border none
    margin 0
    background: BG_PRODUCT
    .omp-atom__header-menu-button-text
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      display flex
      align-items center
      justify-content center
      { font_16_m_100 }
    .omp-atom__header-menu-button-text-transparent
      color transparent
      opacity 0
      { font_16_b_100 }
    &[data-active="false"]
      background-color BG_PRODUCT
      border 1px solid BG_PRODUCT
      .omp-atom__header-menu-button-text
        { font_16_m_100 }
      .omp-atom__header-menu-button-text-transparent
        { font_16_b_100 }
      .omp-atom__header-menu-button-new-tag
        align-self flex-start
        margin-left ptr(2)
        width ptr(4)
        height ptr(4)
        background-color OG
        border-radius ptr(4)
    &[data-active="false"]:hover
    &[data-active="false"]:active
    &[data-active="true"]
      background-color OG
      border 1px solid OG
      .omp-atom__header-menu-button-text
        { font_16_b_100 }
      .omp-atom__header-menu-button-text-transparent
        { font_16_b_100 }
      .omp-atom__header-menu-button-new-tag
        align-self flex-start
        margin-left ptr(2)
        width ptr(4)
        height ptr(4)
        background-color WHT
        border-radius ptr(4)

  &__action-button
    width 100%
    display flex
    align-items center
    justify-content center
    border-radius ptr(999)
    cursor pointer
    box-sizing border-box
    border none
    padding 0
    margin 0
    &[data-text_size="normal"]
      { font_16_m_100 }
    &[data-text_size="small"]
      { font_12_m_140 }
    &[data-text_size="small-normal"]
      { font_14_m_140 }
    &[data-solidity="42"]
      height ptr(42)
    &[data-solidity="normal"]
      height ptr(40)
    &[data-solidity="normal-thin"]
      height ptr(36)
    &[data-solidity="thin"]
      height ptr(32)
    &[data-solidity="thick"]
      height ptr(50)
    &[data-fill="100"]
      background-color BK
      color WHT
    &[data-fill="50"]
      background-color rgba(0, 0, 0, 0.5)
      color WHT
    &[data-fill="0"]
      border 1px solid BK
      background-color transparent
      color BK
    &[disabled]
      cursor default
      border 1px solid BK
      background-color transparent
      color BK
    &[data-color="blue"]
      background #1877F2

  &__removable-button
    width max-content
    padding ptr(12) ptr(20)
    display flex
    align-items center
    justify-content center
    border-radius ptr(50)
    border 1px solid GY_50
    background-color transparent
    cursor pointer
    gap ptr(8)
    &__text
      {font_16_m_100}
      color BK
    &__icon-wrap
      position relative
      width ptr(10)
      height ptr(10)
    .omp-atom__icon-x
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      padding ptr(12) ptr(12) ptr(12) ptr(8)
    &[data-is_active="false"]
      .omp-atom__icon-x > path
        stroke GY_80
    &:hover
    &[data-is_active="true"]
      {font_16_b_100}
      color WHT
      background-color OG
      border 1px solid OG
      .omp-atom__icon-x > path
        stroke WHT

  &__text-button
    width fit-content
    display flex
    position relative
    flex-direction row
    align-items center
    justify-content center
    padding ptr(12) ptr(20)
    border-radius ptr(999)
    color black
    cursor pointer
    border none
    margin 0
    .omp-atom__text-button__text
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      display flex
      align-items center
      justify-content center
      { font_16_m_100 }
    .omp-atom__text-button__text--transparent
      color transparent
      opacity 0
      { font_16_b_100 }
    &[data-active="false"]
      background-color transparent
      border 1px solid GY_50
      .omp-atom__text-button__text
        { font_16_m_100 }
      .omp-atom__text-button__text--transparent
        { font_16_b_100 }
    &[data-active="false"]:hover
    &[data-active="false"]:active
    &[data-active="true"]
      background-color OG
      border 1px solid OG
      .omp-atom__text-button__text
        { font_16_b_100 }
      .omp-atom__text-button__text--transparent
        { font_16_b_100 }
