body.event.list
  #event_list
    position relative
    >.header
      h1
        margin 0
        font-weight bold
    >.body
      .event_list
        .event_item
          display inline-block
          vertical-align top
          position relative
          word-break keep-all
          word-wrap break-word
          .thumbnail
            position relative
            overflow hidden
            .thumbnail_url
              font-size 0
            .is_over_wrap
              display none
            &[is_over="true"]
              .is_over_wrap
                display block
                background-color rgba(0,0,0,.6)
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                &:after
                  content '종료된 이벤트'
                  font-size 24px
                  font-weight bold
                  line-height 1
                  text-align center
                  color #fff
                  width 100%
                  height 24px
                  margin auto
                  position absolute
                  top 0
                  left 0
                  right 0
                  bottom 0
            img
              width 100%
              height 100%
              &[src=""]
                visibility hidden
          .sub_title
            color #495057
          .date
            color color_gray3
            line-height 1.33
            font-weight 500
            font-size 12px
            @media screen and (max-width 1024px)
              span
                display block
    @media screen and (min-width 1025px)
      position relative
      width 1248px
      margin 84px auto 142px
      padding 0 16px
      >.header
        margin-bottom 40px
        h1
          font-size 20px
          line-height 1
        h1:after
          content ' 전체 보기'
      >.body
        .event_list
          .infi_view_more
            margin 0
          .event_item
            display inline-block
            vertical-align top
            margin-right 16px
            margin-bottom 40px
            width 300px
            &:nth-child(4n)
              margin-right 0
            .thumbnail
              width 300px
              height 300px
              margin-bottom 16px
            .sub_title
              line-height 1.71
              margin-bottom 8px
            .title
              line-height 1.5
              margin-bottom 8px
              font-size 16px
              font-weight 600
    @media screen and (max-width 1024px)
      position relative
      width 100%
      margin 0 0 40px
      >.header
        margin 16px 0
        h1
          text-align center
          font-size 15px
          line-height 1.07
      >.body
        .event_list
          .infi_view_more
            margin 0 16px
          .event_item
            display inline-block
            vertical-align top
            margin-bottom 32px
            width 50%
            .thumbnail
              width 100%
              height 0
              padding-bottom 100%
              &[is_over="true"]
                .is_over_wrap
                  &:after
                    font-weight 400
                    font-size 20px
                    line-height 1.2
            .info
              margin 8px 16px 0
            .sub_title
              font-size 12px
              margin-bottom 6px
            .title
              line-height 1.43
              margin-bottom 6px

mobile_event_detail_style()
  position relative
  width 100%
  >.header
    width 100%
    margin 40px 0
    h1
      font-size 20px
      margin 0 16px
      line-height 1.2
      margin-bottom 16px
    .date
      line-height 1.71
  >.body
    .event_detail
      width 100%
      margin 0 auto
      >.old_content
        width 100%
        margin-bottom 40px
        img
          width 100%
      >.content_wrap
        width 100%
        .content
          width auto
          margin 24px 16px 40px
          line-height 1.71
          word-break keep-all
          img
            width calc(100% + (16px*2))
            margin-left -16px
      >.share
        margin 0 16px 40px
        button
          width 100%
          height 40px
  >.line
    width auto
  >.footer
    margin 24px 16px 40px
    .event_comment_write
      .upload
        #event_comment_upload
          span.file_name
            width 80px
    .event_comment_list
      .event_comment_item
        position relative
        padding 16px 0
        border-bottom 1px solid #d5dbe0
        >.header
          >div.like_point_wrap
            button
              background-size 15px 15px
              height 15px
              width 15px
              margin-top 1px
        >.body
          .photo
            img
              max-width 100%

body.event.detail
  #event_detail
    position relative
    >.header
      margin-bottom 24px
      text-align center
      h1
        margin 0
      .date
        font-weight 500
        color color_gray3
    >.body
      position relative
      .event_detail
        .content_wrap
          .img
            font-size 0
            width 100%
            img
              width 100%
          .content
            font-weight 500
            .event_video
              width 100%
              height 0
              padding-bottom 56.25%
              position relative
              margin-bottom 24px
              iframe
                position absolute
                width 100%
                height 100%
                top 0
                left 0
                right 0
                bottom 0
                margin auto
            strong
              font-weight 900
            hr
              border none
              height 1px
              background-color #d5dbe0
            ul
              padding 0
              margin 0
              list-style none
            li:before
              content '-'
              margin-right 8px
            img
              width 100%
        >.share
          display none
          button
            border #d5dbe0 solid 1px
            background-color #fff
            margin 0
            width 200px
            height 48px
            font-weight bold
            padding 0
            &:before
              content ''
              background-size 16px 18px
              background url(//s3.marpple.co/files/u_29089/2019/2/original/f_23468_1550585023856_LzGF7xlSGhp3S0P0Jxp.svg)
              margin-right 8px
              display inline-block
              vertical-align top
              width 16px
              height 18px
    >.line
      border-top 8px solid #f5f5f5
      margin 0 auto
    >.footer
      position relative
      .event_comment_write
        background-color #ffffff
        border 1px solid #d5dbe0
        overflow hidden
        .event_comment_wrap
          border-bottom 1px solid #d5dbe0
          padding 16px
          textarea
            font-size 14px
            resize none
            outline none
            width 100%
            box-shadow none
            background transparent
            padding 0
            border 0
            height 48px
            line-height 1.71
            &::placeholder
              color color_gray3
        .upload
        .confirm
          display inline-block
          vertical-align top
          &.confirm
            float right
            overflow hidden
          button
            width 76px
            height 42px
            background-color #000000
            font-weight bold
            color #ffffff
            border 0
          span.thousand
            font-size 12px
            text-align right
            color color_gray3
            margin-right 16px
          #event_comment_upload
            display inline-block
            vertical-align top
            position relative
            span.file_name
              color color_gray3
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              vertical-align top
              display inline-block
              margin-top 13px
              &[is_show="false"]
                display none
            .close
            label
              position relative
              width 52px
              height 42px
              display inline-block
              vertical-align top
              cursor pointer
              &.close
                cusor pointer
                span
                  width 18px
                  height 18px
                  background-image url("//s3.marpple.co/files/u_18050/2018/9/original/f_5847_1537160874072_d6i3L0G8q3a9FCB9eoi8Ms5f.svg")
                  background-size 18px 18px
              span
                position absolute
                left 0
                right 0
                bottom 0
                top 0
                margin auto
                width 20px
                height 18px
                background-image url(//s3.marpple.co/files/u_29089/2018/12/original/f_18080_1544715244186_G6b3K8pQeU1bHRqA0o2rC.png)
                background-size 20px 18px
              input[type="file"]
                position absolute
                left 0
                right 0
                bottom 0
                top 0
                visibility hidden
            &[is_close="true"]
              label
                display none
              .close
                display inline-block
            &[is_close="false"]
              label
                display inline-block
              .close
                display none
      .event_comment_list
        border-top 1px solid #000
        margin-top 40px
        &[has="false"]
          border-top 0
        .event_comment_item
          position relative
          padding 16px 0
          border-bottom 1px solid #d5dbe0
          &.removed:after
            content ''
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            background-color rgba(0,0,0,.6)
          >.header
            //overflow hidden
            padding 8px 0 8px
            margin-bottom 16px
            > div
              display inline-block
              vertical-align top
              color color_gray3
              margin-right 16px
              position relative
              &.like_point_wrap
                float right
                margin-right 0
                .like_point
                  vertical-align top
                  display inline-block
                  margin-right 8px
                  &[like_point="0"]
                    display none
                button
                  vertical-align top
                  background-image url(//s3.marpple.co/files/u_29089/2019/3/original/f_3415322_1552557313522_ynFW5af8E2aS4J0UgmrW.png)
                  background-size 18px 18px
                  height 18px
                  width 18px
                  display inline-block
                  background-color transparent
                  border 0
                  padding 0
                  &[selected="true"]
                    background-image url(//s3.marpple.co/files/u_29089/2019/3/original/f_3415323_1552557313522_wy9CXIL0C5R0B4SIIkMA.png)

          >.body
            overflow hidden
            .comment
              margin-bottom 16px
              .comment_wrap
                line-height 1.71
            .photo
              &[has_photo="false"]
                display none
              img
                max-width 600px
            .remove
              margin-top 8px
              float right
              &[is_me="false"]
                display none
              button
                background-color #fff
                border 1px solid #d5dbe0
                font-size 12px

    @media screen and (min-width 1025px)
      position relative
      margin 80px 0 142px
      >.header
        width 1248px
        margin 0 auto 40px
        h1
          font-size 38px
          line-height 1.05
          margin-bottom 24px
        .date
          font-size 16px
          line-height 1.5
      >.body
        .event_detail
          margin 0 auto
          >.old_content
            width 800px
            margin 0 auto 80px
          >.content_wrap
            .img
              width 992px
              margin auto
            .content
              width 992px
              margin 40px auto 80px
              line-height 1.71
          >.share
            margin 0 auto 80px
            text-align center
      >.line
        width 992px
      >.footer
        width 992px
        margin 40px auto 80px
    @media screen and (max-width 1024px)
      mobile_event_detail_style()
