// Pc
.omp-search
  &__search-history
    margin-bottom ptr(60)
    &--empty
      display none

    &__history-btns
      display flex
      flex-wrap wrap
      margin-top ptr(16)
      gap ptr(8)
    &__history-btn
      height ptr(40)
      .omp-atom__removable-button
        height ptr(40)

    &__header
      display flex
      align-items center
      gap ptr(16)

    &__header-title
      {font_16_m_100}

    &__remove-all-btn
      resetButton()
      cursor pointer
      {font_16_m_100}
      > span
        color GY_80

    &__empty-text
      {font_16_m_100}
      color GY_80

  &__search-history--empty
    display none
