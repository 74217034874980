#marpplizer_home
  .product_price_wrapper
    flex-direction column
  .brand_name
    margin-bottom 8px
  .review_summary
    display flex
    align-items center
    margin 8px 0
    .score_avg
      margin-left 4px
      font-size 16px
      font-weight 500
    .counts
      margin-left 6px
      font-size 14px
      font-weight 500
      color GY
      cursor default
      &:not(.no_review)
        cursor pointer
        &:hover
          text-decoration underline

  .price
    color OG
  //.product.section
  //  >.body
  //    margin-bottom 16px
  .size
    .one_size
      display none
    position relative
    ul
      position relative
      margin 0
      padding 0
      list-style none
      display flex
      flex-wrap wrap
    li
      box-sizing border-box
      text-align center
      cursor pointer
      margin-right 8px
      border solid 1px BK
      border-radius 12px
      font-size 14px
      height 50px !important
      line-height 50px !important
      margin-bottom 8px
    &[more_than_one="false"]
      li
        flex-grow 1
    li.sold_out
      position relative
      line-height 36px !important
      border 1px solid GY_50
      color GY_50
      &:before
        font-size 12px !important
        content "일시 품절"
        html[lang="en"] &
          content "Sold Out"
        html[lang="jp"] &
          content "品切れ"
        color GY_50
        width 100%
        position absolute
        top 70%
        left 0
        line-height 0
        text-align center
    &[length="1"]
      li
        height initial !important
        line-height initial !important
        &.selected
          border 0 none !important
          color BK !important
          min-width 69px !important
          text-align left
        &.sold_out
          &:before
            text-align left
            top 25px !important
  .options
    display flex
    gap 6px
  .open_chooser
    display none
    padding 10px 16px
    justify-content center
    align-items center
    border-radius 100px
    border 1px solid BK !important
    background transparent
    *
      color BK
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    &:focus
      outline none
    &:active
      background-color #ced4da
