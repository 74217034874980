
.pd_print_frame
  > .don_wrapper > .header
    position absolute
    top 0
    left 0
    right 0
    background-color #fff
    .title
      padding 40px 0 34px
      font-size 20px
      text-align center
  .print_area
    margin 106px 40px 40px
    color color_black
    line-height 1.71
    h2
      margin 36px 0 15px
      font-size 14px
      font-weight bold
    .img1
      margin 36px 0
    .m
      display none
    h3
      margin 0
      padding 0
      font-size 14px
      font-weight bold
    p.txt3
      margin 0
      padding 0
      em
        color #ea291f
        font-style normal
    p.txt1
      margin 0 0 15px
      padding 0
    p.txt2
      margin 0
      padding 0
      color color_gray3

.pd_delivery_frame
  > .don_wrapper > .header
    position absolute
    top 0
    left 0
    right 0
    background-color #fff
    .title
      padding 40px 0 34px
      font-size 20px
      text-align center
  .delivery_area
    margin 106px 40px 40px
    color color_black
    line-height 1.71
    h2
      margin 36px 0 15px
      font-size 14px
      font-weight bold
    p.txt1
      margin 0 0 14px
      padding 0
      em
        color #ea291f
        font-style normal
    ul
      margin 0
      padding 0
      list-style none
    table
      width 100%
      border-collapse collapse
      border-top 1px solid #e9ecef
      th
        color color_gray3
        width 156px
        font-weight normal
        text-align left
        box-sizing border-box
        background-color #fafafa
      th
      td
        font-size 14px
        vertical-align top
        padding 10px 5px 10px 24px
        border-bottom 1px solid #e9ecef
      td
        color color_black
      td
        .price_table
          cursor pointer
          text-decoration underline
        .map
          margin-top 14px
        .m
          display none

.pd_faq_frame
  > .don_wrapper > .header
    position absolute
    top 0
    left 0
    right 0
    background-color #fff
    .title
      padding 40px 0 34px
      font-size 20px
      text-align center
  .faq_area
    margin 106px 40px 40px
    color color_black
    line-height 1.71
    ul
      margin 0
      padding 0
      list-style none
      li
        margin-bottom 40px
      .q
        margin 0 0 15px
        color color_black
        font-size 14px
        font-weight bold
      .a
        padding 24px
        color color_black
        background-color #fafafa
        background-image url("//s3.marpple.co/files/u_14355/2019/3/original/f_23765_1552465457127_RqZ9K2oT2ly2zXLXukr.png")
        background-size 16px 16px
        background-position 0 0
        background-repeat no-repeat

.pd_guide_frame
  > .don_wrapper
    height 660px !important
    > .header
      position absolute
      top 0
      left 0
      right 0
      background-color #fff
      .title
        padding 40px 0 34px
        font-size 20px
        text-align center
  .guide_area
    margin 106px 40px 40px
    color color_black
    line-height 1.71
    h2
      margin 36px 0 15px
      font-size 14px
      font-weight bold
    h3
      margin 12px 0 4px
      padding 0
      font-size 14px
      font-weight bold
    ul.list1
      margin 0
      padding 0
      list-style none
    ul.list2
      margin 0 0 0 16px
      padding 0
      li
        color color_gray3
    p.txt1
      margin 0
      padding 0
      color color_gray3
    p.txt2
      margin 36px 0 0
      padding 0
    p.txt3
      margin 0
      padding 0
      color color_gray3


.quick_price_table_frame
  .quick_price_area
    text-align center
    padding 50px




















