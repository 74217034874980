.omp-cell
  &__category
    text-decoration none
    width fit-content
    height fit-content
    display flex
    flex-direction column
    gap ptr(10)
  &__category-thumbnail
    width ptr(220)
    height ptr(220)
    border-radius ptr(20)
    overflow hidden
    display flex
    background-color #f6f6f6
    &[data-bg="model"]
      background-color BG_MODEL
    &[data-bg="product"]
      background-color BG_PRODUCT
    &>img
      width 100%
      height 100%
      object-fit cover
  &__category-text
    color BK
    height ptr(20)
    { font_14_m_140 }
  &__category-all
    text-decoration none
    width fit-content
    height fit-content
    display flex
    position relative
    flex-direction column
    gap ptr(10)
  &__category-all-thumbnail
    width ptr(250)
    height ptr(250)
    border-radius ptr(20)
    overflow hidden
    &[data-bg="model"]
      background-color BG_MODEL
    &[data-bg="product"]
      background-color BG_PRODUCT
    &>img
      width 100%
      height 100%
      object-fit cover
  &__category-all-button-wrapper
    position absolute
    bottom 0
    left 0
    width 100%
    height fit-content
    padding ptr(20)
    box-sizing border-box
    display flex
    flex-direction row
    justify-content center
  &__category-all-button
    padding ptr(7.5) ptr(16) ptr(8.5)
    background-color BK
    border-radius ptr(999)
    color WHT
    { font_14_m_100 }
  &__category-all-plus-button-wrapper
    width ptr(30)
    height ptr(30)
  &__category-list
    width fit-content
    height fit-content
    display flex
    flex-direction column
    gap ptr(10)
  &__category-list-thumbnail
    text-decoration none
    width ptr(535)
    height ptr(280)
    overflow hidden
    &>img
      width 100%
      height 100%
      object-fit cover
      border-radius ptr(20)
    &__all
      display none
  &__category-list-text
    padding ptr(8) 0 ptr(16) 0
    display flex
    flex-direction column
    gap ptr(8)
  &__category-list-title
    text-decoration none
    color BK
    height ptr(28)
    {font_20_m_140}
  &__category-list-sub
    text-decoration none
    color BK
    {font_16_m_100}
  &__category-buttons
    display flex
    gap ptr(8)
    flex-wrap wrap
