.circle_icon
  .icon
    &:active
    &:hover
    &.drop
      background-color OG
  &.add_img .icon
    mp-editor-icon-upload()
  &.loc_optimization_btn .icon
    mp-editor-icon-optimize-position()
  &.my_lib .icon
    mp-editor-icon-my-image()
  &.add_sticker2 .icon
  &.worker_folder .icon
    mp-editor-icon-design()
  &.add_text .icon
    mp-editor-icon-text()
  &.add_sticker_line .icon
  &.add_sticker_custom .icon
    mp-editor-icon-design()
  &.open_chooser .icon
    mp-editor-icon-product()
  &.open_chooser .disabled_icon
    background-image url(//s3.marpple.co/files/u_2798351/2024/7/original/01d01c9c525711117474c2d1df6366e54f7b6c8d1.svg)
  &.open_cv_background_marpplizer .icon
    background-image url(//s3.marpple.co/files/u_193535/2022/1/original/e3c9a52e62c3f71c61d40bda01f2a3bec34618ec1.svg)
  &.share_j .icon
    background-image url(//s3.marpple.co/files/u_193535/2019/9/original/f_884045_1569314894216_Z8Cyyekvs7Tbv5zr6C5u.png)

.circle_icon
  margin-bottom 21px
  cursor pointer
  position relative
  display flex
  flex-direction column
  align-items center
  justify-content center
  &.add_sticker2
    html.creator-settings &
      display none
  &:nth-last-child(1)
    margin-bottom 24px
  .count
    html[my_image_count="0"] &
      opacity 0
    opacity 1
    transition opacity 0.3s
    position: absolute;
    background OG
    border-radius 100px
    box-sizing border-box
    color: #fff;
    z-index: 104;
    font-size 10px
    font-weight 400px
    //padding 5px 9px
    min-height 16px
    min-width 16px
    padding 0 4px
    top -1px
    right 0
    display flex
    justify-content center
    align-items center
  .icon
    background-color #ffffff
    z-index 100
    position: relative
    background-repeat no-repeat
    border-radius 50%
    border 1px solid transparent
    width 50px
    height 50px
    background-size 28px
    background-position 50% 50%
  .text
    text-align center
    font-size 12px
    margin-top 6px
    box-sizing border-box
    color BK

.decoration_menu_for_pc
  padding 0
  right 0
  position absolute
  top 54%
  margin 0
  transform translateY(-50%)
  z-index 1
  opacity 1
  transition opacity 0.3s
  @media screen and (max-height: 820px)
    top 80px
    transform translateY(0)
    .circle_icon
      margin-bottom 12px
  .circle_icon
    &.add_sticker_line
    &.share_j
    &.add_sticker_custom
      display none

.circle_icon
  @media screen and (max-height: 680px)
    .icon
      width calc(50px * 2/3)
      height calc(50px * 2/3)
      background-size calc(28px * 2/3)
      background-position 50% 50%
    .count
      font-size 8px
      right -10px
    .text
      font-size 10px
