.omp-nb
  &__section
    & + .omp-nb__section
      margin-top ptr(60px)
    &-header
      {font_26_m_100}
      margin 0 0 ptr(16px)
    &.best-pick
      .omp-nb__pick
        gridColumns(3)
        grid-gap ptr(16)
  &__justify-between
  &__custom-wrap
    display flex
    justify-content space-between
    align-items flex-start
    > div
      flex 1
  &__grid
    gridColumns(4)
    grid-gap ptr(40) ptr(8)

