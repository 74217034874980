@media screen and (min-width 1025px)
  .don_frame[frame_name='/modules/User/FindId/S/Mui/frame.js']
    // 로그인 창 z-index 100
    //z-index 101 !important
    >.don_wrapper
      width 488px
      height fit-content
      display flex
      flex-direction column
      >.body >.don_page >.don_wrapper
        >.header
          height 63px
          display flex
          align-items flex-end
          justify-content center
          >.don_back_page
            display none
          >.title
            font-size 20px
            font-weight normal
            text-align center
            color #000
    .find-id-wrapper
      box-sizing border-box
      width 100%
      height 417px
      padding 40px
      display flex
      .find-id
        width 100%
        display flex
        flex-direction column
        align-items center
        justify-content space-between
        margin 0 auto
        .row
          display flex
          align-items center
          justify-content space-between
          gap 8px
        &__sub-title
          width 100%
          padding 0
          margin 0 0 8px 0
          font-size 14px
          font-weight 600
          text-align left
          color #000
        &__data
          width 100%
          display flex
          flex-direction column
          gap 8px
          margin-bottom 50px
          input
            -moz-appearance textfield
            box-sizing border-box
            outline none
            line-height 1.14
            flex-grow 1
            height 40px
            padding 0 16px
            border solid 1px #dddddd
            font-size 14px
            letter-spacing -0.2px

            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &.invalid
              border solid 1px #f66565
            &::-webkit-outer-spin-button
            &::-webkit-inner-spin-button
              -webkit-appearance none
              margin 0
          &__title
            width 72px
            font-size 14px
            font-weight normal
            text-align left
            color #000
            padding 0
            margin 0
          &__row
            display flex
            gap 10px
          &__cert
            padding-left 80px
            position relative
            &.disabled
              display none
            &__invalid
              &.disabled
                display none
              font-size 13px
              margin 8px 0 0 0
              font-weight 500
              line-height 1.31
              letter-spacing -0.5px
              text-align left
              color #f66565
            &__timer-wrapper
              position absolute
              display flex
              height 100%
              width fit-content
              top 0
              right 0
              padding-right 16px
              align-items center
              justify-content center
            &__timer
              font-size 14px
              line-height 1.38
              letter-spacing -0.2px
              color #3c89f9
              &.red
                color #f66565
          &__no-equal
            display none
            font-size 12px
            margin 8px 0 0 0
            font-weight 500
            line-height 1.31
            letter-spacing -0.5px
            text-align left
            color #f66565
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          box-sizing border-box
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          cursor pointer
          &.lightly
            width 120px
            height 40px
            letter-spacing -0.2px
            color #4e4e4e
            background-color #fff
            border solid 1px #d5dbe0

@media screen and (max-width 1024px)
  .don_frame[frame_name='/modules/User/FindId/S/Mui/frame.js']
    >.don_wrapper
      width 100%
      height fit-content
      display flex
      flex-direction column
      >.body >.don_page >.don_wrapper
        >.header
          box-sizing border-box
          height 63px
          display flex
          padding-top 25px
          align-items flex-start
          justify-content center
          >.don_back_page
            display none
          >.title
            color #212529
            font-size 16px
            font-weight 600
            text-align center
          >.don_hide_frame
            top 25px
            right 17px
            width 14px
            height 14px
    .find-id-wrapper
      box-sizing border-box
      width 100%
      padding 16px
      display flex
      .find-id
        width 100%
        display flex
        flex-direction column
        align-items center
        justify-content space-between
        margin 0 auto
        .row
          display flex
          align-items center
          justify-content space-between
          gap 8px
        &__sub-title
          width 100%
          padding 0
          margin 0 0 16px 0
          font-size 14px
          font-weight 600
          text-align left
          color #000
        &__data
          width 100%
          display flex
          flex-direction column
          gap 8px
          input
            -moz-appearance textfield
            box-sizing border-box
            outline none
            line-height 1.14
            flex-grow 1
            min-width 0
            height 40px
            padding 0 16px
            border solid 1px #dddddd
            font-size 14px
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &::-webkit-outer-spin-button
            &::-webkit-inner-spin-button
              -webkit-appearance none
              margin 0
          &__title
            width 64px
            font-size 14px
            font-weight normal
            text-align left
            color #000
            padding 0
            margin 0
          &__number
            padding-top 8px
          &__cert
            padding-left 72px
            position relative
            &.disabled
              display none
            &__invalid
              &.disabled
                display none
              font-size 12px
              margin 8px 0 0 0
              font-weight 500
              line-height 1.31
              letter-spacing -0.5px
              text-align left
              color #f66565
            &__timer-wrapper
              display flex
              position absolute
              height 100%
              width fit-content
              top 0
              right 0
              padding-right 16px
              align-items center
              justify-content center
            &__timer
              font-size 14px
              line-height 1.38
              letter-spacing -0.2px
              color #3c89f9
              margin 0
              padding 0
              &.red
                color #f66565
        &__buttons
          box-sizing border-box
          position fixed
          bottom 24px
          padding 0 16px
          display flex
          width 100%
        &__button
          box-sizing border-box
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          cursor pointer
          &.lightly
            width 95px
            height 40px
            color #4e4e4e
            background-color #fff
            border solid 1px #d5dbe0
