// modules/OMP/Portfolio/F/Style/Pc/index.styl
.omp-portfolio
  .omp-portfolio__section
    .omp-portfolio__section-header
      h3
        {font_26_m_100}
        margin-top 0
        margin-bottom ptr(16)
    .portfolio-item-wrapper
      display grid
      grid-template-columns 1fr 1fr 1fr
      flex-wrap wrap
      row-gap ptr(16)
      column-gap ptr(8)
      .portfolio-item
        padding-bottom ptr(16)
        display flex
        flex-direction column
        gap ptr(8)
        .portfolio-description
          display flex
          flex-direction column
          gap ptr(8)
          .brand-name
            margin-top ptr(8)
            {font_20_m_140}
            color BK
          .content
            {font_16_r_150}
            color BK
        img
          border-radius 20px
          overflow hidden
          max-width ptr(535)
          aspect-ratio 1 / 1
          width 100%

  .omp-portfolios__kit
    .omp-portfolio__biz-inbound
      margin-top ptr(80)
      text-align center
      background-repeat no-repeat
      background-origin content-box
      height ptr(300)
      display flex
      gap ptr(24)
      justify-content center
      align-items center
      flex-direction column
      background-image image-set(url(//image1.marpple.co/files/u_2659202/2023/10/original/9b10c0dc3965a9ac66280cbc71c2440b8b46a29c1.png?w=1620) 1x, url(//image1.marpple.co/files/u_2659202/2023/10/original/afc9cc51d066275fd545e5e8ecdfbc6134e34e902.png?w=3240) 2x, url(//image1.marpple.co/files/u_2659202/2023/10/original/272a0a5e30b08bcc62ebf2fe2db69d7ea9b348963.png?w=4860) 3x)
      background-image -webkit-image-set(url(//image1.marpple.co/files/u_2659202/2023/10/original/9b10c0dc3965a9ac66280cbc71c2440b8b46a29c1.png?w=1620) 1x, url(//image1.marpple.co/files/u_2659202/2023/10/original/afc9cc51d066275fd545e5e8ecdfbc6134e34e902.png?w=3240) 2x, url(//image1.marpple.co/files/u_2659202/2023/10/original/272a0a5e30b08bcc62ebf2fe2db69d7ea9b348963.png?w=4860) 3x)
      background-size 100% 100%
      .omp-portfolio__biz-inbound-dec
        {font_26_m_130}
        color BK
        white-space break-spaces
      .omp-portfolio__biz-inbound-btn
        {font_16_m_100}
        background none
        border none
        display flex
        align-items center
        color WHT
        background-color BK
        padding ptr(6) ptr(40) ptr(7) ptr(40)
        border-radius ptr(999)
        height ptr(50)
        &:focus-visble
          border none
    .omp__section.omp__faq
      .omp__faq-item
        ul
          list-style-type disc
          padding 0 ptr(28)
