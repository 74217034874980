.omp-my-page
  &__header
    display flex
    flex-direction column
    gap ptr(16)
  &__header-title
    width fit-content
    {font_26_m_130}
  &__header-menus
    padding 0
    margin 0
    list-style none
    display flex
    gap ptr(24)
  &__header-menu
    list-style none
    {font_16_m_100}
    &[data-active="true"]
      {font_16_b_100}
    > a
      display flex
      align-items center
      justify-content center
      gap ptr(4)
      > svg
        width ptr(16)
        height ptr(16)


.omp-my-page-landing
  display flex
  flex-direction column
  gap ptr(50)
  margin-top ptr(24)
  &__grey
    color GY_80 !important
    {font_14_m_100}
  &__dashboard
    display flex
    align-items center
    justify-content space-between
    padding ptr(30)
    border-radius ptr(20)
    min-height ptr(102)
    flex 1 1 0
    background BK
    color WHT
    {font_26_m_130}
  &__dashboard-owner
    height 100%
    display flex
    flex-direction column
    justify-content space-between
    gap ptr(8)

    &__title
      display flex
      flex-direction column
      gap ptr(4)
  &__dashboard-data
    height 100%
    width 70%
    display flex
    gap ptr(24)
  &__dashboard-data-pair
    height 100%
    color WHT !important
    width 33%
    display flex
    flex-direction column
    justify-content space-between
  &__latest-order
    display flex
    flex-direction column
    gap ptr(24)
    &__header
      display flex
      align-items center
      justify-content space-between
    &__header-title
      {font_26_m_130}
    &__header-more
      {font_14_r_140}

    &__status
      display flex
      justify-content space-between
      padding ptr(20) 0
      min-height ptr(118)
      gap ptr(8)

    &__status-wrapper
      display flex
      flex-direction column
      align-items center
      flex 1 1 0
      gap ptr(8)
      .omp-my-page-landing__latest-order__status-top-wrapper
        display flex
        flex-direction column
        gap ptr(8)
        padding ptr(24) ptr(20)
        box-sizing border-box
        width 100%
        &[data-active="true"]
          border-radius ptr(20)
          background-color BG_PRODUCT
      &[data-active="false"]
        color GY_80
      &[data-active="true"]
        .omp-my-page-landing__latest-order__status-count
          color OG
        color BK

    &__status-count
      text-align center
      {font_26_m_100}
    &__status-title
      text-align center
      {font_16_m_100}
    &__status-desc
      text-align center
      {font_14_r_140}

    &__table-pages
      display flex
      padding ptr(24) 0
      align-items center
      justify-content center
      gap ptr(16)
    &__table-page
      cursor pointer
      {font_16_m_100}
      color GY_80
      &[data-active="true"]
        color BK

  &__new-product
    display flex
    flex-direction column
    gap ptr(24)
  &__new-product__header
    display flex
    align-items center
    justify-content space-between
  &__new-product__header-title
    {font_26_m_130}

.omp-order
  &__status-desc
    text-align center
    padding ptr(16) 0
    .underline
      text-decoration underline

  &__table
    tr.omp-cell__table-body:has([data-file-error="true"])
      background-color BG_PRODUCT
    .underline
      text-decoration underline
    .td-wrapper
      display flex
      align-items center
      justify-content center
      min-height ptr(100)
      padding ptr(20) 0
      box-sizing border-box
      {font_16_r_150}
    [data-key="order_info"]
      {font_16_m_150}
      width 100%
      display flex
      flex-direction column
      align-items center
      justify-content center
    [data-key="detail"]
      display flex
      align-items center
      justify-content flex-start !important
      gap ptr(20)
      .omp-order-product
        &__thumbnail
          border 1px solid GY_50
          border-radius ptr(8)
          width ptr(60)
          height ptr(60)
          position relative
          > .thumbnail_wrapper
            border-radius ptr(8)
            overflow hidden
          > img
            width 100%
            height 100%
          .expiration
            display flex
            align-items center
            justify-content center
            background-color BG_PRODUCT
            color GY
            text-align center
            border-radius ptr(8)
            width 100%
            height 100%
            {font_12_r_140}
          > svg
            width ptr(20)
            height ptr(20)
            position absolute
            left ptr(-8)
            top ptr(-8)
            z-index 1
        &__thumbnail:has(img)
          overflow hidden
        &__thumbnail-canvas-wrapper
          position relative
          width 100%
          padding-top 100%
          overflow hidden
          canvas
            position absolute
            width 200%
            height 200%
            top 0
            left 0
            transform scale(0.5)
            transform-origin 0 0
        &__info
          display flex
          flex-direction column
          gap ptr(4)
        &__name
          {font_16_m_150}
        &__detail
          color GY
          {font_14_r_140}


    [data-key="price"]
      {font_16_m_150}
      display flex
      flex-direction column
      align-items center
      justify-content center
      .discounted
        text-decoration line-through
        color GY_80
    [data-key="status"]
      display flex
      flex-direction column
      align-items center
      justify-content center
      .omp-order-product-status__name
        {font_16_m_150}
      .omp-order-product-status__info
        {font_14_r_140}
      .omp-order-product-status__file-error
        color OG
        {font_16_m_150}
    [data-key="etc"]
      display flex
      gap ptr(8)
      flex-direction column
      align-items center
      justify-content center
      button
        border none

      .table-button
        &__purchase
        &__review
        &__check_modifications
        &__resubmit
          text-decoration none
          box-sizing border-box
          display flex
          align-items center
          justify-content center
          padding ptr(10)
          width ptr(136)
          height ptr(34)
          cursor pointer
          background-color WHT
          color BK
          border 1px solid BK
          border-radius ptr(8)
          {font_14_m_100}
        &__purchase
        &__resubmit
          color OG
          border-color OG
        &__purchase
          &[is_designed_by_worker="true"]
            color GY
            border-color GY

    [data-key="file"]
      .omp-order-product
        &__printing-file
          display flex
          flex-direction column
          width 100%
        &__printing-file-name
          display flex
          min-width 0
          justify-content center
          text-decoration underline
          font-weight 500
          cursor pointer
          &.disabled
            color GY_80
            text-decoration none
            cursor text
        &__printing-file-title
          text-overflow ellipsis
          white-space nowrap
          overflow hidden
          line-height ptr(24)
        &__printing-file-mimetype
          flex-shrink 0
        &__printing-file-date
          color GY
          align-self center
          {font_14_r_140}
