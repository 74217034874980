.omp-review-write
  display flex
  flex-direction column
  gap ptr(16)
  min-width ptr(880)
  padding-top ptr(16)
  &__product-wrapper
    display flex
    gap ptr(16)
  &__product
  &__product-rate
    flex 1 1 0
    min-height ptr(90)
    border-radius ptr(16)
    border 1px solid GY_50
    box-sizing border-box
  &__product
    padding 0 ptr(16)
    display flex
    justify-content flex-start
    align-items center
    gap ptr(16)
  &__product-thumbnail
    overflow hidden
    border 1px solid GY_50
    border-radius ptr(8)
    width ptr(60)
    height ptr(60)
    > img
      width 100%
      height 100%
  &__product-thumbnail-canvas-wrapper
    position relative
    width 100%
    padding-top 100%
    canvas
      position absolute
      width 200%
      height 200%
      top 0
      left 0
      transform scale(0.5)
      transform-origin 0 0
  &__product-info-wrapper
    display flex
    flex-direction column
    gap ptr(4)
  &__product-additional-info
    display flex
    gap ptr(16)
    {font_14_m_140}
    .underline
      text-decoration underline
  &__product-info
    display flex
    flex-direction column
  &__product-name
    {font_14_m_140}
  &__product-detail
    color GY
    {font_14_r_140}
  &__product-rate
    padding ptr(16)
    display flex
    flex-direction column
    gap ptr(4)
    {font_14_m_140}
    > input[name="score"]
      display none
  &__product-rate-star-wrapper
    display flex
  &__product-rate-star
    cursor pointer
    width ptr(24)
    height ptr(24)

  &__content-wrapper
    display flex
    flex-direction column
    gap ptr(8)
    padding ptr(16)
    border-radius ptr(16)
    border 1px solid GY_50
    height ptr(250)
    overflow hidden
    box-sizing border-box
    [name="comment"]
      border none
      outline none
      resize none
      padding 0
      margin 0
      overflow auto
      flex 1 1 0
      {font_16_r_150}
      &::placeholder
        color GY
  &__content-title
    {font_16_b_100}

  &__photo-wrapper
    display flex
    &[data-style_gap="8"]
      gap ptr(8)
    [name="file"]
      display none
    .add-photo
      cursor pointer
      width ptr(60)
      height ptr(60)
      display flex
      align-items center
      justify-content center
      border 1px solid GY_50
      border-radius ptr(8)
      &__icon-wrapper
        width ptr(28)
        height ptr(28)
  &__photos
    display flex
    gap ptr(8)
  &__photo-container
    position relative
    display flex
    align-items center
    justify-content center
    width fit-content
    height fit-content

  &__photo
    border 1px solid GY_50
    width ptr(60)
    height ptr(60)
    border-radius ptr(8)
    overflow hidden
    img
      width 100%
      height 100%
  &__photo-remove
    position absolute
    display inline-flex
    align-items center
    justify-content center
    top ptr(4)
    right ptr(4)
    cursor pointer
    width ptr(6)
    height ptr(6)
    border-radius 100%
    border 1px solid GY_50
    padding ptr(3)
    background WHT

  &__sns-wrapper
    display flex
    flex-direction column
    gap ptr(16)
  &__sns-title
    {font_16_b_100}
  &__sns-description
    {font_14_r_140}

  &__agree_marketing
    padding-top ptr(16)
