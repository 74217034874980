
.canvas_fit
  width 100%
  height auto
  > canvas
    width 100% !important
    height auto !important

// 100 이하는 PX로 고정
.canvas_60
  width 60px
  height 60px
  display flex
  align-items center
  justify-content center
  overflow hidden
  &> img
    width 60px
    height 60px
  &> canvas
    width 150px
    height 150px
    transform scale(60/150)

.canvas_75
  width 75px
  height 75px
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width 150px
    height 150px
  > *
    transform scale(75/150)

.canvas_100
  width 100px
  height 100px
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width 150px
    height 150px
  > *
    transform scale(100/150)
  > img
    max-width 100px
    max-height 100px
    width auto
    height auto
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

.canvas_120
  width 120px
  height 120px
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width 150px
    height 150px
  > *
    transform scale(120/150)

.canvas_200
  width ptr(200)
  height ptr(200)
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width ptr(300)
    height ptr(300)
  > *
    transform scale(200/300)

.canvas_300
  width ptr(300)
  height ptr(300)
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width ptr(400)
    height ptr(400)
  > *
    transform scale(300/400)


.canvas_600
  width ptr(600)
  height ptr(600)
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width ptr(700)
    height ptr(700)
  > *
    transform scale(600/700)

.canvas_700
  width ptr(700)
  height ptr(700)
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width ptr(860)
    height ptr(860)
  > *
    transform scale(700/860)

.canvas_860
  width ptr(860)
  height ptr(860)
  overflow hidden
  display flex
  align-items center
  justify-content center
  > canvas
    width ptr(860)
    height ptr(860)
