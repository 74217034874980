@charset "utf-8"
html, body
  margin 0
  padding 0

@import "./share.styl"

html
  button
    color BK
  &[lang="kr"]
    body, input, textarea, button
      font-family "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  &[lang="jp"]
    body, input, textarea, button
      font-family "Pretendard JP Variable", "Pretendard JP", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  &[lang="en"]
    body, input, textarea, button
      font-family "Inter var", Inter, "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;


html[is_mobile=false]:not(.product.detail)
  @media screen and (min-width 1280px)
    font-size .87vw !important
  @media screen and (min-width 1400px)
    font-size .87vw !important
  @media screen and (min-width 1600px)
    font-size .85vw !important
  @media screen and (min-width 1920px)
    font-size 16px !important
  @media screen and (max-width 1280px)
    font-size .87vw

html[is_mobile=false].omp.product.detail
  font-size 16px !important



ptr(value)
  unit(value/16, 'rem')

font_26_m_100 =
  font-style normal
  font-weight 500
  font-size ptr(26px)
  line-height 100%

font_26_m_130 =
  font-style normal
  font-weight 500
  font-size ptr(26px)
  line-height 130%

font_20_m_100 =
  font-style normal
  font-weight 500
  font-size ptr(20px)
  line-height 100%

font_20_b_140 =
  font-style normal
  font-weight 700
  font-size ptr(20px)
  line-height 140%

font_20_b_100 =
  font-style normal
  font-weight 700
  font-size ptr(20px)
  line-height 100%

font_20_m_130 =
  font-style normal
  font-weight 500
  font-size ptr(20px)
  line-height 130%

font_20_m_140 =
  font-style normal
  font-weight 500
  font-size ptr(20px)
  line-height 140%

font_20_m_160 =
  font-style normal
  font-weight 500
  font-size ptr(20px)
  line-height 160%

font_16_b_100 =
  font-style normal
  font-weight 700
  font-size ptr(16px)
  line-height 100%

font_16_m_150 =
  font-style normal
  font-weight 500
  font-size ptr(16px)
  line-height 150%

font_16_m_100 =
  font-style normal
  font-weight 500
  font-size ptr(16px)
  line-height 100%

font_16_r_150 =
  font-style normal
  font-weight 400
  font-size ptr(16px)
  line-height 150%

font_14_b_100 =
  font-style normal
  font-weight 700
  font-size ptr(14px)
  line-height 100%

font_14_m_140 =
  font-style normal
  font-weight 500
  font-size ptr(14px)
  line-height 140%

font_14_m_100 =
  font-style normal
  font-weight 500
  font-size ptr(14px)
  line-height 100%

font_14_r_140 =
  font-style normal
  font-weight 400
  font-size ptr(14px)
  line-height 140%

font_14_r_160 =
  font-style normal
  font-weight 400
  font-size ptr(14px)
  line-height 160%

font_12_b_100 =
  font-style normal
  font-weight 700
  font-size ptr(12px)
  line-height 100%

font_12_m_140 =
  font-style normal
  font-weight 500
  font-size ptr(12px)
  line-height 140%

font_12_m_100 =
  font-style normal
  font-weight 500
  font-size ptr(12px)
  line-height 100%

font_12_r_100 =
  font-style normal
  font-weight 400
  font-size ptr(12px)
  line-height 100%

font_12_r_140 =
  font-style normal
  font-weight 400
  font-size ptr(12px)
  line-height 140%

font_10_r_100 =
  font-style normal
  font-weight 400
  font-size ptr(10px)
  line-height 100%

font_10_m_100 =
  font-style normal
  font-weight 500
  font-size ptr(10px)
  line-height 100%

*::-webkit-scrollbar
  width: ptr(12)
  height: ptr(12)
  &-track
    background-color transparent
  &-thumb
    border 2px solid #fff
    border-radius ptr(6)
    background-color #D8D8D8

@import "./variables.styl"

// 기존 마플 css 지우거나 복사해서 위로 올리거나
@import "./don.component.styl"
@import "../../../../www/css/mp/login.styl"
@import "../../../../www/css/mp/review.styl"
@import "../../../../www/css/mp/event.styl"
//@import "../../../../www/css/mp/receipt.styl"
@import "../../../../www/css/mp/order.styl"
@import "../../../../www/css/mp/my_point.styl"
@import "../../../../www/css/mp/my_review.styl"
//@import "../../../../www/css/mp/help.styl"
@import "../../../../www/css/mp/group.styl"
@import "../../../../www/css/mp/old_message.styl"


@import "../../../../www/css/mp/verify.styl"
//@import "../../../../www/css/mp/high_quality.styl"
@import "../../../../www/css/mp/shipping_country.styl"
@import "../../../../www/css/mp/my_info.styl"

@import "../../../../www/css/all/composite_template_selection.styl"
@import "../../../../www/css/all/compositeTemplateList.styl"

@import "../../../../www/css/all/unsupported_browser_notice.styl"

@import "../../../../modules/User/FindId/F/Style/Mp/index.styl"
@import "../../../../modules/User/FindId/Result/F/Style/Mp/index.styl"
@import "../../../../modules/User/ResetPassword/F/Style/Mp/index.styl"
@import "../../../../modules/User/ResetPassword/Verify/F/Style/Mp/index.styl"
@import "../../../../modules/Dormant/Cert/F/Style/Mp/index.styl"
@import "../../../../modules/Dormant/Return/F/Style/Mp/index.styl"
@import "../../../../modules/Dormant/PhoneCert/F/Style/Mp/index.styl"
// end 기존 마플 css 지우거나 복사해서 위로 올리거나

@import "./omp.don.frame.pc.styl"
@import "./frame.pc.styl"
@import "./marpple.pc.styl"
@import "./canvas.styl"

@import "../Atom/F/Style/Share/index.styl"
@import "../Atom/F/Style/Pc/index.styl"
@import "../Cell/F/Style/Pc/index.styl"
@import "../Layout/F/Style/Pc/index.styl"
@import "../Layout/Navigation/F/Style/Pc/index.styl"
@import "../Legacy/F/Style/Pc/color_picker.styl"
@import "../Legacy/F/Style/Pc/has_up_modal_style.styl"
@import "../Legacy/F/Style/Pc/make_product_color_prices.styl"
@import "../Legacy/F/Style/Pc/don_tooltip.styl"
@import "../Legacy/F/Style/Pc/terms.styl"
@import "../Legacy/F/Style/Pc/help.styl"

@import "../../../Terms/CommerceLaw/F/Style/Pc/index.styl"

@import "../../Review/F/Style/Pc/index.styl"
@import "../../Review/Detail/F/Style/Pc/index.styl"
@import "../../Review/Write/F/Style/Pc/index.styl"
@import "../../Search/F/Style/Pc/index.styl"
@import "../../Search/AutoComplete/F/Style/Pc/index.styl"
@import "../../Search/History/F/Style/Pc/index.styl"
@import "../../Auth/F/Style/Pc/index.styl"
@import "../../Auth/FindID/F/Style/Pc/index.styl"
@import "../../Auth/FindID/Result/F/Style/Pc/index.styl"
@import "../../Auth/ResetPassword/F/Style/Pc/index.styl"
@import "../../Auth/ResetPassword/Verify/F/Style/Pc/index.styl"
@import "../../Auth/EmailVerify/F/Style/Pc/index.styl"
@import "../../Auth/NeedVerify/F/Style/Pc/index.styl"
@import "../../Auth/ChangeEmail/F/Style/Pc/index.styl"
@import "../../Auth/Dormant/Certification/F/Style/Pc/index.styl"
@import "../../Auth/Dormant/MobileVerify/F/Style/Pc/index.styl"
@import "../../Auth/Dormant/Return/F/Style/Pc/index.styl"
@import "../../MyPage/F/Style/Pc/index.styl"
@import "../../MyPage/Order/F/Style/Pc/index.styl"
@import "../../MyPage/Order/Detail/F/Style/Pc/index.styl"
@import "../../MyPage/Order/Detail/ChangePayMethod/F/Style/Pc/index.styl"
@import "../../MyPage/Wish/F/Style/Pc/index.styl"
@import "../../MyPage/Wish/List/F/Style/Pc/index.styl"
@import "../../MyPage/Point/F/Style/Pc/index.styl"
@import "../../MyPage/Info/F/Style/Pc/index.styl"
@import "../../MyPage/Review/F/Style/Pc/index.styl"
@import "../../MyPage/Withdrawal/F/Style/Pc/index.styl"
@import "../../Maker/CanvasEditor/F/Style/Pc/index.styl"
@import "../../Maker/VectorEditor/F/Style/Pc/index.styl"
@import "../../Maker/VectorEditor/Others/F/Style/Pc/index.styl"
@import "../../ProductDetail/F/Style/Pc/index.styl"
@import "../../DesignCollection/F/Style/Pc/index.styl"
@import "../../DesignCollection/Admin/Detail/F/Style/Pc/index.styl"
@import "../../ProductList/F/Style/Pc/index.styl"
@import "../../ProductList/Modal/F/Style/Pc/index.styl"
@import "../../ProductList/FilterModal/F/Style/Pc/index.styl"
@import "../../ProductList/Modal/F/Style/Pc/index.styl"
@import "../../Cart/F/Style/Pc/index.styl"
@import "../../Cart/Option/F/Style/Pc/index.styl"
@import "../../Cart/AddColor/F/Style/Pc/index.styl"
@import "../../Home/F/Style/Pc/index.styl"
@import "../../Checkout/F/Style/Pc/index.styl"
@import "../../Checkout/F/Style/Pc/en.styl"

@import "../../Event/F/Style/Pc/index.styl"
@import "../../NewProducts/F/Style/Pc/index.styl"
@import "../../Guideline/F/Style/Pc/index.styl"
@import "../../ContactUs/F/Style/Pc/index.styl"
@import "../../Receipt/F/Style/Pc/index.styl"
@import "../../ShinhanBrand/F/Style/Pc/index.styl"

@import "../../Kit/Form/F/Style/Pc/index.styl"
@import "../../Kit/Form/Result/F/Style/Pc/index.styl"

@import "../../GroupAndBizProduct/F/Style/Pc/index.styl"
@import "../../Portfolio/F/Style/Pc/index.styl"

@import "../../Core/Frames/StaticStyle/F/Style/Pc/index.styl"
@import "../../Core/Frames/TabStyle/F/Style/Pc/index.styl"

@import "../../../NewMaker/Components/ImageDropBox/F/Style/Pc/index.styl"

@import "../../Quotation/F/Style/Pc/index.styl"
@import "../../ChannelTalkModal/F/Style/Pc/index.styl"

@import "../../ShareProduct/F/Style/Pc/index.styl"
@import "../../ShareProduct/Expired/F/Style/Pc/index.styl"
@import "../../PromptModal/F/Style/Pc/index.styl"

@import "../../../NewMaker/Main/Pc/F/Style/Pc/index.styl"
@import "../../../GlobalPortOne/RefundBank/F/Style/Pc/index.styl"

@import "../../../Auth/LoginResult/F/Style/Pc/index.styl"
@import "../../../Auth/LoginModal/F/Style/Pc/index.styl"
@import "../../../Auth/LoginError/F/Style/Pc/index.styl"
