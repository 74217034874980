ic-close = url("//s3.marpple.co/files/u_18054/2018/9/original/f_5853_1537163999801_xZp4kmo6Zse1am5Ts1h9W.svg")
down_close = url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png);
body
  #don_frame_screen
    top 0
    left 0
    right 0
    bottom 0
    position fixed
    width 100%
    height 100%
    background transparent
    z-index 2147483647
  .don_frame
  .don_page
  .don_tab
    display none
    &[hide_frame_button_type=""]
      >.don_wrapper
        >.header
          .don_hide_frame
            display none

    >.don_wrapper
      position relative
    &.is_show
      display block
  .don_frame
  .don_page
    >.don_wrapper
      >.header
        >.don_back_page
          z-index 3
        >.title
          z-index 1
        >.tab_buttons
          z-index 1
        >.don_hide_frame
          z-index 3
  .don_frame[frame_index="0"]
    .don_hide_frame
      display none !important
  .don_frame
    >.don_wrapper
      height 100%
      >.body
        height 100%
    .don_hide_frame
    .don_back_page
    .tab_button
      padding 0
      margin 0
      border 0
      cursor pointer
    &.no_header[hide_frame_button_type="V"]
    &.no_header[hide_frame_button_type="v"]
      >.don_wrapper
        >.body
          z-index 1 !important
          box-shadow 0 2px 7px 2px #aaaa
        >.header
          z-index 2 !important
          .title
            display none
    &[hide_frame_button_type="V"]
    &[hide_frame_button_type="v"]
      >.don_wrapper >.body
        z-index 2
      >.don_wrapper >.header
        z-index 1
        background transparent
        .title
          box-shadow 0 2px 7px 2px #aaaa
          position absolute
          top 30px
          min-height 30px
          width 100%
          background white
        .don_hide_frame
          transform translateX(-50%)
          left 50%
          position absolute
          width 50px
          height 22px
          text-align center
          border none
          top 9px
          box-shadow: 0 -1px 10px 0px #aaaa
          border-radius 2px 2px 0 0
          background #fff url("//s3.marpple.co/file/u_18053/2018/7/original/f_5654_1532605972210_fSO2BpOAb5g7y7S4Afgr.png") no-repeat 50% 54%
          background-size 10px 6px
          &:focus
            outline none
          span
            color transparent
          span:after
            background #fff
            width 100px
            height 10px
            bottom -9px
            position absolute
            left -24px
            content ""
    .don_page
      >.don_wrapper
        >.header .title
          font-size 16px
          font-weight bold
          color color_black
      &[tab_length="0"]
      &[tab_length="1"]
        >.don_wrapper
          >.header
            .tab_buttons
              display none
      &[page_index="0"]
        >.don_wrapper
          >.header
            .don_back_page
              display none
      >.don_wrapper
        >.header
          position relative
          background #fff
          top 0
.infi_container
  opacity 0
  &.completed
    transition opacity .2s ease-in-out
    opacity 1
  .infi_view_more
    position absolute
    bottom 0
    left 0
    right 0
    &:before
      content ""
      background linear-gradient(to bottom, rgba(0,0,0,0), #fff 95%, #fff)
      display block
      height 200px
    button
      box-sizing border-box !important
      font-weight bold
      border 1px solid #000
      background #fff
      padding 0
      margin 0
      width 100%
      height 48px
  .infi_wrapper
    .infi_items
      >div.is_hide
        display none !important

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg) }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)  }
}


.don_frame[is_modal="true"]
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  &:before
    content ''
    position fixed
    top -100px
    left -100px
    bottom -100px
    right -100px
    background-color rgba(0,0,0,0.2)
  &[frame_index="1"]:before
    background-color rgba(0,0,0,0.4)
  > .don_wrapper
    position absolute
    top 0
    left 0
    bottom 0
    right 0
    margin auto
    width ptr(1036px)
    height ptr(800px)
    box-sizing border-box
    background-color #fff
    box-shadow 0 2px 16px 0 rgba(0, 0, 0, 0.1)
    border-radius ptr(20)
    overflow hidden
    > .body
      width 100%
      height 100%
      overflow auto
      box-sizing border-box
      >.don_page
        height 100%
        >.don_wrapper
          height 100%
          position static !important
          >.body
            height 100%
            >.don_tab
              height 100%
              >.don_wrapper
                height 100%
.don_back_page
  display block
  background-color transparent
  position absolute
  background-position 50% 50%
  background-repeat no-repeat
  background-size ptr(16) ptr(16)
  background-image url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icon-Arrow'%3E%3Cpath id='Rectangle 208' d='M10.667 14L4.66699 8L10.667 2' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A")
  width ptr(24)
  height ptr(24)
  top ptr(24)
  left ptr(24)
  border none
  outline none
  &:active
    background-color #ced4da
[hide_frame_button_type="X"]
[hide_frame_button_type="x"]
[hide_frame_button_type="V"]
[hide_frame_button_type="v"]
  >.don_wrapper
    >.header
      .don_hide_frame
        display block
        background-color transparent
        position absolute
        background-position 50% 50%
        background-repeat no-repeat
        background-size ptr(24) ptr(24)
        background-image url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath className='outline' d='M3.46875 2.53027L21.4688 20.5303M3.46875 20.5303L21.4688 2.53027' stroke='black' stroke-width='1.5' stroke-linecap='round' /%3E%3C/svg%3E")
        width ptr(36)
        height ptr(36)
        top ptr(18)
        right ptr(18)
        border none
        outline none
