.don_frame[frame_name="/modules/GlobalPortOne/RefundBank/S/Mui/frame.js"][is_modal="true"]
  @import '../share.styl'
  >.don_wrapper
    width ptr(540) !important
    height ptr(500) !important
  .don_page
    overflow hidden
    > .don_wrapper
      > .header
          .title
            padding ptr(25) 0
            font-size ptr(20)
            text-align center
            font-weight normal
            color #000
  .page_body
    padding ptr(32)
    select
    input
      margin-bottom ptr(8)
