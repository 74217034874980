@media screen and (min-width 1025px)
  .don_frame[frame_name='/modules/Dormant/Cert/S/Mui/frame.js']
    .dormant-cert-wrapper
      padding 96px 0
      display flex
      .dormant-cert
        display flex
        flex-direction column
        align-items center
        margin 0 auto
        width 992px
        &__logo
          img
            height 24px
        &__info
          width 100%
          display flex
          flex-direction column
          padding-bottom 50px
        &__title
          padding 0 0 20px 0
          margin 0
          line-height 1.08
          letter-spacing -0.5px
          font-size 24px
          font-weight bold
        &__content
          margin 4px 0
          padding 0
          line-height 1.6
          letter-spacing -0.2px
          font-size 15px
          color #111
          .bold
            font-weight bold
        &__privacy
          box-sizing border-box
          width 100%
          display flex
          flex-direction column
          gap 10px
          margin 20px 0
          padding 20px 24px
          background-color #f8f8f8
          &__data
            display flex
            gap 10px
          &__key
            width 128px
            color #828282
          &__value
            font-size 14px
            &.email
              font-weight 600
              color #111
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &[disabled]
            background-color #a2a2a2
            cursor default

@media screen and (max-width 1024px)
  .don_frame[frame_name='/modules/Dormant/Cert/S/Mui/frame.js']
    .dormant-cert-wrapper
      padding 60px 16px 80px
      display flex
      .dormant-cert
        width 100%
        display flex
        flex-direction column
        align-items center
        margin 0 auto
        &__logo
          img
            height 20px
        &__info
          display flex
          flex-direction column
          padding-top 40px
          padding-bottom 30px
        &__title
          padding 0 0 24px 0
          margin 0
          font-size 16px
          font-weight bold
        &__content
          margin 4px 0
          padding 0
          line-height 1.57
          letter-spacing -0.2px
          font-size 14px
          color #111
          .bold
            font-weight bold
        &__privacy
          width 100%
          box-sizing border-box
          &__data
            width 100%
            display flex
            gap 10px
          &__key
            letter-spacing -0.3px
            font-size 14px
            width 112px
            color #828282
          &__value
            width 173px
            font-size 14px
            word-wrap break-word
            &.email
              font-weight 600
              color #111
          display flex
          flex-direction column
          gap 12px
          margin 16px 0
          padding 16px
          background-color #f8f8f8
        &__buttons
          display flex
          flex-direction column
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &[disabled]
            background-color #a2a2a2
            cursor default
