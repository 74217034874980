

@media screen and (min-width 1025px)
  html[lang="jp"].my_info
    .mp_my_info_frame .my_info_modify .controls .control .input
      padding-left 190px

html.my_info
  .don_frame[frame_name="mp.checkout.change_email"]
    &[is_modal="true"]
      >.don_wrapper
        height 416px !important
    .notice
      display none
  @media screen and (min-width 1025px)
    .mp_my_info_frame
      background-color #f5f5f5
      .my_info_modify
        overflow hidden
        width 992px
        margin 0 auto 100px
        min-height 500px
        h1
          margin 86px 0 0
          padding 0
          font-size 32px
          margin-bottom 40px
        .controls
          padding 20px
          border-top 1px solid #000
          background-color #fff
          .dot
            border-top 1px dashed #eee
            margin-bottom 20px
          .control
            position relative
            width 100%
            margin 0 0 20px
            > label
              position absolute
              top 1px
              left 0
            .input
              padding 0 0 0 160px
              input[type="text"]
              input[type="number"]
                padding 5px 10px
              label
                margin-right 20px
                span
                  display inline-block
                  margin-left 5px
            &.mobile
            &.birthday
              span
                margin-left 20px
                color #666
            &.email
              button.btn_change_email
                margin-left 20px
                padding 5px 10px
                background-color #000
                border 1px solid #000
                font-size 14px
                text-align center
                color #fff
            &.password
              button.btn_change_pw
                padding 5px 10px
                background-color #000
                border 1px solid #000
                font-size 14px
                text-align center
                color #fff
        .buttons
          position relative
          width 100%
          margin-top 20px
          .btn_modify
            padding 10px 20px
            background-color #000
            border 1px solid #000
            font-size 14px
            text-align center
            color #fff
          .btn_withdrawal
            position absolute
            top 0
            right 0
            padding 10px 20px
            background-color #fff
            border 1px solid #ccc
            font-size 14px
            text-align center
            color color_black
    html[lang="kr"]
      .mp_user_withdrawal_frame
        > .don_wrapper
          width 680px !important
          height 600px !important
    .mp_user_withdrawal_frame
      > .don_wrapper
        width 680px !important
        height 460px !important
      .don_page > .don_wrapper > .header
        padding-top 35px
        text-align center
        .title
          display inline-block
          font-size 18px
      .user_withdrawal_area
        margin 50px
        .description
          font-size 13px
          line-height 24px
          color #666
          .br
            display block
          text-align center
        .point_area
          margin-top 40px
          text-align center
          padding 10px 20px
          border 2px solid #ccc
          color #666
          .point
            font-size 15px
            font-weight bold
            color color_black
        .buttons
          margin-top 40px
          text-align center
          .btn_withdrawal
            padding 10px 20px
            background-color #000
            border 1px solid #000
            font-size 14px
            text-align center
            color #fff

  @media screen and (max-width 1024px)
    .mp_my_info_frame
      .my_info_modify
        margin 0 0 100px
        h1
          margin 86px 0 0
          padding 0
          font-size 18px
          text-align center
          margin-bottom 20px
        .controls
          padding 20px
          border-top 1px solid #000
          background-color #fff
          .dot
            display none
          .control
            position relative
            width 100%
            margin 0 0 10px
            > label
              display block
              padding 5px
              font-size 12px
              background-color #eee
            .input
              margin-top 10px
              margin-bottom 20px
              padding 0
              input[type="text"]
              input[type="number"]
              input[type="tel"]
                padding 5px 10px
                border 1px solid #ccc
              label
                margin-right 20px
              span
                display inline-block
                margin-left 5px
                font-size 11px
              button.btn_change_email
                margin-left 20px
                padding 5px 10px
                background-color #000
                border 1px solid #000
                font-size 14px
                text-align center
                color #fff
              button.btn_change_pw
                padding 5px 10px
                background-color #000
                border 1px solid #000
                font-size 14px
                text-align center
                color #fff
            &.mobile
            &.birthday
              span
                margin-left 20px
                color #666
        .buttons
          position relative
          margin 0 4% 0
          padding-top 20px
          border-top 1px solid #ccc
          .btn_modify
            padding 10px 20px
            background-color #000
            border 1px solid #000
            font-size 14px
            text-align center
            color #fff
          .btn_withdrawal
            position absolute
            top 20px
            right 4%
            padding 10px 20px
            background-color #fff
            border 1px solid #ccc
            font-size 14px
            text-align center
            color color_black
    .mp_user_withdrawal_frame
      .don_page > .don_wrapper > .header
        padding-top 35px
        text-align center
        .title
          display inline-block
          font-size 18px
      .user_withdrawal_area
        margin 20px 6%
        .description
          font-size 13px
          line-height 24px
          color #666
        .point_area
          margin-top 20px
          text-align center
          padding 10px 20px
          border 2px solid #ccc
          color #666
          .point
            font-size 15px
            font-weight bold
            color color_black
        .buttons
          margin-top 40px
          text-align center
          .btn_withdrawal
            padding 10px 20px
            background-color #000
            border 1px solid #000
            font-size 14px
            text-align center
            color #fff