.omp-atom
  &__toggle
    position relative
    width 100%
    height 100%
    &>input[type="checkbox"]
      cursor pointer
      margin 0
      opacity 0
      width 100%
      height 100%
      position absolute
      z-index 1
      &:checked ~.omp-atom__toggle-background>.omp-atom__toggle-switch
        transform translateX(100%)
      &:checked ~.omp-atom__toggle-background
        background-color OG
  &__toggle-background
    display block
    width 100%
    height 100%
    position relative
    box-sizing border-box
    border-radius ptr(80)
    padding ptr(4)
    background-color GY_50
    transition background-color 0.2s
  &__toggle-switch
    display block
    height 100%
    aspect-ratio 1/1
    border-radius 100%
    background-color WHT
    transition transform 0.2s

.omp-atom
  &__checkbox
    cursor pointer
    position relative
    display flex
    align-items center
    width fit-content
    height fit-content
    gap ptr(8)
    &> input[type="checkbox"]
      display none
      &:checked
        ~.omp-atom__checkbox-box
          background-image url(//s3.marpple.co/files/u_14355/2018/10/original/f_12370_1540968742928_EEZcT5AfHu3P9SBPR2w.png)
  &__checkbox-box
    position relative
    display block
    width ptr(16)
    height ptr(16)
    background url(//s3.marpple.co/files/u_14355/2018/10/original/f_12369_1540968528808_wa9m8Im4uHIc9u0ORxMn.png) no-repeat
    background-size cover
  &__checkbox-title
    {font_14_r_140}

.omp-atom
  &__empty-checkbox
    cursor pointer
    position relative
    display flex
    align-items flex-start
    justify-content center
    width fit-content
    height fit-content
    gap ptr(8)
    &> input[type="checkbox"]
      display none
      ~.omp-atom__empty-checkbox-box-wrapper .omp-atom__empty-checkbox-box-check
        stroke transparent
      &:checked
        ~.omp-atom__empty-checkbox-box-wrapper .omp-atom__empty-checkbox-box-check
          stroke black
  &__empty-checkbox-box-wrapper
    width ptr(28)
    height ptr(28)
    &[data-style_size="small"]
      width ptr(20)
      height ptr(20)
      flex-shrink 0
  &__empty-checkbox-title
    {font_14_r_140}
