OG = #FF6B00
LO = #FFD0AD
BK = #000000
WHT = #FFFFFF
BG_MODEL = #E8E8E8
BG_PRODUCT = #F6F6F6
GY = #858585
GY_50 = #D8D8D8
GY_80 = #A5A5A5

OG_ERROR = #FF6B00

ICON_ARROW_LEFT = url(//s3.marpple.co/files/u_2232571/2023/6/original/a51f3fa7c27e0a9def9819d9933f8c05d7e532501.png)
ICON_ARROW_RIGHT = url(//s3.marpple.co/files/u_2232571/2023/6/original/8efbdeb3b0dc368f71dba818da2a2b0358831d283.png)
ICON_ARROW_UP = url(//s3.marpple.co/files/u_2232571/2023/6/original/445ef95ef3b73da337e4e1e14034cab169b4afd51.png)
ICON_ARROW_DOWN = url(//s3.marpple.co/files/u_2232571/2023/6/original/22df64ad77fa4198fa3c3af58ba5f3db01e4683d2.png)
ICON_ARROW_CIRCLE_LEFT = url(//s3.marpple.co/files/u_2283830/2023/6/original/36664d542b5fd1fd3c044efdc7034a4732b205e81.png)
ICON_ARROW_CIRCLE_RIGHT = url(//s3.marpple.co/files/u_2283830/2023/6/original/c60f57461146995205f6483ccbfbcfc8867c47ad2.png)
ICON_CART = url(//s3.marpple.co/files/u_2283830/2023/6/original/e0a01c21ed85aab1aacc97022db0c8f329d58cf91.png)
ICON_PLUS = url(//s3.marpple.co/files/u_2283830/2023/6/original/a68f935da4dfc0e6ce1eb8b2f08845f11af8eaa21.png)
ICON_PLUS2 = url(//s3.marpple.co/files/u_1504988/2023/10/original/ebd51329741ac9b78ff06f468fd6fb6a0bc6026a1.svg)
ICON_SEARCH = url(//s3.marpple.co/files/u_2232571/2023/6/original/f46b8b85b05a94411396d30adb71c9babd3827bb1.png)
ICON_AVATAR = url(//s3.marpple.co/files/u_2232571/2023/6/original/c94b5391bf1a99463e7652e298dc7a3182c0781b1.png)
ICON_EMPTY_HEART = url(//s3.marpple.co/files/u_2283830/2023/6/original/f79b96b3df8944fe943294901037845aaff8efba2.png)
ICON_EMPTY_HEART_GRAY = url(//s3.marpple.co/files/u_2283830/2023/6/original/2d871feb5557f2d98fe8e1aceecdf2b72b138f791.png)
ICON_FULL_HEART = url(//s3.marpple.co/files/u_2283830/2023/6/original/d2ce01a8edf2c7a9fdb1740b5c971a92e780c6451.png)
ICON_X = url(//s3.marpple.co/files/u_2232571/2023/6/original/16d0a2bb96f5e33b13ad4940c1dba5dbb11d4a5d1.png)
ICON_X_GRAY = url(//s3.marpple.co/files/u_2232571/2023/6/original/0554f8995ff7c3da01a7b03f89675577d781c8781.png)
ICON_DROP_DOWN = url(//s3.marpple.co/files/u_2283830/2023/6/original/e528bce6b77f403bc6dec65616480efdf95320cc1.png)


resetButton()
  margin 0
  padding 0
  border none
  background none

icon()
  display inline-block
  width 100%
  height 100%
  background-repeat no-repeat
  background-size contain
  content ' '

horizontalPadding(n)
  padding 0 ptr(n)

hideScrollBar()
  -ms-overflow-style none
  scrollbar-width none
  &::-webkit-scrollbar
    display none

gridColumns(n)
  display grid
  grid-template-columns repeat(n, minmax(0, 1fr))

gridRows(n)
  display grid
  grid-template-rows repeat(n, minmax(0, 1fr))

limitLine(l)
  display -webkit-box
  -webkit-box-orient vertical
  -webkit-line-clamp l
  overflow hidden

maker-option-select-style1(padding-td)
  border none !important
  border-bottom 1px solid BK !important
  background no-repeat url(//s3.marpple.co/files/u_193535/2023/7/original/dcba01bcdbefff186a378596095099b9631d75d71.svg) right 0 bottom 50% !important
  background-size ptr(12) ptr(12) !important
  appearance none !important
  -moz-appearance none !important
  -webkit-appearance none !important
  padding padding-td 0
  color black
