html.custom-detail-style-a
  .maker_menu
    .flip_h
    .flip_v
      display none !important
  .menu_down_list
    .reset
    .undo
    .redo
    .trash
      display none !important
  .open_chooser
    display none !important
  .btn_tip.how_to_custom
  .enter_marpple_shop
    display none !important
  .maker_menu
    .flip_h
    .flip_v
      opacity 0.2 !important
  .product_editor
    .open_chooser
      display none
  .decoration_menu_for_pc
    .open_chooser
    .open_cv_background_marpplizer
    .add_img
    .my_lib
    .add_text
    .add_sticker2
    .add_sticker_line
      display none
    .add_sticker_custom
      display block !important
  .sticker_image_list
    .image_item
      &[is_white_group="true"]
        .thumb
          padding 2px
          background-color #eee
          border-radius 4px
          background-size auto 78% !important
      &[is_white_image="true"]
        .white-image
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          border-radius 6px
          background-color rgba(0, 0, 0, 0.15)
      .is_require
        position absolute
        top 8px
        left 8px
        display flex
        flex-direction row
        justify-content center
        align-items center
        padding 3px 8px
        border-radius 999px
        background-color #dee9ff
        color OG
        font-size 12px
        font-family "Pretendard"





.shinhan-event-page
  position relative
  img
    width 100%
    vertical-align top
  &__btn1
    position fixed
    bottom 8%
    left 50%
    transform translateX(-50%)
    cursor pointer
    html.is-in &
      display none
  &__btn2
    display none
    position fixed
    bottom 8%
    left 50%
    z-index 10
    transform translateX(-50%)
    cursor pointer
    html.is-in &
      display block
    html.is-in2 &
      display none !important


html.shinhanfriends #footer
html.shinhanfriends2 #footer
html.shinhanfriends3 #footer
  display none
