.omp-my-point
  &__header
    margin ptr(24) 0 ptr(60) 0

  &__title
    {font_26_m_100}
    padding ptr(120) 0
    display inline-block

  &__guide
    display flex
    gap ptr(60)

  &__earn-guide__title
    {font_16_m_150}
  &__earn-guide__desc
    {font_16_r_150}
    margin 0
    padding 0
    margin-top ptr(4)
    padding-left ptr(16)

  &__policy-guide__title
    {font_16_m_150}
  &__policy-guide__desc
    {font_16_r_150}
    margin 0
    padding 0
    margin-top ptr(4)
    padding-left ptr(16)
  &__policy-guide__faq
    text-decoration underline !important

  &__log-title
    {font_20_m_100}
    margin 0
    margin-bottom ptr(20)
    display inline-block

  &__log-table
    text-align center
    tbody
      {font_16_r_150}
      tr:last-child
        border-bottom 1px solid GY_50
      td
        padding ptr(24) 0

  &__log-table__point
    color GY_80
    {font_16_r_150}
  &__log-table__point--plus
    color OG
