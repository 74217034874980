// modules/Auth/LoginError/F/Style/Pc/index.styl
.omp.login_error
  .omp-layout-body
    padding-top 0
    justify-content center
  .login_error
    &--wrapper
      display flex
      flex-direction column
      align-items center
    &--title
      margin-top ptr(48)
      font-size ptr(20)
      font-weight 700
      line-height 140%
    &--description
      margin-top ptr(8)
      color #A2A2A2;
      font-size ptr(16)
      font-weight 500
      line-height 150%
      text-align center
    &--contact
      margin-top ptr(8)
      color #FF6B00
      font-size ptr(16)
      font-weight 500
      line-height 150%
