
body.my_point
  #body
    .don_tab[tab_name="mp.point"]
      font-weight normal
      >.don_wrapper >.body
        padding 16px 16px 80px 16px
        .header_title
          margin 24px 0
          font-size 24px
          line-height 1.2
          color color_black
          font-weight bold
        .header_point
          background-color #fafafa
          .h
            display inline-block
            vertical-align top
          .total_point
            display inline-block
            vertical-align top
            font-weight bold
            float right
            color color_active
        .user_point_log_list
          .user_point_log_header
            padding 16px 0
            font-weight bold
            border-top 1px solid #000
            span
              display inline-block
              vertical-align top
              &:last-child
                text-align right
          .user_point_log
            border-top 1px solid #d5dbe0
            >div
              display inline-block
              vertical-align top
            .date
              color color_gray3
            .point
              text-align right
              html[lang="en"] &
                font-family CircularStd
    @media screen and (min-width 1025px)
      .don_tab[tab_name="mp.point"]
        >.don_wrapper >.body
          max-width 992px
          margin auto
          padding 40px 0 120px 0
          min-height 424px
          .header_title
            margin 40px 0
          .header_point
            padding 39px 40px
            margin-bottom 40px
            .h
              line-height 42px
              font-size 20px
            .total_point
              line-height 42px
              font-size 32px
          .user_point_log_list
            .user_point_log_header
              span:nth-child(1)
                width 150px
              span:nth-child(2)
                width 685px
              span:nth-child(3)
                width 157px
            .user_point_log
              padding 24px 0
              .date
                width 150px
              .description
                width 685px
              .point
                font-size 16px
                width 157px
    @media screen and (max-width 1024px)
      .don_tab[tab_name="mp.point"]
        >.don_wrapper >.body
          .header_title
            margin 24px 0 16px
            font-size 16px
          .header_point
            padding 18px 16px
            margin-bottom 24px
            .h
              line-height 25px
            .total_point
              line-height 25px
              font-size 20px
          .user_point_log_list
            .user_point_log_header
              span:last-child
                float right
            .user_point_log
              padding 16px 0
              .date
                display block
                font-size 12px
                margin-bottom 8px
              .description
                font-size 12px
                max-width 80%
              .point
                font-size 16px
                top -2px
                position relative
                float right