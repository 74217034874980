
[frame_name="/modules/VectorEditor/Sticker/Free/PC/S/Mui/frame.js"]
  vector-editor-frame()
  position absolute
  left 0
  right 0
  top 0
  height 0
  > .don_wrapper
    position relative
    width 100%
    > .body
      position relative
      width 100%
  .don_page
    position relative
    width 100%
    > .don_wrapper
      position relative
      width 100%
      > .body
        position relative
        width 100%
  .don_tab
    user-select none
    position relative
    width 100%
    > .don_wrapper
      position relative
      width 100%
      height 100%
      display flex

      .left_container
        position relative
        width 0
        flex-grow 1
        height 100%
        background-color #FFFFFF
        .editor_container
          position absolute
          top 0
          right 0
          bottom 0
          left 0
          background-color #F8F9FA
          .free_template
            transition-duration 1s
            transition-timing-function ease-in-out
            transition-property opacity
          .free_template[data-is_show="false"]
            opacity 0
          .free_template[data-is_show="true"]
            opacity 1
            [data-free-sticker-template-role="cutting-line"]
              cursor pointer
        .top_menu_container
          position absolute
          top 0
          left 0
          right 2px
          height 66px
          background-color #FFFFFF
          top-menus()
        .new_sticker_button
          position absolute
          display flex
          align-items center
          justify-content center
          width 190px
          height 56px
          box-sizing border-box
          margin 0
          padding 0
          border 0 none
          color #000000
          background-color #FFFFFF
          border-radius 28px
          top 235px
          left 56px
          box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.05)
          transform translateX(0)
          transition transform 1s
          .icon
            display inline-flex
            width 26px
            height 26px
            margin-right 8px
            background-repeat no-repeat
            background-position center center
            background-size 100% 100%
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-sticker@2x.png")
          &[data-is_show="false"]
            transform translateX(-200%)
          &:active, &:hover
            color #FFFFFF
            background-color OG
            box-shadow 0 2px 10px 0 rgba(1, 87, 255, 0.3)
            .icon
              background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-sticker-wht@2x.png")

      .right_container
        @keyframes hide_to_right {
          from {
            opacity 1
            transform translateX(0)
            visibility visible
          }
          to {
            opacity 0
            transform translateX(20%)
            visibility hidden
          }
        }
        @keyframes hide_to_left {
          from {
            opacity 1
            transform translateX(0)
            visibility visible
          }
          to {
            opacity 0
            transform translateX(-20%)
            visibility hidden
          }
        }
        @keyframes show_to_right {
          from {
            opacity 0
            transform translateX(-20%)
          }
          to {
            opacity 1
            transform translateX(0)
          }
        }
        @keyframes show_to_left {
          from {
            opacity 0
            transform translateX(20%)
          }
          to {
            opacity 1
            transform translateX(0)
          }
        }
        vector-editor-right_container()
        height 98%

        overflow hidden
        .right_wrapper
          width 100%
          height 100%
          display flex
          flex-direction column
          box-sizing border-box
          padding 40px 0 32px 0
          background-color #FFFFFF
          overflow hidden

          .panel_container
            width 100%
            height 0
            flex-grow 1
            position relative
            overflow hidden
            .panel_wrapper
              width 100%
              height 100%
              position absolute
              display flex
              flex-direction column
              animation-duration 200ms
              animation-timing-function ease-in-out
              animation-iteration-count 1
              animation-fill-mode forwards
              &[data-panel="home"][data-is_show="false"]
                animation-name hide_to_left
              &[data-panel="home"][data-is_show="true"]
                animation-name show_to_right
              &[data-panel="select"][data-is_show="false"]
                animation-name hide_to_right
              &[data-panel="select"][data-is_show="true"]
                animation-name show_to_left
              .inner_wrapper
                width 100%
                height 0
                flex-grow 1
                position relative
                @media screen and (max-width vector-editor-short-version-width)
                  setting-background-right-panel(24px, 30px)
                @media screen and (min-width vector-editor-short-version-min-width)
                  setting-background-right-panel(28px, 40px)
              .button_container
                width 100%
                height 56px
                display flex
                justify-content space-between
                box-sizing border-box
                padding 0 40px 0 40px
                button
                  height 100%
                  margin 0
                  padding 0
                  border 0 none
                  width 100%
                  margin-right 8px
                  &:last-child
                    margin-right 0
                  &.cancel
                    background-color #FFFFFF
                    box-sizing border-box
                    border 1px solid #D5DBE0
                    color #000000
                  &.done
                    background-color #000000
                    color #FFFFFF
              .right_panel_select
                position absolute
                width 100%
                height 100%
                background-color #FFFFFF
                display flex
                flex-direction column
                box-sizing border-box
                padding 0 40px
                .header
                  position relative
                  width 100%
                  height 22px
                  display flex
                  align-items center
                  justify-content center
                  .back
                    position absolute
                    left 0
                    top 50%
                    transform translateY(-50%)
                    background-color transparent
                    background-image url("//s3.marpple.co/files/u_1187077/2021/5/original/2aac30aabf4af156704b95903af308892eefb75a1.png")
                    width 19px
                    height 16px
                    margin 0
                    padding 0
                    border 0 none
                  .title
                    font-size 19px
                .wrapper
                  width 100%
                  height 0
                  flex-grow 1
                  display flex
                  flex-direction column
                  align-items center
                  justify-content center
                  .description
                    display inline-flex
                    align-items center
                    margin-bottom 16px
                    .icon
                      margin-right 6px
                    p
                      margin 0
                      font-weight bold
                  .edit
                    width 100%
                    height 48px
                    background-color #FFFFFF
                    border 1px solid #D5DBE0
                    margin 0
                    box-sizing border-box

      .blank_plate
        position fixed
        top 0
        right 0
        bottom 0
        left 0
        display none
        &[data-is_show="true"]
          display block
