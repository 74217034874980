@media screen and (min-width 1025px)
  .don_frame[frame_name='/modules/Dormant/Return/S/Mui/frame.js']
    .dormant-return-wrapper
      padding 80px 0 129px
      display flex
      .dormant-return
        display flex
        flex-direction column
        align-items center
        margin 0 auto
        width 480px
        &__title
          width 100%
          padding 0 0 24px 0
          margin 0
          font-size 24px
          font-weight bold
        &__info
          width 100%
          display flex
          flex-direction column
          padding-bottom 40px
        &__privacy
          box-sizing border-box
          width 100%
          display flex
          flex-direction column
          gap 10px
          padding 20px 24px
          background-color #f8f8f8
          &__data
            display flex
            gap 10px
          &__key
            width 128px
            color #828282
          &__value
            font-size 14px
            font-weight 600
        &__password-new
          width 100%
          margin-bottom 16px
        &__password
          width 100%
          display flex
          flex-direction column
          margin-bottom 24px
          gap 16px
          &__input-wrapper
            display flex
            align-items center
            justify-content space-between
            gap 8px
          &__title
            width 100px
            font-size 14px
            font-weight normal
            color #111
            padding 0
            margin 0
            &.bold
              font-weight 600
              margin-bottom 8px
          &__value
          &__value-check
            height 40px
            box-sizing border-box
            outline none
            flex-grow 1
            padding 0 16px
            border solid 1px #d5dbe0
            font-size 14px
            color #333
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &.no-equal
              border solid 1px #f66565
          &__no-equal
            padding-left 108px
            display none
            font-size 13px
            margin 8px 0 0 0
            font-weight 500
            line-height 1.31
            letter-spacing -0.5px
            text-align left
            color #f66565
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #a2a2a2
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &:hover
            cursor default
          &.enable
            background-color #111
            &:hover
              cursor pointer

@media screen and (max-width 1024px)
  .don_frame[frame_name='/modules/Dormant/Return/S/Mui/frame.js']
    .dormant-return-wrapper
      padding 60px 16px 80px
      display flex
      .dormant-return
        padding-top 40px
        display flex
        flex-direction column
        align-items center
        margin 0 auto
        width 100%
        height 100%
        &__title
          width 100%
          font-size 14px
          font-weight 600
          text-align left
          color #000
          padding 0 0 24px 0
          margin 0
        &__info
          width 100%
          display flex
          flex-direction column
          padding-bottom 40px
        &__privacy
          box-sizing border-box
          width 100%
          display flex
          flex-direction row
          justify-content flex-start
          align-items center
          gap 10px
          padding 16px
          background-color #f8f8f8
          &__data
            display flex
            gap 10px
          &__key
            font-size 14px
            width 90px
            color #828282
          &__value
            font-size 14px
            font-weight 600
        &__password
          width 100%
          display flex
          flex-direction column
          gap 16px
          &__input-wrapper
            display flex
            align-items center
            justify-content space-between
            gap 8px
          &__title
            width 64px
            font-size 14px
            font-weight normal
            color #000
            padding 0
            margin 0
            &.bold
              width fit-content
              font-weight 600
              margin-bottom 8px
          &__value
          &__value-check
            box-sizing border-box
            outline none
            flex-grow 1
            height 40px
            padding 0 16px
            border solid 1px #dddddd
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &.no-equal
              border solid 1px #f66565
          &__no-equal
            padding-left 72px
            display none
            font-size 12px
            margin 8px 0 0 0
            font-weight 500
            line-height 1.31
            letter-spacing -0.5px
            text-align left
            color #f66565
        &__buttons
          margin-top 40px
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #a2a2a2
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &:hover
            cursor default
          &.enable
            background-color #111
            &:hover
              cursor pointer

