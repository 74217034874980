.omp-cell__product
  .omp-atom__badge
    display none
    &:nth-child(n+3)
      display none !important
/* 메인 */
.omp-home .omp-home__section__steady
  .omp-cell__product
    .omp-atom__badge[data-type="custom_badge"]
    .omp-atom__badge[data-type="order_quantity"]
    .omp-atom__badge[data-type="bp_first"]
    .omp-atom__badge[data-type="best"]
      display flex

/* 상품리스트, 상품만들기내 상품리스트, 검색 */
.omp-product-list-modal__body
.omp-search__result-wrap
.omp-product-list .omp-product-list__body
  .omp-cell__product
    .omp-atom__badge[data-type="custom_badge"]
    .omp-atom__badge[data-type="bp_first"]
    .omp-atom__badge[data-type="sale"]
    .omp-atom__badge[data-type="new"]
    .omp-atom__badge[data-type="moq"]
      display flex
/* 베스트, 마이페이지 베스트 */
.omp-my-wish__best-products
body.omp.best-products
  .omp-cell__product
    .omp-atom__badge[data-type="custom_badge"]
    .omp-atom__badge[data-type="order_quantity"]
    .omp-atom__badge[data-type="bp_first"]
    .omp-atom__badge[data-type="sale"]
    .omp-atom__badge[data-type="best"]
    .omp-atom__badge[data-type="new"]
    .omp-atom__badge[data-type="review"]
      display flex


.omp-home .omp-home__section__steady
.body.omp.new-products
  .omp-cell__product-text__product-description
    display block
