
.mp_maker[data-is_pdf_upload_type_designed="true"]
  .canvass
    display none !important
.upload-type-direct-result
  position relative
  width 50%
  margin-top ptr(-40)
  min-width ptr(480)
  max-width ptr(600)
  &__image
    background #fff
    width 100%
    padding-top 100%
    display flex
    justify-content center
    align-items center
    position relative
    img
      object-fit contain
      position absolute
      top 0
      left 0
      width 100%
      height 100%
  &__footer
    color GY
    text-align: center;
    font-size: ptr(16)
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    width 100%
    margin-top ptr(20)
  &__text
    display flex
    flex-direction column
    justify-content center
    align-items center
    svg
      margin-bottom ptr(6)
    border-radius ptr(12)
    padding ptr(20) ptr(32)
    margin-bottom ptr(40)
    width 100%
    background #fff
    box-sizing border-box
    .notice_detail
      font-size ptr(16)
      font-weight 400
      line-height 150%
      margin-bottom ptr(16)
      word-break keep-all
    .notice_period
      font-size ptr(16)
      font-weight 500
      line-height 150%
      color GY_80

.maker-bp-option-preview
  position relative
  margin-top ptr(-40)
  width 50%
  min-width ptr(500)
  max-width ptr(600)
  @media screen and (max-height 900px)
    width 400px
    min-width initial
    max-width initial
  &__image
    //background #fff
    width 100%
    padding-top 100%
    display flex
    justify-content center
    align-items center
    position relative
    img
      position absolute
      top 0
      left 0
      width 100%
      height 100%
  &__text
    display flex
    flex-direction column
    justify-content center
    align-items center
    border-radius ptr(12)
    left 50%
    color BK
    font-size: ptr(20);
    line-height: 160%;
    background #fff
    padding ptr(20) ptr(32)
    margin-bottom ptr(40)
