.omp-product-list-filter-modal-frame
  >.don_wrapper
    width ptr(500) !important
    height ptr(700) !important
  &__footer
    padding ptr(30)
    position absolute
    left 0
    right  0
    background WHT
    bottom 0
.omp-product-list-filter-modal
  display flex
  flex-direction column
  height 100%
  &__header
    padding ptr(30) ptr(30) 0
  &__title
    { font_16_m_100 }
    height ptr(24)
    margin-bottom ptr(24)
    display flex
    align-items center
  &__checked-filter-wrapper
    { font_12_m_100 }
    color GY
    padding ptr(8) 0 ptr(12)
    min-height ptr(16)
    &[data-checked="true"]
      .omp-product-list-filter-modal__tt-select-filter
        display none
    &[data-checked="false"]
      .omp-product-list-filter-modal__checked-filter-items
        display none
  &__checked-filter-items
    display flex
    flex-wrap wrap
    &[data-list_show="false"]
      display none
  &__body
    border-top 1px solid GY_50
    overflow-y auto
    overflow-x hidden
    height 100%
  &__flex
    display flex
    align-items center
  &__filter-lists
    padding-bottom ptr(160)
  &__filter-list
    width 100%
    margin 0
    padding ptr(24) ptr(16) ptr(24) ptr(30)
    display flex
    align-items center
    justify-content space-between
    background none
    border none
    border-bottom 1px solid GY_50
    { font_14_m_100 }
    cursor pointer
  &__filter-list-name
    margin-right ptr(4)
  &__filter-list-count[data-count="0"]
    display none
  &__filter-list-icon
    width ptr(16)
    height ptr(16)
  &__footer
    padding ptr(30)

.omp-product-list-filter-item-modal
  width ptr(500)
  height ptr(700)
  display flex
  flex-direction column
  &__header
    padding ptr(30) ptr(30) 0
  &__title
    { font_16_m_100 }
    margin ptr(-1) 0 ptr(24) ptr(16)
  &__body
    border-top 1px solid GY_50
    overflow-y auto
    overflow-x hidden
    height 100%
  &__flex
    display flex
    align-items center
  &__filter-items
    padding-bottom ptr(160)
  &__filter-item
    width 100%
    margin 0
    padding ptr(20) ptr(16) ptr(20) ptr(30)
    display flex
    align-items center
    justify-content space-between
    background none
    border none
    border-bottom 1px solid GY_50
    { font_14_m_100 }
    cursor pointer
  &__filter-item-name
    margin-right ptr(4)
    height ptr(24)
    display flex
    align-items center
  &__filter-item-icon
    width ptr(24)
    height ptr(24)
    &[data-checked="false"]
      display none
