.rangeslider__fill
  background OG
  box-shadow none !important
.rangeslider
  background GY_50
  box-shadow none !important
.rangeslider__handle
  border 1px solid OG
  background OG
.maker_range_wrapper
  &[only_number="true"]
    .range_wrapper
    .blank
      display none
  &[up_down_control="false"]
    .up_down_control
      display none
  .up_down_control
    cursor pointer
    .up
    .down
      height 50%
      width 25%
      >img
        position absolute
        left 50%
        transform translate(-50%, -50%)
    .up
      position absolute
      top 0
      right 0
      >img
        top 70%
    .down
      >img
        top 30%
      position absolute
      bottom 0
      right 0
  .number_wrapper
    border 1px solid #dee2e6
    position relative
    input
      box-sizing border-box
      position absolute
      -webkit-appearance: none;
      margin: 0;
      width 100%
      height 100%
      font-size 14px
      padding 0
      text-align center
      background-color #fff
      box-shadow none
      border none
