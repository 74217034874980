.omp-my-review
  margin-top ptr(24)

  &__tabs
    display flex
    margin 0
    padding 0

  &__tab
    width 100%
    list-style none
    padding ptr(16) 0 ptr(24) 0
    display flex
    gap ptr(8)
    justify-content center
    align-items center
    border-bottom 1px solid GY_80
    cursor pointer

  &__tab--active
    border-bottom-color BK
    .omp-my-review__tab-title
      color BK
    .omp-my-review__tab-count
      > span
        background-color OG
        color WHT

  &__tab-title
    {font_16_m_100}
    color GY_80

  &__tab-count
    min-width 1.5rem
    display flex
    align-items center
    > span
      {font_12_b_100}
      background-color GY_50
      color GY_80
      padding ptr(2) ptr(6)
      border-radius 999px
      min-width ptr(8)
      text-align center

  &__tab-count--hidden
    visibility hidden

  &__creatable-reviews
    thead
      display none
    .omp-my-review__write-btn
      resetButton()
      text-align center
      cursor pointer
      color OG !important
      border-color OG !important

  &__written-reviews
    .omp-review__contents-wrapper
      margin-top ptr(20)
  &__written-reviews--empty
    {font_16_r_150}
    padding ptr(38) 0
    display flex
    justify-content center

  &__event
    display flex
    flex-direction row
    justify-content center
    align-items center
    gap ptr(20)
    background BG_PRODUCT
    padding ptr(16) 0
  &__event-content
    text-align center
    display flex
    flex-direction row
    justify-content center
    align-items center
    font-family 'Pretendard'
    font-style normal
    font-weight 500
    font-size ptr(16)
    line-height 150%
    .icon
      margin-right ptr(12)
    .highlight
      font-weight 700
      color OG
  &__event-link
    color GY !important
    font-family Pretendard
    font-size ptr(14)
    font-style normal
    display flex
    align-items center
    justify-content center
    gap ptr(4)
    line-height 140%
  &__event-link-icon
    width ptr(12)
    height ptr(12)
