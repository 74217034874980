.sticker_etc_share_style
  .is_cursor_pointer
    cursor pointer
  .range_slider_wrapper
    display flex
    height 32px
    .title
      width 65px
      display flex
      align-items center
    .number_wrapper
      margin-left 20px
      .number_box
        width 80px
        display flex
        justify-content center
        align-items center
        text-align center
        height 100%
        vector-editor-border-radius-xs()
        position relative
        &[data-unit_name="%"]
          &:after
            content '%'
            position absolute
            top 50%
            transform translateY(-50%)
      &[data-up_down="true"]
        .number_box
          padding-right 6px
      &[data-key="opacity"]
        .number_box
          background url(//s3.marpple.co/files/u_193535/2021/5/original/a8977e40d9b9a95670f4615ac6b0bc68dc617b9c1.svg) no-repeat 6px 50%
          &[data-up_down="true"]
            padding-right 20px
      &[data-key="letter-spacing"]
        .number_box
          padding-right 0
          text-align center
          background url(//s3.marpple.co/files/u_193535/2021/5/original/6a095f9439165dcfdd31ac2b08d79b6ef5a42bf91.svg) no-repeat 6px 50%
          background-size 12px 7px
          &[data-up_down="true"]
            padding-right 20px
    .range_wrapper
      flex 1
      width 100%
      &[data-is_hidden_range_slider="true"]
        display none
    .number_wrapper
      position relative
      .up_down
        position absolute
        right 0
        display flex
        flex-direction column
        width 18px
        height 100%
        top 0
        >button
          height 100%
          border none
          outline none
        .up
          background url(//s3.marpple.co/files/u_193535/2021/5/original/dde69860a4142ca57e3796b41e6eb8c20e7de0941.svg) no-repeat 50% 75%
          background-size 6px 4px
        .down
          background url(//s3.marpple.co/files/u_193535/2021/5/original/d493403e593644cfb59442bc53a461cf02cdf4cb1.svg) no-repeat 50% 25%
          background-size 6px 4px


.sticker_etc_share_style
  .range_slider_wrapper
    height 32px
    .number_wrapper
      margin-left 20px
      .number_box
        width 80px
        &[data-unit_name="%"]
          &:after
            right 15px
.sticker_etc_share_style.rc_wrapper
  .range_slider_wrapper
    height 40px
    .number_wrapper
      margin-left 20px
      &[data-is_hidden_range_slider="true"]
        margin-left 0
      .number_box
        width 80px
        &[data-unit_name="%"]
          &:after
            right 18px
