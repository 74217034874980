// modules/OMP/Kit/Form/Result/F/Style/Pc/index.styl

.omp_biz-application-result
  display flex
  align-items center
  justify-content center
  flex-direction column
  width 80vw
  height 80vh
  h1
    {font_26_m_130}
  p
    text-align center
    margin-bottom ptr(40)
    {font_16_m_150}
  button
    width ptr(200)
    {font_16_m_150}
    padding ptr(12.5)
    color #fff
    cursor pointer
    background #000
    border-radius ptr(50)
    border none
