@media screen and (min-width 1025px)
  .don_frame[frame_name='/modules/User/ResetPassword/S/Mui/frame.js']
    // 로그인 창 z-index 100
    z-index 101 !important
    >.don_wrapper
      width 488px
      height fit-content
      display flex
      flex-direction column
      >.body >.don_page >.don_wrapper
        >.header
          height 63px
          display flex
          align-items flex-end
          justify-content center
          >.don_back_page
            display none
          >.title
            font-size 20px
            font-weight normal
            text-align center
            color #000
    .reset-password-wrapper
      box-sizing border-box
      width 100%
      height 417px
      padding 40px
      display flex
      .reset-password
        width 100%
        display flex
        flex-direction column
        align-items center
        justify-content space-between
        margin 0 auto
        &__email
          width 100%
          display flex
          align-items center
          justify-content space-between
          gap 8px
          &__title
            width 72px
            font-size 16px
            color #000
            padding 0
            margin 0
          &__value
            height 40px
            box-sizing border-box
            outline none
            flex-grow 1
            padding 0 16px
            border solid 1px #d5dbe0
            font-size 14px
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &[disabled]
            background-color #a2a2a2
            cursor default
@media screen and (max-width 1024px)
  .don_frame[frame_name='/modules/User/ResetPassword/S/Mui/frame.js']
    >.don_wrapper
      width 100%
      height fit-content
      display flex
      flex-direction column
      >.body >.don_page >.don_wrapper
        >.header
          box-sizing border-box
          height 63px
          display flex
          padding-top 25px
          align-items flex-start
          justify-content center
          >.don_back_page
            display none
          >.title
            color #212529
            font-size 16px
            font-weight 600
            text-align center
          >.don_hide_frame
            top 25px
            right 17px
            width 14px
            height 14px
    .reset-password-wrapper
      box-sizing border-box
      width 100%
      padding 16px
      display flex
      .reset-password
        width 100%
        display flex
        flex-direction column
        align-items center
        justify-content space-between
        margin 0 auto
        &__email
          width 100%
          display flex
          align-items center
          justify-content space-between
          gap 8px
          &__title
            width 64px
            font-size 14px
            color #000
            padding 0
            margin 0
          &__value
            height 40px
            box-sizing border-box
            outline none
            flex-grow 1
            padding 0 16px
            border solid 1px #d5dbe0
            font-size 14px
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
        &__buttons
          box-sizing border-box
          position fixed
          bottom 24px
          padding 0 16px
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &[disabled]
            background-color #a2a2a2
            cursor default