order-table-header =
  display flex
  font-size 14px
  font-weight bold
  box-sizing border-box
  border-bottom 1px solid #d5dbe0
  >span
    display flex
    align-items center
    justify-content center
    height 48px

body.order
  .header_title
    margin 40px 0
    font-size 24px
    font-weight bold
    line-height 1.2
    @media screen and (max-width 1024px)
      font-size 16px
      margin 24px 0 16px
  button.write_a_review
    background-color #fff
    padding 8px 16px
    margin 8px 0 0 0
    border 1px solid #d5dbe0
    span
      display inline-block
      vertical-align top
    &:before
      content ''
      width 10px
      height 10px
      margin-right 8px
      display inline-block
      background-image url(//s3.marpple.co/files/u_29089/2018/12/original/f_18089_1545297131653_k1iBmf5WhrHL0PP5h5GG.png)
      background-size 10px 10px
  button.btn_review_end
    background-color transparent
    padding 0 16px
    margin 8px 0 0
    border none
  #body
    .don_tab.order_list_tab
      font-weight normal
      >.don_wrapper .order_body
        padding 16px 16px 80px 16px
        .empty_message
          height 50vh
          {flex-center}
          flex-direction column
          .img
            background svg-empty-order no-repeat center
            background-size contain
            height 80px
            width 80px
            margin-bottom 24px
          .message
            font-size 16px
            text-align center
            color color_black
        .prj_item
          padding 24px 0
          border-bottom 1px solid #d5dbe0
        .item_header
          margin-bottom 16px
          font-size 14px
          color color_gray3
          .no:before
            content ''
            font-size 1px
            margin 0 8px
            border 1px solid #dee2e6
        .item_body
          display flex
          .thumb
            width 96px
            height 96px
            margin-right 24px
          .up_c_info
            font-size 14px
            .title
              width 380px
            .title >*
              margin-bottom 6px
            .price
              color color_black
              .discount_price
                margin-right 8px
                color #ea291f
              .origin_price.discounted
                text-decoration line-through
                color color_gray3
            .status
              font-weight bold
              >div
                margin-top 8px
                .write_a_review
                  font-weight 400
                  text-decoration underline
    @media screen and (min-width 1025px)
      .don_tab.order_list_tab
        >.don_wrapper .order_body
          max-width 992px
          margin auto
          padding 40px 0 120px 0
          min-height 424px
          .empty_message
            border none
          .prj_list
            border-top 1px solid #000000
            .table_header
              {order-table-header}
              span:nth-child(1)
                width 440px
                margin-right 32px
              span:nth-child(2)
                width 224px
                margin-right 32px
              span:nth-child(3)
                width 160px
                margin-right 32px
            .up_c_info
              display flex
              align-items center
              position relative
              width 100%
              >*
                position absolute
              .price
                width 100px
                left 414px
                text-align center
              .status
                text-align center
                width 160px
                left 608px
              .view_btn
                {flex-center-center}
                right 0
                width 84px
                height 32px
                font-size 12px
                font-weight 600
                background-color #ffffff
                border solid 1px #d5dbe0

    @media screen and (max-width 1024px)
      .don_tab.order_list_tab
        >.don_wrapper .order_body
          .prj_list
            .up_c_info
              .price
                margin-top 8px
              .status
                margin-top 16px

    .don_tab[tab_name="mp.order.detail"]
      font-weight normal
      >.don_wrapper .tab_body
        background-color #f5f5f5
        &:not([prj_status="ordering"]):not([prj_status="before_print"]) .status_summary
          .controls >.cancel_order
            display none !important
        &[prj_status="before_print"] .status_summary
          .progress_line
            width calc(100% * 0.1)
        &[prj_status="printing"] .status_summary
          .progress_line
            width calc(100% * 0.27)
        &[prj_status="before_deliver"] .status_summary
          .progress_line
            width calc(100% * 0.52)
        &[prj_status="delivering"] .status_summary
          .progress_line
            width calc(100% * 0.75)
        &[prj_status="before_confirm"] .status_summary
          .progress_line
            width 100%
        &[prj_status="confirmed"] .status_summary
          .progress_line
            width 100%
        .card
          background-color #ffffff
          >.title
            font-size 14px
            font-weight bold
            color color_black
        .status_summary
          .progress
            padding-bottom 24px
            border-bottom 1px solid #d5dbe0
            .status_title
              font-weight bold
              text-align center
              color color_black
            .progress_bar
              position relative
              height 8px
              width 100%
              border-radius 4px
              background-color #e9ecef
              margin 16px 0
              .progress_line
                position absolute
                left 0
                top 0
                bottom 0
                border-radius 4px
                background-color color_active
                height 8px
            .status_labels
              {flex-center-between}
              font-size 12px
              color color_black
          .info_and_controls
            .info
              font-size 12px
              color color_black
              .order_number
                {flex-center-between}
                .tracking
                  text-decoration underline
                  color color_active
            .controls
              display flex
              .btn
                font-size 12px
                letter-spacing 1px
                background-color #ffffff
                border solid 1px #d5dbe0
                text-align center
                padding 8px
                margin-left 8px
                position relative
                &:first-child
                  margin-left 0
                span
                  position relative
                  z-index 2
                &.additional_payment
                  font-weight bold
                  &:before
                    content: '';
                    position: absolute;
                    bottom: 10px;
                    right: 50px;
                    left: 50px;
                    height: 9px;
                    background-color: #ffff80;
                    z-index 1
        .ordered_items
          .up_c_item
            position relative
            padding 24px 0
            border-top none !important
            .item_body_wrap2
              position relative
              &[is_wrote="true"]
                .product_price
                  position absolute
                  bottom 0
                  right 0
              &[is_review="true"]
                .product_price
                  position absolute
                  bottom 8px
                  right 0
            &:not(:last-child)
              border-bottom solid 1px #d5dbe0
        .bottom
          .special_requests, .shipping_address
            .text
              font-size 14px
              line-height 1.71
              padding 16px 0 24px
          html[collabo_type="line"] &
            .special_requests
              display none
          html[lang="kr"] &
            .shipping_address
              position relative
              &:after
                position absolute
                top 0
                right 0
                content '택배'
                @media screen and (max-width 1024px)
                  top 24px
                  right 16px
              &[type="quick"]:after
                content '퀵배송'
              &[type="combined"]:after
                content '묶음배송'
              &[type="visit"]:after
                content '방문수령'
          .payment_summary
          .projection_payments
            @media screen and (min-width 1025px)
              margin-bottom 40px
            html[lang="kr"] &
              .total_quantity:after
                content '개'
            .title
              margin-bottom 16px
            .content
              font-size 14px
              margin-bottom 8px
              {flex-center-between}
              &.total_price
                margin-top 16px
                span:last-child
                  font-weight bold
              &.method
                border-top 1px solid #d5dbe0
                margin-top 16px
                padding-top 16px
            .req_receipt
              display inline-block
              position relative
              cursor pointer
              border 1px solid #d5dbe0
              padding 1px 4px
              margin-left 8px
              font-size 12px
              outline none
              &:active
                border-color #000
          .projection_payments
            padding-bottom 30px
            .title + .payment
              padding-top 0
            .payment
              border-bottom 1px solid #d5dbe0
              padding 12px 0px
              &:last-child
                border-bottom none
              &[paid_at="null"] .paid_amount
              &:not([paid_at="null"]) .amount
                display none

    @media screen and (min-width 1025px)
      .don_tab[tab_name="mp.order.detail"]
        >.don_wrapper .tab_body
          max-width 992px
          margin auto
          padding 40px 0 120px 0
          background-color #ffffff
          &[prj_status="before_print"] .status_summary
            .progress_line
              width calc(100% * 0.05)
          .title_and_summary
            margin-bottom 48px
          .status_summary
            padding-bottom 32px
            .progress
              .status_title
                font-size 20px
            .info_and_controls
              padding-top 16px
              {flex-center-between}
              .info
                {flex-center-between}
                font-size 14px
                .bar
                  width 1px
                  height 10px
                  margin 0 8px
                  background-color #d5dbe0
              .controls
                .btn
                  cursor pointer
                  width 168px
          .ordered_items
            margin-bottom 10px
            >.up_c_list
              margin-top 16px
              border-top 1px solid #000000
              border-bottom 1px solid #d5dbe0
              .item_body_wrap1
                align-items center
              .table_header
                {order-table-header}
                span:nth-child(1)
                  width 480px
                  margin-right 32px
                span:nth-child(2)
                  width 224px
                  margin-right 32px
                span:nth-child(3)
                  width 224px
              .up_c_info
                position relative
                display flex
                align-items center
                .title
                  width 380px
                .review_info
                  margin-top 8px
                  div
                    margin-top 8px
              .title_and_faces
                {flex-left-center}
                flex-direction column
                width 354px
                height 100%
                display flex
                .up_id
                  color color_gray3
                  padding-bottom 6px
                .title
                  line-height 1.5
                  .pc_name
                    html:not(.creator_store) &
                      display none
                  a
                    &:hover
                      text-decoration underline
                      &:after
                        background #eee
                    &:after
                      display inline-block
                      position relative
                      cursor pointer
                      border 1px solid #d5dbe0
                      padding 0px 4px
                      margin-left 8px
                      font-size 12px
                      content '재주문'
                      html.creator &
                        display none
              .sizes
                display flex
                width 288px
                margin 0
                .up_c_s_list
                  margin auto
                  max-width 100px
                  display flex
                  justify-content center
                  flex-direction column
                  height 100%
                .quantity
                  font-size 14px
                  {flex-center-right}
                  >span.qty
                    display none
                  >span.val
                    width 32px
                    height 24px
                    {flex-center-center}
                    background-color #ffffff
                    border solid 1px #e9ecef
              .product_price.pc_v
                {flex-center}
                position absolute
                left 645px
                top 0
                height 100%
                width 224px
          .projection_down_print
            html:not([collabo_type=""]) &
              display none
            form
              display inline-block
            button
              margin 0 10px 0 0
              padding 0
              text-decoration underline
              border 0 none
              background-color transparent

          .container.bottom
            margin-top 80px
            width 100%
            .card
              width 436px
              display inline-block
              vertical-align top
              >.title
                padding-bottom 16px
                border-bottom 1px solid #000000
            .special_requests
            .payment_summary
              margin-right 120px
        html[collabo_type="line"] &
          .container.bottom
            .card
              margin-right 0 !important
              &.special_requests
                display none
              &.shipping_address
                float right


    @media screen and (max-width 1024px)
      .don_tab[tab_name="mp.order.detail"]
        >.don_wrapper .tab_body
          .projection_down_print
            display none
          .card
            padding 24px 16px 0 16px
            &:not(.title_and_summary)
              margin-top 8px
          .status_summary
            padding 24px 0
            .info_and_controls
              padding-top 24px
              .info
                padding-bottom 16px
                .status_change_date
                  margin-bottom 8px
              .controls
                .btn
                  width 100%
          .up_c_item
            .title_and_faces
              .up_id
                color color_gray3
                padding-bottom 4px
              .title
                .pc_name
                  html:not(.creator_store) &
                    display none
                a
                  line-height 1.8
                  span
                    margin-right 6px
                  &:after
                    display inline-block
                    position relative
                    cursor pointer
                    border 1px solid #d5dbe0
                    padding 0px 4px
                    line-height 1.5
                    font-size 12px
                    content '재주문'
                    html.creator &
                      display none
            .title_and_faces *
            .sizes *
              font-size 13px
          .up_c_info
            width 100%
          .payment_summary
            padding-bottom 16px
            >div:last-child
              padding-bottom 16px

.don_frame.additional_or_change_pay_method
  .don_page
    > .don_wrapper
      > .header
        .title
          padding 40px 0
          font-size 20px
          text-align center
          font-weight normal
          color color_black
  .section_header
    font-weight bold
    margin-bottom 23px
  .payment_type_container
    margin 0 auto
    input[type="text"]
    select
      {checkout-order-input-look}
    select
      font-size 14px
      -webkit-appearance none
      -moz-appearance none
      background svg-down-arrow2 no-repeat
      background-position right 16px top 16px
      cursor pointer
      &:active
        {checkout-order-active-border}
      &:valid
        color color_black
      &:focus
        {checkout-order-active-border}
    .payment_types
      button
        font-weight normal
        font-size 14px
        width 109px !important
        margin-right 8px
        box-sizing border-box
        &:last-child
          margin-right 0 !important
    .payment_type
      margin-top 24px
  .options
    padding-top 8px
    button
      text-align center
      height 40px
      background-color #000
      font-size 14px
      color #ffffff
      border solid 1px #000
      font-weight 200
      cursor pointer
      box-sizing border-box
      padding 0
      &.cancel
        color color_black
        background #fff
        border solid 1px #dee2e6

@media screen and (min-width 1025px)
  .don_frame.additional_or_change_pay_method
    > .don_wrapper
      width 423px
      height 420px
    .payment_type_container
      width 343px
    &[frame_name="mp.order.change_pay_method"]
      .payment_types
        button
          font-weight normal
          font-size 14px
          width 167px !important
        button:last-child
          width 168px !important
    .options
      button
        width 100% !important

@media screen and (max-width 1024px)
  .don_frame.additional_or_change_pay_method
    .payment_type_container
      margin 0 16px
      width auto
      .payment_types
        button
          width 31.2% !important
          margin-right 2% !important
    &[frame_name="mp.order.change_pay_method"]
      .payment_type_container
        .payment_types
          button
            width 49% !important
    .options
        button
          width 100% !important

html:not([lang="kr"])
  body.order #body .don_tab[tab_name="mp.order.detail"] >.don_wrapper .tab_body .ordered_items >.up_c_list .title_and_faces .title a:after
    content 'reorder' !important














html.print_receipt
  #header
    display none
  .info
    margin 10px 0 30px
    table
      width 100%
      border-collapse collapse
      th
      td
        padding 5px 2px
        vertical-align middle
        border 1px solid #999
      .seal
        margin-left 10px
        width 40px
        vertical-align middle
      .total
        td
          &.total_price
            font-size 16px
            font-weight bold
            text-align right
  .print_receipt_area
    padding 0 40px
    box-sizing border-box
    width 1100px
    input[type="text"]
      border 0 none
      background transparent
    .btn_print_area
      padding 10px 0
      text-align center
      background-color #eee
      @media print
        display none
    .item_body_wrap1
      display flex
    .up_c_item
      position relative
      padding 24px 0
      border-top none !important
      .thumb
        display inline-block
      .item_body_wrap2
        position relative
        &[is_wrote="true"]
          .product_price
            position absolute
            bottom 0
            right 0
        &[is_review="true"]
          .product_price
            position absolute
            bottom 8px
            right 0
      &:not(:last-child)
        border-bottom solid 1px #d5dbe0
    .up_c_list
      margin-top 16px
      border-top 1px solid #000000
      border-bottom 1px solid #d5dbe0
      .item_body_wrap1
        align-items center
      .table_header
        {order-table-header}
        span:nth-child(1)
          width 480px
          margin-right 32px
        span:nth-child(2)
          width 224px
          margin-right 32px
        span:nth-child(3)
          width 224px
      .up_c_info
        position relative
        display flex
        align-items center
        .faces
          > *
            display inline-block
        .origin
          text-decoration line-through
          margin-right 8px
          font-weight 300
          text-align right
          color color_gray3
        .price
        .product_price
          color color_black
          .discount_price
            margin-right 8px
            color #ea291f
          .origin_price.discounted
            text-decoration line-through
            color color_gray3
      .title_and_faces
        {flex-left-center}
        flex-direction column
        width 300px
        height 100%
        display flex
        .title
          line-height 1.5
          a
            span
              margin-right 6px
            &:hover
              text-decoration underline
              &:after
                background #eee
            &:after
              display inline-block
              position relative
              cursor pointer
              border 1px solid #d5dbe0
              padding 0px 4px
              font-size 12px
              content '재주문'
      .sizes
        display flex
        width 200px
        margin 0
        .up_c_s_list
          margin auto
          max-width 100px
          display flex
          justify-content center
          flex-direction column
          height 100%
          .up_c_s_item
            .size
            .quantity
              display inline-block
        .quantity
          font-size 14px
          {flex-center-right}
          >span.qty
            display none
          >span.val
            width 32px
            height 24px
            {flex-center-center}
            background-color #ffffff
      .product_price.pc_v
        {flex-center}
        position absolute
        left 545px
        top 0
        height 100%
        width 224px
