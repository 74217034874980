.omp-cell
  &__content-large
    //width fit-content
    //height fit-content
    //width 100%
    height fit-content
    display flex
    flex-direction column
    gap ptr(8)
  &__content-large-thumbnail
    text-decoration none
    display block
    position relative
    //width ptr(535)
    //height ptr(280)
    aspect-ratio 535 / 280
    border-radius ptr(20)
    overflow hidden
    &>img
      width 100%
      height 100%
      object-fit cover
  &__content-large-text
    text-decoration none
    display flex
    position relative
    flex-direction column
    color BK
    gap ptr(8)
    padding-top ptr(8)
  &__content-large-title
    width fit-content
    cursor pointer
    height ptr(28)
    margin-top ptr(8)
    limitLine(1)
    { font_20_m_140 }
  &__content-large-description
    width fit-content
    cursor pointer
    height ptr(24)
    { font_16_r_150 }
    limitLine(1)
  &__content-product-wrapper
    //width ptr(535)
    height fit-content
    text-decoration none
    display flex
    align-items center
    justify-content space-between
    box-sizing border-box
    margin-top ptr(8)
    padding-top ptr(8)
    padding-right ptr(20)
    border-top 1px solid BG_PRODUCT
    &:first-child
      border-top 0 none
      margin-top 0 none
  &__content-product
    width fit-content
    height ptr(60)
    text-decoration none
    display flex
    gap ptr(10)
  &__content-product-thumbnail
    display flex
    align-items center
    justify-content center
    width ptr(60)
    height ptr(60)
    border-radius ptr(8)
    border 1px solid GY_50
    box-sizing border-box
    background BG_PRODUCT
    &>img
      //width 76%
      //height 76%
      width 90%
      height 90%
      object-fit cover
  &__content-product-text
    display flex
    flex-direction column
    gap ptr(4)
    height 100%
    justify-content center
    color BK
  &__content-product-name
    color GY
    {font_14_m_100}
    limitLine(1)
  &__content-product-minimum-price
    color OG
    {font_14_m_100}
  &__content-product-heart
    cursor pointer

  &__content
    text-decoration none
    width fit-content
    height fit-content
    max-width 100%
    display flex
    flex-direction column
    gap ptr(8)
  &__content-thumbnail
    text-decoration none
    display block
    position relative
    //width ptr(399)
    //height ptr(399)
    aspect-ratio 1 / 1
    border-radius ptr(20)
    overflow hidden
    &>img
      width 100%
      height 100%
      object-fit cover
  &__content-text
    display flex
    position relative
    flex-direction column
    color BK
    gap ptr(8)
    padding-top ptr(8)
  &__content-title
    width fit-content
    cursor pointer
    height ptr(28)
    limitLine(1)
    { font_20_m_140 }
  &__content-description
    width fit-content
    cursor pointer
    //height ptr(24)
    { font_16_r_150 }
