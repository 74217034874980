.omp-cell
  &__product
    width 100%
    a
      height fit-content
      display flex
      flex-direction column
      gap ptr(8)
  &__product-anchor
    color BK
    text-decoration none
  &__product-thumbnail
    cursor pointer
    display block
    position relative
    width 100%
    aspect-ratio 399 / 500
    border-radius ptr(20)
    overflow hidden
    &[data-bg="model"]
      background-color BG_MODEL
    &[data-bg="product"]
      background-color BG_PRODUCT
    &>img
      width 100%
      height 100%
      object-fit cover
    &:hover
      .omp-cell__product-create-wrapper
        display block
        opacity 1
  &__product-create-wrapper
    opacity 0
    transition all 0.3s
    position absolute
    bottom 0
    left 0
    width 100%
    height fit-content
    padding ptr(20)
    box-sizing border-box
    &.right
      left auto
      right 0
  &__product-badge-wrapper
    position absolute
    top 0
    left 0
    width 100%
    height fit-content
    padding ptr(20)
    box-sizing border-box
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    & > div
      display flex
      align-items center
    & .right
      svg
        width ptr(85)
        height ptr(14)

  &__product-text
    display flex
    position relative
    flex-direction column
    gap ptr(8)
    padding-top ptr(8)
    &__brand-name
      width fit-content
      cursor pointer
      height ptr(16)
      { font_16_m_100 }
    &__product-name
      width 85%
      margin-bottom ptr(4)
      cursor pointer
      //height ptr(28)
      { font_20_m_140 }
      limitLine(2)
      box-sizing border-box
    &__brand-name + &__product-name
      padding-right 0
    &__product-description
      width fit-content
      cursor pointer
      height ptr(24)
      { font_16_r_150 }
      display none
    &__product-color
      width fit-content
      cursor pointer
      display grid
      grid-template-columns repeat(20, minmax(0, 1fr))
      grid-gap 0 ptr(8)
    &__product-color-plus
      width ptr(12)
      height ptr(12)
      display flex
      align-items center
      justify-content center
      svg
        width ptr(10)
        height ptr(10)
        path
          stroke-width 2
    &__product-color-point
      width ptr(12)
      height ptr(12)
      border-radius 100%
      &[need_ccc="true"]
      &[data-color_code="#ffffff"]
      &[data-color_code="#fefefe"]
      &[data-color_code="#FFFDFA"]
      &[data-color_code="white"]
        border 1px solid GY_50
        box-sizing border-box
    &__price-wrapper
      display flex
      align-items center
      gap ptr(4)
    &__price
      height ptr(20)
      width fit-content
      cursor pointer
      { font_14_m_140 }
      color GY
      position relative
    &__minimum-price
      height ptr(20)
      width fit-content
      cursor pointer
      { font_14_m_140 }
      color black
      &.paper
        height: auto
        { font_16_m_150 }
      &.chip
        background-color LO
        padding ptr(4) ptr(8)
        border-radius ptr(8)
        { font_12_m_100 }
        height auto
    &__sale-percent
      margin-left ptr(4)
      height ptr(20)
      width fit-content
      cursor pointer
      { font_14_m_140 }
      color OG
      &.no-margin
        margin 0
    &__heart
      cursor pointer
      position absolute
      top ptr(7)
      right ptr(24)
    &__meta-wrapper
      display flex
      gap ptr(8)
      svg
        width ptr(14)
        height ptr(14)
        path
          fill GY !important
    &__meta-item
      display flex
      align-items center
      gap ptr(4)
    &__meta-label,
    &__meta-value
      { font_14_m_140 }
      color GY
      height ptr(20)

    &__tips
      margin-top ptr(4)
      display flex
      align-items flex-start
      gap ptr(8)
    &__tip
      display flex
      align-items center
      padding ptr(7) ptr(16) ptr(6)
      border-radius ptr(50)
      { font_12_m_140 }
      background BG_PRODUCT
      &.light-orange
        background rgba(255, 107, 0, 0.10)
      svg
        width ptr(16)
        height ptr(16)
        margin-right ptr(4)
