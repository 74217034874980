.omp-tag-editor
  margin ptr(30) 0
  display flex
  flex-direction column
  gap ptr(20)

  &__tags
    display flex
    gap 10px
    flex-wrap wrap

  &__tag
    .omp-atom__icon-x
      box-sizing content-box !important
    &[data-is_main="true"]
      .omp-atom__removable-button__text
        color OG
    &:hover
      .omp-atom__removable-button__text
        color BK

  &__label
    {font_16_b_100}
    display block
    margin-bottom ptr(12)

  &__tag-input
    {font_14_m_100}
    border-radius 8px
    padding ptr(6) ptr(10)
    width ptr(300)
    margin-right ptr(8)

  &__emoji-input
    {font_14_m_100}
    border-radius 8px
    padding ptr(6) ptr(10)
    width ptr(80)
    min-width ptr(80)

  &__submit-btn
    width ptr(100)
    display inline-block
    margin-left ptr(8)

  &__auto-complete
    width ptr(500)
    height ptr(350)
    overflow-y auto

  &__auto-complete-item
    {font_16_m_100}
    cursor pointer
    padding ptr(12)
    &[data-is_main="true"]
      color OG
    &[data-is_already_registered="true"]
      color GY
      text-decoration line-through
    &:hover
      {font_16_b_100}
      background-color BG_PRODUCT

