.omp-cart
  display flex
  flex-direction column
  gap ptr(24)
  &__header
    display flex
    flex-direction column
    gap ptr(16)
  &__header-title
    {font_26_m_130}
  &__header-steps
    display flex
    align-items center
    gap ptr(16)
  &__header-step
    {font_16_m_100}
    color GY
    &[data-active="true"]
      {font_16_b_100}
      color BK
  &__header-icon
    width ptr(16)
    height ptr(16)

  &__product-table
    .td-wrapper
      display flex
      align-items center
      justify-content center
      padding ptr(24) 0
      box-sizing border-box
      {font_16_r_150}
    .omp-cart__product
      position relative
    .omp-cart__footer
      display flex
      justify-content center
      padding ptr(16) 0 ptr(80)
    .omp-cart__add-color
      cursor pointer
      display flex
      gap ptr(4)
      align-items center
      color OG
      {font_14_m_140}
      &[data-disabled="true"]
        cursor default
        color GY
        display none
    .omp-cart__add-color-icon
      width ptr(12)
      height ptr(12)
      line-height 100%
    [data-key="product_number"]
      color GY
      flex-direction column
    [data-key="product_info"]
      justify-content flex-start !important
      gap ptr(20)
      .omp-cart
        &__product-thumbnail
          overflow hidden
          border 1px solid GY_50
          border-radius ptr(8)
          flex-shrink 0
          width ptr(120)
          height ptr(120)
          .expiration
            display flex
            align-items center
            justify-content center
            background-color BG_PRODUCT
            color GY
            text-align center
            {font_14_r_140}
            width 100%
            height 100%
        &__product-thumbnail-canvas-wrapper
          position relative
          width 100%
          padding-top 100%
          canvas
            position absolute
            width 200%
            height 200%
            top 0
            left 0
            transform scale(0.5)
            transform-origin 0 0
        &__product-info-wrapper
          overflow hidden
          flex-shrink 1
          display flex
          flex-direction column
          gap ptr(24)
        &__product-info
          display flex
          flex-direction column
          gap ptr(4)
        &__product-name
          {font_16_m_150}
        &__product-print
          color GY
          {font_14_r_140}
        &__product-file
          display flex
          flex-direction column
          gap ptr(4)
        &__product-file-wrapper
          display flex
          flex-direction column
          gap ptr(4)
        &__product-file-name
          color BK
          font-weight 500
          font-size ptr(14)
          line-height 140%
          text-decoration underline
          cursor pointer
          display flex
          min-width 0
          &.disabled
            color GY_80
            text-decoration none
        &__product-file-title
          text-overflow ellipsis
          white-space nowrap
          overflow hidden
        &__product-file-mimetype
          flex-shrink 0
        &__product-file-date
          color GY
          {font_14_r_140}
    [data-key="product_sizes"]
      display flex
      flex-direction column
      gap ptr(8)
      .omp-cart__product-size-set
        display flex
        align-items center
        gap ptr(16)
      .omp-cart__product-size
        min-width ptr(150)
        text-align center
      .omp-cart__product-quantity
        min-width ptr(80)
        text-align center
    [data-key="product_price"]
      flex-direction column
      gap ptr(4)
      {font_16_r_150}
      .discounted
        color GY
        text-decoration line-through

    [data-key="product_edit"]
      flex-direction column
      gap ptr(8)
      .omp-cart__product-delete
        position absolute
        top ptr(24)
        right ptr(16)
        width ptr(16)
        height ptr(16)
        border none
        outline none
        background-color transparent
        display flex
        justify-content center
        align-items center
        padding 0
        margin 0
      button
        width ptr(136)
        height ptr(34)
        border-radius ptr(8)
        border 1px solid BK
        outline none
        cursor pointer
        background WHT
        {font_14_m_100}

  &__button
    width ptr(400)
    height ptr(50)
  &__summary
    display flex
    flex-direction column
    align-items center
    margin-top ptr(110)
  &__summary-header
    width 100%
    display flex
    align-items center
    {font_16_m_100}
    background-color BG_PRODUCT
    padding-top ptr(40)
    border-radius ptr(20) ptr(20) 0 0
  &__summary-header-title
    text-align center
    flex 1 1 0
    height ptr(16)
    position relative
  &__summary-body
    align-items start
    width 100%
    display flex
    padding ptr(35) 0 ptr(40) 0
    {font_20_m_100}
    background-color BG_PRODUCT
    border-radius 0 0 ptr(20) ptr(20)
    margin-bottom ptr(40)
    .total-price
      flex 1 1 0
      display flex
      flex-direction column
      align-items center
      gap ptr(8)
      .delivery-fee-info
        font-size ptr(16)
        font-weight 400
        color #858585
        html[lang="kr"] &
          display none
  &__summary-body-value
    text-align center
    flex 1 1 0
    &[data-style_total="true"]
      {font_26_m_100}
      color OG
    &.shipping-price
      html[lang="en"] &
      html[lang="jp"] &
        display none
  &__shipping-price-notice-wrapper
    position absolute
    bottom ptr(75)
    width 100%
    height fit-content
    display flex
    align-items center
    justify-content center
    transform-origin bottom center
    &::after
      content ''
      position absolute
      bottom ptr(-8)
      background-repeat no-repeat
      background-size ptr(10) ptr(8)
      width ptr(10)
      height ptr(8)
      background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8' fill='none'%3E%3Cpath d='M4.6188 8L9.23761 0H0L4.6188 8Z' fill='%23E8E8E8'/%3E%3C/svg%3E")
  &__shipping-price-notice
    width fit-content
    background BG_MODEL
    padding ptr(12) ptr(24)
    display flex
    flex-direction column
    gap ptr(8)
    border-radius ptr(12)
    backdrop-filter blur(20px)
    transform-origin bottom center
    position relative
    {font_14_r_140}
    &> a
      display flex
      align-items center
      justify-content center
      gap ptr(4)
      & > span
        text-decoration underline
        {font_14_m_140}
  &__shipping-price-notice-desc
    {font_14_r_140}
    .og
      color OG
      {font_14_m_140}
  &__shipping-price-notice-more
    width ptr(16)
    height ptr(16)

  &__empty
    padding ptr(64) 0
    display flex
    flex-direction column
    gap ptr(24)
    text-align center
  &__empty-notice
    {font_26_m_100}
  &__empty-desc
    {font_16_m_130}
  .omp-cell__table
    tr
      border-top none
    tr:last-child
      border-bottom none
  &__buttons-quotation
    width ptr(136)
    height ptr(34)
    padding ptr(6) ptr(16)
    border-radius ptr(8)
    border 1px solid BK
    background-color WHT
    outline none
    cursor pointer
    {font_14_m_100}
