//.omp-product-list-modal-frame
//  >.don_wrapper
    //height ptr(800) !important

.omp-product-list-modal
  display flex
  flex-direction column
  background #fff
  height 100%
  .omp-product-list
    &__flex-space-between
      align-items center
    &__cate-lists
      margin-bottom ptr(20)
    &__checked-cate-list
      &[data-open="true"]
        svg
          transform rotate(90deg)
    &__checked-sort-name
     { font_16_m_100 }
    &__list
      display grid
      grid-template-columns repeat(3, minmax(0, 1fr))
      grid-gap ptr(32) ptr(8)
      padding-bottom ptr(30)
    &__sort-selector
      left auto
      right 0
      text-align right
  &__header
    padding ptr(64) ptr(30) ptr(4)
    z-index 1
  &__body
    overflow auto
    padding-right ptr(10)
    margin 0 ptr(20) 0 ptr(30)
  &__filter-open-btn
    display flex
    background none
    border none
    cursor pointer
    padding 0
    margin 0 ptr(32) 0 0
    align-items center
    { font_16_m_100 }
    padding-bottom ptr(20)
  &__filter-open-icon
    width ptr(24)
    height ptr(24)
    margin-right ptr(8)
  &__cate-lists
    display none
  &__checked-filter-items
    display flex
    flex-wrap wrap
    padding ptr(0) ptr(30) ptr(20)
    &[data-list_show="false"]
      display none
