top-menus()
  right 20px !important
  border-radius 20px
  .top_menus
    position absolute
    width 100%
    height 100%
    display flex
    align-items center
    justify-content space-evenly
    .top_menu
      display inline-flex
      width 40px
      height 46px
      flex-direction column
      align-items center
      justify-content center
      margin 0
      padding 0
      border 0 none
      background none
      cursor pointer
      .top_menu_icon
        width 18px
        height 18px
        display inline-block
        background-repeat no-repeat
        background-position center center
        background-size 18px auto
      .top_menu_label
        overflow visible
        font-size 10px
        white-space nowrap
        color #000000
        margin-top 5px
      &:active
        background-color #CED4DA
      &:disabled
        opacity 0.2
        cursor not-allowed
      &:not(:last-child)
        margin-right 10px
        @media screen and (max-width vector-editor-short-version-width)
          margin-right 0
      &[data-is_show="false"]
        display none

      &[data-top_menu_id="history_reset"]
        .top_menu_icon
          mp-editor-menu-refresh()
      &[data-top_menu_id="history_undo"]
        .top_menu_icon
          mp-editor-menu-undo()
      &[data-top_menu_id="history_redo"]
        .top_menu_icon
          mp-editor-menu-redo()
      &[data-top_menu_id="toggle_layers_mode"]
        .top_menu_icon
          mp-editor-menu-element-layer()
      &[data-top_menu_id="duplicate"]
        .top_menu_icon
          mp-editor-menu-duplicate()
      &[data-top_menu_id="delete"]
        .top_menu_icon
          mp-editor-menu-trash()
      &[data-top_menu_id="forward"]
        .top_menu_icon
          mp-editor-menu-forward()
      &[data-top_menu_id="backward"]
        .top_menu_icon
          mp-editor-menu-backward()
      &[data-top_menu_id="group"]
        .top_menu_icon
          mp-editor-menu-grouping()
      &[data-top_menu_id="ungroup"]
        .top_menu_icon
          mp-editor-menu-ungrouping()
      &[data-top_menu_id="flip_horizontal"]
        .top_menu_icon
          mp-editor-menu-flip-horizontal()
      &[data-top_menu_id="flip_vertical"]
        .top_menu_icon
          mp-editor-menu-flip-vertical()
      &[data-top_menu_id="move_to_left_end"]
        .top_menu_icon
          mp-editor-menu-align-left()
      &[data-top_menu_id="move_to_horizontal_center"]
        .top_menu_icon
          mp-editor-menu-align-center-horizontal()
      &[data-top_menu_id="move_to_right_end"]
        .top_menu_icon
          mp-editor-menu-align-right()
      &[data-top_menu_id="move_to_top_end"]
        .top_menu_icon
          mp-editor-menu-align-top()
      &[data-top_menu_id="move_to_vertical_center"]
        .top_menu_icon
          mp-editor-menu-align-center-vertical()
      &[data-top_menu_id="move_to_bottom_end"]
        .top_menu_icon
          mp-editor-menu-align-bottom()
