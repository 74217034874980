.don_frame.additional_or_change_pay_method.omp
  > .don_wrapper
    width ptr(500)
    height fit-content
    > .body
      > .don_page
        > .don_wrapper
          > .header
            > .title
              text-align center
              {font_26_m_130}
              padding ptr(30) 0 ptr(24)
            > .don_hide_frame
              top ptr(30)
              right ptr(30)
          > .body
            .section
              padding-bottom ptr(30)
            .section_header
              margin-bottom ptr(16)
            .txt
              text-align center
              {font_14_m_140}
            .options
              padding-top ptr(24)
              .submit
                {font_16_m_100}
                width 100%
                height ptr(50)
                border-radius ptr(50)
            .card_body
              .payment_types
                .payment_type.card
                .payment_type.trans
                .payment_type.without_bank
                  display none
                display flex
                align-items center
                justify-content center
                flex-direction column
                &[type="without_bank"] > .without_bank
                  display block
                &[type="card"] button[payment_type="card"]
                &[type="without_bank"] button[payment_type="without_bank"]
                &[type="trans"] button[payment_type="trans"]
                &[type="offline_card"] button[payment_type="offline_card"]
                &[type="offline_cash"] button[payment_type="offline_cash"]
                &[type="samsung"] button[payment_type="samsung"]
                &[type="payco"] button[payment_type="payco"]
                &[type="naverpay"] button[payment_type="naverpay"]
                &[type="global_port_one"] button[payment_type="global_port_one"]
                &[type="ssgpay"] button[payment_type="ssgpay"]
                &[type="lpay"] button[payment_type="lpay"]
                &[type="paypal"] button[payment_type="paypal"]
                &[type="alipay"] button[payment_type="alipay"]
                &[type="vbank"] button[payment_type="vbank"]
                &[type="tosspay"] button[payment_type="tosspay"]
                &[type="kakaopay"] button[payment_type="kakaopay"]
                &[type="PAYMENTWALL_CREDIT_CARD"] button[payment_type="PAYMENTWALL_CREDIT_CARD"]
                &[type="PORTONE_PAYMENTWALL_CREDIT_CARD"] button[payment_type="PORTONE_PAYMENTWALL_CREDIT_CARD"]
                &[type="PAYMENTWALL_KONBINI"] button[payment_type="PAYMENTWALL_KONBINI"]
                &[type="KOMOJU_PAY_EASY"] button[payment_type="KOMOJU_PAY_EASY"]
                &[type="KOMOJU_BANK_TRANSFER"] button[payment_type="KOMOJU_BANK_TRANSFER"]
                &[type="KOMOJU_KONBINI_DAILY_YAMAZAKI"] button[payment_type="KOMOJU_KONBINI_DAILY_YAMAZAKI"]
                &[type="KOMOJU_KONBINI_LAWSON"] button[payment_type="KOMOJU_KONBINI_LAWSON"]
                &[type="KOMOJU_KONBINI_FAMILY_MART"] button[payment_type="KOMOJU_KONBINI_FAMILY_MART"]
                &[type="KOMOJU_KONBINI_MINISTOP"] button[payment_type="KOMOJU_KONBINI_MINISTOP"]
                &[type="KOMOJU_KONBINI_SEICOMART"] button[payment_type="KOMOJU_KONBINI_SEICOMART"]
                  border-color OG !important
                  color OG !important
                .selector
                  display grid
                  grid-template-columns 1fr 1fr 1fr
                  gap ptr(8)
                  width ptr(300)
                  button
                    background WHT
                    cursor pointer
                    width 100% !important
                    height ptr(50) !important
                    {font_14_m_100}
                    font-size ptr(14)
                    border-radius ptr(12)
                    border 1px solid GY_50 !important
                    margin 0 !important
              .payment_type.without_bank
                margin-top ptr(40)
                .bank_info
                  display flex
                  flex-direction column
                  gap ptr(16)
                  input&::placeholder
                    color GY_80
                  input
                  select
                    border none
                    padding 0
                    margin 0
                    border-bottom 1px solid BK
                    {font_16_m_100}
                    height ptr(32)
                  select
                    background-repeat no-repeat
                    background-position right 0 top ptr(8)
                    background-size ptr(12) ptr(12)
                    background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 13 12' fill='none'%3E%3Cpath d='M6.5 9L2.5 3H10.5L6.5 9Z' fill='black'/%3E%3C/svg%3E")
            .don_tab[tab_name="mp.order.change_pay_method"]
              .card_body
                .selector
                  grid-template-columns 1fr 1fr
              .options
                padding 0

