#marpplizer
  display flex
  flex-direction column
  justify-content space-between
  .general_option_buttons
    padding 20px 40px 40px
    html[marpplizer_mode="short"] &
      padding 20px 30px 40px
    .condition_agree_text
      margin-bottom 32px
    > button
      border-radius 100px
      cursor pointer
      border none
      width 100%
      font-size 16px
      text-align center
      color WHT
      font-weight 500
      background-color BK
      display flex
      justify-content center
      align-items center
      padding 14px 0
    #to_vector_editor
      display none
      cursor pointer
      margin-bottom 16px
      background OG
      position relative
      text-align center
      color #fff
