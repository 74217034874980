.curation-pod-item
  position relative
  aspect-ratio 399 / 500
  &__anchor
    display block
    height 100%
  &__image
    position relative
    height 100%
    border-radius ptr(20)
    overflow hidden
    img
      width 100%
      height 100%
      object-fit cover
  &__dim
    position absolute
    inset 0
    background linear-gradient(180deg, rgba(0, 0, 0, 0.00) 40.1%, rgba(0, 0, 0, 0.50) 79.17%)
  &__meta
    position absolute
    inset auto 0 0
    padding ptr(24)
    display flex
    flex-direction column
    gap ptr(8)
  &__title
    {font_26_m_130}
    margin 0
    color #fff
    white-space pre-wrap
  &__sub-title
    {font_16_m_150}
    margin 0
    color #fff
    white-space pre-wrap
.curation-keyword-item
  &__anchor
    display block
  &__image
    aspect-ratio 1 / 1
    margin-bottom ptr(12)
    img
      width 100%
      height 100%
      object-fit cover
  &__title
    {font_20_m_140}
    margin 0
    text-align center
    white-space pre-wrap
