.omp-my-wish
  margin-top ptr(24)

  &__tabs
    display flex
    margin 0
    padding 0

  &__tab
    width 100%
    height ptr(56)
    list-style none
    display flex
    gap ptr(8)
    justify-content center
    align-items center
    border-bottom 1px solid GY_80
    cursor pointer

  &__tab--active
    border-bottom-color BK
    .omp-my-wish__tab-title
      color BK
    .omp-my-wish__tab-count
      > span
        background-color OG
        color WHT

  &__tab-title
    {font_16_m_100}
    color GY_80

  &__tab-count
    min-width 1.5rem
    display flex
    align-items center
    > span
      {font_12_b_100}
      background-color GY_50
      color GY_80
      padding ptr(2) ptr(6)
      border-radius 999px
      min-width ptr(8)
      text-align center

  &__tab-count--hidden
    visibility hidden

  &__content
    display flex
    flex-direction column
    gap ptr(24)

  &__wish-products
    margin-top ptr(24)
  &__wish-products:has(.omp-my-wish__empty)
    margin-top 0

  &__buy-later__product
    position relative

    .omp-my-wish__product-delete.divided-edit-column
      position absolute
      top ptr(24)
      right ptr(16)
      width ptr(16)
      height ptr(16)
      border none
      outline none
      background-color transparent
      display flex
      justify-content center
      align-items center
      padding 0
      margin 0

  &__empty
    padding ptr(64) 0
    display flex
    flex-direction column
    gap ptr(24)
    text-align center
  &__empty-title
    {font_26_m_100}
  &__empty-description
    {font_16_m_100}

  &__best-products__title
    {font_26_m_100}
    display inline-block
    margin-bottom ptr(16)

  &__add-cart-all
    width ptr(400)
    margin auto
    margin-top ptr(16)


  .omp-cart__add-color-wrapper
    display none
