/* [README]
  1. svg 태그를 이용하여 아이콘을 생성할 경우 `icon()` 함수를 넣을 필요 없습니다.
*/

.omp-atom
  &__icon-heart
    &--gray
      .outline
        stroke GY_80

  &__icon-badge
    position absolute
    display flex
    justify-content center
    align-items center
    box-sizing border-box
    border-radius 999px
    padding 0 ptr(4)
    right 0
    bottom 0
    margin 0
    min-height ptr(16)
    min-width ptr(16)
    color WHT
    background-color OG
    { font_10_m_100 }

  &__icon-count
    color GY
    { font_12_m_100 }

  &__icon-search
    &[data-color="GY"]
      path
        stroke GY
    &[data-color="WHT"]
      path
        stroke WHT

  &__icon-biz-group
    &[data-color="GY"]
      path
        fill GY
      circle
        fill GY
    &[data-color="WHT"]
      path
        stroke WHT
      circle
        fill WHT

  &__icon-avatar
    &[data-color="GY"]
      path
      circle
        stroke GY

  &__icon-double-arrow
    &[data-style_color="GY_50"]
      path
        stroke GY_50

    &[data-style_color="GY"]
      path
        stroke GY

    &[data-style_direction="right"]
      transform rotate(180deg)

    &[data-style_direction="up"]
      transform rotate(90deg)

    &[data-style_direction="down"]
      transform rotate(-90deg)

  &__icon-arrow
    &[data-style_color="GY_50"]
      path
        stroke GY_50

    &[data-style_color="GY"]
      path
        stroke GY

    &[data-style_direction="right"]
      transform rotate(180deg)

    &[data-style_direction="up"]
      transform rotate(90deg)

    &[data-style_direction="down"]
      transform rotate(-90deg)

  &__icon-arrow-circle
    &--right
      transform rotate(180deg)

  &__icon-cart
    position relative

  &__icon-x
    &[data-color="GY"]
      path
        stroke GY
    &[data-color="GY_80"]
      path
        stroke GY_80
    &[data-color="WHT"]
      path
        stroke WHT

  &__icon-home
    &[data-color="GY"]
      path
        stroke GY

  &__icon-new-best
    &[data-color="GY"]
      path
        stroke GY

  &__icon-review
    &[data-color="GY"]
      .omp-atom__icon-review__outline
        stroke GY
      .omp-atom__icon-review__star > path
        fill GY

  &__icon-star
    &[data-style_color="GY_50"]
      .omp-atom__icon-star-path
        fill GY_50
    &[data-style_color="BK"]
      .omp-atom__icon-star-path
        fill BK

  &__icon-customer
    &[data-color="WHT"]
      path
        stroke WHT

  &__icon-plus
    &[data-style_color="GY"]
      path
        stroke GY
    &[data-style_color="GY_50"]
      path
        stroke GY_50
    &[data-style_color="BK"]
      path
        stroke BK
    &[data-style_color="OG"]
      path
        stroke OG

  &__icon-radio
    &[data-is_checked="true"]
      .omp-atom__icon-radio__inner-circle
        fill OG
