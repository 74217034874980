.omp-my-page-orders
  width 100%

  &__wrapper
    margin ptr(24) 0

    &__status
      display flex
      justify-content space-between
      padding ptr(20) 0
      gap ptr(8)

      &-wrapper
        display flex
        flex-direction column
        align-items center
        flex 1 1 0
        gap ptr(8)

        &[data-active="false"]
          color GY_80

        &[data-active="true"]
          .omp-my-page-orders__wrapper__status-wrapper__top-count
            color OG
          color BK

        &__top
          display flex
          flex-direction column
          gap ptr(8)
          padding ptr(24) ptr(20)
          box-sizing border-box
          width 100%
          &[data-active="true"]
            border-radius ptr(20)
            background-color BG_PRODUCT

          &-count
            text-align center
            {font_26_m_100}

          &-title
            text-align center
            {font_16_m_100}


.don_dialog_msg:has(.omp-my-resubmit__wrapper)
  .content > .body > .msg
    max-width none
  .content
    padding 0
    gap 0
.omp-my-resubmit
  &__wrapper
    box-sizing border-box
    width ptr(500)
    height ptr(368)
    padding ptr(30)
    display flex
    flex-direction column
    justify-content space-between
    gap ptr(40)
    font-size ptr(16)
  &__header
    font-weight 500
    font-size ptr(26)
    line-height ptr(33.8)
    display flex
    align-items center
    justify-content center
  &__body
    display flex
    flex-direction column
    align-items center
    gap ptr(20)
  &__desc
    font-weight 500
    line-height ptr(24)
    text-align center
  &__agreed
    font-weight 500
  &__checkbox-wrapper
    position relative
    label
      display flex
      padding ptr(4) 0 ptr(2) ptr(32)
      {font_16_m_100}
      gap ptr(4)
    input
      height ptr(20)
      width ptr(20)
      position absolute
      top 0
      left 0
      opacity 0
    input + label::before
      content ''
      height ptr(20)
      width ptr(20)
      background-color WHT
      border ptr(2) solid BK
      border-radius ptr(3)
      position absolute
      top 0
      left 0
    input + label::after
      content ''
      border ptr(2) solid BK
      border-left 0
      border-top 0
      border-radius ptr(1.5)
      border-bottom-width ptr(1.5)
      border-right-width ptr(1.5)
      height ptr(10)
      width ptr(6)
      transform rotate(45deg)
      position absolute
      top ptr(4)
      left ptr(8)
      opacity 0
      transition opacity 0.2s ease-in-out
    input:checked + label::after
      opacity 1
  &__footer
    display flex
  &__upload-button
    background-color BK
    color WHT
    border-radius 999px
    font-weight 500
    font-size ptr(16)
    line-height ptr(16)
    flex 1 0 auto
    border none
    height ptr(50)
    cursor pointer
    display flex
    align-items center
    justify-content center
    &.disabled
      cursor default
      background-color GY_50
    > input[type="file"]
      display none

