.omp-cell
  &__table-wrapper
    width 100%
    {font_16_m_100}
  &__table-body
    &[data-style_inter_border_color="GY_50"]
      border-top 1px solid GY_50

    &.is_last
      &[data-style_border_color="BK"]
        border-bottom 1px solid BK

      &[data-style_border_color="GY_50"]
        border-bottom 1px solid GY_50

  &__table-header
    height ptr(50)

  &__table-page-buttons
    display flex
    gap ptr(20)
    align-items center

    span
      cursor pointer
      width ptr(18)
      height ptr(18)

      &[disabled="true"]
        cursor default

  &__table-pages
    display flex
    padding ptr(24) 0
    align-items center
    justify-content center
    gap ptr(20)

  &__table-page
    width ptr(20)
    text-align center
    cursor pointer

    {font_16_m_100}
    color BK
    &[data-active="true"]
      color OG

  &__table
    width 100%
    border-collapse collapse
    table-layout fixed

    th
      {font_16_m_100}
      border-bottom 1px solid BK
    th
    td
      &[data-size="11"]
       width ptr(180)
      &[data-size="12"]
        width ptr(200)
      &[data-size="15"]
        width ptr(250)
      &[data-size="18"]
        width ptr(300)
      &[data-size="470"]
        width ptr(470)
