
.omp-custom-card
  display flex
  justify-content space-between
  align-items flex-start
  &__thumbnail
    width 56%
    aspect-ratio 450 / 280
    border-radius ptr(20px)
    overflow hidden
    img
      width 100%
      height: 100%
      object-fit cover
  &__meta
    width 44%
    padding-left ptr(16px)
    box-sizing border-box
  &__prod-name
    {font_20_m_140}
    margin 0 0 ptr(8px)
  &__prod-desc
    {font_16_r_150}
    margin 0 0 ptr(8px)
  &__prices
    display flex
    justify-content flex-start
    align-items center
    {font_14_m_140}
    &-price
      color #858585
      text-decoration line-through
      margin 0 ptr(8px) 0 0
    &-minimum
      margin 0
      color #ff6b00
  &__anchor
    {font_16_m_100}
    display inline-block
    margin-top ptr(16px)
    padding ptr(11.5px) ptr(32px) ptr(10.5px)
    border 1px solid #000
    border-radius 20px

.omp-best-card
  display flex
  justify-content space-between
  align-items flex-start
  gap ptr(16)
  &__thumbnail
    position relative
    width 50%
    aspect-ratio 260 / 325
    border-radius ptr(20px)
    overflow hidden
    img
      width 100%
      height: 100%
      object-fit cover
  &__review-count
    position absolute
    top ptr(20px)
    left ptr(20px)
    padding ptr(7px) ptr(14px) ptr(6px)
    {font_12_m_140}
    border-radius 20px
    background #fff
  &__meta
    width 50%
  &__prod-name
    margin 0 0 ptr(8px)
    {font_20_m_140}
  &__prod-desc
    margin 0 0 ptr(8px)
    {font_14_r_140}
  &__prices
    display flex
    justify-content flex-start
    align-items center
    {font_14_m_140}
    &-price
      color #858585
      text-decoration line-through
      margin 0 ptr(8px) 0 0
    &-minimum
      margin 0
      color #ff6b00
  &__anchor
    {font_16_m_100}
    display inline-block
    margin-top ptr(16px)
    padding ptr(11.5px) ptr(32px) ptr(10.5px)
    word-break keep-all
    border 1px solid #000
    border-radius 20px

:root {
  --row-gap-16 ptr(16)
}