black_button =
  background-color #000
  font-weight bold
  border-color #000
  color #fff
.don_frame.terms_modal_style
  >.don_wrapper
    width auto
    height auto
    max-width 896px
    max-height 680px
    border-radius 8px
    overflow hidden
    position relative
    margin 0
    left 50%
    top 50%
    transform translate(-50%, -50%)
    display flex
    flex-direction column
    @media screen and (max-width 944px)
      max-width 95%
    @media screen and (max-height 715px)
      max-height 95%
    >.header
      display flex
      justify-content space-between
      align-items center
      padding 48px 50px 24px
      box-sizing border-box
      .title
        font-size 20px
        font-weight bold
        line-height 1.3
        letter-spacing -0.3px
        color #111111
      .don_hide_frame
        background-image url(//s3.marpple.co/files/u_29089/2021/5/original/f05b717d9a56b9d20020119abb82aad91b130c6f1.png)
        position relative
        top auto
        right auto
        background-size 20px
        margin-right -8px
    >.body
      order 2

html.terms_page
  .text_body
    padding 0 !important
.don_frame.terms_style:not(.terms_not_modal_style)
  z-index 101 !important
.don_frame.terms_style .omp-layout-body__cont
.don_frame.terms_modal_style
  color #666
  p.no_padding
    margin 0
    padding 0
  .text_align_right
    text-align right
  .text_align_center
    text-align center
  .paragraph
    margin-bottom 40px
    > *:not(.header)
      padding-inline-start 10px
    h2
      margin-top 20px
  ul
  ol
    padding-inline-start 0
    ul
    ol
      padding-inline-start 10px
  li
    list-style none
    line-height 20px
  h2
    margin 16px 0
    font-weight 700
  h3
    margin 14px 0
    font-weight 700
  h4
    margin 8px 0
    font-weight 400
  table
    border 1px solid #444444
    border-collapse collapse
  th
    background #eee
  th, td
    padding 10px
  .tb1
    text-align center
    margin-left 10px
    th, td
      border 1px solid #444444
  .tb2
    margin-left 10px
    th
      border 1px solid #444444
    td
      border-right 1px solid #444444


  .terms_body
    h1
      font-size 24px
    .terms_preview
      table-layout fixed
      width 100%
      border 1px solid #ccc
      th
      td
        border 1px solid #ccc
      td
        padding 5px 20px
        vertical-align top
    >.option
      text-align center
      margin-bottom 16px
      height 56px
      .done
        { black_button }
        width 240px
        font-size 14px
        height 100%
        line-height 56px
        text-align center
        border-radius 100px
  .agree_header
    p
      font-size 13px
      line-height 22px
  .table_of_contents
    border 1px solid #eee
    background-color #f4f4f4
    font-weight bold
    padding 25px 25px 14px
    display flex
    ul
      flex-grow 1
      margin 0
      padding 0
      list-style none
      li
        margin-bottom 12px
        padding-left 8px
        background url("https://s3.marpple.co/s/5/marpple2/img/user/rules_icon1.png") no-repeat 0 50%
        a
          text-decoration none
  .text_body
    html[lang="kr"] &
      font-size 12px
      box-sizing border-box
    a
      color #333
      font-weight 500
      text-decoration underline
    .prev_link
      margin-top 15px
.don_frame.terms_not_modal_style
  .terms_body
    >.option
      display none
    width 1200px
    margin 0 auto 80px
    .title
      margin-bottom 26px
      margin-top 50px
    .text_body
      html[lang="kr"] &
        padding 25px
    .table_of_contents
      font-size 13px
.don_frame.terms_modal_style
  .table_of_contents
    display none

  .terms_body
    .table_of_contents
      font-size 11px
    >.title
      display none
    >.body
      height 340px
      overflow-y scroll
      margin 0 60px 16px
      border 1px solid #ccc
      padding 0 16px


.privacy-popup
  &__body
    width 100%
    box-sizing border-box
    padding 12px 50px 50px

  &__table
    width 100%
    table, th, td
      border 1px solid #ddd
      border-collapse collapse
    th, td
      width 33.33333%
      font-size 12px
      text-align center
      color #4e4e4e
      letter-spacing -0.2px
      word-break keep-all
    &--marketing
      th, td
        width 25%
  &__p
    margin 12px 0 24px
    font-size 12px
    line-height 1.67
    letter-spacing -0.2px
    color #4e4e4e
  button.done
    height 56px
    width 100%
    border 0 none
    background #111
    color #fff
    line-height 56px
    font-size 16px
    font-weight 600
    border-radius 100px

html.marpple.terms.omp
  .option
    display none
  .terms_body
    h1
      margin-top 0
  .table_of_contents
    li a
      font-size 13px
