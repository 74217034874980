// modules/OMP/Core/Layout/Navigation/F/Style/Pc/index.styl

.omp-mp-navigation__test
  position fixed
  top 0
  left 0
  width 100%
  z-index 1000

.omp-mp-navigation
  padding ptr(14) ptr(40) ptr(24)
  width 100%
  max-width ptr(1920)
  min-width ptr(800)
  box-sizing border-box
  background-color WHT
  &__wrap
    width 100%
    min-height ptr(508)
    display flex
    justify-content space-between
  &__left
    width ptr(196)
  &__right
    width ptr(288)
  &__center
    flex 1
    width 100%
    display flex
    justify-content space-between
  &__description
    width 100%
    &__text
      font-size ptr(26)
      line-height ptr(33.8)
      font-weight 500
      word-break keep-all
      word-wrap break-word
    &--menu
      display flex
      flex-direction column
      gap ptr(26)
  &__section
    display flex
    width 100%
    flex-direction column
    padding 0 ptr(8) 0 ptr(16)
    gap ptr(16)
    border-left 1px solid BG_PRODUCT
    box-sizing border-box
    &:nth-child(1)
      border-left 0 none
  &__section:first-child
    border-left 0 none
  &__section:not(.all-section)
    > .omp-mp-navigation__category
      height 100%
  &__category
    //height 100%
    display flex
    flex-direction column
    gap ptr(8)
    word-break break-all
    &__title
      height ptr(24)
      display flex
      align-items center
      { font_16_b_100 }
  &__link-category
    min-width ptr(100)
    max-width ptr(189)
  &__link-wrapper
    display flex
    flex-direction column
    gap ptr(8)
  &__banner-category
    height 100%
    display flex
    flex-direction column
    gap ptr(12)
  &__items
    min-width ptr(80)
    max-width ptr(189)
    display flex
    gap ptr(8)
    flex-direction column
    &__color-wrap
      display flex
      flex-wrap wrap
  &__items-products
    width ptr(268)
    display flex
    flex-direction column
    gap ptr(8)
  &__items-cate
    width ptr(189.6)
    display flex
    gap ptr(8)
    flex-direction column
  &__item
    &__product
      display flex
      gap ptr(10)
      &__img-wrap
        width ptr(60)
        height ptr(60)
        border-radius ptr(8)
        border solid ptr(1) #D8D8D8
        display flex
        justify-content center
        background-color #f6f6f6
      &__img
        width ptr(53)
        height ptr(60)
        object-fit contain
      &__texts
        display flex
        flex-direction column
        gap ptr(4)
        justify-content center
      &__text
        { font_size_}
        font-size ptr(14)
        line-height ptr(18)
        font-weight 500
        limitLine(2)
    &__url
      { font_14_m_140 }
    &__color
      &__circle-wrap
        padding ptr(4)
        background-clip content-box
      &__circle
        width ptr(32)
        height ptr(32)
        border-radius 50%
        &[data-color-code="#fff"]
          border 1px solid #D8D8D8 !important
    &__banner
      border-radius ptr(20)
      position relative
      width 100%
      padding-top 100%
      background-color whitesmoke
    &__banner-img
      border-radius ptr(20)
      position absolute
      top 50%
      left 50%
      transform translateY(-50%) translateX(-50%)
      object-fit cover
      width 100%
      max-width 100%
      max-height 100%
      vertical-align top
    &__banner-desc
      font-size ptr(14)
      line-height ptr(22.4)
  .name
    color GY
    display block
    max-width ptr(180)
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  .price
    color OG
  .all-category-type-text
    min-width ptr(50)

html.omp.product.detail
  @media screen and (max-width: 1600px)
    .omp-mp-navigation__wrap
      min-height 30vw
    .omp-mp-navigation__left
      width 10vw
    .omp-left__menu
      font-size 1.3vw
    .omp-left__menu2
      font-size 1vw
    .omp-mp-navigation__category
      gap 0.4vw
    .omp-mp-navigation__category__title
      font-size: 0.9vw
    .omp-mp-navigation-icon
      width: 0.9vw
    .omp-mp-navigation__items
      gap 0.4vw
    .omp-mp-navigation__item__url
      font-size: 0.8vw
    .omp-mp-navigation__section
      gap 1vw
    .omp-mp-navigation__banner-category
      gap 0.8vw
    .omp-mp-navigation__right
      width 15vw
    .omp-mp-navigation__item__banner-desc
      font-size 1vw
      line-height 1.5vw
    .omp-mp-navigation__item__banner
      width 13vw

@media screen and (max-width: 1000px)
  .maker_page
    .omp-mp-navigation__left
      display none

