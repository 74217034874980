.omp-review
  display flex
  flex-direction column
  width 100%
  align-items flex-start
  gap ptr(16)
  &__header
    width 100%
    display flex
    align-items center
    justify-content space-between
  &__header-title
    {font_26_m_100}
    display flex
    align-items center
    br
      display none
  &__event-link
    margin-left ptr(24)
    display flex
    align-items center
    gap ptr(4)
  &__event-link-text
    color BK
    font-family Pretendard
    font-size ptr(16)
    font-weight 500
    margin-left ptr(4)
    line-height 150%
  &__event-link-icon
    width ptr(16)
    height ptr(16)

  &__header-option
    display flex
    align-items center
    gap ptr(16)
    &__product-filter
      position relative
      padding ptr(4)
      &:hover
        .omp-review__header-option__product-filter-list
          display flex
    &__product-filter-list
      display none
      width fit-content
      position absolute
      margin-top ptr(26)
      top 0
      left ptr(-16)
      padding ptr(16)
      gap ptr(16)
      flex-direction column
      {font_16_m_100}
      background rgba(255, 255, 255, 0.80)
      backdrop-filter blur(20px)
      border-radius ptr(16)
      border 1px solid GY_50
      z-index 100000
      white-space nowrap
      [data-active="true"]
        {font_16_b_100}
    &__product-filter-item
      cursor pointer
    &__product-filter-label
      cursor pointer
      {font_16_m_140}
      position relative
      display flex
      gap ptr(8)
      align-items center
    &__product-filter-icon
      width ptr(8)
      height ptr(8)
      display flex
      align-items center
      justify-content center
    &__photo-review
      display flex
      align-items center
      gap ptr(8)
    &__photo-review-label
      {font_16_m_140}
    &__photo-review-wrapper
      width ptr(44)
      height ptr(26)
  &__contents-wrapper
    width 100%
    display flex
    flex-direction column
    gap ptr(16)

  &__contents
    width 100%
    .infi_items > div
      width 100%
      display grid
      display -ms-grid
    &[data-type="is_all"]
      .infi_items > div
        gap ptr(8) ptr(12)
        grid-template-columns 1fr 1fr
        margin-bottom ptr(8)
    &[data-type="is_photo"]
      .omp-review__content-wrapper
        width 100%
        padding-bottom ptr(16)
      .infi_items > div
        justify-content space-between
        gap ptr(16) 0
        grid-template-columns repeat(4, 24.5%)
        margin-bottom ptr(16)
  &__contents-next-wrapper
    width 100%
    margin ptr(44) 0
    display flex
    align-items center
    justify-content center
  &__contents-next
    {font_20_m_140}
  &__no-photo
    display flex
    align-items center
    justify-content center
    height ptr(240)
    {font_20_m_140}

.omp-review-mui
  .omp-layout-body__footer
    display none
