.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
.tippy-box[data-theme~=maker-marpplizer]
  color BK
  background-color BG_MODEL
  border-radius 100px
  cursor pointer !important
  .tippy-content
    padding 9px 18px
    font-size 12px
.tippy-box[data-theme~=maker-select-face-pc]
  color WHT
  background-color BK
  border-radius 22px
  cursor pointer !important
  width 198px
  html[lang="en"] &
    width 198px
  html[lang="jp"] &
    width 198px
  .tippy-content
    padding 12px 0
    display flex
    justify-content center
    font-size 12px
  .tippy-arrow
    color BK !important
    transform translate3d(159px, 0px, 0px) !important
.tippy-box[data-theme~=maker-select-face-mobile]
  color WHT
  background-color BK
  border-radius 22px
  width 198px
  .tippy-arrow
    color BK !important
  html[lang="en"] &
    width 198px
  html[lang="jp"] &
    width 198px
  .tippy-content
    font-size 12px
    padding 10px 0
    display flex
    justify-content center

.tippy-box[data-theme~=maker-save_shared_product-mobile]
  color WHT
  background-color rgba(0, 0, 0, 0.60)
  border-radius 99px
  .tippy-content
    padding 8px 16px

.tippy-box[data-theme~=maker-marpplizer][data-placement^=top] > .tippy-arrow:before {
  border-top-color: BG_MODEL
}

.tippy-box[data-theme~=maker-marpplizer][data-placement^=bottom] > .tippy-arrow:before {
  border-bottom-color: BG_MODEL
}

.tippy-box[data-theme~=maker-marpplizer][data-placement^=left] > .tippy-arrow:before {
  border-left-color: BG_MODEL
}

.tippy-box[data-theme~=maker-marpplizer][data-placement^=right] > .tippy-arrow:before {
  border-right-color: BG_MODEL
}

.tippy-box[data-theme~=maker-marpplizer] > .tippy-backdrop {
  background-color: BG_MODEL
}

.tippy-box[data-theme~=maker-marpplizer] > .tippy-svg-arrow {
  fill: BG_MODEL
}



