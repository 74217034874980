@media screen and (min-width 1025px)
  .don_frame[frame_name='/modules/User/ResetPassword/Verify/S/Mui/frame.js']
    .reset-password-verify-wrapper
      padding 80px 0 120px 0
      display flex
      .reset-password-verify
        display flex
        flex-direction column
        align-items center
        margin 0 auto
        width 520px
        img
          height 80px
        &__title
          margin 24px 0 40px 0
          padding 0
          font-size 32px
          font-weight bold
        &__info
          width 100%
          display flex
          flex-direction column
          padding-bottom 40px
        &__privacy
          box-sizing border-box
          width 100%
          display flex
          flex-direction column
          gap 10px
          padding 20px 24px
          background-color #f8f8f8
          &__data
            display flex
            gap 10px
          &__key
            width 100px
            color #828282
          &__value
            font-size 14px
            font-weight 600
        &__password-new
          width 100%
          margin-bottom 16px
        &__password
          width 100%
          display flex
          flex-direction column
          margin-bottom 24px
          &__title
            width 92px
            font-size 14px
            color #000
            padding 0
            margin 0
            &.bold
              font-weight 600
              margin-bottom 23px
          &__input-wrapper
            display flex
            align-items center
            justify-content space-between
            width 100%
            gap 8px
          &__value
          &__value-check
            flex-grow 1
            height 40px
            box-sizing border-box
            outline none
            padding 0 16px
            border solid 1px #d5dbe0
            font-size 14px
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &.no-equal
              border solid 1px #f66565
          &__no-equal
            padding-left 100px
            display none
            font-size 13px
            margin 8px 0 0 0
            font-weight 500
            line-height 1.31
            letter-spacing -0.5px
            text-align left
            color #f66565
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #a2a2a2
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          font-weight bold
          &:hover
            cursor default
          &.enable
            background-color #000
            &:hover
              cursor pointer
@media screen and (max-width 1024px)
  .don_frame[frame_name='/modules/User/ResetPassword/Verify/S/Mui/frame.js']
    .reset-password-verify-wrapper
      padding 140px 0 80px 0
      display flex
      .reset-password-verify
        display flex
        flex-direction column
        align-items center
        padding 0 16px
        width 100%
        img
          height 60px
        &__title
          margin 16px 0 40px 0
          padding 0
          font-size 20px
          font-weight bold
        &__info
          width 100%
          display flex
          flex-direction column
          padding-bottom 40px
        &__privacy
          box-sizing border-box
          width 100%
          display flex
          flex-direction column
          gap 10px
          padding 20px 24px
          background-color #f8f8f8
          &__data
            display flex
            gap 10px
          &__key
            width 60px
            color #828282
          &__value
            font-size 14px
            font-weight 600
        &__password-new
          width 100%
          margin-bottom 16px
        &__password
          width 100%
          display flex
          flex-direction column
          margin-bottom 24px
          &__title
            width 64px
            font-size 14px
            color #000
            padding 0
            margin 0
            &.bold
              width fit-content
              font-weight 600
              margin-bottom 23px
          &__input-wrapper
            display flex
            align-items center
            justify-content space-between
            width 100%
            gap 8px
          &__value
          &__value-check
            flex-grow 1
            height 40px
            box-sizing border-box
            outline none
            padding 0 16px
            border solid 1px #d5dbe0
            font-size 14px
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &.no-equal
              border solid 1px #f66565
          &__no-equal
            padding-left 72px
            display none
            font-size 12px
            margin 8px 0 0 0
            font-weight 500
            line-height 1.31
            letter-spacing -0.5px
            text-align left
            color #f66565
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #a2a2a2
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &:hover
            cursor default
          &.enable
            background-color #000
            &:hover
              cursor pointer
