@media screen and (min-width 1025px)
  .don_frame[frame_name='/modules/User/FindId/S/Mui/frame.js']
    .find-id-result-wrapper
      box-sizing border-box
      width 100%
      height 417px
      padding 40px
      display flex
      .find-id-result
        width 100%
        display flex
        flex-direction column
        align-items center
        justify-content space-between
        margin 0 auto
        &__sub-title
          width 100%
          padding 0
          margin 0 0 8px 0
          font-size 14px
          font-weight 600
          text-align left
          color #000
        &__emails
          width 100%
          padding-bottom 50px
          display flex
          flex-direction column
          gap 16px
        &__radio-label
          display flex
          gap 8px
          span
            font-size 15px
            letter-spacing -0.2px
            color #000
          >input[type='radio']
            margin-top -1px
            width 16px
            height 16px
            border solid 1px #d5dbe0
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #a2a2a2
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &.reset_pw
            border solid 1px #000
            color #000
            background-color #fff
          &.login
            color #fff
            background-color #000
@media screen and (max-width 1024px)
  .don_frame[frame_name='/modules/User/FindId/S/Mui/frame.js']
    .find-id-result-wrapper
      box-sizing border-box
      width 100%
      padding 16px
      display flex
      .find-id-result
        width 100%
        display flex
        flex-direction column
        align-items center
        justify-content space-between
        margin 0 auto
        &__sub-title
          width 100%
          padding 0
          margin 0 0 8px 0
          font-size 14px
          font-weight 600
          text-align left
          color #000
        &__emails
          width 100%
          padding-bottom 50px
          display flex
          flex-direction column
          gap 16px
        &__radio-label
          display flex
          gap 8px
          span
            font-size 15px
            letter-spacing -0.2px
            color #000
          >input[type='radio']
            margin-top -1px
            width 16px
            height 16px
            border solid 1px #d5dbe0
        &__buttons
          box-sizing border-box
          position fixed
          bottom 24px
          padding 0 16px
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #d5dbe0
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &.reset_pw
            border solid 1px #000
            color #000
            background-color #fff
          &.login
            color #fff
            background-color #000

