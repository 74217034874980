// modules/OMP/ChannelTalkModal/F/Style/Pc/index.styl
.don_frame[frame_name="/modules/OMP/ChannelTalkModal/S/Mui/frame.js"]
  & > .don_wrapper
    width ptr(500px)
    padding ptr(30px)
    border-radius ptr(20px)
    background var(--WHT, #FFF)
    box-sizing content-box

.channel_talk_modal_wrapper
  .underline
    text-decoration-line underline

  .channel_talk_modal_title
    color var(--BK, var(--kakao-logo, #000))
    font-family Pretendard
    font-size ptr(26px)
    font-style normal
    font-weight 500
    line-height 130%
    margin-bottom ptr(40px)

    display flex
    justify-content space-between

    & svg
      cursor pointer

  .channel_talk_modal_desc
    color var(--BK, var(--kakao-logo, #000))
    font-family Pretendard
    font-size ptr(16px)
    font-style normal
    font-weight 500
    line-height 150%
    margin-bottom ptr(16px)

  .channel_talk_modal_section
    padding ptr(16px)
    border-radius ptr(12px)
    background var(--BG-PRODUCT, #F6F6F6)
    margin-bottom ptr(32px)

    &_title
      color var(--BK, var(--kakao-logo, #000))
      font-family Pretendard
      font-size ptr(16px)
      font-style normal
      font-weight 700
      line-height 100%
      margin-bottom ptr(12px)

    &_desc
      color var(--BK, var(--kakao-logo, #000))
      font-family Pretendard
      font-size ptr(16px)
      font-style normal
      font-weight 500
      line-height 150%

      &.dot
        display flex
        align-items center
        color var(--GY, #858585)

    &.last
      margin-bottom ptr(40px)


  .channel_talk_modal_buttons
    & a
      display flex
      height ptr(50px)
      padding ptr(6px) ptr(16px) ptr(7px) ptr(16px)
      justify-content center
      align-items center
      align-self stretch
      border-radius ptr(999px)
      background var(--BK, #000)
      margin-bottom ptr(18px)
      color var(--WHT, #fff)


  .channel_talk_modal_footer
    color var(--BK, var(--kakao-logo, #000))
    text-align center
    font-family Pretendard
    font-size ptr(16px)
    font-style normal
    font-weight 500
    line-height 100%
