@import "../../../../Share/F/index.styl"

maker-warning-font =
  content ''
  position absolute
  bottom 0
  right 0
  left 0
  z-index -1
  background-color #ffff80
  color color_black
maker-warning-background =
  background-color #ffff80
  color color_black066

maker-warning-background-color = #ffff80

maker-panel-width = 460px

body.maker_canvas_editor_v1
  @import "tutorial.styl"
  .pc_size_select_face_block
    background-color #ffffff
    border-radius 20px
  @import "icon_menu.styl"
  @import "../Share/index.styl"
  @import "menu.styl"
  @import "etc.styl"
  @import "etc2.styl"
  @import "etc_of_etc.styl"
  @import "layer.styl"
  @import "marstargram_editor.styl"
  @import "range.styl"
  @import "enter_marpple_shop.styl"

  @import "./Frame/simple_modal.styl"
  @import "./Frame/maker_guide.styl"
  @import "./Frame/composite_preview.styl"
  @import "./Frame/image_library.styl"
  @import "./Frame/preview.styl"
  @import "./Panel/marpplizer.styl"
  @import "./Panel/image.styl"
  @import "./Panel/text.styl"
  @import "./Panel/product.styl"
  @import "./Panel/bp_option_groups.styl"
  @import "../../../../../../NewMaker/Property/BaseProduct/F/Style/Pc/index.styl"

maker-basic-panel-width = 480
maker-short-panel-width = 320

.select_face
  top 92px
.delivery-additional-price
  display none

html.maker_page[marpplizer_mode="basic"] &
  .delivery-additional-price
    display inline-block
    margin-left 8px
  .maker_menu
    height 72px
    padding 0 40px
    .list
      > div
        margin-right 18px
      .reset
      .redo
      .trash
      .backward
      .ungroup
      .flip_v
        margin-right 6% !important
        @media screen and (max-width: 1550px)
          margin-right 3% !important
      span
        width 18px
        height 18px
        background-size 18px auto
      .text
        margin-top 6px
        font-size 10px
        font-weight 400
  .maker_menu
    height 72px
  #marpplizer
    width (maker-basic-panel-width)px
    .activity
      >.body
        width (maker-basic-panel-width)px
        padding 0 40px 0
        box-sizing border-box
        >.main_head
          text-align center
          padding-top 40px
        >.product.section
          padding-top 40px
  .maker_menu
  #maker_frame .board
    right  (maker-basic-panel-width + 20)px
  #maker_frame .select_face
    right (maker-basic-panel-width + 20)px !important
  #marpplizer .cv_text_editor .style button
    width 52px
    height 38px
  #marpplizer .cv_text_editor .visibility_hidden
    display block
  #marpplizer
    .color_picker.mkf
      .color_item
        position relative
        width 28px !important
        height 28px !important
        margin 0 11px 8px 0 !important
        display flex
        justify-content center
        align-items center
        span
          width 24px !important
          height 24px !important
      .color_item:nth-child(10n)
        margin 0 0 8px 0 !important
      .color_item.used
        &::before
          position absolute
          top -6px
          content ""
          background-color #555
          width 4px
          height 4px
          border-radius 100%
    .image_color_item
      width 40px
      height 40px
      margin-right 8px

html.maker_page[marpplizer_mode="short"] &
  .delivery-additional-price
    display block
    margin-top 5px
    line-height 19px
  .maker_menu
    height 62px
    .list
      > div
        margin-right 13px
      .reset
      .redo
      .trash
      .backward
      .ungroup
      .flip_v
        margin-right ptr(40)
      span
        width 18px
        height 18px
        background-size 18px auto
      .text
        margin-top 6px
        font-size 10px
        font-weight 400
  #maker_frame
    #marpplizer
      width (maker-short-panel-width)px !important
      .activity
        >.body
          width (maker-short-panel-width)px !important
          padding 0 30px !important
          box-sizing border-box

          >.main_head
            text-align center
            padding-top 40px
          >.product.section
            padding-top 40px
    .maker_menu
    .board
      right (maker-short-panel-width + 20)px !important
    .select_face
      right (maker-short-panel-width + 20)px !important
    #marpplizer .cv_text_editor .style button
      width 36px
      height 36px
    #marpplizer .cv_text_editor .visibility_hidden
      display none
    #marpplizer
      .color_picker.mkf
        .color_item
          position relative
          display flex
          justify-content center
          align-items center
          width 26px !important
          height 26px !important
          margin 0 7px 7px 0 !important
          span
            width 22px !important
            height 22px !important
        .color_item:nth-child(8n)
          margin 0 0 7px 0 !important
        .color_item.used
          &::before
            position absolute
            top -6px
            content ""
            background-color #555
            width 4px
            height 4px
            border-radius 100%
      .image_color_item:nth-of-type(8n)
        margin-right 8px !important
      .image_color_item:nth-of-type(5n)
        margin-right 0 !important
@media screen and (max-height: 730px)
  #marpplizer
    bottom 20px !important
    .general_option_buttons
      bottom 20px !important
  .how_to_custom
    top 68px !important
  .enter_marpple_shop
    top 124px !important
  .maker_menu
    height 50px !important
    .list
      > div
        width 40px !important
      .reset
      .redo
      .trash
      .backward
      .ungroup
      .flip_v
        margin-right ptr(40) !important
      span
        width 18px !important
        height 18px !important
        background-size 18px auto !important
      .text
        display none
  .select_face
    top 69px
@media screen and (max-width: 1024px)
  .maker_menu
    .text
      display none
    .list
      .reset
      .redo
      .trash
      .backward
      .ungroup
      .flip_v
        margin-right ptr(10) !important
