body.group
  .don_frame[frame_name="mp.group"]
    #group
      ul
        margin 0
        padding 0
        list-style none
      h2.group_lists_title
        text-align center
        font-weight 400
        margin 0
      #top_banners
        width 100%
        position relative
        overflow hidden
        ul
          height 100%
          li
            position relative
            overflow hidden
            a
              display block
        .swiper-pagination
          .swiper-pagination-bullet
            opacity .4
            background #999999
            &.swiper-pagination-bullet-active
              opacity 1
      #why_marpple
        position relative
        .reasons
          position relative
          text-align center
          ul
            li.reason
              display inline-block
              vertical-align top
              text-align left
              position relative
              &.reason1
                .icon
                  background-image url(//s3.marpple.co/files/u_29089/2019/3/original/f_23664_1551442590752_u9dx9KOH5XH6qbJ8ft6GU.png)
              &.reason2
                .icon
                  background-image url(//s3.marpple.co/files/u_29089/2019/3/original/f_23666_1551442590752_o0bwO0Ly7uyPMki2B0Ik.png)
              &.reason3
                .icon
                  background-image url(//s3.marpple.co/files/u_29089/2019/3/original/f_23665_1551442590752_NGTIlFMq0AcAG9BEe.png)
              .title
                margin-bottom 8px
                span
                  display block
              .content
                >div
                  color #495057
      #group_lists
        .group_list
          background-color #efefef
          position relative

      #now_check
        width 100%
        background-color #1f1f1f
        position relative
        .decor
          position absolute
          .line
            background-color rgba(255,255,255,.1)
            position absolute
        .decor1
        .decor3
          width 100px
          height 100px
          transform rotate(45deg)
          .line1
            left 50%
            top 0
            margin-left -10px
            width 20px
            height 100px
          .line2
            left 0
            top 50%
            margin-top -10px
            width 40px
            height 20px
          .line3
            right  0
            top 50%
            margin-top -10px
            width 40px
            height 20px
        .decor2
          width 60px
          height 60px
          .line1
            left 50%
            top 0
            margin-left -5px
            width 10px
            height 60px
          .line2
            left 0
            top 50%
            margin-top -5px
            width 25px
            height 10px
          .line3
            right 0
            top 50%
            margin-top -5px
            width 25px
            height 10px
        h2
          font-weight 400
          line-height 1
          text-align center
          color #f5f5f5
          margin 0
        .now_check_wrap
          background-color #fcf753
          .call
          .period
            display inline-block
            vertical-align top
            span
              margin-right 4px
          .period
            color #495057
      #group_template
        .thumbnail
          word-break keep-all
      @media screen and (min-width 1025px)
        h2.group_lists_title
            font-size 32px
        #top_banners
          max-width 1920px
          .swiper-pagination
            .swiper-pagination-bullet
              width 10px
              height 10px
          ul
            li
              position relative
              .video_wrap
                height 40vw
              .img
                height 600px
                position relative
                margin 0 auto
        #why_marpple
          position relative
          margin-bottom 80px
          .reasons
            padding 32px 0
            margin 0 auto
            ul
              li.reason
                width auto
                margin 0 25px
                .icon
                  width 100px
                  height 80px
                  margin 0 24px 0 0
                  vertical-align top
                  display inline-block
                  background-size 100px 80px
                  background-position center
                  background-repeat no-repeat
                .text
                  vertical-align top
                  margin-top 6px
                  display inline-block
                  .title
                    font-size 18px
                    line-height 1.11
                    margin-bottom 10px
                  .content
                    line-height 1.43
        #group_lists
          margin 0 auto 80px
          width 1280px
          text-align center
          .group_lists_wrap
            margin-top 40px
            .group_list
              width 406px
              height 406px
              margin 0 8px 16px
              vertical-align top
              display inline-block
              .img
                width 100%
                height 334px
                position relative
                img
                  margin auto
                  position absolute
                  top 0
                  left 0
                  bottom 0
                  right 0
                  max-width 300px
                  max-height 300px
              .button
                position absolute
                bottom 24px
                left 24px
                right 24px
                button
                  margin 0
                  padding 0
                  border solid 1px #d5dbe0
                  background-color #ffffff
                  font-size 16px
                  line-height 1.5
                  width 358px
                  height 48px
        #now_check
          padding 40px 0 32px
          text-align center
          margin-bottom 80px
          .decor1
            top -25px
            left 25%
          .decor2
            top 25px
            right 20%
          .decor3
            bottom -45px
            left 50%
            transform rotate(-20deg)
          h2
            font-size 32px
            margin-bottom 16px
          .now_check_wrap
            margin 0 auto
            height 40px
            text-align left
            display inline-block
            padding 0 24px
            .call
              font-size 24px
              margin 6px 0 0 0
              a
                font-size 26px
            .period
              margin 10px 0 0 24px
              font-size 18px
        #group_template
          width 1280px
          margin 0 auto 80px
          >.group_template_wrap
            width 1248px
            margin 0 auto
            position relative
            >.body
              margin-top 40px
              >.option
                position absolute
                top 0
                font-size 13px
                left 250px
                padding 5px
                border 1px solid #000
      @media screen and (max-width 1024px)
        h2.group_lists_title
          font-size 16px
        #top_banners
          .img
            height 0
            padding-bottom 124.8%
        #why_marpple
          padding 24px 0
          background-color #fff
          .reasons
            ul
              li.reason
                margin 0 12px
                max-width 80px
                .icon
                  width 50px
                  height 40px
                  margin 0 auto 8px
                  background-size 50px 40px
                  background-position center
                  background-repeat no-repeat
                .content
                  display none !important
                .title
                  font-size 12px
                  text-align center
                  line-height 1.33
            i.m_br
              display block
        #group_lists
          padding 40px 0
          .group_lists_wrap
            margin-top 16px
            .group_list
              width 50%
              vertical-align top
              display inline-block
              margin-bottom 24px
              background-color transparent
              .img
                width 100%
                position relative
                font-size 0
                img
                  width 100%
              .button
                position relative
                text-align center
                margin 8px 0
                button
                  border 0
                  background-color transparent

        #now_check
          padding 24px 0
          text-align center
          margin-bottom 40px
          .decor
            transform scale(.5)
          .decor1
            transform scale(.5) rotate(-45deg)
            top -25px
            left 10%
          .decor2
            top 25px
            right 5%
          .decor3
            bottom -45px
            left 50%
            transform scale(.5) rotate(-20deg)
          h2
            font-size 18px
            margin-bottom 16px
          .now_check_wrap
            width 100%
            height 32px
            text-align center
            .call
              font-size 16px
              margin 6px 0 0
              a
                font-size 18px
            .period
              margin 10px 0 0 8px
              font-size 12px
              span
                margin-right 4px
        #group_template
          margin-bottom 24px
          >.group_template_wrap
            >.body
              margin-top 16px
