mp-editor-menu-undo()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/322dcd3a367d35e662f5939b713a21fe8b014ff62.svg)
mp-editor-menu-redo()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/4328669249444ab070296aef05502c7f7326a4d118.svg)
mp-editor-menu-refresh()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/d6b0f9c8a3e599333b39130f8ea679e60328d12f26.svg)
mp-editor-menu-align-left()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/5375e68f04947b20745d76d6802ed8d664afc8453.svg)
mp-editor-menu-align-center-vertical()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/2dd336ce51bd4cc3c40060bcedd0dab7a5a7a57924.svg)
mp-editor-menu-align-right()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/2ea5ca89996eb74ccb08daa497414ecabcdc17ee11.svg)
mp-editor-menu-align-top()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/b8628847a3b48870761272e515fdbabff8ec2bf317.svg)
mp-editor-menu-align-center-horizontal()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/e8616b3ea55acfe55dac85ddd59e1f9dcd99cc6414.svg)
mp-editor-menu-align-bottom()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/3a975cccc93c256678591a03fc28241b3bae3b3315.svg)
mp-editor-menu-flip-vertical()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/66b1a0d75ed1d90a08823493ee83dcdd066641e29.svg)
mp-editor-menu-flip-horizontal()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/a399e52a359a611f3a9d54cc023f588df19d46c520.svg)
mp-editor-menu-element-layer()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/bfcdcdd589c3e8a6abe95d9a5872bf5962c4f8b022.svg)
mp-editor-menu-grouping()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/c9afb20ff65050bc210fb06ec87d6434c7f3963b19.svg)
mp-editor-menu-ungrouping()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/a90bd6e86e87ddf7055e15c146e65c5915106c4221.svg)
mp-editor-menu-forward()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/7a689445876608dd7bfd6717401c2a4472e264937.svg)
mp-editor-menu-backward()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/ed559b523d499e76dbfa193dca005971d10a64b613.svg)
mp-editor-menu-trash()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/d26cbb6f426711a71abfdd6c0af61e3cd965fb775.svg)
mp-editor-menu-locked()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/eab989f4ccfeaad173b96cf337c0bf780190d78310.svg)
mp-editor-menu-unlocked()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/300f730b65bd14c5e4f58ee3f7cc17291aa2e65823.svg)
mp-editor-menu-unlocked-og()
  background-image url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI0ZGNkIwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICA8cGF0aCBkPSJNNC41IDRjLjYyNC0xLjczOSAyLjI3NS0zIDQuMjIyLTMgMi40NzUgMCA0LjUgMi4wMjUgNC41IDQuNVY3aC01TTIuODgyIDdoMTIuMjM2QzE2LjE1NyA3IDE3IDcuODE1IDE3IDguODE4djYuMzY0QzE3IDE2LjE4NSAxNi4xNTcgMTcgMTUuMTE4IDE3SDIuODgyQzEuODQzIDE3IDEgMTYuMTg1IDEgMTUuMTgyVjguODE4QzEgNy44MTUgMS44NDMgNyAyLjg4MiA3eiIvPgogIDxwYXRoIGQ9Ik05IDEyLjVhMS41IDEuNSAwIDEgMCAuMDAxLTIuOTk5QTEuNSAxLjUgMCAwIDAgOSAxMi41ek05IDEzdjEuNSIvPgogIDwvZz4KPC9zdmc+")
mp-editor-menu-hidden()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/52ecc5a9c499c080bcd59ac1b963eb1c57c8d64b1.svg)
mp-editor-menu-visible()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/45ddc953992ce0ba1803aef2271dbf661418bba73.svg)
mp-editor-menu-duplicate()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/09b8e8c3cea9468ff35d09c56f4d07549f46a5f77.svg)


mp-editor-etc-mshop()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/6c02eb7a1e276dc9fc30358fb3c42c1035fd4be21.svg)
mp-editor-etc-tip()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/96affa6cc6ed284191dbf58da173f7b082db4f6125.svg)
mp-editor-etc-sticker()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/9c1b4290504c0e809844e73db29b934cbff9eff03.svg)
mp-editor-etc-acrylic-circle()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/6ad41ac1339d3a66fb44cdd154614cf1aaf8f9e11.svg)
mp-editor-etc-acrylic-hole()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/13ee9e7f4ab4d78293ae8bc061dcfa5e85fb29082.svg)

mp-editor-radio-active()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/bbafd8d7013e6c15168da30e81e749e73c6d2d1e1.svg)
mp-editor-radio-inactive()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/b3d0db1aed315ef156bf3c402e840f5c4d600ac72.svg)

mp-editor-check-active()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/a3b4e4f389f3b19ca9a13af819f2b0f955933cda1.svg)
mp-editor-check-inactive()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/5b593f62ffccc32629cd4fb39467e89a9b12b5d32.svg)


mp-editor-arrow-s-up()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/4f15f6a4c886b0d648c15ed0b35203a0990274321.svg)
mp-editor-arrow-s-down()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/ec736cc9450a53f9538a9eda329ce673c62acead2.svg)
mp-editor-arrow-l-up()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/c0fc68962b8fbccdbe844aada44380e020732a7c1.svg)
mp-editor-arrow-s-down()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/5ba584f0fea9dc032cb254d63c9511b7023ead4a2.svg)
mp-editor-arrow-s-left()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/5d7add8f868b7a87d49219604edd5239d13774b23.svg)
mp-editor-arrow-s-right()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/1f3e33663a4726f7a7ec226bf4187a91de23100e4.svg)


mp-editor-icon-plus()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/b6e76502aac97e8f8039448e1c7ecec749ec43661.svg)
mp-editor-icon-plus-wht()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/e34af1dc8ce3aae39521c93b68d9f06eca4bf51b1.svg)
mp-editor-icon-upload()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/7e848f02404f8c0744dde8a690e2decef4d04c102.svg)
mp-editor-icon-my-image()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/6c892b6da61fa8a1b572b423eba5dc5dfbd89b834.svg)
mp-editor-icon-design()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/062e718f03e50debd15875b32c65733ce2b1ee5212.svg)
mp-editor-icon-optimize-position()
  background-image url(//s3.marpple.co/files/u_1187078/2024/5/original/f3a2aded89bef34ba0c3df6b5fdde633131fe6831.svg)
mp-editor-icon-text()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/594f27f95fb34cd9c7ed94ae5cf336561b3165b415.svg)
mp-editor-icon-product()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/c03c81631019999f3498ec16c0733fb11fa157b313.svg)
mp-editor-icon-stickers()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/d502001e3d938d86a0c95a79051840cdb68d68786.svg)
mp-editor-icon-path()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/cffb95cce489f3cc3bfc4e5761edda6c47641b669.svg)
mp-editor-tool-keyboard()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/59f129a00136ff605b701745d539e85c0fa5a59b5.svg)
mp-editor-tool-text()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/0724a0634b40622b23f945b1c7b03567c8a56d073.svg)
mp-editor-tool-copy()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/09b8e8c3cea9468ff35d09c56f4d07549f46a5f77.svg)
mp-editor-tool-pencil()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/cdfcc0b08597f5f6b8e22c8d68271324e9ed2fa58.svg)
mp-editor-tool-delete()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/d26cbb6f426711a71abfdd6c0af61e3cd965fb775.svg)

mp-editor-tool-stroke()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/1ee51e104951b0799d442c22ef08f220daf1b5e210.svg)
mp-editor-tool-set-color()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/d8f89536ef4262d6726ea580955679872160a46c11.svg)
mp-editor-tool-pattern()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/bcbcf573eeafa9f6459e2b6f6d90a0a0f9ab0fac16.svg)
mp-editor-tool-color-palette()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/862877b9b8984ba1acc08dab90ee51e4c32e88a114.svg)


mp-editor-path-straight()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/edada6978c1517b6de6042bd7ff71ea58c33ea961.svg)
mp-editor-path-curved()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/ac60c74b6674368a6c5e1462002b2f9e2991a0b72.svg)
mp-editor-path-closed()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/41725b65646627373ca927322188e339d6f603023.svg)
mp-editor-path-changed-curved()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/f363ec994842ea657b22a7189c367743e5b3fe144.svg)
mp-editor-path-new()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/4f33a9c2afd2c733b22b8c60a17304c6caa468a05.svg)
mp-editor-path-changed-straight()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/c39f17e1a972e5973f1d2ed4d88742336c93ff7b6.svg)
mp-editor-path-delete()
  background-image url(//s3.marpple.co/files/u_193535/2023/7/original/ce426047b4fcc19b2606be44ab373e1193e855f97.svg)

@import "tooltip.styl"
.bp_option_groups
  .select_box
    [data-is_public='false']
      display none !important
      html[mp_worker_policy="true"] &
        display flex !important
  select
    [data-is_public='false']
      display none !important
      html[mp_worker_policy="true"] &
        display block !important
