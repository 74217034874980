.omp-swiper
  overflow hidden
  &__container
    overflow visible
    position relative
  &-prev, &-next
    &.swiper-button-disabled
      display none
    cursor pointer
    position absolute
    top 35%
    width ptr(40)
    height ptr(40)
    z-index 1
    img
      max-width 100%
  &-prev
    left 0
    transform translate(-50%, -50%)
  &-next
    right 0
    transform translate(50%, -50%)


.omp-home__curation-pod
  .curation-pod-swiper-nav
    top 50%
