.don_frame[frame_name="/modules/Terms/CommerceLaw/S/Mui/frame.js"]
  .commerce_law
    color #666
    .title
      h1
        margin-top 0
    .paragraph
      h3
        margin-top 0
        margin-bottom ptr(16)
      > p
        margin-top 0
        margin-bottom ptr(32)
    .body
      .contacts
        display flex
        align-items center
        gap ptr(32)
        margin-bottom ptr(32)
