.don_frame.omp-cart-option
  .don_wrapper
    position absolute
    top 0
    left 0
    right 0
    bottom 0
  >.don_wrapper >.header
    display none
  .don_page >.don_wrapper >.header
    z-index 20
    position absolute
    top 0
    right 0
    box-sizing border-box
    width ptr(419)
    padding ptr(30) ptr(30) 0
    .title
      {font_26_m_130}
    .don_hide_frame
      top ptr(28)
      right ptr(30)
  .modal
    background-color white
    display flex
    height 100%
    >.product_preview
      position relative
      background-color #f8f9fa
      width ptr(617)
      height 100%
      >.product_face_container
        position relative
        width 100%
        height 100%
      >.product_face_container.file_expired
        display flex
        align-items center
        justify-content center
        font-weight 500
        font-size ptr(26)
        line-height ptr(33.8)
        color GY
      .product_face_list.top
        z-index 1
        .product_face
          display flex
          justify-content center
          padding-top 10%
          height 80%
      .product_face_list.bottom
        {flex-center}
        position absolute
        left 0
        right 0
        z-index 2
        bottom ptr(20)
        .product_face.preview_thumb
          margin 0 8px
          cursor pointer
          display flex
          flex-direction column
          gap ptr(8)
          .img.canvas_60
            opacity 0.5
            width ptr(80)
            height ptr(80)
          .face_name
            text-align center
            color GY
            {font_14_m_100}
          &.active
            .img.canvas_60
              opacity 1
            .face_name
              color OG
          &:active
            .face_name
              color BK
            .img.canvas_60
              opacity .8
      .swiper_control
        width ptr(40)
        height ptr(40)
        border-radius ptr(999)
        background WHT
        position absolute
        top 0
        bottom 0
        margin auto 0
        z-index 20
        cursor pointer
      .swiper-button-disabled
        &.preview_prev
          background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 18 16' fill='none'%3E%3Cpath d='M8 1L1 8L8 15' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 8H12H7' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
        &.preview_next
          background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3E%3Cpath d='M10 1.5L17 8.5L10 15.5' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 8.5H6H11' stroke='%23D8D8D8' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
          cursor default
      .preview_prev
        bottom ptr(138)
        left ptr(32)
        background-size ptr(18) ptr(16)
        background-position center
        background-repeat no-repeat
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 18 16' fill='none'%3E%3Cpath d='M8 1L1 8L8 15' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 8H12H7' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
      .preview_next
        bottom ptr(138)
        right ptr(32)
        background-size ptr(18) ptr(16)
        background-position center
        background-repeat no-repeat
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 18 17' fill='none'%3E%3Cpath d='M10 1.5L17 8.5L10 15.5' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 8.5H6H11' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
    >.for_desktop
      flex 1
      display flex
      flex-direction column
      align-items center
      justify-content space-between
      position relative
      padding ptr(30) ptr(34) ptr(30)
      .list_body
        flex 1
        width 100%
        box-sizing border-box
        overflow-y auto
        overflow-x hidden
        display flex
        flex-direction column
        gap ptr(8)
        padding-bottom ptr(30)
        .product_title
          margin-right ptr(38)
          .bp_name
            {font_26_m_130}
            margin-bottom ptr(6)
          .price
            display none !important
        .item
          box-sizing border-box
          position relative
          border-bottom 1px solid GY_50
          padding ptr(16) 0
          display flex
          flex-direction column
          &.is_not_stock
            .product_price_wrap
              display none
            .info
              color GY !important
            .size_info
              span
                position relative
                display flex
                gap ptr(10)
                flex-wrap wrap
                margin-right ptr(10)
                align-items center
                word-break keep-all
                word-wrap break-word
                &:after
                  content "Sold Out"
                  html[lang="kr"] &
                    content "일시품절"
                  color GY !important
                  {font_14_r_140}
                  word-break keep-all
                  word-wrap break-word
          &.is_discontinued
            .size_info
              span
                &:after
                  content "Discontinued"
                  html[lang="kr"] &
                    content "단종"
          &.is_overflowed
            padding-bottom ptr(30)
            &:before
              content "The image size is too large."
              html[lang="kr"] &
                content "인쇄크기 넘침"
              html[lang="jp"] &
                content "印刷可能な大きさを超えました。"
              position absolute
              bottom ptr(6)
              right 0
              color OG !important
          .info_wrap
            {flex-center-between}
            .info
              {font_14_m_140}
              display flex
              width 50%
              &.qty_info
                display flex
                flex-direction column
                align-items end
            span.size, span.qty
              margin-right 8px
            .qty_wrap
              display flex
              width 100%
              justify-content end
            .mp-select-product-quantity
              width 100%
              select
                {font_14_m_140}
                width 100%
                maker-option-select-style1(8px)
            .input_wrap:not(.mp-select-product-quantity)
              width ptr(106)
              height ptr(28)
              background-color #ffffff
              border solid 1px GY_50
              box-sizing border-box
              border-radius ptr(4)
              {flex-center-between}
              input.quantity
                {font_14_r_140}
                background-color #ffffff
                text-align center
                outline none
                border none
                width ptr(80)
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button
                  -webkit-appearance none
                  margin 0
              .plus, .minus
                padding ptr(8)
                cursor pointer
                width ptr(20)
                height ptr(20)
              .plus
                background-position center
                background-size ptr(12) ptr(12)
                background-repeat no-repeat
                background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76563H6M6 5.76563H11M6 5.76563V10.7656M6 5.76563V0.765625' stroke='%23D8D8D8' stroke-linecap='round'/%3E%3C/svg%3E")
                &:active
                  background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76563H6M6 5.76563H11M6 5.76563V10.7656M6 5.76563V0.765625' stroke='%23000000' stroke-linecap='round'/%3E%3C/svg%3E")
              .minus
                background-position center
                background-size ptr(12) ptr(12)
                background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76562H6H11' stroke='%23D8D8D8' stroke-linecap='round'/%3E%3C/svg%3E")
                background-repeat no-repeat
                &:active
                  background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 5.76562H6H11' stroke='%23000000' stroke-linecap='round'/%3E%3C/svg%3E")
          .product_price_wrap
            display flex
            justify-content flex-end
          .product_price
            display flex
            gap ptr(4)
            {font_14_m_140}
            &[is_discounted="true"]
              .discounted
                padding-top ptr(16)
                display block
                color OG
              .original
                padding-top ptr(16)
                text-decoration line-through
                color GY
            &.hide
              .discounted, .original
                display none
            .original
              padding-top ptr(16)
            .discounted
              display none
      .modal_footer
        flex-shrink 0
        box-sizing border-box
        padding-top ptr(30)
        width 100%
        background-color WHT
        .summary
          display flex
          justify-content space-between
          align-items end
          .title
            {font_16_b_100}
          .total_price
            {font_14_m_100}
            display flex
            align-items end
            .prices
              display flex
              gap 4px
              flex-direction column
            .percent
              margin-right ptr(5)
              color OG
            .origin
              text-decoration line-through
              text-align right
              color GY
            .sign
              font-weight bold
              margin-right 1px
            .discounted
              {font_16_b_100}
        .msg
          margin-top ptr(8)
          font-size ptr(9)
          color GY
          html[lang="kr"] &
          html[lang="jp"] &
            display none
        .buttons
          margin-top ptr(24)
          #confirm
            border none
            padding 0
            margin 0
            {font_14_m_100}
            cursor pointer
            width 100%
            height ptr(44)
            background-color #000
            color #fff
            border-radius ptr(999)
