// modules/NewMaker/Components/ImageDropBox/F/Style/Pc/index.styl
body
  .image_selector_wrap
    position absolute
    top 0
    z-index 2
    width 100%
    height 100%
    .maker-image-selector
      display flex
      flex-direction column
      border ptr(1) solid #E8E8E8
      border-radius ptr(8)
      background white
      user-select none
      &__head
        font-size ptr(14)
        padding ptr(16)
        display flex
        justify-content space-between
        align-items center
        cursor pointer
      .hide
        display none
      &__options
        display grid
        padding ptr(12)
        max-height ptr(452)
        border-top ptr(1) solid #E8E8E8
        overflow auto
        box-sizing border-box
        .paper_option
          padding ptr(12)
          text-align center
          color #858585
          cursor pointer
          &__img[data-column_length="2"]
            max-width ptr(164)
          &__img[data-column_length="3"]
            max-width ptr(64)
          &__img
            width 100%
          &__name
            padding-top ptr(12)
            font-size ptr(14)
          &:hover
            background #F6F6F6
            border-radius ptr(16)
        .selected_option
          color #FF6B00
