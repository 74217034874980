.don_frame.simple_modal_frame_style
  .don_page
    height 100%
    >.don_wrapper
      height 100%
      >.body
        height 100%
        >.don_tab
          height 100%
          >.don_wrapper
            height 100%
.don_page[page_name="/modules/SimpleModal/S/Mui/page.js"]
  height 100%
  >.don_wrapper
    height 100%
    >.body
      height 100%
      >.don_tab
        height 100%
        >.don_wrapper
          height 100%
.simple_modal
  height 100%
  .simple_modal_wrapper
    height 100%
    position relative
    .header
      height 100px
      position absolute
      top 0
      left 0
      right 0
      font-size 22px
      display flex
      align-items center
      justify-content center
    .body
      top 100px
      bottom 100px
      left 0
      right 0
      position absolute
    .footer
      height 100px
      position absolute
      bottom 0
      left 0
      right 0
      display flex
      align-items center
      padding 0 40px
      button
        flex 1
        height 40px
        font-size 16px
        display flex
        align-items center
        justify-content center
        background none
        border 1px solid #d5dbe0
        margin 0 8px
        border-radius 100px
    .bg_removal_body
      box-sizing border-box
      text-align center
      height 100%
      padding-top 30px
      img
        max-width 100%
        max-height 90%
        object-fit contain
        width auto
      .content
        height 10%
        display flex
        justify-content center
        align-items center
        font-size 16px

.simple_modal[name="loc_optimization"]
  .header
    height 6.25rem
    font-size 1.625rem
  .body
    top 6.5rem
    .contents
      .thumbnails
        display flex
        justify-content center
        gap 0.5rem
        margin 4.25rem 3.75rem 3.5rem 3.75rem
        font-weight 500
        .example-thumbnail
          display flex
          flex-direction column
          justify-content center
          align-items center
          width 14rem
          height 19rem
          gap 1.75rem
          background-color #f6f6f6
          img
            width 11.6rem
            height 11.6rem
      .description
        display flex
        align-items center
        flex-direction column
        gap 0.5rem
        p
          margin 0 auto
          font-weight 500
          &.warning
            font-size 0.875rem
            color #A5A5A5
      .agreement_loc_optimization
        display flex
        justify-content center
        align-items center
        margin-top 3rem
        text-align center
        cursor pointer
        user-select none
        .check_box
          mp-editor-check-inactive()
          display inline-block
          vertical-align middle
          width 2.5rem
          height 1.5rem
          margin-right 0.4rem
          background-repeat no-repeat
          background-position 50% 50%
          background-size 1.5rem
        &.checked
          .check_box
            mp-editor-check-active()
            background-size 1.5rem
        &.lock
          opacity 0.4
          cursor not-allowed
        .title
          vertical-align middle
          display inline-block
  .footer
    height 6.25rem
    button
      font-size 1rem
      width 28.125rem
      height 3.125rem
      border-radius 62.4375rem
      border 1px solid black
      &.loc_optimization_toggler
        color white
        background-color black
        &.not_allowed
          cursor not-allowed
          opacity 0.2
