.omp-atom__icon-button
  resetButton()
  width ptr(28)
  height ptr(28)
  cursor pointer
  position relative
  .omp-atom__icon-badge
    box-sizing border-box
    width fit-content

.omp-atom__heart-circle-button
  resetButton()
  width ptr(28)
  height ptr(28)
  background-color WHT
  padding ptr(4)
  cursor pointer

.omp-atom__heart-count-button
  width ptr(60)
  height ptr(60)
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap ptr(4)
  .omp-atom__icon-button
    width ptr(20)
    height ptr(20)
  .omp-atom__icon-count
    &[data-style_active="true"]
      color OG
