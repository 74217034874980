@import "../../../../Share/F/index.styl"
vector-editor-frame()
  left 2.5rem !important
  right 2.5rem !important
vector-editor-light-selected()
  background rgba(255, 107, 0, 0.1)
vector-editor-border-radius-xs()
  background WHT
  border-radius 4px !important
  border 1px solid GY_50 !important
vector-editor-border-radius-s()
  background WHT
  border-radius 12px !important
  border 1px solid BK !important
vector-editor-border-radius-m()
  border-radius 20px !important
  border 1px solid BK !important
vector-editor-border-radius-l()
  border-radius 100px !important
  border 1px solid BK !important


vector-editor-short-version-width = 1412px
vector-editor-short-version-min-width = 1413px

vector-editor-right_container()
  border-radius 20px
  width 480px
  @media screen and (max-width vector-editor-short-version-width)
    width 320px
  height 97% !important
  button
    cursor pointer
  .cancel
  .prev
    vector-editor-border-radius-l()
  .next
  .done
    vector-editor-border-radius-l()
    background BK
    color WHT

@import '@simonwep/pickr/dist/themes/monolith.min.css'

/* 탑 매뉴 모듈*/
@import "../../../TopMenu/Pc/F/Style/style.styl"
/* 배경 색상 css 모듈 */
@import "../../../SettingBackground/Pc/F/Style/style.styl"


/* 아크릴 스탠드 */
@import "../../../AcrylicFigure/StandLeg/Pc/F/Style/style.styl"

/* 키링 고리 */
@import "../../../Keyring/Hook/Pc/F/Style/style.styl"

/* 스티커 자유형 아트보드 */
@import "../../../Sticker/Free/Pc/F/Style/style.styl"
/* 스티커 그리드 아트보드 */
@import "../../../Sticker/Grid/Pc/F/Style/style.styl"




/* 도형 라이브러리 */
@import "../../../FreeShape/Pc/F/Style/style.styl"


/*4845, 4801, 6501, 5046, 5067*/
/* 오토 커팅 라인 */
@import "../../../Free/Pc/CuttingLineAuto/F/Style/style.styl"
/* 수동 커팅 라인 */
@import "../../../Free/Pc/CuttingLineManual/F/Style/style.styl"
/* 패스 애디터 */
@import "../../../Free/Pc/PathEditor/F/Style/style.styl"
/* 싱글 애디터 */
@import "../../../Free/Pc/F/Style/style.styl"

@import "../../../../../../BpOption/UploadType/F/Style/Pc/index.styl"


.maker-upload-type-template
  button
    border-radius 8px
  &__download-btn
    background rgba(255, 107, 0, 0.10)
    border 1px solid OG
    color OG
  &__upload-btn
    background OG
    border 1px solid OG
  &__upload-description
    background BG_PRODUCT
    border-radius 8px
.maker-upload-type-board
  background #f6f6f6
  color OG
