.don_frame[frame_name="maker.design_collection"]
  >.don_wrapper >.header
    background-color #f8f9fa
    height ptr(60)
    position absolute
    top ptr(-60)
    left 0
    right 0
    width 100%
    .title
      font-size ptr(23)
      height 100%
      display flex
      justify-content center
      align-items center
  #marpplizer
    .maker_to_cart
      display none !important
  .image_upload_desc
  .loc_opt_desc
    display none !important
  #to_option
    display none
  .maker_go_next
    display block !important

html.design_collection_page[lang="en"][collabo_type="line"]
  .design_collection_content[type="h1"]
    *
      font-family: Volte,AppleSDGothicNeo,-apple-system,BlinkMacSystemFont,"Droid Sans","Roboto","Segoe UI","Helvetica",Arial,sans-serif;

html.design_collection_page.group
  .recommend_design_collections
    .etc.a_wrapper
      display none


body.design_collection_maker
  .select_box_wrapper
    .select_box
      select
        position fixed
        font-size ptr(20)
        padding 0 ptr(16)
      .blank
        position fixed
        top 0
        left 0
        right 0
        bottom 0
        background transparent
  .only_line
    display none
  .for_design_collection_content
    .arrow
      display none !important
    .item
      margin-bottom ptr(20)
      opacity 0.7
      vertical-align middle
      cursor pointer
      width ptr(200)
      display inline-block
      margin-right ptr(20)
      &:nth-child(2n)
        margin-right 0
      &:hover
        opacity 1
  .move.left
    background #fff url(//s3.marpple.co/files/u_193535/2019/8/original/f_724612_1566310871073_Oa8Bs5I1QV3NsT2szs8Wv.png) no-repeat 50% 50% !important
    background-size ptr(6) !important
    &:focus
      outline none
    &:hover
      background #000 url(//s3.marpple.co/files/u_193535/2019/8/original/f_724634_1566311079914_m7eJX0J7LbE1kWrmF4De.png) no-repeat 50% 50% !important
      background-size ptr(8) !important
  .move.right
    background #fff url(//s3.marpple.co/files/u_193535/2019/8/original/f_724639_1566311153358_I3quligApE8HC8Q7owc.png) no-repeat 50% 50% !important
    background-size ptr(6) !important
    &:focus
      outline none
    &:hover
      background #000 url(//s3.marpple.co/files/u_193535/2019/8/original/f_724626_1566310988666_weIn0mdg6kie8sb1tF8g.png) no-repeat 50% 50% !important
      background-size ptr(8) !important
  .move.top
    background #fff url(//s3.marpple.co/files/u_193535/2019/8/original/f_693037_1565612436790_mldmWV2X4tVk2LW3qL0x.png) no-repeat 50% 50% !important
    background-size ptr(10) !important
    &:focus
      outline none
    &:hover
      background #000 url(//s3.marpple.co/files/u_193535/2019/8/original/f_724625_1566310988666_ZURv7E9fy6BEvqT1D8OL.png) no-repeat 50% 50% !important
      background-size ptr(10) !important
  .move.down
    background #fff url(//s3.marpple.co/files/u_193535/2019/2/original/f_38416_1550764006898_ot4A9J3A6b5EAgci7E4h3Ul.png) no-repeat 50% 50% !important
    background-size ptr(10) !important
    &:focus
      outline none
    &:hover
      background #000 url(//s3.marpple.co/files/u_193535/2019/2/original/f_38415_1550764006898_Xf2h4Q2L4J3tvmI3DO5IR8T.png) no-repeat 50% 50% !important
      background-size ptr(10) !important
  .add_product
    { transform-center }
    text-align center
    .plus_button
      width ptr(24)
      height ptr(24)
      border none
      background url(//s3.marpple.co/files/u_193535/2019/4/original/f_150926_1555470121124_AS3amkUAf4D0V1KefCx.png) no-repeat 50% 50%
      background-size ptr(24)
      margin-bottom ptr(16)
    .text
      font-size ptr(14)
      color color_black
      text-align center
  #main_option
    display block
    margin-top ptr(16)
    position relative
    flex-wrap wrap
    margin-bottom ptr(50)
    .colm
      margin-right ptr(40)
      margin-bottom ptr(8)
    .delete
    .recover
    .msg
      display none
    &[is_hidden="true"]
      .msg
        font-size ptr(30)
        width 100%
        position absolute
        bottom ptr(-64)
        text-align center
        color #e06e2d
        display block
      .recover
        display inline-block
    &[is_hidden="false"]
      .delete
        display inline-block
    button, select
      margin-right ptr(16)
      background #fff
      border ptr(1) solid transparent
      padding ptr(8)
      border-radius ptr(4)
      box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
      &:hover
        background #000
        color #fff
      &:focus
        outline none
      &:nth-last-child
        margin-right 0
    html.pc_type &
      button.pc
        background #000
        color #fff
    html.mobile_type &
      button.mobile
        background #000
        color #fff
    &[is_public="true"]
      .is_public[key="is_public"]
        background #000
        color #fff
    &[is_hidden_public="true"]
      .is_hidden_public[key="is_hidden_public"]
        background #000
        color #fff
    &[is_hidden_public_en="true"]
      .is_hidden_public[key="is_hidden_public_en"]
        background #000
        color #fff
    &[is_hidden_public_jp="true"]
      .is_hidden_public[key="is_hidden_public_jp"]
        background #000
        color #fff
    &[is_public_en="true"]
      .is_public[key="is_public_en"]
        background #000
        color #fff
    &[is_public_jp="true"]
      .is_public[key="is_public_jp"]
        background #000
        color #fff
    &[lang=""]
      .kr
        background #000
        color #fff
    &[lang="_en"]
      .en
        background #000
        color #fff
    &[lang="_jp"]
      .jp
        background #000
        color #fff
    &[option_pc="1"]
      .option_pc
        background #000
        color #fff
        &:hover
          background #fff
          color color_black
    &[option_mobile="1"]
      .option_mobile
        background #000
        color #fff
        &:hover
          background #fff
          color color_black
    &[is_main="true"]
      .is_main[key="is_main"]
        background #000
        color #fff
        &:hover
          background #fff
          color color_black
    &[is_main_en="true"]
      .is_main[key="is_main_en"]
        background #000
        color #fff
        &:hover
          background #fff
          color color_black
    &[is_main_jp="true"]
      .is_main[key="is_main_jp"]
        background #000
        color #fff
        &:hover
          background #fff
          color color_black


dci_info_pc =
  .info
    padding-top ptr(12)
    .name
    .price
      color #333333
      text-align center
    .pc_name
    .bp_name
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
    .name
      line-height 1.57
      font-size ptr(14)
      font-weight 500
      margin 0 auto ptr(5)
      width ptr(190)
    .price
      font-size ptr(15)
      font-weight 500
dci_info_mobile =
  .info
    padding ptr(6) ptr(6) 0
    .name
      margin 0 auto ptr(5)
      width initial !important
    .name
    .price
      color #333333
      text-align center
    .pc_name
      font-size ptr(13)
      margin 0 auto ptr(2)
    .bp_name
      font-size ptr(13)
    .pc_name
    .bp_name
      font-weight 500
      text-overflow ellipsis
      white-space nowrap
      line-height 1.57
      overflow hidden
    .price
      font-size ptr(14)
      font-weight 500


design_collection_content_items_pc_styl =
  .design_collection_content_items
    box-sizing initial
    display flex
    flex-wrap wrap
  .design_collection_content_item
    .img_wrapper
      display block
    img
      width 100%
    .img
      display block
      position relative
      img
        {transform-center}
    { dci_info_pc }

design_collection_pc =
  .design_collection
    overflow hidden
    .design_collection_contents
      width ptr(496 * 2)
    &[no_background_url="false"]
      .design_collection_contents
        margin ptr(-96+19) auto 0
      >.background_url +.design_collection_contents
        margin ptr(-96+19-54) auto 0
    &[no_background_url="true"]
      .design_collection_contents
        margin ptr(19-57) auto
    &[option_pc="1"]
      .design_collection_contents
        margin 0 auto !important
      >.header
      >.background_url
        display none
    p
      margin 0
      padding 0
    .design_collection_content
      &[type="h1"]
        .body.val
          text-align inherit
          width 100%
          font-size ptr(37)
          border none
          font-weight 500
      &[type="p1"]
        .body.val
          text-align inherit
          width 100%
          box-sizing initial
          font-size ptr(16)
          line-height 1.5
          border none
    >.header
      margin ptr(80) 0 0
      text-align center
      .title
        >.body
          width 100%
          text-align center
          font-size ptr(38)
          font-weight 500
          border none
      .description
        >.body
          width 100%
          box-sizing initial
          text-align center
          font-size ptr(16)
          line-height 1.5
          border none
    >.background_url
      >.body
        min-height ptr(200)
        background-size cover
  .design_collection_content
    &[type="full_img"]
    &[type="left_img"]
    &[type="right_img"]
      >.body
        width 100%
        margin 0 auto
    &[type="left_img"]
    &[type="right_img"]
      .title
        margin-bottom ptr(24)
    .design_collection_content_item
      margin-right ptr(8)
    &[type="items"]
    &[type="items_5"]
      >.body
        width 100%
        margin 0 auto
    &[type="items_6"]
      >.body
        width ptr(1217)
        margin 0 auto
    &[type="banner"]
      width 100%
      margin 0
    &[type="banner_margin"]
      width 100%
      margin 0 auto
    /*여기*/
    >.option
      right 0 !important
    &[type="items"] >.option
    &[type="items_5"] >.option
    &[type="items_6"] >.option
      right ptr(-1) !important
    >.option
      left initial !important
      transform initial !important
      > button
        &:nth-of-type(1)
          border-bottom-left-radius 0 !important
        &:nth-last-of-type(1)
          border-bottom-right-radius 0 !important
    &[type="items"]
      .design_collection_content_item
        .img
        .img_wrapper
          width ptr(242)
          height ptr(242)
    &[type="items_5"]
      .design_collection_content_item
        width ptr(186)
        .img
        .img_wrapper
          width ptr(186)
          height ptr(186)
    &[type="items_6"]
      .design_collection_content_item
        width ptr(190)
        .img
        .img_wrapper
          width ptr(190)
          height ptr(190)
    &[type="items"]
      .design_collection_content_item
        margin-bottom ptr(54)
        &:nth-child(4n)
          margin-right 0
    &[type="items_5"]
      .design_collection_content_item
        margin-bottom ptr(44)
        &:nth-child(5n)
          margin-right 0
    &[type="items_6"]
      .design_collection_content_item
        margin-bottom ptr(25)
        &:nth-child(6n)
          margin-right 0
    &[type="items"]
    &[type="items_5"]
    &[type="items_6"]
      { design_collection_content_items_pc_styl}
    &[type="full_img"]
    &[type="banner"]
    &[type="banner_margin"]
      >.body >.wrapper
        background #fff
        .thumbnail_url
          position relative
          >.wrapper
            { transform-center }
            width 100%
            text-align center
        .thumbnail_url
          position relative
          overflow hidden
          background #fff
          .body
            img
              width 100%
              vertical-align top
      .design_collection_banner_group
        display flex
        gap ptr(16)
        width 100%
        img
          flex 1
    &[type="right_img"]
      >.body >.wrapper
        flex-direction row-reverse
    &[type="left_img"]
    &[type="right_img"]
      >.body >.wrapper
        display flex
        background #fff
        height ptr(496)
        .thumbnail_url
        .title_description_background_color
          flex 1
          position relative
          >.wrapper
            { transform-center }
            width 100%
            text-align center
          .title
            margin-left ptr(20)
            margin-right ptr(20)
            >.body
              width 100%
              text-align center
              font-size ptr(32)
              font-weight 500
              border none
              background transparent
          .description
            margin-left ptr(20)
            margin-right ptr(20)
            >.body
              width 100%
              box-sizing initial
              text-align center
              font-size ptr(16)
              line-height 1.5
              border none
              background transparent
        .thumbnail_url
          position relative
          overflow hidden
          background #fff
          .body
            img
              position absolute
              left 50%
              transform translateX(-50%)
              width auto
              height ptr(496)

design_collection_content_item_line_pc =
  .long_thumbnail_mobile
    width ptr(350)
    min-height ptr(350)
    margin 0 auto ptr(40)
    .long_thumbnail_url
      &[has_item="false"]
        >.body
          height ptr(350)
  .design_collection_long_thumbnail
    width ptr(1250)
    margin ptr(80) auto 0
    padding-top ptr(80)
    .long_thumbnail_url
      html.design_collection_maker &
        >.header
          text-align center
    html.home &
      margin 0 auto
      padding-top 0
      margin-bottom ptr(80)
      &:first-child
        margin-top ptr(60)
    border-top ptr(1) solid black
  .long_thumbnail
    .long_thumbnail_url
      &[has_item="false"]
        >.body
          height ptr(300)
  .design_collection_long_thumbnail
  .long_thumbnail_mobile
    .design_collection_content_items
      box-sizing initial
      display flex
      flex-wrap wrap
      &[lines="0"]
      &[lines="1"]
        justify-content space-around
        >div
          margin-right 0
  .design_collection_long_thumbnail
    .design_collection_content_items
      justify-content space-between
    html.home &
      border none
      .add_product
        display none
      .option
        display none
    >.header
      html.home &
        display none
      font-size ptr(20)
      text-align center
      margin-bottom ptr(40)
    .long_thumbnail
      margin-bottom ptr(40)
    .thumbnail_title
      margin-bottom ptr(16)
      font-size ptr(20)
      display flex
      justify-content space-between
    .long_thumbnail_url
      .val
        width 100%
        vertical-align top
      &[has_item="false"]
        margin-bottom ptr(40)
        .val
          display none
        >.body
          width 100%
          background #fff
          border ptr(2) dotted #000
    .select_product_wrapper
      cursor pointer
      width ptr(175)
      position absolute
      top ptr(-42)
      right 0
      border ptr(2) dotted #000
      padding ptr(10)
      .text
        font-size ptr(14)
        color color_black
        text-align center
    .design_collection_content_item
      width ptr(300)
      .img_wrapper
        position relative
        width ptr(300)
        height ptr(300)
        img
          width 100%
          { transform-center }
      .info
        margin-top ptr(8)
        text-align center
a_button_1 =
  padding ptr(5)
  border ptr(1) solid #000
  border-radius ptr(4)
  box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
  margin-right ptr(8)
  &:hover
    background #000
    color #fff

design_collection_thumbnail =
  .thumbnail
    vertical-align top
    >.is_main
    >.option
      display none
    .thumbnail_url
      position relative
      overflow hidden
      img
        width 100%
        html.design_collection_maker &
          width ptr(300)
          height ptr(300)
      .msg
        display none
    .item_none
      border ptr(1) solid #868e96
      position relative
      img
        display none
      .msg
        display block
        {transform-center}
        font-size ptr(16)
    .thumbnail_title
      font-size ptr(14)
      padding ptr(8) ptr(8) 0 ptr(8)
      white-space pre-line
      font-weight 600
    .thumbnail_description
      white-space pre-line
      color #495057
      font-size ptr(12)
      padding ptr(4) ptr(8) 0 ptr(8)

design_collection_content_items_mobile_styl =
  .design_collection_content_item
    &:nth-child(2n -1)
      padding-right 1%
    &:nth-child(2n)
      padding-left 1%
    width 50%
    display inline-block
    vertical-align top
    margin-bottom 8%
    html[collabo_type="line"] &
      margin-bottom 11%
    .img_wrapper
      display block
      position relative
      width 100%
      padding-top 100%
      &a
        display block
      img
        width 100%
        { transform-center }
    { dci_info_mobile }

design_collection_mobile =
  .design_collection_content_items
    padding 0 ptr(18) 0
    { design_collection_content_items_mobile_styl }
  .design_collection
    margin-bottom ptr(20)
    &[option_mobile="1"]
      .design_collection_contents
        margin 0 auto !important
      >.header
      >.background_url
        display none
    &[no_background_url="true"]
      >.header
        margin-top ptr(42)
        margin-bottom ptr(40)
        padding 0
        img
          display none
        >.wrapper
          position initial
    >.header
      width 100%
      padding-top 100%
      position relative
      >.wrapper
        position absolute
        top 0
        left 0
        width 100%
        height 50%
        display flex
        flex-direction column
        justify-content center
        .title
          font-size ptr(24)
          font-weight 500
          text-align center
          margin-bottom ptr(14)
        .description
          font-size ptr(14)
          text-align center
          >.body
            margin 0 ptr(57)
        &[is_title_none="true"]
          .title
            display none
        &[is_description_none="true"]
          .title
            margin-bottom 0
          .description
            display none
      .background_url
        position absolute
        top 0
        left 0
        width 100%
        &[has_item="true"]
          padding-bottom 100%
        >.body img
          position absolute
          top 0
          left 0
          width 100%
  .design_collection_content
    margin-bottom ptr(40)
    &[type="h1"]
      padding 0 ptr(30)
      .body
        font-size ptr(24)
        font-weight 500
        line-height 1.5
    &[type="p1"]
      padding 0 ptr(30)
      .body
        font-size ptr(14)
        line-height 1.5
    &[type="items"]
    &[type="items_5"]
    &[type="items_6"]
      margin-bottom ptr(30)
    &[type="full_img"]
    &[type="banner"]
    &[type="banner_margin"]
      .thumbnail_url
      .thumbnail_url_mobile
        img
          width 100%
          vertical-align top
    &[type="left_img"]
    &[type="right_img"]
      >.body >.wrapper
        position relative
        padding-bottom 496px
      .thumbnail_url
        z-index 2
      .title_description_background_color
        padding-top 496px
        z-index 1
        .title
          text-align center
          font-size ptr(18)
          font-weight 500
          margin-bottom ptr(8)
        .description
          text-align center
          font-size ptr(14)
          display flex
          flex-direction column
          justify-content center
          line-height 1.5
        >.wrapper
          position absolute
          top 0
          left 0
          width 100%
          padding 0 ptr(16)
          padding-top 496px
          &[is_title_none="true"]
            .title
              display none
          &[is_description_none="true"]
            .description
              display none
          >.wrapper2
            position absolute
            top 50%
            left 50%
            width 90%
            transform translate(-50%, -50%)
      .thumbnail_url
        position absolute
        top ptr(136)
        text-align center
        width 100%
        img
          width 496px
          height 496px


.don_frame[frame_name="mp.design_collection_content_items_frame"]
  .don_page >.don_wrapper
    .header
      .done
        display block
        position absolute
        top ptr(19)
        font-size ptr(18)
        border ptr(1) solid #000
        right ptr(29)
        padding ptr(5) ptr(14)
        border-radius ptr(15)
        &:hover
          background #000
          color #fff
      .title
        font-size ptr(24)
        text-align center
        padding ptr(20) 0
  .card_list
    display flex
    flex-wrap wrap
    .item
      flex-basis ptr(300)
      position relative
      height ptr(300)
      margin ptr(16) ptr(16)
      cursor pointer
      box-shadow 0 ptr(8) ptr(16) 0 rgba(0,0,0,0.2)
      &:after
        position absolute
        font-size ptr(40)
        width ptr(20)
        top ptr(10)
        left ptr(10)
      img
        position absolute
        left 50%
        transform translate(-50%, -50%)
      &[no_thumbnail="null"]
        box-shadow initial !important
        &:after
          display none


basic_setting_jip =
  *
    box-sizing border-box
  p
    padding 0
    margin 0

body.design_collection_maker
  .design_collection_content[type="banner"]
  .design_collection_content[type="full_img"]
    .has_img_wrapper[has_item="false"]
      .tool_wrapper.temp
        position relative
        &:before
          position absolute
          top 50%
          left 50%
          transform translate(-50%, -50%)
          content "width: ptr(1920)"
          html.mobile_type &
            content "width: ptr(800)"
          font-size ptr(20)
  .bg
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 1
.design_collection_editor
  [data-checked="true"]
    text-decoration underline
  .design_collection_contents
    html:not(.design_collection_maker) &
      width ptr(496 * 2+2) !important
  .edit_btn_wrapper
    position relative
    >.edit_btn
      position absolute
      padding ptr(10)
      border ptr(1) solid black
      border-radius ptr(4)
      display none
      top ptr(8)
      right ptr(8)
    .edit_option
      position absolute
      top ptr(8)
      right ptr(8)
      > *
        height ptr(30)
        text-align center
        background #fff
        border ptr(1) solid #000
        border-left none
        vertical-align top
        &:first-child
          border-left ptr(1) solid #000
        &:hover
          background #000
          color #fff
      .hide
        display none

    &[key="background_url_mobile"]
      >.edit_btn
      .edit_option
        top initial
        bottom 0
    &.background_url
      .edit_option
        .hide
          display inline-block
    &:hover
      >.edit_btn
        display block
    &.editing
      >.edit_btn
        display none
  .design_collection_content
    .has_img_wrapper[has_item="false"]
      .val
        display none
      .body
        width 100%
        height ptr(496)
        html.mobile_type &
          height ptr(375)
        background #fff
        border ptr(2) dotted #000
    &.content_editable_wrapper
      >.option
        top 0
        transform translate(-50%, -50%)
    &.thumbnail_url
      >.option
        top 0
    &.design_collection_content_item
      >.option
        top ptr(20)
  .design_collection_content_item >.option > button
    &:nth-of-type(1)
      border-top-left-radius 0
      border-bottom-left-radius 0
      border-top-right-radius ptr(4)
      border-bottom-right-radius ptr(4)
  .design_collection_long_thumbnail
    display none
    html[collabo_type="line"] &
      display block
  { basic_setting_jip }
  {design_collection_content_item_line_pc}
  html.pc_type &
    .design_collection_header
      >.title
        margin-bottom ptr(20)
      >.description
        margin-bottom ptr(40)
  textarea
    padding 0
    vertical-align top
    &:focus
      outline none
  button:focus
    outline none
  input[type="text"]
    border ptr(1) solid #868e96
  .edit_view
    .background_color
    .font_color
    .lib
      display none
  .ing
    >.option
      display inline-block !important
      white-space nowrap
      z-index 100000
    .edit_view
      margin-left ptr(8)
      display inline-block
      vertical-align top
    &[edit_view_type="color"]
      .font_color
      .background_color
        display inline-block
        font-size ptr(14)
        width ptr(80)
        height ptr(30)
        text-align center
    &[edit_view_type="url"]
      .lib
        background #fff
        display block
        width ptr(100)
        border ptr(1) solid #000
        border-radius ptr(4)
        max-height ptr(500)
        overflow scroll
        img
          width 100% !important
          height auto !important
          display block
          &:hover
            border ptr(3) solid #868e96
  .hidden_input
    position absolute
    left ptr(-2000)
  .design_collection
    .hidden_option
      > button
        display none
    &[no_background_url="true"]
      .background_url
        display none
      .hidden_option
        button
          display block !important
          position absolute
          top ptr(-37)
          left 0
          margin-right ptr(16)
          background #868e96
          border ptr(1) solid transparent
          padding ptr(8)
          color #fff
          border-radius ptr(4)
          box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
          &:hover
            background #000
            color #fff
          &:focus
            outline none
  html.pc_type &
    { design_collection_pc }
    .add_product_wrapper
      cursor pointer
      position relative
      border ptr(2) dotted #000
    .design_collection_content[type="items_6"]
      .add_product_wrapper
        height ptr(247)
        width ptr(192)
    .design_collection_content[type="items"]
      .add_product_wrapper
        height ptr(293)
        width ptr(236)
    .design_collection_content[type="items_5"]
      .add_product_wrapper
        height ptr(247)
        width ptr(188)
    .design_collection_contents
      border-bottom ptr(1) solid grey
    //.design_collection_content
    //  margin-bottom 0 !important
    .design_collection_content_item
      &.option_wrapper_default
        >.option
          display none
        &:hover
          >.option
            display block
      .zoom_text
        { transform-center }
        opacity 0
        font-size ptr(30)
        transition opacity 0.5s
        padding 0 ptr(8)
        border-radius ptr(8)
        background #fff
        &:after
          content "%"
      &.zoom_ing
        .zoom_text
          opacity 0.7
    .background_url[has_item="false"]
      .body
        width 100%
        height ptr(360)
        background #fff
        border ptr(2) dotted #000
    .design_collection_thumbnail
      >.header
        font-size ptr(20)
        text-align center
        margin-bottom ptr(40)
      .short_name
      .thumbnail_title
      .thumbnail_description
        padding 0 ptr(8)
        margin-bottom ptr(16)
        label
          display block
          margin-bottom ptr(8)
        input
          width 100%
      .thumbnail_url[has_item="false"]
        .val
          display none
        .body
          width ptr(300)
          height ptr(300)
          background #fff
          border ptr(2) dotted #000
    .title_description_background_color
      >.background_color
        position absolute
        top 0
        left 0
        width ptr(496)
        height ptr(496)
        >.body
          width 100%
          height 100%
  html.mobile_type &
    { design_collection_mobile }
    .add_product_wrapper
      width 50%
      padding-top 50%
      position relative
      border ptr(2) dotted #000
    .design_collection
      width ptr(375) !important
      margin ptr(40) auto 0 !important
      >.header >.background_url[has_item="false"]
        .val
          display none
        .body
          width 100%
          height ptr(375)
          border ptr(2) dotted #000
    .background_url
      >.option
        top 70%
    .design_collection_contents
      width 100%
  { design_collection_thumbnail }
  .design_collection
    margin-bottom ptr(40)
  .design_collection_contents[design_collection_contents_length="0"]
    margin 0 auto !important
  .design_collection_thumbnail
    width ptr(300)
    box-sizing initial
    margin 0 auto
    margin-top ptr(80)
    margin-bottom ptr(40)
  .design_collection_contents
    position relative
  .option_wrapper1:not(.ing)
    cursor pointer
  .plus_content
    text-align center
    padding ptr(10) 0
    background #000
    box-sizing initial
    opacity 0.7
    .plus_button
      padding ptr(5)
      box-sizing initial
      width ptr(24)
      height ptr(24)
      margin 0 auto
  .plus_button
    width ptr(24)
    height ptr(24)
    border none
    background #fff url(//s3.marpple.co/files/u_193535/2019/4/original/f_150926_1555470121124_AS3amkUAf4D0V1KefCx.png) no-repeat 50% 50%
    background-size ptr(24)
  .design_collection_content
    >.option
      text-align right !important
    .thumbnail_url
      >.option
        top ptr(5) !important
    .title_description_background_color
      >.background_color
        >.option
          top ptr(5) !important

  .option_wrapper_default
    position relative
    input[key="margin_bottom"]
      width ptr(50)
    >.option
      font-size initial
      position absolute
      top ptr(-30)
      white-space nowrap
      .hidden_input
        display none !important
      > .body > *
        height ptr(30)
        text-align center
        background #fff
        border ptr(1) solid #000
        margin 0
        border-left none
        vertical-align top
        &:first-child
          border-left ptr(1) solid #000
        &:hover
          background #000
          color #fff
    &.design_collection_content_item
      >.option
        top ptr(10)
        left 50%
        transform translateX(-50%)
  .design_collection_content_item >.option > button
    &:nth-of-type(1)
      border-top-left-radius 0
      border-bottom-left-radius 0
      border-top-right-radius ptr(4)
      border-bottom-right-radius ptr(4)


.product_detail_tab_body[data-tab_name="STORY_TAB"]
  .mp_today_detail_mobile_style
    background #fff
    padding-top ptr(50)
    padding-bottom ptr(140)
.don_frame[frame_name="mp.design_collection"]
.product_detail_tab_body[data-tab_name="STORY_TAB"]
  .etc
    width ptr(195)
    height ptr(195)
    border ptr(1) solid #d5dbe0
    display inline-block
    position relative
    cursor pointer
    a
      background url(//s3.marpple.co/files/u_193535/2019/1/original/f_24990_1547523794727_g8Ir6r8ilgIsfE9cO0IB.png) no-repeat 50% 0
      background-size ptr(16)
      font-size ptr(14)
      font-weight 500
      padding-top ptr(24)
      color BK
      { transform-center }
.mp_today_detail_pc_style
  .etc
    width ptr(195)
    height ptr(195)
    border ptr(1) solid #d5dbe0
    display inline-block
    position relative
    cursor pointer
    .text
      background url(//s3.marpple.co/files/u_193535/2019/1/original/f_24990_1547523794727_g8Ir6r8ilgIsfE9cO0IB.png) no-repeat 50% 0
      background-size ptr(16)
      font-size ptr(14)
      font-weight 500
      padding-top ptr(24)
      { transform-center }
.don_frame[frame_name="mp.design_collection"]
.mp_today_detail_pc_style
  *
    box-sizing border-box
  { design_collection_pc }
  .design_collection
    &[no_background_url="true"]
      >.header
        margin ptr(80) 0 ptr(80) !important
      >.background_url
        display none
    >.header
      .title
        margin-bottom ptr(20)
      .description
        margin-bottom ptr(40)
    >.header[is_title_none="true"]
      .title
        display none
    >.header[is_description_none="true"]
      .title
        margin-bottom ptr(40)
      .description
        display none
  .title_description_background_color
    .description
      margin-top ptr(24)
    >.wrapper[is_title_none="true"]
      .title
        display none
      .description
        margin-top 0
    >.wrapper[is_description_none="true"]
      .description
        display none
  .recommend_design_collections
    width ptr(1248)
    margin ptr(8) auto ptr(80)
    position relative
    { design_collection_thumbnail }
    .thumbnail
      width ptr(195)
      margin-right ptr(15)
      display inline-block
    .short_name
      display none
    .thumbnail_title
      font-size ptr(14) !important
    .thumbnail_description
      display none
    >.header
      font-size ptr(20)
      font-weight 500
      padding ptr(40) 0 ptr(20)
    >.body
      width ptr(1251)
  .sharing
    display none
    height ptr(48)
    width ptr(200)
    border ptr(1) solid #d5dbe0
    position relative
    margin 0 auto ptr(80)
    a
      font-size ptr(14)
      font-weight 500
      padding-left ptr(24)
      background url(//s3.marpple.co/files/u_193535/2019/1/original/f_25028_1547526497515_o7L8WG9lao4PN9M2qB0u6Mk.png) no-repeat 0 0
      background-size ptr(16)
      { transform-center }

html.design_collection_list
  [design_policy="false"]
    display none !important


.don_frame[frame_name="mp.design_collection_list"]
.don_frame[frame_name="mp.design_collection_list_editor"]
  .design_collection_list_body
    .short_name
      display none
    html.group.design_collection_list &
      .thumbnail_description
        display none
.don_frame[frame_name="mp.design_collection_list_editor"]
  select[name="design_collection_types"]
    -webkit-appearance auto
    cursor pointer
.don_frame[frame_name="mp.group"]
.don_frame.mp_today_list_style
.don_frame[frame_name="mp.design_collection_list"]
.don_frame[frame_name="mp.design_collection_list_editor"]
  .omp-layout-body__cont
    >.body
      position relative
      >.header
        display inline-block
        .option
          display inline-block
          margin-left ptr(32)
          > a
            border ptr(1) solid #000
            padding ptr(5)
  .a_wrapper
    cursor pointer
  *
    box-sizing border-box
  p
    margin 0
  {design_collection_thumbnail}
  .thumbnail_url
    width ptr(300)
    height ptr(300)
  .thumbnail
    margin-bottom ptr(40)
    margin-right ptr(16)
    .thumbnail_title
      padding ptr(17) 0 ptr(7)
    .thumbnail_description
      padding 0
  .don_tab >.don_wrapper >.body
    width ptr(1248)
    margin 0 auto ptr(80)
    >.header
      margin ptr(80) 0 ptr(40)
      font-size ptr(20)
      font-weight 500
  .design_collection_list_body
    display flex
    flex-wrap wrap
.don_frame[frame_name="mp.design_collection_list_editor"]
  .msg
    display none
  .omp-layout-body__cont >.body >.header .option
    a:first-child
      margin-right ptr(32)
    a
      { a_button_1 }
    a.selected
      background #000
      color #fff
  .thumbnail
    .revise
      { a_button_1 }
    position relative
    padding ptr(42) 0
    width ptr(300)
    >.option
      display block
      position absolute
      top 0
      right 0
    .thumbnail_url
      position relative
      .is_hidden_public
        display none
        position absolute
        top 0
        right 0
        background #ff4d4d
        box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
        color #fff
        padding ptr(8)
        &[is_hidden_public="true"]
          display block
      .is_public
        display none
        position absolute
        bottom 0
        left 0
        background #003eff
        box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
        color #fff
        padding ptr(8)
        &[is_public="true"]
          display block
      .is_public_en
        display none
        position absolute
        bottom 0
        left 50%
        transform translateX(-50%)
        background #ee00ff
        box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
        color #fff
        padding ptr(8)
        &[is_public_en="true"]
          display block
      .is_public_jp
        display none
        position absolute
        bottom 0
        right 0
        background #ffaf00
        box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
        color #fff
        padding ptr(8)
        &[is_public_jp="true"]
          display block
      .is_hidden_public_en
        display none
        position absolute
        bottom 0
        right 0
        background #ff4d4d
        box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
        color #fff
        padding ptr(8)
        &[is_hidden_public_en="true"]
          display block
      .is_hidden_public_jp
        display none
        position absolute
        bottom 0
        left 0
        background #ff4d4d
        box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
        color #fff
        padding ptr(8)
        &[is_hidden_public_jp="true"]
          display block
      .is_main
        border ptr(1) solid transparent
        position absolute
        top 0
        display none
        border-radius ptr(4)
        box-shadow ptr(3) ptr(2) ptr(8) ptr(3) rgba(0, 0, 0, 0.1)
        padding ptr(8)
        width 100%
        text-align center
        margin-bottom ptr(8)
        background #000
        color #fff
        &[is_main="true"]
          display block


.don_frame[frame_name="mp.design_collection_list_editor"]
  .omp-layout-body__cont >.body >.header
    height ptr(50)
    margin-bottom ptr(16)
    margin-top ptr(10)
