.sticker_etc_share_style
  .block_for_stroke_dash_wrapper
    position relative
  .block_for_stroke_dash
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    display none
  [data-has_stroke = "false"]
    .block_for_stroke_dash_wrapper
      opacity 0.2
      .block_for_stroke_dash
        display block
.svg_editor_stroke_dash_wrapper
  *
    box-sizing border-box
  .title
    display flex
    span
      margin-right 8px
      width 16px
      height 16px
      cursor pointer
  &[data-has_dash_stroke="true"]
    .title
      span
        //background no-repeat left/16px url(//s3.marpple.co/files/u_193535/2021/8/original/64e9a1ff2911569b2bf96546d45073d2c7096b4e1.svg)
        mp-editor-check-active()
        background-size 16px 16px
  &[data-has_dash_stroke="false"]
    .title
      span
        mp-editor-check-inactive()
        background-size 16px 16px
  .body
    display flex
    justify-content space-between
    align-items center
    flex 1
    width 100%
    >div
      flex-grow 1
  .control_box
    vector-editor-border-radius-xs()
    display flex
    justify-content space-between
    align-items center
    width 45%
    > div
      flex-grow 1
    .number
      width 50%
      text-align center
    .operator
      width 30%
      cursor pointer
      &.minus
        background no-repeat center/10px url(//s3.marpple.co/files/u_193535/2021/8/original/346989aa64b91aead8bf9f1c2124cf55fec5d1964.svg)
      &.plus
        background no-repeat center/10px url(//s3.marpple.co/files/u_193535/2021/8/original/56acb8b265c1902b37ddf8f84bcf606669af074d5.svg)
  .lock
    width calc(8px + 14px + 8px)
    height 40px
  &[data-is_locked="true"]
    .lock
      background no-repeat center/14px url(//s3.marpple.co/files/u_193535/2021/8/original/3a092a09c87ea61ee155eb72bc0f9649a04da9fd3.svg)
  &[data-is_locked="false"]
    .lock
      background no-repeat center/14px url(//s3.marpple.co/files/u_193535/2021/8/original/e275212e8435080458951ee4a466d7eb88869f946.svg)
