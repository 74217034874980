.don_frame[frame_name="/modules/OMP/Core/Frames/TabStyle/S/Mui/frame.js"]
  > .don_wrapper
    padding 30px
    padding-bottom 0
    > .body
      overflow hidden
  &[modal_title="MATERIAL"]
    > .don_wrapper
      height ptr(544)
  &[modal_title="MODIFY"]
    > .don_wrapper
      height ptr(824)
    .is_size_issue
      height ptr(510)

.don_page[page_name="/modules/OMP/Core/Frames/TabStyle/S/Mui/page.js"]
    .header
      .modal_title
        font-size ptr(26)
        font-weight 500
        height ptr(30)
        padding-bottom ptr(40)
      .modal_description
        display flex
        gap ptr(24)
        font-size ptr(14)
        &__title
          flex-basis ptr(164)
          font-size ptr(20)
          font-weight 500
          line-height 140%
          padding-bottom ptr(40)
          flex-shrink 0
        &__content
          //width ptr(787)
          font-weight 400
          line-height 160%
          padding-bottom ptr(40)
          word-break keep-all

.don_tab[tab_name="/modules/OMP/Core/Frames/TabStyle/S/Mui/tab.js"]
  .modal_wrapper
    display flex
    gap ptr(24)
    .modal_side
      flex-shrink 0
      width ptr(164)
      ul
        list-style none
        padding-left 0
        margin 0
        .list
          font-size ptr(16)
          padding-bottom ptr(24)
          cursor pointer
        .selected
          color #FF6B00
    .modal_content
      .content_info
        display none
        &__content
          width ptr(391)
          font-size ptr(14)
          &__title
            font-size ptr(20)
            font-weight 500
            margin-bottom ptr(24)
            line-height 140%
          &__description
            font-size ptr(14)
            font-weight 400
            line-height 160%
            border-bottom ptr(1) solid #D8D8D8
            padding-bottom ptr(16)
          &__detail
            > div
              display flex
              padding ptr(16) 0
              &:not(:last-child)
                border-bottom ptr(1) solid #D8D8D8
              .title
                font-weight 500
                width ptr(120)
              .content
                font-weight 400
                display flex
                flex-direction column
                gap ptr(8)
                &__sub
                  font-size 12px
                  color #858585
        img
          width ptr(373)
          height ptr(373)
          border-radius ptr(12)
      .selected
        display flex
        gap ptr(24)

      .modify_info
        display none
        height ptr(588)
        &__content
          width ptr(788)
          display flex
          flex-direction column
          gap 24px
          font-size ptr(14)
          overflow scroll
          overscroll-behavior-y none
          border-top ptr(1) solid black
          &__title
            font-size ptr(20)
            font-weight 500
            padding-top ptr(24)
          &__description
            line-height 160%
          &__img
            width ptr(680)
          &__content
            line-height 170%
            font-size ptr(16)

      .selected
        display flex

      .size_issue_tmpl_download
        display flex
        width ptr(320)
        padding ptr(13)
        justify-content center
        gap 8px
        border-radius ptr(8)
        border 1px solid var(--OG, #FF6B00)
        background rgba(255, 107, 0, 0.10)
        color var(--OG, #FF6B00)
        margin ptr(16) 0
        font-size ptr(14)
        font-weight 500
        align-items center
