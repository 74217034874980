.header-banner
  position fixed
  top 0
  left 0
  right 0
  z-index 1
  &__wrap
    position relative
    max-width ptr(1920)
    margin 0 auto
    display flex
    align-items center
    justify-content center
    height ptr(80)
  &__img
    height 100%
    img
      height 100%
  &__btn-close
    position absolute
    top 50%
    right ptr(36)
    transform translateY(-50%)
    cursor pointer
    padding ptr(10)
    svg
      width ptr(20)
      height ptr(20)
      margin 0
      vertical-align top

.omp-header
  position fixed
  top 0
  left 0
  right 0
  height ptr(150)
  background-color rgba(255, 255, 255, 0.9)
  z-index 1
  max-width ptr(1920)
  min-width ptr(800)
  margin 0 auto
  &[data-header_banner="header_banner"]
    top ptr(80)
  &__wrap
    position relative
    z-index 1
  &__logo
    position absolute
    top ptr(39)
    left ptr(40)
    margin 0
    padding 0
    font-size 1px
    line-height 1
  &__logo-img
    height ptr(20)
  &__body
    position absolute
    top ptr(25)
    left ptr(260)
    right ptr(300)
  &__body-scroll
    display flex
    align-items center
    justify-content center
    .omp-cell__category-buttons
      flex-wrap nowrap
      > *
        flex-shrink 0
  &__ul
    margin 0
    padding 0
    list-style none
    align-items center
    display flex
    height ptr(40)
  &__li
    margin-left ptr(4)
  &__right
    position absolute
    top ptr(26)
    right ptr(40)
  &__buttons
    display flex
    align-items center
    justify-content flex-end
    height ptr(40)
  &__menus
    position absolute
    top ptr(90)
    left ptr(40)
    display flex
    align-items flex-start
    justify-content flex-start
    gap ptr(8)
    z-index 0
  &__menu-buttons
    position relative
    width 100%
    border-radius ptr(22)
  &__menu-buttons-wrap
    display flex
    align-items center
    justify-content flex-start
    .omp-atom__header-menu-button:not(:last-child)
      margin-right 8px
  &__menu-buttons-next
  &__menu-buttons-prev
    display none
    position absolute
    margin-top 0
    transform translateY(-50%)
    top 50%
    right ptr(-50)
    left auto
    opacity 0
    width ptr(40)
    height ptr(40)
    background WHT
    border 1px solid BG_PRODUCT
    border-radius 9999px
    svg
      position absolute
      top 50%
      left 50%
      width ptr(9)
      height ptr(16)
      margin-top ptr(-8)
      margin-left ptr(-4)
  &__menu-buttons-prev
    svg
      margin-left ptr(-5)
      transform rotate(-180deg)
  &__navi
    display none
    position fixed
    top ptr(150)
    left 0
    right 0
  &__navi-bg
    display block
    position fixed
    top ptr(150)
    left 0
    right 0
    bottom 0
    background rgba(246, 246, 246, 0.50)
    backdrop-filter blur(20px)
  &__navi-body
    position relative
    z-index 1
    display flex
    align-items center
    justify-content center
    background-color WHT


// 검색 페이지 자동완성 리스트를 보여주기 위해 overflow 제거함
.omp-search-frame
  .omp-header__body
    overflow unset !important


html.omp.product.detail
  &.omp-gnb-hover
    .don_frame[frame_index="0"] > .don_wrapper > .header
      background-color #fff !important
  .omp-header
    height ptr(98)
    &__body
      display none !important
    &__menus
      top ptr(27)
      left ptr(245)
      right ptr(328)
    &__menu-buttons
      opacity 1
    &__navi
      top ptr(90)
      z-index 1
    &__menu-buttons-next
    &__menu-buttons-prev
      display block
      &.swiper-button-disabled
        display none


html.omp.product.detail[lang="en"]
  .omp-header
    &__menus
      right ptr(328) !important

html.omp.product.detail[lang="jp"]
  .omp-header
    &__menus
      right ptr(354) !important


html.omp.product.detail
  @media screen and (max-width: 1600px)
    .omp-header__menus
      top 50% !important
      transform translateY(-50%)
      right 264px
    .omp-atom__header-menu-arrow
      padding 4px 8px
      height 28px
    .omp-atom__header-menu-arrow-text
      font-size 12px !important
    .omp-atom__header-menu-button
      padding 4px 8px
    .omp-atom__header-menu-button-text
      font-size 12px !important
    .omp-header__menu-buttons-wrap .omp-atom__header-menu-button:not(:last-child)
      margin-right 2px
    .omp-header__menu-buttons-next
    .omp-header__menu-buttons-prev
      width 22px
      height 22px
      right -39px
      svg
        width 10px
        height 10px
        margin-top -4px

