
html[lang="jp"], html[lang="jp"] body
  letter-spacing 0.5px
  *
    word-break break-all !important

input:not([type="radio"]):not([type="checkbox"]):not([type="text"])
select
  -webkit-border-radius 0
  -webkit-appearance none

html[lang="kr"]
html[lang="kr"] body
  letter-spacing -0.1px

button
  cursor pointer

html, body
  img:not([src])
    visibility hidden
  img
    border 0
  .don_frame
    z-index 3
  .don_frame[frame_index="0"]
    z-index 1
  .is_kr[lang="en"]
  .is_kr[lang="jp"]
    display none !important
  a
    &:visited
    &:hover
    &:active
    &:link
      color color_black
      text-decoration none

html, body
  &.fixed
    position fixed !important
    overflow-y scroll
    top 0
    left 0
    right 0
    bottom 0

  #not_found_results
    display none
    text-align center
    margin 216px auto
    font-size 16px
    &:before
      content ''
      width 80px
      height 80px
      display block
      margin 0 auto 24px
      background-image url(//s3.marpple.co/files/u_29089/2018/11/original/f_12439_1541417929892_ee1z2Lp6ee7s1SzW4E9i3G4D.png)
      background-size 80px 80px
  .countries
    .selected
      text-align left !important
      &:after
        margin ptr(5) 0 0 ptr(8)
        content ''
        display inline-block
        vertical-align top
        background ICON_DROP_DOWN
        background-repeat no-repeat
        background-size 100%
        width ptr(9)
        height ptr(9)
    ul
      list-style none
      padding 0
      margin 0
      display none
    span.flag
      display inline-block
      vertical-align top
      width ptr(18)
      height ptr(18)
      margin-right ptr(8)
      background-size ptr(18) ptr(18)

  .mobile_v
    display none !important
  #error_404
    position relative
    margin 0 auto
    padding 300px 0
    display table
    text-align center
    h1
      font-size 50px
    h3
      font-size 30px
    p
      text-align center
      a
        margin-top 20px
        display inline-block
        border 1px solid #d5dbe0
        padding 16px 24px
        font-size 20px

.navigation.home
  .sell_family
    display none !important

.live_chat
  cursor pointer

#ch-plugin-core
  > div[data-ch-testid]
    z-index 1
    transition transform 0.3s, opacity 0.3s, z-index 0s 0.5s
    transform translate3d(0, 0, 0)

html:not([f_index="0"]) #ch-plugin-core
#ch-plugin-core.hide
  > div[data-ch-testid]
    opacity 0
    transform scale(0.8)
    z-index -1 !important

html body.ch-plugin-mobile
  position: inherit !important;
  width: inherit !important;
  height: inherit !important;
  overflow: inherit !important;


.mp_currency_kr:after
  content '원'
.mp_currency_kr2:before
  content '￦'
.mp_currency_en:before
  content '$'
.mp_currency_jp:before
  content '¥'

html[lang="kr"]
  .mp_currency:after
    content '원'
  .mp_currency2:before
    content '￦'
  .mp_currency_minus:after
    content '원'
  .mp_currency_minus:before
    content '- '
  div[lang="en"]
  div[lang="null"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- $'
    .mp_currency:before
    .mp_currency2:before
      content '$'
  div[lang="jp"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- ¥'
    .mp_currency:before
    .mp_currency2:before
      content '¥'

html[lang="en"]
html[lang="null"]
  .mp_currency:before
  .mp_currency2:before
    content '$'
  .mp_currency_minus:before
    content '- $'
  .old_message
    display none
  div[lang="kr"]
    .mp_currency:before
    .mp_currency2:before
      content '￦'
    .mp_currency_minus:before
      content '- ￦'
  div[lang="jp"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- ¥'
    .mp_currency:before
    .mp_currency2:before
      content '¥'
html[lang="jp"]
  .mp_currency:before
  .mp_currency2:before
    content '¥'
  .mp_currency_minus:before
    content '- ¥'
  .old_message
    display none
  div[lang="kr"]
    .mp_currency:before
    .mp_currency2:before
      content '￦'
    .mp_currency_minus:before
      content '- ￦'
  div[lang="en"]
  div[lang="null"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- $'
    .mp_currency:before
    .mp_currency2:before
      content '$'


