.new_maker_guide_layer
  >.don_wrapper
    width 880px !important
    height 654px !important
    @media screen and (max-height 720px)
      width 722px !important
      height 554px !important
  .maker_guide_body
    position relative
    height 100%
    .img
      text-align center
      img
        max-width 100%
        vertical-align top
    .btns
      position absolute
      left 40px
      right 40px
      bottom 40px
      display flex
      margin 0 auto
      justify-content space-between
      button
        width 49.3%
        height 40px
        color #000
        font-size 16px
        font-weight 500
        vertical-align top
        background-color #fff
        border 1px solid #d5dbe0
        border-radius 100px
        cursor pointer
        &.btn_edit
          color #fff
          border-color #000
          background-color #000
        &.btn_confirm
          width 100%
          color #fff
          border-color #000
          background-color #000
