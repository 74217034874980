.omp-cell
  &__side-header
    display flex
    align-items center
    justify-content center
    gap ptr(20)
    height 100%
  &__side-header-lang-wrapper
    &[data-list_show="true"]
      .omp-cell__side-header-lang-list
        display flex
  &__side-header-lang-head
    cursor pointer
    display flex
    align-items center
    gap ptr(8)
  &__side-header-button-wrapper
    display flex
    align-items center
    gap ptr(16)
  &__side-header-search
  &__side-header-my-page
  &__side-header-wish
  &__side-header-cart
    width ptr(28)
    height ptr(28)
  &__side-header-lang-icon
    background ICON_DROP_DOWN
    background-repeat no-repeat
    background-size 100%
    width ptr(9)
    height ptr(9)
  &__side-header-lang
    position relative
    {font_16_m_100}
  &__side-header-lang-list
    position absolute
    top ptr(-4)
    left ptr(-16)
    margin 0
    padding ptr(6) 0
    list-style none
    background-color #fff
    border-radius ptr(16)
    border 1px solid GY_50
    background rgba(255, 255, 255, 0.80)
    backdrop-filter blur(20px)
    display none
    flex-direction column
    align-items center
    justify-content center
    {font_16_m_100}
  &__side-header-lang-li
    a
      display block
      padding ptr(8) ptr(16)
      {font_16_m_100}
    &[data-is_on="true"]
    &:hover
      a
        font-weight 700
