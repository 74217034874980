.omp-auth
  &__change-email
    margin-top ptr(40)
    width ptr(440)

    &__form
      display flex
      flex-direction column
      gap ptr(40)

    &__input-wrap
      display flex
      flex-direction column
      gap ptr(16)

    &__current-email-wrap
      display flex
      flex-direction column
      gap ptr(8)

    &__current-email-label
      {font_14_m_140}

    &__current-email
      {font_16_m_150}
      padding-bottom ptr(16)
      border-bottom 1px solid BK
      background-color WH

    &____new-email
      {font_16_m_150}
