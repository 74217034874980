.omp-list
  &__header
    margin 0 0 ptr(16)
    {font_26_m_100}
  &__list
    gridColumns(4)
    gap ptr(32px) ptr(8px)
    > *
      width 100% !important
  &__more
    margin-top ptr(16)
    padding-top ptr(44)
    text-align center
    button
      {font_20_m_140}
      background transparent
      padding 0
      margin 0
      cursor pointer
      border none
      outline none