.omp-atom
  &__form-input
    position relative
    .omp-atom__input
      padding-left ptr(16)
      padding-right ptr(16)

  &__form-search
    position relative
    .omp-atom__input
      padding-left ptr(42)
    &:before
      icon()
      position absolute
      top 50%
      left ptr(14)
      transform translateY(-50%)
      background-image ICON_SEARCH
      width ptr(20)
      height ptr(20)
