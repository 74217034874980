.don_frame[frame_name='/modules/OMP/Auth/ResetPassword/Verify/S/Mui/frame.js']
  .omp-reset-password-verify-wrapper
    display flex
    flex-direction column
    align-items center
    justify-content center
    min-height 50vh
    .omp-reset-password-verify
      display flex
      flex 1 1 0
      flex-direction column
      align-items center
      justify-content center
      width ptr(450)
      gap ptr(24)
      &__title
        align-self flex-start
        {font_26_m_130}
      &__privacy
        box-sizing border-box
        width 100%
        display flex
        flex-direction column
        gap ptr(16)
        &__data
          width 100%
          display flex
          gap ptr(10px)
          background-color BG_PRODUCT
          border-radius ptr(20)
          padding ptr(24)
          box-sizing border-box
          overflow hidden
        &__key
          width ptr(110px)
          {font_14_m_140}
          flex-shrink 0
        &__value
          min-width 0
          overflow hidden
          word-wrap break-word
          {font_14_r_140}
      &__password-data
        display flex
        flex-direction column
        gap ptr(16)
      &__password
        width 100%
        display flex
        flex-direction column
        gap ptr(30)
        &__title
          min-width ptr(89)
          {font_16_m_150}
          &.bold
            {font_16_b_100}
      &__buttons
        display flex
        width 100%
