#marpplizer
  .cv_image_editor .request_bg_removal.section
    display none



html.maker_page[lang="en"] &
html.maker_page[lang="jp"] &
  .request_bg_removal
    display none !important

html.all_faces_mode &
  .maker_menu:after
    z-index 2
    opacity 0.85
@media screen and (min-height: 800px)
  html.prev_product_matcher &
    .prev_product_matcher >.bottom
      .options
        margin 26px 0 !important
      >.header
        margin 24px auto !important


.mp_maker[is_shade="true"]
.mp_maker[data-is_dosu_color_app="true"]
  .cv_image_editor
    .image_color.section
      display none

#maker_frame .decoration_menu
  display none


.btm_menus
  display none


//.sold-out-select-box-view-v2
//  &.sold_out
//    display flex
//    > *
//      color #878b8f
//  &__no_stock
//    display none
//  &__name
//    overflow: hidden;
//    white-space: nowrap;
//    text-overflow: ellipsis;
//    word-break: break-all;
//    max-width 160px
//  &.sold_out
//    .sold-out-select-box-view-v2__no_stock
//      min-width 40px
//      margin-left 5px
//      display flex
//      justify-content center
//      align-items center
//      color #878b8f
