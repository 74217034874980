


#dream_factory
  .maker_worker_folder_frame
    .don_tab[tab_name="maker.worker_folder_tab"]
      .title
        position absolute
        top 20px
        left 0
        right 0
        text-align center
        font-size 16px
        font-weight normal
        color #212529
      .sticker_list
        padding-top 100px
  .maker_my_lib_frame
  .maker_sticker_frame
    position fixed
    background-color transparent !important
    > .don_wrapper
      padding-top 0
      position absolute
    .don_page > .don_wrapper > .header .don_backpage
      top 12px !important
      left 14px !important
    .don_page[page_name="maker_sticker_page"]
      position static !important
      > .don_wrapper
        > .body
          top 66px !important
      .don_tab[tab_name="maker_sticker_tab"]
        margin-top 40px
    .don_page[page_name="maker_sticker_page2"]
    .don_page[page_name="maker_embro_page2"]
      > .don_wrapper
        > .body
          top 50px !important
    .don_page.sticker_header_style1 > .don_wrapper > .header
      top -38px !important
    .don_page[page_name="maker_sticker_page3"]
      top 60px !important
      &.sticker_header_style1 > .don_wrapper > .header
        top -99px !important
    .don_page[page_name="maker_my_lib_page"]
      top 60px !important
      &.sticker_header_style1 > .don_wrapper > .header
        top -121px !important
      .folder_detail_area
        padding-bottom 50px !important
      .btn_area
        bottom 0 !important
    .sticker_image_list
      padding-top 50px


.automatic_swiper.color_picker
.color_picker.mkf
.color_picker.mkf2
  .color_item
    width 28px
    height 28px
    padding 1px
    box-sizing border-box
    border-radius 13px
    cursor pointer
    border 1px solid transparent
    &[need_ccc="true"]
    //&[color_code="#ffffff"]
    //&[color_code="#FFFFFF"]
    //&[color_code="#fefefe"]
    //&[color_code="#FFFDFA"]
    //&[color_code="white"]
      span
        border 1px solid #ced4da
    &.selected
      border-color OG
    span
      display block
      width 24px
      height 24px
      box-sizing border-box
      border-radius 50%
.color_picker.mkf
  display flex
  flex-wrap wrap



.range_sty1
  .control.range
    position relative
  .control.range
    .rangeslider--horizontal
      border-top 7px solid #fff
      border-bottom 7px solid #fff
  input[type="number"]
    box-sizing border-box
    width 80px
    height 32px
    border-radius 2px
    border solid 1px #dee2e6
    font-size 14px
    color color_black
    &:focus
      outline none
    &:disabled
      background-color #fff !important
      opacity 0.9 !important



html.maker_page.projection:not(.can_product_change) &
  #marpplizer
    .product_editor
      .one_size
      .color
        display none
  .decoration_menu_for_pc
    .open_chooser
      display none
html.all_faces_mode &
  #marpplizer:after
    z-index 2
    opacity 0.85
#tooltip_canvas
.msg.pinch
.fake_mp_maker
.footer_buttons
.more_btn
.pinch_btn
.super_menu
  display none
#maker_frame
  width 100% !important
#maker
  .btn_tip
    cursor pointer
    &.how_to_custom
      top 92px
      .icon
        top 50%
        transform translateY(-50%)
        left 32px
        img
          width 26px
    &.enter_marpple_shop
      top 150px
      .icon
        width 25px
        height 25px
        left 34px
        top 50%
        transform translateY(-50%)
        img
          width 100%
          height auto
    position absolute
    left 0
    width 200px
    height 46px
    overflow hidden
    padding-left 74px
    border-radius 100px
    background-color WHT
    box-sizing border-box
    transition width 0.4s
    &[is_empty="true"]
      display none !important
    .icon
      position absolute
      cursor pointer
    .txt1
      position absolute
      top 50%
      transform translateY(-50%)
      left 74px
      color color_black
      font-size 14px
      font-weight bold
      opacity 1
      transition-property opacity
      transition-duration 0.2s
      transition-delay 0.2s
    .btn_close
      display none
    .is_open
      opacity 0
    &.tip_open
      width 300px
      transition width 0.3s
      .txt1
        opacity 0
        transition opacity 0s
      .is_open
        left 74px
        position absolute
        opacity 1
        transition-property opacity
        transition-duration 0.2s
        transition-delay 0.2s
        top 50%
        transform translateY(-50%)
      .txt2
        width 250px
        color color_black
        font-size 14px
        font-weight bold
        cursor pointer
      .txt3
        width 250px
        color color_black
        font-size 12px
        cursor pointer
      .btn_close
        position absolute
        top 10px
        right 30px
        opacity 1
        transition-property opacity
        transition-duration 0.6s
        transition-delay 0.4s
        cursor pointer
        img
          width 8px
.mp_maker
  overflow hidden
  position relative
html.prev_product_matcher &
  .prev_product_matcher
    height 332px
    display block !important
    .new_bp_name
      position absolute
      top 2px
    .options
      margin 10px 0
      justify-content center
      button
        width 260px
        height 50px
    .design
      width 104px
      height 104px
      box-sizing border-box
    .bottom
      position absolute
      .header
        margin 12px auto
  .select_face
    display none !important
html.all_faces_mode &
  .decoration_menu_for_pc
  #marpple_layer
    display none




.mp_maker_colors_wrapper_style
  .colors
    .row
      margin-bottom 12px
      &:last-child
        margin-bottom 0
    .color
      width 28px
      height 28px
      border-radius 28px
      cursor pointer
      span
        width 24px
        height 24px
        border-radius 24px
        background-size 24px 24px
  .colors
    .color
      &[data-color_code="#ffffff"]
        span
          border 1px solid #ced4da
      border 1px solid transparent
      display flex
      justify-content center
      align-items center
      &.active
        border 1px solid OG
    .row
      display flex
      justify-content space-between
#maker_frame.mp_maker
  .open_cv_background_marpplizer
    display none
  &[data-has_background="true"]
    .open_cv_background_marpplizer
      display flex
.don_frame[frame_name="/modules/NewMaker/CvObject/CvBackground/S/Mui/frame.js"]
  .main_wrapper
    padding 24px
