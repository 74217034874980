html[lang='en']
  .omp-my-info
    .omp-my-info__withdrawal-btn
      width ptr(250)

.omp-my-info
  margin-top ptr(24)

  &__form
    display flex
    flex-direction column
    gap ptr(24)

  &__body
    display flex
    gap ptr(16)

  &__table
    width 100%
    border-spacing 0

  &__table-header
    text-align start
    {font_16_b_100}
    th
      text-align start
      border-bottom 1px solid BK
      padding ptr(16) 0 ptr(24) 0

  &__table-row
    height ptr(72)
    padding 0
    {font_16_r_150}
    td
      border-bottom 1px solid GY_50
    &--empty
      opacity 0

  &__table-row__label
    {font_16_m_150}
    width ptr(200)

  &__text-button
    resetButton()
    {font_16_m_150}
    color OG
    cursor pointer

  input[type="text"]
  input[type="number"]
  input[type="date"]
    width 100%
    {font_16_r_150}
    border none
    outline none
    color BK
    &::placeholder
      color GY
  input[type="date"]
    width fit-content
    cursor pointer

  // 우측 화살표 제거
  input[type="number"]
    &::-webkit-outer-spin-button
    &::-webkit-inner-spin-button
      -webkit-appearance none
      margin 0

  &__email
    margin-right ptr(16)

  &__btn-wrap
    display flex
    gap ptr(8)

  &__btn
    width ptr(180)
    cursor pointer

  &__radio-wrap
    display flex
    gap ptr(24)
