
@import "./top_banner.styl"
@import "./header.styl"
@import "./left.styl"
@import "./footer.styl"
@import "./body.styl"

//body.omp
//  #ch-plugin
//    display none !important
//    opacity 0 !important

body.omp > #body > .don_frame[frame_index="0"] > .don_wrapper
  display flex
  flex-direction column
  max-width ptr(1920)
  min-width ptr(800)
  margin 0 auto

body.body-fixed
  position fixed !important
  top 0
  left 0
  right 0
  bottom 0

body.omp .don_frame:not([is_modal="true"]) > .don_wrapper > .body
  order 2
  box-sizing border-box

body .don_frame[frame_index="0"] > .don_wrapper > .header
  order 1
.omp-image-lazy
  opacity 0
  transition opacity 300ms ease-in-out
  &.b-loaded
    opacity 1


.omp-header
  &[data-header_banner="header_banner"]
    .omp-header__navi
      top ptr(150+70)
    .omp-header__navi-bg
      top ptr(150+70)

.omp-layout-body
  &[data-header_banner="header_banner"][data-left_mode="left"]
    padding-top ptr(80+150)
    .omp-layout-body__left-wrap
      top ptr(80+150)

.omp-layout-body
  min-height 100vh
  padding-top ptr(160)
  box-sizing border-box
  display flex
  flex-direction column
  justify-content space-between
  &[data-left_mode="left_hide"]
    .omp-layout-body__cont
      margin 0 auto
      padding 0
      display flex
      flex-shrink 0
      justify-content center
  &[data-left_mode="left_delete"]
    .omp-layout-body__wrap
      position relative
      z-index 1
    .omp-layout-body__cont
      margin 0
      padding-right 0
    .omp-layout-body__footer
      margin-top 0
  &[data-left_mode="left_fixed"]
    .omp-layout-body__wrap
      position relative
      z-index 1
    .omp-layout__sidebar
      position relative
      z-index 1
    .omp-layout-body__cont
      margin 0
      padding-right 0
    .omp-layout-body__footer
      margin-top 0
      z-index 1
  &[data-left_mode="left"]
    .omp-layout-body__cont
      max-width calc(100% - var(--left-width) - 1.25rem)
      box-sizing border-box
    .omp-layout-body__left-wrap
      position sticky
      top ptr(160)
      padding-top ptr(4)
      padding-bottom ptr(40)
      html.marpple.omp.product-list &
        top auto
        padding-bottom ptr(140)
  &__wrap
    display flex
    align-items stretch
    justify-content flex-start
  &__left
    margin-left ptr(40px)
    width ptr(200px)
    flex-basis ptr(200px)
    html[lang="en"] &
      width ptr(220px)
      flex-basis ptr(220px)
    will-change min-height
    flex-shrink 0
  &__left-wrap
    transform translate(0, 0) /* For browsers don't support translate3d. */
    transform translate3d(0, 0, 0)
    will-change position, transform
  &__cont
    margin 0 0 ptr(40px) ptr(20px)
    padding-right ptr(40px)
    flex 1

#mp_layer_pop
#mp_layer_pop2
  box-shadow 2px 2px 20px #888
  position absolute
  top 180px
  right 8.714285714285714rem
  z-index 15
  background-color #000
  &.center
    right 50%
    transform translate(50%, 0)
  .banner
    text-align center
    a
      display block
      width 100%
    a.mp_layer_pop__talk
      display block
      position absolute
      top 445px
      left 255px
      width 160px
      height 23px
    img
      vertical-align top
    .mobile
      display none
  .one_day_close
    display inline-block
    padding 7px 10px 5px
    color #fff
    cursor pointer
    font-size 12px
  .close
    position absolute
    bottom 2px
    right 7px
    cursor pointer
    img
      height 14px

#twc-chat-icon
  position fixed
  right ptr(28)
  bottom ptr(130)
  z-index 19
#twc-chat-icon-btn
  cursor pointer
  background-color transparent
  padding 0
  border 0 none
  img
    width ptr(60)
    border-radius ptr(22)
    box-shadow 1px 5px 5px rgba(0,0,0,0.3)

:root {
  --left-width ptr(240)
}
