
@media screen and (min-width 1025px)
  html.login_page
    #footer
      display none
    .mp_login_frame
      .don_wrapper
        margin 0 auto
        > .header
          overflow visible !important
          height auto !important
          .don_hide_frame
            display none
      .don_page[page_name="mp_login_page"]
        min-height auto !important
        .don_wrapper .header
          height auto !important

  html:not(.maker_page)
    .mp_login_frame
      > .don_wrapper
        width 487px
        height 643px
        .don_page
          > .don_wrapper > .header
            margin-top 50px
  .mp_login_frame
    z-index 100 !important
    > .don_wrapper > .header
      height 48px
      .title
        display none
    .don_page
      > .don_wrapper > .header
        margin 136px auto 40px
        width 487px
        height 64px
        text-align center
        border-bottom 1px solid #e9ecef
        z-index 1
      .tab_buttons
        height 100%
        button.tab_button
          position relative
          z-index 1
          display inline-block
          margin 0
          padding 0
          min-width 164px
          height 65px
          box-sizing border-box
          overflow hidden
          border 0 none
          border-bottom 1px solid transparent
          background-color transparent
          outline none
          border-radius 0
          font-size 32px
          font-weight normal
          text-align center
          color color_gray3
          html[lang="en"] &
            font-family CircularStd
          &:active
            color color_black
          &[selected="true"]
            color color_black
            border-bottom-color #000
      .don_tab
        background-color #fff
    .don_page[page_name="mp_login_page"] .don_tab[tab_name="mp_login_sign_up_tab"]
    .don_page[page_name="mp_login_page"] .don_tab[tab_name="mp_login_tab"]
      > .don_wrapper > .header
        display none
      > .don_wrapper > .wrap
        position absolute
        top 0
        left 0
        right 0
        width 328px
        margin 0 auto
      p.txt1
        margin 0
        padding 4% 0 5%
        font-size 24px
        font-weight bold
        color #212529
      .login_input
        .control
          margin 0
          margin-bottom 8px
          input[type="email"]
          input[type="text"]
          input[type="password"]
            color #333
            font-size 14px
            padding-left 36px
            background-size 12px 12px
            background-repeat no-repeat
            background-position 16px 50%
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="name"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6004_1539247856227_uGXEQ7fTRT0q2lf2vrf.png)
            &[name="email"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6003_1539247856227_I2T3xMm1t5wHzmr3sQSq.png)
            &[name="password"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6002_1539247856227_PwUfLn9zS2C9Veyu1RC.png)
      .gender
        margin 20px 0 0
        label
          position relative
          margin-right 24px
          color color_black
          > *
            vertical-align middle
          span.radio_box
            display inline-block
            width 16px
            height 16px
            margin-right 8px
            background url(//s3.marpple.co/files/u_18053/2018/10/original/f_6008_1539249097835_AtG6XVyrVUB8ILV6xJ.png) no-repeat
            background-size cover
          input[type="radio"]
            position absolute
            top 0
            left 0
            visibility hidden
          &.on
            span.radio_box
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6007_1539249097835_J9HXd3f3Hd4pXt1X9XJBR.png)
      .agree_email
        margin 20px 0
        label
          display block
          position relative
          padding-left 24px
          padding-bottom 6px
          color color_black
          line-height 19px
          word-spacing 1.1px
          .check_box
            display block
            position absolute
            top 2px
            left 0
            width 16px
            height 16px
            background url(//s3.marpple.co/files/u_14355/2018/10/original/f_12369_1540968528808_wa9m8Im4uHIc9u0ORxMn.png) no-repeat
            background-size cover
          input[type="checkbox"]
            position absolute
            top 0
            left 0
            visibility hidden
          a
            color color_black
            text-decoration underline
          &.on
            .check_box
              background-image url(//s3.marpple.co/files/u_14355/2018/10/original/f_12370_1540968742928_EEZcT5AfHu3P9SBPR2w.png)
      button[type="button"]
        width 100%
        height 48px
        color #fff
        background-color #000
        border 0 none
        box-sizing border-box
        font-size 14px
        letter-spacing 1px
        text-align center
        font-weight bold
      .submit
        margin 24px 0 0
      .fb_submit
        margin 16px 0 0
        button[type="button"]
          color #4167b2
          background-color #f8f9fa
          &:before
            content ''
            background-image url("//s3.marpple.co/files/u_29089/2019/1/original/f_23324_1548301193608_UQk6c1C0lREqzRyt5FJ.svg")
            width 8px
            height 12px
            display inline-block
            vertical-align top
            margin 4px 8px 0 0
      .or
        margin 16px 0
        text-align center
        font-size 12px
        line-height 1.25
        color color_gray3
        position relative
        html[lang="en"] &
          font-family CircularStd
        &:before
          content ''
          width 24px
          height 1px
          background-color #e9ecef
          display inline-block
          vertical-align top
          margin 7px 8px 0 0
        &:after
          content ''
          width 24px
          height 1px
          background-color #e9ecef
          display inline-block
          vertical-align top
          margin 7px 0 0 8px
      .forgot_password
      p.txt3
        margin 20px 0 0
        padding 0 1% 10% 1%
        font-size 12px
        text-align center
        color color_black
        a
        span
          cursor pointer
          color color_black
          text-decoration underline
      .forgot
        margin 32px 0 0
        display flex
        align-items center
        justify-content center
        font-size 12px
        padding 0 1% 40px 1%
        color #dddddd
        gap 8px
        span
          font-size 14px
          font-weight 600
          line-height 1.71
          letter-spacing -0.2px
          text-align center
          color #111
          cursor pointer
  .don_page[page_name="mp_login_page"] .don_tab[tab_name="mp_login_tab"]
    padding-top 40px
@media screen and (max-width 1024px)
  html.login_mode
    #header
      display none
  html.login_page
    #footer
      margin-top 70px
      display none
    #header
      display block
    .mp_login_frame
      padding-top 112px !important
      > .don_wrapper > .header .don_hide_frame
        display none
      .don_page
        min-height auto !important
  .mp_login_frame
    background-color #fff
    > .don_wrapper > .header
      height 48px
      .title
        display none
    .don_page
      > .don_wrapper > .header
        height 48px
        width 100%
        text-align center
        border-bottom 1px solid #e9ecef
        z-index 1
      .tab_buttons
        height 100%
        padding 0 6%
        button.tab_button
          position relative
          z-index 1
          display inline-block
          margin 0
          padding 0
          width 50%
          height 48px
          line-height 48px
          box-sizing border-box
          overflow hidden
          border 0 none
          border-bottom 1px solid transparent
          background-color transparent
          outline none
          font-size 20px
          color color_gray3
          html[lang="en"] &
            font-family CircularStd
          &:active
            color color_black
          &[selected="true"]
            color color_black
            border-bottom-color #000
      .don_tab
        padding-top 25px
        background-color #fff
    .don_page[page_name="mp_login_page"] .don_tab[tab_name="mp_login_sign_up_tab"]
    .don_page[page_name="mp_login_page"] .don_tab[tab_name="mp_login_tab"]
      > .don_wrapper > .header
        display none
      p.txt1
        margin 0
        padding 4% 0 5% 4%
        font-size 24px
        font-weight bold
        color #212529
      .login_input
        .control
          margin 0 6%
          margin-bottom 2%
          input[type="email"]
          input[type="text"]
          input[type="password"]
            color #333
            font-size 14px
            padding-left 36px
            background-size 12px 12px
            background-repeat no-repeat
            background-position 16px 50%
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="name"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6004_1539247856227_uGXEQ7fTRT0q2lf2vrf.png)
            &[name="email"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6003_1539247856227_I2T3xMm1t5wHzmr3sQSq.png)
            &[name="password"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6002_1539247856227_PwUfLn9zS2C9Veyu1RC.png)
      .gender
        margin 20px 6% 0
        label
          position relative
          margin-right 24px
          > *
            vertical-align middle
          span.radio_box
            display inline-block
            width 16px
            height 16px
            margin-right 8px
            background url(//s3.marpple.co/files/u_18053/2018/10/original/f_6008_1539249097835_AtG6XVyrVUB8ILV6xJ.png) no-repeat
            background-size cover
          input[type="radio"]
            position absolute
            top 0
            left 0
            visibility hidden
          &.on
            span.radio_box
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6007_1539249097835_J9HXd3f3Hd4pXt1X9XJBR.png)
      .agree_email
        margin 20px 6%
        label
          display block
          position relative
          padding-left 24px
          padding-bottom 6px
          color color_black
          .check_box
            display block
            position absolute
            top 0
            left 0
            width 16px
            height 16px
            background url(//s3.marpple.co/files/u_14355/2018/10/original/f_12369_1540968528808_wa9m8Im4uHIc9u0ORxMn.png) no-repeat
            background-size cover
          input[type="checkbox"]
            position absolute
            top 0
            left 0
            visibility hidden
          a
            text-decoration underline
          &.on
            .check_box
              background-image url(//s3.marpple.co/files/u_14355/2018/10/original/f_12370_1540968742928_EEZcT5AfHu3P9SBPR2w.png)

      button[type="button"]
        width 100%
        height 48px
        color #fff
        background-color #000
        border 0 none
        box-sizing border-box
        font-size 14px
        letter-spacing 1px
        text-align center
      .submit
        margin 5% 6% 5%
      .fb_submit
        margin 5% 6% 5%
        button[type="button"]
          color #4167b2
          background-color #f8f9fa
          &:before
            content ''
            background-image url("//s3.marpple.co/files/u_29089/2019/1/original/f_23324_1548301193608_UQk6c1C0lREqzRyt5FJ.svg")
            width 8px
            height 12px
            display inline-block
            vertical-align top
            margin 4px 8px 0 0
      .or
        margin 16px 0
        text-align center
        font-size 12px
        line-height 1.25
        color color_gray3
        position relative
        html[lang="en"] &
          font-family CircularStd
        &:before
          content ''
          width 24px
          height 1px
          background-color #e9ecef
          display inline-block
          vertical-align top
          margin 7px 8px 0 0
        &:after
          content ''
          width 24px
          height 1px
          background-color #e9ecef
          display inline-block
          vertical-align top
          margin 7px 0 0 8px
      .forgot_password
      p.txt3
        margin 0
        padding 0 4% 10% 4%
        font-size 12px
        text-align center
        color color_black
        span
        a
          cursor pointer
          color color_black
          text-decoration underline
      .forgot
        margin 32px 0 0
        display flex
        align-items center
        justify-content center
        font-size 12px
        padding 0 1% 40px 1%
        color #dddddd
        gap 8px
        span
          font-size 14px
          font-weight 600
          line-height 1.71
          letter-spacing -0.2px
          text-align center
          color #111
          cursor pointer
.mp_email_certification
  .certification
    width 400px
    margin 200px auto 0
    padding 20px
    background-color #fff
    border-radius 10px



@media screen and (min-width 1025px)
  html:not([lang="kr"])
    .mp_id_pw_find_frame
      > .don_wrapper
        width 407px !important
        height 336px !important
        .don_page[page_name="mp.id_pw_find_page"] .don_tab
          .id_pw_find_area .reset_pw_area
            h2
              margin 60px 0 0
              font-size 20px
            p.txt1
              padding 24px 0 24px
              font-size 14px
          .id_pw_find_area .id_pw_area
            display none
          .controls
            .control
              margin 0 0 24px
              input[type="email"]
                height 40px

  .mp_id_pw_find_frame
    z-index 101 !important
    > .don_wrapper
      width 407px !important
      height 480px !important
    > .don_wrapper > .header
      height 48px
      .title
        display none
      .don_hide_frame__
        position absolute
        top 5px
        right 0
        width 40px !important
        height 40px !important
        z-index 11
        background-position 50% 50% !important
        background-size 12px auto
    .don_page
      > .don_wrapper > .header
      .tab_buttons
        display none
      .don_tab
        background-color #fff
    .don_page[page_name="mp.id_pw_find_page"] .don_tab
      > .don_wrapper > .header
        display none
      > .don_wrapper .reset_pw_area
        width 327px
        margin 0 auto
        h2
          margin 50px 0 0
          padding 0
          font-size 16px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
          &[lang="kr"]
            display none
      > .don_wrapper .id_pw_area
        width 327px
        margin 34px auto 0
        border-top 1px solid #ccc
        h2
          margin 0
          padding 30px 0 0
          font-size 16px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        margin-top 20px
        .control
          margin 0 0 14px
          input[type="text"]
          input[type="email"]
            font-size 14px
            width 100%
            padding 0 5px 0 16px
            margin 0
            color #333
            height 36px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="email"]
              padding-left 36px
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6003_1539247856227_I2T3xMm1t5wHzmr3sQSq.png)
              background-size 12px 12px
              background-repeat no-repeat
              background-position 16px 52%
      .submit
        button[type="button"]
          width 100%
          height 44px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 13px
          letter-spacing 1px
          text-align center
    .don_page[page_name="mp.phone_cert_page"]
      > .don_wrapper > .header
        display block
      .don_tab
        > .don_wrapper .phone_cert_area
          width 327px
          margin 0 auto
          h2
            margin 0
            padding 80px 0 0
            font-size 20px
            font-weight normal
            color color_black
          p.txt1
            margin 0
            padding 24px 0 24px
            font-size 16px
            line-height 1.71
            color color_black
            &[lang="kr"]
              display none
        .controls
          margin-top 10px
          .control
            margin 0 0 14px
            input[type="text"]
              width 100%
              padding 0 5px 0 16px
              margin 0
              color #333
              height 54px
              font-size 14px
              padding-top 2px
              box-sizing border-box
              border solid 1px #d5dbe0
              background-color #fff
              &::-webkit-input-placeholder
              &:-ms-input-placeholder
              &::placeholder
                color color_gray3
        .submit
          button[type="button"]
            margin-bottom 20px
            width 100%
            height 44px
            color #fff
            background-color #000
            border 0 none
            box-sizing border-box
            font-size 13px
            letter-spacing 1px
            text-align center
            &.btn_resend
              margin-top 40px
              color color_black
              height 36px
              font-size 12px
              background-color #ccc
    .don_page[page_name="mp.phone_cert_complete_page"]
      > .don_wrapper > .header
        display block
      .phone_cert_complete_area
        width 327px
        margin 0 auto
        h2
          margin 0
          padding 80px 0 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 15px
          line-height 1.71
          color color_black
          &[lang="kr"]
            display none
        .users
          margin-bottom 30px
          ul
            margin 0
            padding 0
            list-style none
            li
              margin-bottom 10px
              input[type="radio"]
                margin-right 10px
        .buttons
          button[type="button"]
            margin-bottom 20px
            width 100%
            height 44px
            color #fff
            background-color #000
            border 0 none
            box-sizing border-box
            font-size 13px
            letter-spacing 1px
            text-align center
            &.btn_resend
              margin-top 40px
              color color_black
              height 36px
              font-size 12px
              background-color #ccc
    .don_page[page_name="mp.cert_reset_password_page"]
      > .don_wrapper > .header
        display block
      .phone_cert_complete_area
        width 327px
        margin 0 auto
        h2
          margin 0
          padding 80px 0 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 16px
          line-height 1.71
          color color_black
          &[lang="kr"]
            display none
        .controls
          margin-top 30px
          .control.email
            label
              font-size 16px
          .control
            margin 0 0 14px
            input[type="password"]
              width 100%
              padding 0 5px 0 16px
              margin 0
              color #333
              height 36px
              font-size 14px
              padding-top 2px
              box-sizing border-box
              border solid 1px #d5dbe0
              background-color #fff
              &::-webkit-input-placeholder
              &:-ms-input-placeholder
              &::placeholder
                color color_gray3
        .buttons
          button[type="button"]
            margin-bottom 20px
            width 100%
            height 44px
            color #fff
            background-color #000
            border 0 none
            box-sizing border-box
            font-size 13px
            letter-spacing 1px
            text-align center

  .reset_password_frame
    z-index 101 !important
    > .don_wrapper
      width 407px !important
      height 336px !important
    > .don_wrapper > .header
      height 48px
      .title
        display none
      .don_hide_frame__
        position absolute
        top 5px
        right 0
        width 40px !important
        height 40px !important
        z-index 11
        background-position 50% 50% !important
        background-size 12px auto
    .don_page
      > .don_wrapper > .header
      .tab_buttons
        display none
      .don_tab
        background-color #fff
    .don_page[page_name="mp.reset_password_page"] .don_tab
      > .don_wrapper > .header
        display none
      > .don_wrapper > .reset_pw_area
        position absolute
        top 0
        left 0
        right 0
        width 327px
        margin 0 auto
        h2
          margin 60px 0 0
          padding 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        .control
          margin 0 0 24px
          input[type="email"]
          input[type="text"]
          input[type="password"]
            color #333
            font-size 14px
            padding-left 36px
            background-size 12px 12px
            background-repeat no-repeat
            background-position 16px 52%
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="email"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6003_1539247856227_I2T3xMm1t5wHzmr3sQSq.png)
      .submit
        button[type="button"]
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          letter-spacing 1px
          text-align center


@media screen and (max-width 1024px)
  html:not([lang="kr"])
    .mp_id_pw_find_frame
      .id_pw_area
        display none
  .mp_id_pw_find_frame
    background-color #fff
    > .don_wrapper > .header
      height 48px
      .title
        display none
    .don_page
      > .don_wrapper > .header
        display none
      .don_tab
        padding-top 25px
        background-color #fff
    .don_page[page_name="mp.id_pw_find_page"] .don_tab
      > .don_wrapper > .header
        display none
      .id_pw_area
        margin-top 50px
        h2
          margin-bottom 25px !important
      .id_pw_area
      .reset_pw_area
        padding 0 6%
        h2
          margin 0
          padding 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        .control
          margin 0 0 24px
          input[type="email"]
            padding-left 36px
          input[type="email"]
          input[type="text"]
          input[type="password"]
            color #333
            font-size 14px
            background-size 12px 12px
            background-repeat no-repeat
            background-position 16px 52%
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="email"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6003_1539247856227_I2T3xMm1t5wHzmr3sQSq.png)
      .submit
        button[type="button"]
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          letter-spacing 1px
          text-align center
    .don_page[page_name="mp.phone_cert_page"] .don_tab
      > .don_wrapper > .header
        display none
      .phone_cert_area
        padding 0 6%
        h2
          margin 0
          padding 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        .control
          margin 0 0 24px
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
      .submit
        button[type="button"]
          margin-bottom 20px
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          letter-spacing 1px
          text-align center
    .don_page[page_name="mp.phone_cert_complete_page"] .don_tab
      > .don_wrapper > .header
        display none
      .phone_cert_complete_area
        padding 0 6%
        h2
          margin 0
          padding 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
      .users
        margin-bottom 30px
        ul
          margin 0
          padding 0
          list-style none
          li
            margin-bottom 10px
            input[type="radio"]
              margin-right 10px
      .buttons
        button[type="button"]
          margin-bottom 20px
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          letter-spacing 1px
          text-align center
    .don_page[page_name="mp.cert_reset_password_page"] .don_tab
      > .don_wrapper > .header
        display none
      .phone_cert_complete_area
        padding 0 6%
        h2
          margin 0
          padding 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        .control.email
          margin-top 24px
        .control
          margin 0 0 24px
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
      .buttons
        button[type="button"]
          margin-bottom 20px
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          letter-spacing 1px
          text-align center



  .reset_password_frame
    background-color #fff
    > .don_wrapper > .header
      height 48px
      .title
        display none
      .don_hide_frame__
        position absolute
        top 5px
        right 0
        width 40px !important
        height 40px !important
        z-index 11
        background-position 50% 50% !important
        background-size 12px auto
    .don_page
      > .don_wrapper > .header
        display none
      .don_tab
        padding-top 25px
        background-color #fff
    .don_page[page_name="mp.reset_password_page"] .don_tab
      > .don_wrapper > .header
        display none
      .reset_pw_area
        padding 0 6%
        h2
          margin 0
          padding 0
          font-size 20px
          font-weight normal
          color color_black
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        .control
          margin 0 0 24px
          input[type="email"]
          input[type="text"]
          input[type="password"]
            color #333
            font-size 14px
            padding-left 36px
            background-size 12px 12px
            background-repeat no-repeat
            background-position 16px 52%
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="email"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6003_1539247856227_I2T3xMm1t5wHzmr3sQSq.png)
      .submit
        button[type="button"]
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          letter-spacing 1px
          text-align center



@media screen and (min-width 1025px)
  .change_password
    z-index 101 !important
    > .don_wrapper > .header
      height 48px
      .title
      .don_hide_frame
        display none
    .don_page
      > .don_wrapper > .header
      .tab_buttons
        display none
      .don_tab
        background-color #fff
    .don_page .don_tab
      > .don_wrapper > .header
        display none
      > .don_wrapper > .verify_change_pw
        width 327px
        min-height 500px
        margin 0 auto 140px
        h2
          margin 0
          padding 244px 0 0
          font-size 32px
          color color_black
          text-align center
          background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12428_1541140904027_UJ5bp6OsZC4TA1l8sfdz.png) no-repeat
          background-size 80px auto
          background-position 50% 140px
          html[lang="en"] &
            font-family CircularStd
        h3
          margin 0
          padding 30px 0 0
          font-size 14px
          line-height 1.71
          color color_black
        p.txt2
          margin 13px 0 0
          padding 0
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        margin-top 20px
        .control
          margin 0
          input[type="email"]
          input[type="text"]
          input[type="password"]
            color #333
            font-size 14px
            padding-left 36px
            background-size 12px 12px
            background-repeat no-repeat
            background-position 16px 52%
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="password"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6002_1539247856227_PwUfLn9zS2C9Veyu1RC.png)
      .submit
        margin-top 40px
        button[type="button"]
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          text-align center
          font-weight 300

@media screen and (max-width 1024px)
  .change_password
    background-color #fff
    > .don_wrapper > .header
      height 48px
      .title
      .don_hide_frame
        display none
    .don_page
      > .don_wrapper > .header
        display none
      .don_tab
        padding-top 25px
        background-color #fff
    .don_page .don_tab
      > .don_wrapper > .header
        display none
      .verify_change_pw
        padding 0 6% 150px
        h2
          margin 0
          padding 156px 0 0
          font-size 20px
          color color_black
          text-align center
          background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12428_1541140904027_UJ5bp6OsZC4TA1l8sfdz.png) no-repeat
          background-size 60px auto
          background-position 50% 80px
          html[lang="en"] &
            font-family CircularStd
        h3
          margin 0
          padding 30px 0 0
          font-size 14px
          line-height 1.71
          color color_black
        p.txt2
          margin 13px 0 0
          padding 0
          font-size 14px
          line-height 1.71
          color color_black
      .controls
        margin-top 24px
        .control
          margin 0 0 24px
          input[type="email"]
          input[type="text"]
          input[type="password"]
            color #333
            font-size 14px
            padding-left 36px
            background-size 12px 12px
            background-repeat no-repeat
            background-position 16px 52%
          input
            width 100%
            padding 0 16px
            margin 0
            color #333
            height 40px
            padding-top 2px
            box-sizing border-box
            border solid 1px #d5dbe0
            background-color #fff
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color color_gray3
            &[name="password"]
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6002_1539247856227_PwUfLn9zS2C9Veyu1RC.png)
      .submit
        button[type="button"]
          width 100%
          height 48px
          color #fff
          background-color #000
          border 0 none
          box-sizing border-box
          font-size 14px
          font-weight 200
          letter-spacing 1px
          text-align center


@media screen and (min-width 1025px)
  html[lang="kr"]
    .mp_email_verify_frame
      > .don_wrapper
        width 475px !important
        height 330px !important
  .mp_email_verify_frame
    z-index 110 !important
    > .don_wrapper
      width 475px !important
      height 350px !important
      > .header
        position absolute
        top 0
        left 0
        right 0
        .title
          padding 25px 0 0
          font-size 26px
          font-weight bold
          text-align center
      > .body
        margin-top 100px
        .email_verify_cont
          margin 0 20px
          padding 0 50px
          text-align center
          .email
            margin-bottom 10px
          p.txt1
            margin 0 0 10px
            padding 0
            line-height 1.83
          .p.txt2
            margin 0
            padding 18px 0 0
            border-top 1px solid #ccc
          .btn_resend_email_verify
            margin-top 40px
            button
              text-align center
              width 200px
              height 40px
              background-color #000
              font-size 14px
              color #fff
              font-weight 200
              cursor pointer
              

@media screen and (max-width 1024px)
  html[lang="kr"]
    .mp_email_verify_frame
      > .don_wrapper
        min-width auto
  .mp_email_verify_frame
    > .don_wrapper
      > .header
        .title
          padding 25px 0 0
          font-size 26px
          font-weight bold
          text-align center
      > .body
        margin-top 30px
        .email_verify_cont
          margin 0 20px
          padding 0
          text-align center
          .email
            margin-bottom 10px
          p.txt1
            margin 0 0 10px
            padding 0
            line-height 1.83
          .p.txt2
            margin 0
            padding 18px 0 0
            border-top 1px solid #ccc
          .btn_resend_email_verify
            margin-top 40px
            button
              text-align center
              width 200px
              height 40px
              background-color #000
              font-size 14px
              color #fff
              border solid 1px #000
              font-weight 200
              cursor pointer
