.don_frame[frame_name="mp.product.detail"]
  .footer_buttons
    font-weight 300
  //.fake_mp_maker
  //  padding-top 30px
//bottom -200px !important

body.omp.product.detail
  .omp-header
    position relative
    background-color transparent

  .don_frame:not([is_modal="true"]) > .don_wrapper > .body
    padding-top 0

@import "custom_detail_style_a.styl"
body.maker_canvas_editor_v1
  > #body >.don_frame[frame_index="0"] > .don_wrapper
    max-width none
    margin 0
    > .header
      background-color BG_PRODUCT
  .mp_product_detail
    background-color BG_PRODUCT
    >.body
      .mp_maker_wrapper
        max-width 1920px
        min-width 800px
        margin 0 auto
        padding 0 40px
        @media screen and (max-width 1352px)
          padding 0 20px
  @import "product.detail.styl"
  @import "product.detail.pop.styl"
  @import "product_detail_tabs.styl"
body.maker_vector_editor_v1
  > #body >.don_frame[frame_index="0"] > .don_wrapper
    max-width none
    margin 0
    > .header
      position relative
      z-index 3
      background-color background-color rgb(248, 249, 250) !important
  .mp_product_detail
    background-color background-color rgb(248, 249, 250) !important
    >.body
      .mp_maker_wrapper
        max-width 1920px
        min-width 800px
        margin 0 auto
        padding 0 40px
        @media screen and (max-width 1352px)
          padding 0 20px


html.marpple.omp.product.detail
  .omp-header
    opacity 1
    z-index auto
  .omp-layout-body
    padding-top 0

.file-expired-alert-content
  font-weight 500
  line-height ptr(24)
.file-expired-alert-content
  margin-top ptr(20)
  padding ptr(16) ptr(24)
  display flex
  flex-direction column
  background-color BG_PRODUCT
  padding ptr(16) ptr(24)
  border-radius ptr(8)
  gap ptr(16)
  > .title
    {font_16_m_150}
  .file-expired-alert-options
    display flex
    flex-direction column
    gap ptr(12)
    .option
      display flex
      justify-content space-between
      align-items center
      .option_group_name
        color GY


