.don_frame[frame_name="mp.make_product_color_price"]
  >.don_wrapper
    width 1024px
  .don_tab>.don_wrapper
    position absolute
    top 0
    right 0
    bottom 0
    left 0
  .product_preview
    position relative
    background-color #f8f9fa
    width 624px
    height 100%
    >.product_face_container
      position relative
      width 100%
      height 100%
    .product_face_list.top
      z-index 1
      .product_face
        padding-top 6%
        height 80%
      .img.canvas_600
        width auto
        height 100%
        margin auto
        text-align center
        > canvas
          display inline-block
          transform scale(1)
          width auto !important
          height 100% !important
    .product_face_list.bottom
      display flex
      align-items center
      justify-content center
      height 70px
      position absolute
      bottom 8%
      left 0
      right 0
      z-index 2
      .product_face.preview_thumb
        margin 0 8px
        cursor pointer
        .img.canvas_60
          opacity 0.5
        .face_name
          font-size 10px
          text-align center
          line-height 0.5
        &.active
          .img.canvas_60
            opacity 1
          .face_name
            color color_active
        &:hover
          .img.canvas_60
            opacity .8
    .swiper_control
      width 10px
      height 20px
      position absolute
      top 0
      bottom 0
      margin auto 0
      z-index 20
      cursor pointer
    .swiper-button-disabled
      opacity 0.1
      cursor default
    .preview_prev
      left 32px
      background url("//s3.marpple.co/files/u_47936/2018/11/original/f_12482_1542009201349_xW9APhq7JP5IMWUxFa.svg") no-repeat center
    .preview_next
      right 32px
      background url("//s3.marpple.co/files/u_47936/2018/11/original/f_12483_1542009205386_Cu9KvchD4TqpRBVT1S.svg") no-repeat center
  //>.don_wrapper >.body
  //  margin-top 64px
  .don_page >.don_wrapper >.header
    >.title
      display none
      font-size 32px
      text-align center
      html[lang="en"] &
        font-family CircularStd
  .don_tab
    //margin-top 77px
    .canvas_490
      width 490px
      height 490px
      overflow hidden
      background #f8f9fa
      canvas
        width 590px
        height 590px
      > *
        transform scale(490/590)
        transform-origin 0 0
    .product_color_item
      display flex
      height 100%
      *
        box-sizing border-box
      .left
        display flex
        flex-direction column
        justify-content center
        align-items center
        margin 0 auto
        >div
          display inline-block
        >.pc_info
        >.title
        >.set_product_price
        >.option
        >.bar
          width 334px
          font-size 14px
          font-family SFUIText
        >.option button
          margin-top 83px
          background #000
          height 48px
          text-align center
          color #fff
          border none
          line-height 48px
          width 100%
          border-radius 100px
        .title_and_faces
          >.title
          >.faces
            margin-bottom 8px
        >.title
        >.set_product_price
          label
            display block
          input
            width 100%
            height 40px
            border 1px solid #d5dbe0
            box-sizing border-box
            text-indent 16px
        .kr
          .input_mp_currency
            position relative
            &:after
              content "원"
              position absolute
              right 16px
              top 12px
            input
              text-align center
              text-indent 70px
        .en
          .input_mp_currency
            position relative
            &:before
              content "$"
              position absolute
              left 92px
              top 12px
            input
              text-indent 100px
        .jp
          .input_mp_currency
            position relative
            &:before
              content "¥"
              position absolute
              left 92px
              top 12px
            input
              text-indent 100px
        >.title
        >.set_product_price
        .show_profit
          label
            margin-bottom 8px
        .show_profit
          display flex
          justify-content space-between
          >div
            text-align right
          .minimum_price
            width 93px
        >.set_product_price
        .show_profit
          margin-top 24px
        >.pc_info
          margin-bottom 40px
        >.title::before
          height 1px
          position absolute
          width 100%
          top 0
          background #d5dbe0
          content " "
        >.title
          position relative
          padding-top 30px
          >div
            margin-bottom 16px
            input
              margin-top 4px
        .img.canvas_490
          float left

