.don_dialog_msg
  .content
    width fit-content
    min-width ptr(360)
    padding ptr(30)
    box-sizing border-box
    border-radius ptr(20)
    display flex
    flex-direction column
    gap ptr(16)
    >.body
      margin 0 auto
      .msg
        max-width ptr(240)
        {font_20_m_140}
        .custom-body
          max-width ptr(240)
          display flex
          flex-direction column
          align-items center
          gap ptr(8)
          p
            padding 0
            margin 0 !important
            {font_20_m_140}
            color #000000
    .footer
      margin 0
      .buttons
        display flex
        align-items center
        gap ptr(12)
        .cancel
          border 1px solid BK !important
          margin-right 0 !important
          width 100% !important
          display flex
          align-items center
          justify-content center
          height ptr(50)
          border-radius ptr(50)
          flex 1
          {font_16_m_150}
          &[color_reverse="true"]
            background BK
            color WHT
        .ok
          display flex
          align-items center
          justify-content center
          height ptr(50)
          border-radius ptr(50)
          flex 1
          {font_16_m_150}
          &[color_reverse="true"]
            background WHT
            color BK

.don_dialog_msg[type="alert_title"]
  >.content
    font-family Pretendard
    padding ptr(40)
    width ptr(500)
    gap ptr(24)
    >.body
      .msg
        max-width unset
        >.custom-body
          max-width unset
          display flex
          flex-direction column
          align-items center
          gap ptr(25)
          >.omp-msg-title
            {font_20_m_100}
          >.omp-msg-message
            {font_16_m_150}
            &[data-style_content_color="BK"]
              color BK
            &[data-style_content_color="OG"]
              color OG

.don_dialog_msg[type="confirm"]
  >.content
    font-family Pretendard
    padding ptr(30)
    gap ptr(16)
    min-width ptr(500)
    >.body
      .msg
        max-width unset
        >.custom-body
          max-width unset
          display flex
          flex-direction column
          align-items center
          gap ptr(16)
          >.omp-msg-title
            {font_26_m_130}
          >.omp-msg-message
            {font_16_m_150}
            &[data-style_content_color="BK"]
              color BK
            &[data-style_content_color="OG"]
              color OG

.don_frame.omp-frame-fit.title_center[is_modal="true"]
  & > .don_wrapper
    > .body
      > .don_page
        > .don_wrapper
          > .header
            display flex
            align-items center
            justify-content flex-end !important
            width 100%
            > .title
              text-align center
              width 100%
              position absolute
              {font_26_m_130}
.don_frame.omp-frame-fit.no-background[is_modal="true"]
  &:before
    background-color transparent
.don_frame.omp-frame-fit[is_modal="true"]
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  display flex !important
  align-items center
  justify-content center
  &:before
    content ''
    position fixed
    top -100px
    left -100px
    bottom -100px
    right -100px
    background-color rgba(0,0,0,0.4)
  > .don_wrapper
    box-sizing border-box
    width fit-content
    height fit-content
    background-color WHT
    display flex
    flex-direction column
    gap ptr(16)
    padding ptr(30)
    border-radius ptr(20)
    overflow visible
    > .header
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      > .title
        {font_26_m_100}
    > .body
      width fit-content
      height fit-content
      overflow visible
      box-sizing border-box
      > .don_page
        > .don_wrapper
          position static !important

  &[hide_frame_button_type="-"]
    & > .don_wrapper
      & > .header
        display none
      > .body
        > .don_page
          > .don_wrapper
            > .header
              display flex
              flex-direction row
              align-items center
              justify-content space-between
              > .title
                {font_26_m_130}
    [hide_frame_button_type="X"]
    [hide_frame_button_type="x"]
      .don_wrapper
        .header
          .don_hide_frame
            display block
            position relative
            top 0
            right 0
            background-color transparent
            background-repeat no-repeat
            background-image url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath className='outline' d='M3.46875 2.53027L21.4688 20.5303M3.46875 20.5303L21.4688 2.53027' stroke='black' stroke-width='1.5' stroke-linecap='round' /%3E%3C/svg%3E")
            width ptr(20)
            height ptr(20)
            border none
            outline none
            &:active
              background-color #ced4da
