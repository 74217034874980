.don_frame.omp-cart-add-color[is_modal="true"]
  &::before
    background-color rgba(0,0,0,0.4) !important
    opacity 1
  .don_wrapper
    width ptr(480)
    height ptr(732)
    overflow hidden
    padding-bottom ptr(30)
    > .body
      height fit-content
    >.header
      z-index 1
      >.don_hide_frame
        top ptr(26)
        right ptr(30)
    >.color_body
      height 100%
      display flex
      flex-direction column
      align-items center
      justify-content space-between
      box-sizing border-box
      gap ptr(40)
      .title
        box-sizing border-box
        width 100%
        flex 0 0 0
        {font_26_m_130}
        padding ptr(30) ptr(30) 0 ptr(30)
      .colors_wrapper
        display flex
        flex-direction column
        overflow-y auto
        width 100%
        height ptr(598)
        .colors_title
          margin-bottom ptr(12)
          padding 0 ptr(30)
          {font_16_m_150}
          &.added
            display flex
            align-items center
            justify-content space-between
            cursor pointer
        .added_colors
          margin-bottom ptr(24)
          .tab_wrapper
            display grid
            grid-template-rows 0fr
            transition all 0.5s
            .colors_container
              overflow hidden
          .close_icon
            display none
          input[type="checkbox"]
            display none
        .added_colors input:checked ~ .tab_wrapper
          grid-template-rows 1fr
        .added_colors input:checked ~ .colors_title .close_icon
          display inline
          opacity 100%
        .added_colors input:checked ~ .colors_title .open_icon
          display none
          opacity 0
        .enable_colors
          .message
            padding ptr(20) 0
            text-align center
            {font_14_r_140}
            color GY
      .colors_container
        box-sizing border-box
        width 100%
        display grid
        grid-template-columns repeat(4, 1fr)
        grid-auto-rows min-content
        align-items center
        grid-gap ptr(4)
        {font_14_m_140}
        padding 0 ptr(30)
        .color_item
          display flex
          flex-direction column
          align-items center
          height ptr(108)
          gap ptr(8)
          cursor pointer
          border-radius ptr(12)
          padding ptr(8)
          box-sizing border-box
          &:not(.added):hover
            background-color BG_PRODUCT
          &.added
            cursor default
            .code
              opacity 0.2
            .name
              color GY_80
          &.added
            &[need_ccc="true"]
            &[color_code="#ffffff"]
              .code
                border 1px solid rgba(0, 0, 0, 0.4)
          &[need_ccc="true"]
          &[color_code="#ffffff"]
            .code
              border 1px solid #dee2e6
        .code_wrap
          display flex
          align-items end
          flex-basis ptr(48)
          flex-shrink 0
        .code
          width ptr(32)
          height ptr(32)
          border-radius ptr(24)
        .name
          text-align center
          flex-basis ptr(36)
          {font_14_m_140}
    >.sizes_body
      height 100%
      display flex
      flex-direction column
      align-items center
      justify-content space-between
      box-sizing border-box
      gap ptr(40)
      .title
        box-sizing border-box
        width 100%
        flex 0 0 0
        {font_26_m_130}
        padding ptr(30) ptr(30) 0 ptr(30)
      .main
        width 100%
        .divider
          box-sizing border-box
          width 100%
          padding 0 ptr(30)
          margin ptr(8) 0
          hr
            margin 0
            border 0
            border-top 1px solid BG_MODEL
        .color
          box-sizing border-box
          width 100%
          display flex
          align-items center
          padding ptr(16) ptr(30)
          gap ptr(12)
          .color_chip
            width ptr(24)
            height ptr(24)
            border-radius 50%
            margin-left ptr(4)
            &[need_ccc="true"]
            &[color_code="#ffffff"]
              border 1px solid rgba(0, 0, 0, 0.4)
      .sizes_container
        box-sizing border-box
        width 100%
        height ptr(436)
        overflow-y auto
        padding 0 ptr(30)
        padding-bottom ptr(8)
        display flex
        flex-direction column
        gap ptr(12)
        .quantity-editor
          padding ptr(14) 0
          &__quantity-number
            display flex
            justify-content space-between
            align-items center
          &__option-name
            {font_16_m_100}
            color BK
            &.is_not_stock
              color GY_50
              span
                margin-left ptr(8)
      .sizes_buttons
        box-sizing border-box
        width 100%
        display grid
        grid-auto-flow column
        grid-auto-columns 1fr
        gap ptr(8)
        padding 0 ptr(30)
        > button
          height ptr(50)
          border-radius ptr(999)
          font-weight 500
          font-size ptr(16)
          line-height ptr(16)
          border none
          &.btn_prev
            border 1px solid BK
            background-color WHT
          &.btn_add
            background-color BK
            color WHT
            &.disabled
              cursor default
              background-color GY_50
