.enter_marpple_shop2
  text-align center
  html[lang="kr"] &
    display block !important
  padding-top 20px
  button
    border none
    font-size 14px
    font-weight bold
    color color_active
    background transparent
    text-decoration underline
.enter_marpple_shop
.enter_marpple_shop2
  display none
  html[lang="kr"] &
    display block

.don_frame[frame_name='enter_marpple_shop1']
  .don_wrapper
  .don_tab
  .don_page
    border-radius 20px
  #main
    .header
      border-radius 16px 0 0
  #main >.header
    background url(//s3.marpple.co/files/u_4214566/2024/8/original/c86fd8c480aade466a4d2bd87797bb44a921c94b1.png)
  .paragraph
    text-align center
    button.enter_marpple_shop_btn
      width 436px
      height 50px
      background #1C75FF
      border-radius 999px
      border none
      font-weight 500
      font-size 16px
      color WHT
      text-align center
      padding 6px 16px 7px
  >.don_wrapper
    width 500px
    height 606px !important
    >.header
      .don_hide_frame
        background-image url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5L23 23M5 23L23 5' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")
        width ptr(24)
        height ptr(24)
        top ptr(20)
        right ptr(24)
  #main
    >.header
      background-size cover
      width 100%
      height 376px
    >.body
      display flex
      flex-direction column
      align-items center
      padding 32px
      gap 24px
      >.body-content
        display: flex
        flex-direction: column
        align-items: center
        padding: 0
        gap: 16px
        .paragraph
          font-size 14px
          &:nth-child(1)
            font-weight 500
            font-size 20px
            line-height 1.4
            text-align center
            color BK
          &:nth-child(2)
            width 436px
            height 48px
            font-weight 500
            font-size 16px
            line-height 1.5




.don_frame[frame_name='enter_marpple_shop2']
  .paragraph
    text-align center
    &.bold
      font-weight bold
    a
      color color_active
      text-decoration underline
  >.don_wrapper
    width 492px
    height 338px !important
  #main
    >.header
      img
        width 217px
        height auto
      margin-top 38px
      text-align center
    >.body
      .paragraph
        font-size 14px
        &:nth-child(1)
          margin 16px 0 20px
          line-height 1.5
        &:nth-child(2)
          margin-bottom 32px
          line-height 1.5
