checkout-placeholder-look =
  font-size 14px
  color color_gray3

body.mp-checkout-global
  .don_frame[frame_name="mp.quick_shipping_price"]
    .don_page
      >.don_wrapper
        >.header
          height 64px
          display flex
          background #fff
          align-items center
          .title
            padding-left 0
            margin 0 auto
            font-size 16px
            font-weight bold
        .don_tab >.don_wrapper
          >.body
            display flex
            margin-bottom 16px
        padding 0 16px 24px
        table:first-child
          border-right none
          padding-left 1px
        .last
          border-right none
        table, th, td
          border 1px solid #e9ecef
          border-collapse collapse
          width 50%
        th
          background #fafafa
        th,td
          text-align center
          padding 16px 0
        p
          margin 0
          padding 0
          color color_gray3
          font-size 12px

  .don_page.mp_checkout
    font-weight normal
    .omp_msg
      display none
    .header_title
      line-height 1.2
      color color_black
      font-weight bold
      font-size 24px
    >.don_wrapper .checkout_body
      input[type="text"]
      input[type="password"]
        {checkout-order-input-look}
        html[lang="kr"] &
          width auto
        &::placeholder
          {checkout-placeholder-look}
        &:focus
          {checkout-order-active-border}
      input.hidden
        display none !important
      .input_wrap
        .hidden.done_upload_text
          display none
      .input_wrap.done_upload
        .done_upload_text
          display block !important
      button
        outline none
        padding 0
        background-color #fff
        border solid 1px #d5dbe0
        font-size 12px
        text-align center
        &:active
          {checkout-order-active-border}
      select
        {checkout-order-input-look}
        font-size 14px
        -webkit-appearance none
        -moz-appearance none
        background svg-down-arrow2 no-repeat
        background-position right 16px top 16px
        cursor pointer
        html[lang="jp"] &.country
          background #dddddd
        &:active
          {checkout-order-active-border}
        &:valid
          color color_black
        &:focus
          {checkout-order-active-border}
      textarea.make_request
        width 100%
        height 72px
        border solid 1px #d5dbe0
        resize none
        overflow auto
        box-sizing border-box
        padding 16px
        font-size 14px
        outline none
        -webkit-appearance none
        border-radius 0
        &::placeholder
          {checkout-placeholder-look}
        &:active
          {checkout-order-active-border}
        &:focus
          {checkout-order-active-border}
      .checkbox_wrap
        cursor pointer
        margin-top 16px
        {flex-center-left}
        .checkbox
          width 20px
          height 16px
          display inline-block
          margin-right 8px
          &.active
            background svg-checkbox-active no-repeat center
          &.normal
            background svg-checkbox no-repeat center
        .msg
          font-size 12px
          color color_black !important
      .width356
        width 356px !important
      .section
        background-color #ffffff
        margin-bottom 8px
        .header_summary
          font-size 12px
          line-height 1.17
          -webkit-appearance none
          -moz-appearance none
          letter-spacing normal
          color #212529
        .section_header
          line-height 1.14
          margin-bottom 16px !important
          &:nth-child(3)
            margin-top 16px
          html[lang="kr"] &
            margin-bottom 24px !important
          box-sizing border-box
          color color_black
          {flex-center-between}
          .txt
            font-size 14px
            font-weight bold
          .btn
            font-size 12px
            text-decoration underline
            cursor pointer
          .delivery_fee_error
            color #F66565
            font-size 13px
            font-weight 500
        .section_body
          .input_wrap:last-child
            margin-top 16px
        .accordion
          display flex
          flex-direction column
          padding 0 16px
          align-self stretch
          background-color BG_PRODUCT
          &.expand
            .accordion-content
              max-height 1000px
            .accordion-header
              button.toggle-btn
                &::after
                  content 'Close'
                  html[lang='jp'] &
                    content '簡潔'
          &[is_show="false"]
            display none
          .accordion-header
            display flex
            justify-content space-between
            color OG
            .accordion-title
              display flex
              gap 8px
              font-size 14px
              font-weight 700
              line-height 140%
              svg
                min-width 16px
            button.toggle-btn
              width 52px
              text-align right
              background none
              border none
              color black
              text-decoration underline
              cursor pointer
              font-weight 500
              &::after
                content 'View'
                html[lang='jp'] &
                  content '詳細'
          .accordion-content
            max-height 0
            overflow hidden
            transition max-height 0.1s ease-in-out
            .accordion-description
              margin-top 0
              color OG
              font-size 12px
              font-weight 500
              line-height 140%
  .email_text
    margin-bottom 16px
    position relative
    width 400px
  .msg
    font-size 12px
    line-height 1.67
    color color_gray3
    &.red
      color #D92A0F
  .section_body .msg
    &::before
      content '\2022'
  .has_not_email
    .msg
      position relative
      left 106px
      top -10px
  .price_detail
    padding-bottom 16px
    .detail
      margin-bottom 10px
      {flex-center-between}
      color #495057
      font-size 14px
      span.name
        line-height 1.17
      span.info
        font-weight bold
        line-height 1.14
      .discount
        color #ff6b6b !important
  .total_price
    {flex-center-between}
    font-weight bold
    .name
      font-size 14px
      color color_black
    .info
      font-size 16px
      line-height 1
      color color_black
  .up_item
    &:not(:last-child)
      margin-bottom 16px
      border-bottom 1px solid #e9ecef
  .up_c_item
    .up_c_info
      width 100%
      html:not(.creator_store) &
        .pc_name
          display none
      html[lang="jp"] & .quantity:before
        content '数量: '
        color color_gray3
      html[lang="en"] & .quantity:before
        content 'Qty: '
        color color_gray3
      .title
        margin-bottom 16px
  .payment_type.alipay
  .payment_type.PAYMENTWALL_KONBINI.KOMOJU_PAY_EASY.KOMOJU_KONBINI_DAILY_YAMAZAKI.KOMOJU_KONBINI_LAWSON.KOMOJU_KONBINI_FAMILY_MART.KOMOJU_KONBINI_MINISTOP.KOMOJU_KONBINI_SEICOMART.KOMOJU_BANK_TRANSFER
    ul
      padding-left 18px
      font-size 13px
  .we_accept
    position relative
    height 30px
    margin 14px 0
    html[lang="jp"] &
      height 60px
    .txt
      position absolute
      top 6px
      left 0
      font-size 14px
      font-weight bold
      color color_black
    .logos
      position absolute
      top 0
      right 0
      html[lang="jp"] &
        top 36px
        left 0
        right auto
      > img
        width 48px
        height 30px
  .promotion_description:empty
    display none
  .my_point
  .promotion_description
    display inline-block
    color color_gray3
  .input_wrap2
    display flex
    input[readonly]
      color #666
      border solid 1px #d5dbe0 !important
    .apply
      font-size 12px
      background-color #ffffff
      margin-left 8px
      vertical-align top
      min-width 78px
      height 40px
      border solid 1px #000000
      color color_black
      &:after
        content 'APPLY'
        html[lang="kr"] &
          content '쿠폰 적용'
        html[lang="jp"] &
          content '適用'
      &[applied="true"]
        background #000 !important
        color #fff
        &:after
          content 'CANCEL'
          html[lang="kr"] &
            content '쿠폰 취소'
  .payment_type.without_bank
    .receipt_info
      border-top 1px solid #d5dbe0
    .radio_box .row.last
      label
        margin-bottom 8px
  .receipt_info
    padding-top 24px
    .receipt_body
      display none
    &[receipt_type='cash']
      .cash.receipt_body
        display block
    &[receipt_type='company']
      .company.receipt_body
        display block
    &[receipt_type='taxBill']
      .taxBill.receipt_body
        display block
    .receipt_body
      margin-top 24px
    >.header
      margin-bottom 16px
    >.body
      select
      input[type="text"]
      .input
        { checkout-order-input-look }
    >.option
      display none
      margin-top 24px
    .radio_box
      .row:nth-child(1)
        margin-bottom 8px
      .row.last
        display flex
        flex-wrap wrap
        label
          width 175px
          &:last-child
            margin-bottom 0
    .company
    .cash
      select
      input
      .input
        margin-bottom 8px !important
        &:last-child
          margin-bottom 0 !important
      select
        margin-bottom 8px
    .taxBill
      .wrapper
        display flex
        > button
          flex-grow 1
          width 50%
          font-size 14px
          padding 12px 0
          text-align center
          &:nth-child(1)
            margin-right 8px
  .agreement
    font-size 12px
    .description
      color color_gray3
      margin-top 8px
      line-height 1.83
    .check_all
      font-size 14px
      font-weight bold
    .check_item
      position relative
      a
        position absolute
        top 0
        right 0
    .check_list
      margin 24px 24px 0 24px !important
    .check_item:not(:last-child)
      padding-bottom 16px
      position relative
      margin-bottom 16px
      border-bottom 1px solid #d5dbe0
    a
      color color_gray3
      text-decoration underline
  .btn_wrap
    margin 0 20px
  .submit
    margin-top 16px
    width 100%
    height 48px
    border none
    outline none
    border-radius ptr(100)
    background-color BK !important
    font-size 14px
    color #ffffff
    {flex-center-center}
  .shipping_type
    .txt2
      font-size 11px
      color #666
    .mp_input
      input[type="radio"]
        margin-right 8px
    h4
      margin 0
      font-weight normal
      margin-bottom 16px
    .shipping_type_tab
      margin-bottom 24px
      label
        &:nth-child(1)
          margin-right 109px
        &:nth-child(2)
          margin-right 122px
      &[has_before_deliver_projections="true"]
        label
          &:nth-child(2)
            margin-right 78px
          &:nth-child(1)
          &:nth-child(3)
            margin-right 68px
    .shipping_type
      background #fafafa
      padding 12px 16px 16px 16px
      .quick_table
        position relative
        text-decoration underline
      th
        width 60px
        font-size 12px
        color color_gray3
        font-weight normal
        text-align left
        vertical-align initial
        line-height 1.67
      td
        padding-bottom 12px
        font-size 12px
        line-height 1.67
      tr:last-child
        td
          padding-bottom 0
      ul
        margin 0
        margin-top 16px
        padding 16px 0 0
        list-style none
        position relative
        border-top 1px solid #d5dbe0
        li
          font-size 12px
          line-height 1.83
    .shipping_type.combined
      table
        width 100%
      tr
        th
          width 80px
          text-align left
          padding-right 4px
          word-break break-all
        th
        td
          padding 0 0 12px
          vertical-align top
        td:first-child
          label
            position relative
            top -1px
        td
          label
            display inline-block
        td:nth-child(3n)
          padding-right 8px
        td:last-child
          text-decoration underline
  .shipping_picker
    padding-bottom 17px
    position relative
    margin-bottom 17px
    border-bottom 1px solid #d5dbe0
    >label
      margin-right 16px
    button.open_list
      display none
      position absolute
      padding 5px 8px
      border 1px solid #d5dbe0
      font-weight normal
      background #fff
      top -4px
      right 0

  .don_page.mp_checkout
    .don_tab >.don_wrapper .checkout_body
      max-width 992px
      margin auto
      padding 0 0 120px
      position relative
    .width366
      width 366px !important
    .width446
      width 446px !important
    .none_br
      br
        display none
    .to_map
      img
        width 460px
    .quick_table
      padding-left 8px
    .header_title
      margin 24px 0
      position relative
      .status_bar
        font-size 14px
        color color_gray3
        font-weight normal
        position absolute
        right 0
        bottom 0
        span
          &.active
            color color_black
          &.arrow
            margin auto 8px
            height 10px
            min-width 10px
            background svg-right-arrow no-repeat center
    .section
      width 625px
      padding 24px 0
      box-sizing border-box
      html[lang="kr"] &
        width 600px
      > *
        margin 0 24px
        .input_wrap
          position relative
        .for_desktop_wrap
          display flex
          justify-content space-between
        .for_desktop
          &.city_state
            width 284px
          &.postcode_country
            width 284px
            >*
              max-width 284px
          .country
            margin-bottom 0
          &.mobile_number
            display flex
            justify-content space-between
            select.dialing_code
              width 294px
              margin-right 8px
      &.product
        >.section_header
          border-bottom 1px solid #d5dbe0
          padding-bottom 16px
        .up_list
          margin 0
          padding 0 24px
          .item_header
            font-size 12px
          .up_c_info
            .title, .sub_title, .face_list, .size, .quantity
              font-size 12px
            html[lang="jp"] & .quantity:before
              content '数量'
            html[lang="en"] & .quantity:before
              content 'Qty: '
            html[lang="kr"] & .quantity:after
              content '개'
            .faces
              margin-top 8px !important
            .product_price
              margin-top 16px
          .item_body_wrap1
            margin 0
    .bar_wrapper
      display flex
      justify-content space-between
      flex-direction row-reverse
    .right_bar
      .section
        width 359px
        html[lang="kr"] &
          width 384px
    .payment_method
      width 625px
      padding 24px
      box-sizing border-box
      {flex-center-between}
      background-color #fafafa
      .logos
        >img
          margin-left 8px
    .submit
      margin-top 40px

  .don_frame[frame_name="mp.checkout.change_email"][is_modal="true"]
    >.don_wrapper
      width 407px !important
      height 452px !important
      overflow hidden
      .don_page >.don_wrapper
        >.header
          height 65px
          z-index 10
        .page_body
          padding 0 40px

  .don_page[page_name="mp.checkout.change_email"]
    background-color #fff
    .don_wrapper >.page_body
      padding 0 24px
      .header_title
        font-size 16px
        font-weight bold
        color color_black
      .current_data
        margin 24px 0
        .title
          font-size 14px
          color color_black
          margin-bottom 16px
        .email_text
          font-size 14px
          color color_black
          background-color #fafafa
          padding 12px 16px
      .notice
        margin-top 8px
        font-size 12px
        line-height 1.5
        color color_gray3
      .inputs
        margin-bottom 24px
        input
        .input
          box-sizing border-box
          padding 12px 16px 12px 36px
          width 100%
          height 40px
          border solid 1px #d5dbe0
          background-color #ffffff
          outline none
          -webkit-appearance none
          border-radius 0
          &::placeholder
            font-size 14px
            color color_gray3
          &.new_email
            margin-bottom 8px
            background png-email no-repeat
            background-size 12px 12px
            background-position left 16px top 14px
          &.password
            background png-password no-repeat
            background-size 12px 12px
            background-position left 16px top 14px
      .submit_btn
        {flex-center}
        height 48px
        font-size 14px
        color #ffffff
        background-color #000000
        cursor pointer
  .payment_type_container
    padding-bottom 16px
    .payment_types
      .bank_info
        margin 24px 0 !important
        select
          margin-bottom 8px !important
        input
        .input
          margin-bottom 0 !important
      .payment_type
        display none
      .group
        display grid
        gap ptr(8)
        grid-template-columns 1fr 1fr 1fr
      .group.one-button
        grid-template-columns 1fr
      .group.two-buttons
        grid-template-columns 1fr 1fr
      .selector
        button
          background #fff
          font-size 14px
          height 40px
          width 100%
          box-sizing border-box
          outline none
          border ptr(1) solid GY_50
          border-radius ptr(10)
          &:nth-child(3n)
            margin-right 0
      &[type="card"] > .card
      &[type="without_bank"] > .without_bank
      &[type="trans"] > .trans
      &[type="paypal"] > .paypal
      &[type="alipay"] > .alipay
      &[type="KOMOJU_PAY_EASY"] > .KOMOJU_PAY_EASY
      &[type="KOMOJU_BANK_TRANSFER"] > .KOMOJU_BANK_TRANSFER
      &[type="KOMOJU_KONBINI_DAILY_YAMAZAKI"] > .KOMOJU_KONBINI_DAILY_YAMAZAKI
      &[type="KOMOJU_KONBINI_LAWSON"] > .KOMOJU_KONBINI_LAWSON
      &[type="KOMOJU_KONBINI_FAMILY_MART"] > .KOMOJU_KONBINI_FAMILY_MART
      &[type="KOMOJU_KONBINI_MINISTOP"] > .KOMOJU_KONBINI_MINISTOP
      &[type="KOMOJU_KONBINI_SEICOMART"] > .KOMOJU_KONBINI_SEICOMART
        display block
      &[type="card"] button[payment_type="card"]
      &[type="without_bank"] button[payment_type="without_bank"]
      &[type="trans"] button[payment_type="trans"]
      &[type="offline_card"] button[payment_type="offline_card"]
      &[type="offline_cash"] button[payment_type="offline_cash"]
      &[type="samsung"] button[payment_type="samsung"]
      &[type="payco"] button[payment_type="payco"]
      &[type="naverpay"] button[payment_type="naverpay"]
      &[type="global_port_one"] button[payment_type="global_port_one"]
      &[type="ssgpay"] button[payment_type="ssgpay"]
      &[type="lpay"] button[payment_type="lpay"]
      &[type="paypal"] button[payment_type="paypal"]
      &[type="alipay"] button[payment_type="alipay"]
      &[type="vbank"] button[payment_type="vbank"]
      &[type="tosspay"] button[payment_type="tosspay"]
      &[type="kakaopay"] button[payment_type="kakaopay"]
      &[type="PAYMENTWALL_CREDIT_CARD"] button[payment_type="PAYMENTWALL_CREDIT_CARD"]
      &[type="PORTONE_PAYMENTWALL_CREDIT_CARD"] button[payment_type="PORTONE_PAYMENTWALL_CREDIT_CARD"]
      &[type="PAYMENTWALL_KONBINI"] button[payment_type="PAYMENTWALL_KONBINI"]
      &[type="KOMOJU_PAY_EASY"] button[payment_type="KOMOJU_PAY_EASY"]
      &[type="KOMOJU_BANK_TRANSFER"] button[payment_type="KOMOJU_BANK_TRANSFER"]
      &[type="KOMOJU_KONBINI_DAILY_YAMAZAKI"] button[payment_type="KOMOJU_KONBINI_DAILY_YAMAZAKI"]
      &[type="KOMOJU_KONBINI_LAWSON"] button[payment_type="KOMOJU_KONBINI_LAWSON"]
      &[type="KOMOJU_KONBINI_FAMILY_MART"] button[payment_type="KOMOJU_KONBINI_FAMILY_MART"]
      &[type="KOMOJU_KONBINI_MINISTOP"] button[payment_type="KOMOJU_KONBINI_MINISTOP"]
      &[type="KOMOJU_KONBINI_SEICOMART"] button[payment_type="KOMOJU_KONBINI_SEICOMART"]
        border-color BK
        background-color BK
        color WHT
  #shipping_info
    &[can_loqate="true"][searched="false"] .base_address
    &[can_loqate="false"] .search_address input
    &[has_shipping="false"] .search_address input
      display none

  .don_page.mp_checkout .checkout_body
    .shipping_companies
      font-weight normal
      .shipping_company_info
        display none
        &.oversea
          display block

    .shipping_company_selector
      label:first-child
        margin-right 16px
      img
        vertical-align middle
        position relative
        top -3px
        height 24px
        margin-right 6px
      input[type="radio"]
        width auto
        position relative
        top -4px
        margin-right 6px
        vertical-align middle

    .shipping_company_infos
      margin-top 16px
      table
        th
        td
          padding 4px 16px 4px 0
          vertical-align top
          br
            display none
        th
          font-weight bold
          text-align left
          html[lang="en"] &
            width 100px
          html[lang="jp"] &
            width 60px

  .don_page.mp_checkout >.don_wrapper .checkout_body
    .shipping_companies
      .section_header
        margin-bottom 24px !important

html[lang="en"]
html[lang="jp"]
  .don_page.mp_checkout
    .checkout_body
      select:valid
        &[name="express"]
        &[name="express_service"]
          color black
          &.disabled
            color #878E95
    .checkout_body[is_hide_shipping_cost="false"]
      .delivery_fee_adder
        display none
    .checkout_body[is_hide_shipping_cost="true"]
      .shipping_price
        display none

    .checkout_body[data-is_delivery_fee_error="false"]
      span.delivery_fee_error
        display none
    .checkout_body[data-is_delivery_fee_error="true"]
      select[name="express"]
      select[name="express_service"]
        border 1px solid #D92A0F
