#my_lib_group_images[is_empty="true"]
  html[lang="kr"] &
    .image_upload_desc
    .loc_opt_desc
      width 300px !important
      line-height 1.71
      min-height 211px !important
      h3
        font-size 13px
    .loc_opt_desc
      white-space pre-line
      min-height unset
.maker_sticker_frame
  > .don_wrapper
    height pxToRem(900px)
    max-height pxToRem(700px)
    width 1040px
.maker_sticker_frame.maker_my_lib_frame
  #my_lib_group_images[is_empty="true"]
    position relative
    margin 0
    min-height 400px
    &:after
      display block
      padding-top 280px
      text-align center
      font-size 16px
      background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12434_1541404949186_AQ9ZQ6vLkXCbX2v6LE1G.png") no-repeat
      background-size 80px auto
      background-position 50% 170px
      content 'Your library is empty'
      html[lang="kr"] &
        content '이미지를 업로드하고 나만의 멋진 상품을 만들어보세요.'
      html[lang="jp"] &
        content 'ライブラリーが空です。'

.maker_sticker_frame.maker_my_lib_frame
  .don_head .don_head_btn.don_close
  .don_head .don_head_btn.don_done
    display none
  > .don_wrapper
    padding-top 82px
    padding-bottom 100px
    &.drop
      outline 2px solid OG
    > .body
      padding-top 20px
    .don_tab
      > .don_wrapper
        position static !important
        .my_images_wrap
          position relative
    .btn_area
      position absolute
      bottom 40px
      left 0
      right 0
      text-align center
      input[type="file"]
        position absolute
        top -9999px
        left -9999px
        visibility hidden
      button
        border-radius 100px
        border 1px solid BK
        width 343px
        height 48px
        margin-bottom -13px
        font-size 14px
        color #fff
        text-align center
        background-color #000000
      .image_upload_desc
      .loc_opt_desc
        display none
        position absolute
        bottom 64px
        left 50%
        z-index 3
        width 322px
        min-height 144px
        margin-left -161px
        padding 16px
        color color_black
        background-color #fff
        box-sizing border-box
        //box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.1)
        box-shadow 0 2px 16px 2px rgba(0,0,0,0.15)
        .arrow
          position absolute
          bottom -13px
          left 50%
          width 37px
          height 17px
          margin-left -19px
          -webkit-transform rotate(180deg)
          transform rotate(180deg)
          background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12429_1541148267479_wlBszQ4KTt0HO3lAg1U.png") no-repeat
        h3
          margin 0
          padding 0
          font-size 14px
          font-weight bold
        .txt1
          margin 9px 0 0
          padding 0
          font-size 12px
          font-weight 500
          line-height 1.67
          word-spacing -1px
          br
            display none
        .txt2
          margin 6px 0 0
          padding 0
          font-size 12px
          line-height 1.67
          word-spacing -1px
          a
            color color_black
            text-decoration underline
      .loc_opt_desc
        white-space pre-line
        min-height unset
    .image_item
      .delete
        position absolute
        top -15px
        right -15px
        width 30px
        height 30px
        transform rotate(0deg);
        cursor pointer
        border-radius 15px
        background-color #ccc
        span
          display block
          position absolute
          top 23px
          left 0
          height 4px
          width 73%
          background #fff
          border-radius 2px
          transform rotate(45deg)
          &:nth-child(1)
            top 13px
            left 4px
          &:nth-child(2)
            top 13px
            left 4px
            transform rotate(-45deg)


.maker_sticker_frame
  padding-top 0 !important
  >.don_wrapper
    padding-top 102px
    >.header
      display none
  &[is_fixed_head="true"]
    .don_page .page_title
      display none
    .don_page[page_name="maker_my_lib_page"] .page_title
      display block
      .name
        font-size 16px
        font-weight normal
        color #212529
    .don_page[page_name="maker_sticker_page"] .page_title
      position fixed
  .don_page[page_name="maker_sticker_page"]
  .don_page[page_name="maker_line_sticker_page"]
  .don_page[page_name="maker_sticker_shinhan_page"]
    > .don_wrapper > .header
      position absolute
      top 0
      left 0
      right 0
      padding-top 20px
      width auto
      text-align center
      border-bottom 1px solid #e9ecef
      z-index 1
    > .don_wrapper > .body
      top 121px !important
    .tab_buttons
      padding 0 48px
      height 100%
      button.tab_button
        position relative
        z-index 1
        display inline-block
        margin 0 0 -1px
        padding 0
        width 50%
        height 60px
        line-height 60px
        font-weight 300
        box-sizing border-box
        overflow hidden
        border 0 none
        border-bottom 1px solid transparent
        background-color transparent
        outline none
        border-radius 0
        font-size 20px
        text-align center
        color #878b8f
        &:active
          color color_black
        &[selected="true"]
          color color_black
          border-bottom-color #000
  .don_page[page_name="maker_line_sticker_page"]
    .tab_buttons
      button.tab_button
        width 50%
  .don_page.sticker_header_style1
    background-color #fff
    > .don_wrapper
      > .header
        position absolute
        top 0
        left 0
        right 0
        z-index 2
        background-color transparent
        .don_back_pag2e
          position absolute
          top 10px
          left 10px
          display block
          padding 0
          width 40px
          height 40px
          color #fff
          font-weight 500
          cursor pointer
          background url(//s3.marpple.co/files/u_18053/2018/10/original/f_6063_1539332507585_To8KK9bM1Cs2e8bm4Xi6eT.png) no-repeat
          background-size 19px auto
          background-position 50%
          overflow hidden
          span
            position fixed
            top -1000px
            left -1000px
        .name
          position absolute
          top 40px
          left 20%
          right 20%
          font-size 20px
          font-weight bold
          text-align center
          color color_black
  .sticker_list
    margin 0 0 0 48px
    text-align center
    .infi_wrapper
      text-align left
    .sticker_item
      position relative
      display inline-block
      margin 0 32px 55px 0
      width 160px
      height 160px
      cursor pointer
      &:nth-child(5n)
        margin-right 0
      &:active
      &:hover
        .image
          background-image sticker-folder-hover
        .name
          color OG
      .image
        position relative
        width 100%
        height 100%
        padding 0
        background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6066_1539333281684_UC6pb6CwCOeuM6dSaD.png)
        background-repeat no-repeat
        background-position 50% 50%
        background-size contain
        img
          position absolute
          top 15px
          left 0
          right 0
          bottom 0
          max-width 140px
          max-height 90px
          margin auto
      .name
        padding 5px 0 3px
        text-align center
        font-size 14px
        color #343a40
        height 14px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
  .folder_detail_area
    > .body
      padding 0
      background-color #fff
  .sticker_image_list
    margin 0 0 40px 48px
    text-align center
    .infi_items
    .infi_items > div
      text-align left
    .image_item
      position relative
      display inline-block
      margin 0 32px 32px 0
      width 160px
      height 160px
      cursor pointer
      border-radius 6px
      box-sizing border-box
      border 1px solid #d5dbe0
      &[is_group="true"]
        border 0 none
        border-radius 0
        background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6092_1539337454782_MlLaAEK9fq9zP6eMG3K.png)
        background-repeat no-repeat
        background-size contain
        background-position 50% 50%
        .thumbs
          padding 100% 0 0
          display block
          text-align center
          &[group_length="0"]
            display none
          .thumb
            display inline-block
            width 28%
            height 28%
            margin 0
            background-repeat no-repeat
            background-size contain
            background-position center center
            &:nth-child(1)
              position absolute
              top 19%
              left 14%
            &:nth-child(2)
              position absolute
              top 19%
              right 16%
            &:nth-child(3)
              position absolute
              bottom 12%
              left 14%
            &:nth-child(4)
              position absolute
              bottom 12%
              right 16%
        .image
          display none
        &.selected
        &:active
        &:hover
          background-image sticker-group-hover
      &:nth-child(5n)
        margin-right 0
      &.selected
      &:active
      &:hover
        border-color OG
        background-color #fff
      .thumbs
        display none
      .image
        border-radius 6px
        padding 100% 0 0
        background url(//s3.marpple.co/file/guest/2017/10/original/f_710_1507456502604_O2nn8NXb7wq0y0SdR1WTv.png) repeat left top
        img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          max-width 70%
          max-height 70%
          margin auto
          vertical-align top
      .name
        display none
