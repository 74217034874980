.dormant-cert-wrapper
  display flex
  flex-direction column
  min-height 50vh
  .dormant-cert
    flex 1 1 auto
    display flex
    flex-direction column
    align-items center
    justify-content center
    margin 0 auto
    width ptr(802)
    &__title
      padding 0
      margin 0
      {font_26_m_130}
    &__logo
      img
        height 24px
    &__info
      width 100%
      display flex
      flex-direction column
      gap ptr(24)
      {font_16_m_150}
      .bold
        font-weight 700
        line-height 100%
      .og
        color OG
        text-decoration underline
    &__privacy
      box-sizing border-box
      width 100%
      display flex
      flex-direction column
      gap ptr(16)
      margin ptr(24) 0
      padding ptr(24)
      border-radius ptr(20)
      background-color BG_PRODUCT
      &__data
        display flex
        gap ptr(24)
      &__key
        width ptr(110)
        {font_14_m_140}
      &__value
        {font_14_r_140}
    &__buttons
      display flex
      gap ptr(8)
    &__button
      width ptr(180)
      height ptr(50)
      border none
      padding 0
      margin 0
      background-color BK
      color WHT
      {font_16_m_100}
      border-radius ptr(999)
      &[disabled]
        background-color WHT
        border 1px solid BK
        color BK
        cursor default