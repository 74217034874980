@media screen and (min-width 1025px)
  .don_frame[frame_name='/modules/Dormant/PhoneCert/S/Mui/frame.js']
    .phone-cert-wrapper
      display flex
      padding-top 80px
      height 584px
      .phone-cert
        display flex
        flex-direction column
        align-items center
        margin 0 auto
        width 520px
        &__title
          width 100%
          font-size 24px
          font-weight bold
          color #000
          padding 0
          margin 0 0 40px 0
        &__sub-title
          width 100%
          font-size 14px
          font-weight 600
          color #111
          padding 0
          margin 0 0 24px 0
        &__data
          width 100%
          display flex
          flex-direction column
          gap 8px
          margin-bottom 50px
          &__title
            font-weight normal
            width 100px
            font-size 14px
            color #000
            padding 0
            margin 0
          &__number
            align-items center
            justify-content space-between
            display flex
            gap 8px
          &__name
            align-items center
            justify-content space-between
            display flex
            gap 8px
            margin-bottom 8px
          &__input-wrapper
            position relative
            width 100%
          &__cert
            box-sizing border-box
            position relative
            width 100%
            padding-left 108px
            &.disabled
              display none
            &__invalid
              &.disabled
                display none
              font-size 13px
              margin 8px 0 0 0
              font-weight 500
              line-height 1.31
              letter-spacing -0.5px
              text-align left
              color #f66565
            &__timer-wrapper
              display flex
              position absolute
              height 100%
              width fit-content
              top 0
              right 0
              align-items center
              justify-content center
              padding-right 16px
            &__timer
              font-size 14px
              color #0157ff
              margin 0
              padding 0
              &.red
                color #f66565
          input
            -moz-appearance textfield
            box-sizing border-box
            outline none
            flex-grow 1
            height 40px
            line-height 1.38
            padding 0 16px
            border solid 1px #d5dbe0
            font-size 14px
            &[name="code"]
              width 100%
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &.invalid
              border solid 1px #f66565
            &::-webkit-outer-spin-button
            &::-webkit-inner-spin-button
              -webkit-appearance none
              margin 0
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 17px
          &[disabled]
            background-color #a2a2a2
            cursor default
          &.lightly
            width 120px
            height 40px
            letter-spacing -0.2px
            color #000
            font-size 14px
            background-color transparent
            border solid 1px #d5dbe0

@media screen and (max-width 1024px)
  .don_frame[frame_name='/modules/Dormant/PhoneCert/S/Mui/frame.js']
    .phone-cert-wrapper
      display flex
      .phone-cert
        display flex
        flex-direction column
        align-items center
        box-sizing border-box
        padding 100px 16px 80px 16px
        width 100%
        &__title
          width 100%
          font-size 16px
          font-weight bold
          color #000
          padding 0
          margin 0 0 24px 0
        &__sub-title
          width 100%
          font-size 14px
          font-weight 600
          color #111
          padding 0
          margin 0 0 24px 0
        &__data
          width 100%
          display flex
          flex-direction column
          gap 8px
          margin-bottom 40px
          &__title
            font-weight normal
            width 64px
            font-size 14px
            color #000
            padding 0
            margin 0
          &__number
            align-items center
            justify-content space-between
            display flex
            gap 8px
          &__name
            align-items center
            justify-content space-between
            display flex
            gap 8px
            margin-bottom 8px
          &__cert
            box-sizing border-box
            position relative
            width 100%
            padding-left 72px
            &.disabled
              display none
            &__timer
              font-size 14px
              color #0157ff
              position absolute
              top 11.5px
              right 16px
              margin 0
              padding 0
              &.red
                color #f66565
          input
            -moz-appearance textfield
            box-sizing border-box
            outline none
            flex-grow 1
            height 40px
            line-height 1.38
            padding 0 16px
            border solid 1px #d5dbe0
            font-size 14px
            &[name="code"]
              width 100%
            &::placeholder
              color #868e96
            &:focus
              border solid 1px #111
            &.invalid
              border solid 1px #f66565
            &::-webkit-outer-spin-button
            &::-webkit-inner-spin-button
              -webkit-appearance none
              margin 0
        &__buttons
          display flex
          width 100%
          gap 10px
        &__button
          width 100%
          height 48px
          background-color #111
          border none
          padding 0
          margin 0
          color white
          font-size 14px
          &[disabled]
            background-color #a2a2a2
            cursor default
          &.lightly
            width 95px
            height 40px
            color #000
            background-color transparent
            border solid 1px #d5dbe0