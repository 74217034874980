// Pc
.omp-search
  &__search-form
    width ptr(600)

  &__auto-complete-items
    position absolute
    display flex
    flex-direction column
    width 100%
    background-color WHT
    list-style-type none
    box-sizing border-box
    border 1px solid
    border-top none
    border-radius 0 0 ptr(16) ptr(16)
    padding ptr(6) ptr(10) ptr(20) ptr(10)
    margin 0
    gap ptr(8)
    &--hidden
      display none

  &__auto-complete-item
    cursor pointer
    padding ptr(12) ptr(6)
    &[selected="true"]
      background-color BG_PRODUCT
      border-radius ptr(4)

  &__auto-complete-item__text
    {font_16_m_100}
    &--pointed
      color OG
