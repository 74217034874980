.omp-layout-body:has(.omp-withdrawal)
  padding ptr(60) 0
.omp-withdrawal
  max-width ptr(512)
  &[data-can_not_withdrawal="true"]
    .omp-withdrawal__can-withdrawal
      color OG
    .omp-withdrawal__txt3
      strong
        color OG
  &__logo
    img
      width ptr(170)
      height ptr(32)
  &__title
    margin 0
    margin-top ptr(8)
    color GY
    font-size ptr(16)
    font-weight 500
    line-height 140%
  &__txt1
    margin 0
    margin-top ptr(32)
    font-size ptr(20)
    font-weight 700
    line-height ptr(24)
    letter-spacing -0.2px
    color BK
  &__txt2
    margin 0
    margin-top ptr(16)
    letter-spacing -0.2px
    color BK
    font-size ptr(16)
    font-style normal
    font-weight 700
    line-height 24px
    letter-spacing -0.2px
  &__txt3
    margin 0
    padding 0
    color #6b6b6b
    {font_16_m_150}

  &__ol
    margin-top ptr(8)
    padding 0 0 0 ptr(17)
    li
      margin-bottom ptr(8)
      {font_16_m_150}
      color #6B6B6B
      a
        text-decoration underline
        color OG
      strong
        color BK

  &__info
    margin ptr(24) 0 ptr(32)
    padding ptr(24)
    border-radius ptr(8)
    background-color #f8f8f8
  &__info_ul
    margin 0
    padding 0
    list-style none
    li
      display flex
      margin-top ptr(16)
      justify-content space-between
      color #000
      {font_16_m_150}
      &:first-child
        margin-top 0
  &__info_head
    color #6b6b6b
  &__info_body
    font-weight 700
    &.omp-withdrawal__can-withdrawal
      color OG

  &__btns
    display flex
    gap ptr(8)
    margin-top ptr(32)
    > button
      {font_16_m_150}
      padding ptr(12) ptr(16)
      height ptr(50)
      border-radius 999px
      transition:
        background-color 200ms ease-out,
        color 200ms ease-out,
        border 200ms ease-out;
  &__btn_cancel
    display flex
    max-width ptr(164)
    justify-content center
    align-items center
    flex 1 0 0
    border-radius 999px
    border 1px solid BK
    background-color WHT
  &__btn_not_withdrawal
  &__btn_withdrawal
    border none
    display flex
    height ptr(50)
    max-width ptr(340)
    padding ptr(6) ptr(16) ptr(7) ptr(16)
    justify-content center
    align-items center
    flex 1 0 0
    border-radius 999px
    color WHT
    background-color BK

.omp-withdrawal-alert
  text-align left
  font-size ptr(15)
  line-height 1.4
  letter-spacing -0.2px
  color #111
  a
    color OG !important
  &__title
    font-size ptr(20)
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
  &__txt1
    font-size ptr(15) !important
    font-weight normal
    font-stretch normal
    font-style normal
    line-height 1.4
    letter-spacing -0.2px
    color #111
    text-align center
  &__ul
    margin ptr(16) 0 0
    padding 0
    list-style none
