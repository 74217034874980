
.vector-editor__cutting-line-auto
  position absolute
  width 100%
  > .don_wrapper
    position relative
    width 100%
    > .header
      display none
    > .body
      position relative

      width 100%
  .don_tab
    user-select none
    position relative
    width 100%
    > .don_wrapper
      position relative
      width 100%
      height 100%
      display flex

      .left_container
        position relative
        width 0
        flex-grow 1
        height 100%
        background-color #FFFFFF
        .editor_container
          position absolute
          top 0
          right 0
          bottom 0
          left 0
          background-color #F8F9FA
        .foreground_container
          position absolute
          top 0
          left 0
          width 0
          height 0
          overflow visible
          .size_guide_container
            position absolute
            top 0
            left 0
            width 0
            height 0
            overflow visible
            .size_guide_container_value
              visibility hidden
              position absolute
              top 0
              left 0
              display inline-flex
              width 200px
              height 20px
              align-items center
              justify-content center
              background-color #474748
              color #fff
              border-radius 16px
      .right_container
        vector-editor-right_container()
        height 100%
        overflow hidden
        .right_wrapper
          width 100%
          height 100%
          display flex
          flex-direction column
          box-sizing border-box
          padding 40px 0 32px 0
          background-color #FFFFFF
          overflow hidden
          .panel_container
            width 100%
            height 0
            flex-grow 1
            position relative
            .panel_wrapper
              position absolute
              width 100%
              height 100%
              overflow hidden
              display flex
              flex-direction column
              box-sizing border-box
              padding 0 40px 0 40px
              .wrapper_header
                display flex
                width 100%
                height 24px
                align-items center
                justify-content center
                .wrapper_header_title
                  font-size 19px
              .wrapper_body
                width 100%
                height 0
                flex-grow 1
                display flex
                flex-direction column
                align-items center
                justify-content center
                .wrapper_body_description_wrapper
                  display flex
                  width 100%
                  height 18px
                  justify-content center
                  align-items center
                  margin-bottom 16px
                  .wrapper_body_description_wrapper_icon
                    width 18px
                    height 18px
                    margin-right 8px
                    background-repeat no-repeat
                    background-size 18px 18px
                    background-position center center
                  .wrapper_body_description_wrapper_text
                    display inline-flex
                    height 100%
                    align-items center
                    font-size 14px
                    font-weight bold
                .wrapper_body_control_wrapper
                  display flex
                  width 100%
                  height 40px
                  justify-content space-between
                  align-items center
                  .wrapper_body_control_wrapper_auto
                    display flex
                    width 0
                    height 100%
                    flex-grow 1
                    margin-right 8px
                    align-items center
                    box-sizing border-box
                    vector-editor-border-radius-xs()
                    .wrapper_body_control_wrapper_auto_minus
                    .wrapper_body_control_wrapper_auto_plus
                      width 40px
                      height 38px
                      background-color transparent
                      border 0 none
                      margin 0
                      padding 0
                      background-size 40px 38px
                      background-position center center
                      background-repeat no-repeat
                    .wrapper_body_control_wrapper_auto_minus
                      background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-minus@2x.png")
                    .wrapper_body_control_wrapper_auto_plus
                      background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-plus@2x.png")
                    .wrapper_body_control_wrapper_auto_label
                      display inline-flex
                      justify-content center
                      align-items center
                      width 0
                      height 100%
                      flex-grow 1
                      font-size 14px
                  .wrapper_body_control_wrapper_manual
                    display inline-flex
                    justify-content center
                    align-items center
                    width 132px
                    height 100%
                    margin 0
                    padding 0
                    box-sizing border-box
                    font-size 14px
                    font-weight bold
                    background-color #FFFFFF
                    vector-editor-border-radius-xs()
              .wrapper_footer
                display flex
                width 100%
                height 56px
                align-items center
                justify-content space-between
                button
                  border 0 none
                  padding 0
                  margin 0
                  width 0
                  height 100%
                  flex-grow 1
                  box-sizing border-box
                  background-color transparent
                .prev
                  margin-right 8px
                  background-color #FFFFFF
                  vector-editor-border-radius-l()
                .next
                  background-color #000000
                  color #FFFFFF

.vector-editor__cutting-line-auto-b
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .header
      display none
    > .body
      width 100%
      height 100%
  .don_tab
    user-select none
    position relative
    width 100%
    height 100%
    > .don_wrapper
      position relative
      width 100%
      height 100%
      display flex
      .left_container
        position relative
        width 0
        flex-grow 1
        height 100%
        background-color #FFFFFF
        .editor_container
          position absolute
          top 0
          right 0
          bottom 0
          left 0
          background-color #F8F9FA
      .right_container
        vector-editor-right_container()
        height 100%
        overflow hidden
        .right_wrapper
          width 100%
          height 100%
          display flex
          flex-direction column
          box-sizing border-box
          padding 40px 0 32px 0
          background-color #FFFFFF
          overflow hidden
          .panel_container
            width 100%
            height 0
            flex-grow 1
            position relative
            .right_panel
              position absolute
              width 100%
              height 100%
              background-color #FFFFFF
              display flex
              flex-direction column
              box-sizing border-box
              padding 0 30px 0 30px
              .header
                width 100%
                height 22px
                display flex
                align-items center
                justify-content center
                font-size 19px
                font-weight normal
              .wrapper
                width 100%
                height 0
                flex-grow 1
                display flex
                flex-direction column
                align-items center
                justify-content center
                .description_container
                  display inline-flex
                  align-items center
                  margin-bottom 16px
                  &::before
                    content ""
                    display inline-block
                    width 18px
                    height 18px
                    background-size 18px 18px
                    background-repeat no-repeat
                    background-position center center
                    margin-right 8px
                  .description
                    margin 0
                    font-size 12px
                    font-weight bold
                .control_container
                  display inline-flex
                  align-items center
                  width 100%
                  height 40px
                  .offset_control
                    display inline-flex
                    align-items center
                    width 0
                    height 100%
                    flex-grow 1
                    margin-right 8px
                    box-sizing border-box
                    vector-editor-border-radius-xs()
                    .decrease,
                    .increase
                      background-color transparent
                      width 40px
                      height 100%
                      border 0 none
                    .offset_value
                      display inline-flex
                      width 0
                      height 100%
                      flex-grow 1
                      align-items center
                      justify-content center
                  .edit_manual
                    width 100px
                    height 100%
                    box-sizing border-box
                    vector-editor-border-radius-xs()
          .button_container
            width 100%
            height 56px
            display flex
            justify-content space-between
            box-sizing border-box
            padding 0 30px 0 30px
            button
              height 100%
              margin 0
              padding 0
              border 0 none
              width 100%
              &:last-child
                margin-right 0
              &.cancel
                background-color #FFFFFF
                box-sizing border-box
                border 1px solid #D5DBE0
                color #000000
                margin-right 8px
              &.done
                background-color #000000
                color #FFFFFF
.don_tab[tab_name="/modules/VectorEditor/Sticker/Single/PC/CuttingLineAuto/S/Mui/tab.js"]
  .description_container
    &::before
      mp-editor-etc-sticker()
.don_tab[tab_name="/modules/VectorEditor/Keyring/Free/PC/CuttingLineAuto/S/Mui/tab.js"]
.don_tab[tab_name="/modules/VectorEditor/AcrylicFigure/Free/PC/CuttingLineAuto/S/Mui/tab.js"]
  .wrapper_body_description_wrapper_icon
    mp-editor-etc-acrylic-hole()
.don_tab[tab_name="/modules/VectorEditor/AcrylicFigure/StandLeg/PC/S/Mui/tab.js"]
  .wrapper_body_description_wrapper_icon
    mp-editor-etc-acrylic-circle()
