// modules/OMP/Kit/Form/F/Style/Pc/index.styl
.omp_biz-application-form
  max-height 80vh
  overflow-y auto
  overflow-x hidden
  position relative
  .mt-0
    margin-top 0
  .mt-1
    margin-top ptr(1) !important
  h1.title
    {font_26_m_130}
  &--header
    display flex
    justify-content space-between
    width 100%
    padding-bottom ptr(16)
  .omp__form
    display grid
    grid-template-columns ptr(200) ptr(1400)
    margin-top ptr(40)
    padding ptr(0) ptr(16) ptr(16) ptr(0)
    gap ptr(24)
    &--section
      display flex
      gap ptr(50)
      margin-bottom ptr(40)
        .omp__form-content-left
          display flex
          flex-direction column
          {font_20_m_160}
    &--title
      {font_26_m_130}
    &--content
      display flex
      border-top ptr(1) solid #000
      padding-top ptr(16)
      gap ptr(24)
      img
        width 100%
      &--right
        &.info
          background #f6f6f6
          border-radius 16px
          padding ptr(24)
          flex-grow 1
          box-sizing border-box
          {font_16_r_150}
          section
            margin-bottom ptr(24)
      &--title
        {font_16_m_150}
        margin-bottom ptr(8)
      &--right
        width 50%
        img
          border-radius ptr(20)
        ul
          margin-top ptr(8)
      &--left
        width 50%
        img
            border-radius ptr(20)
      &--radio-box
        display flex
        padding-top ptr(8)
        padding-bottom ptr(16)
        height ptr(60)
        &-button
          display flex
          align-items flex-start
          width ptr(180)
          label
            flex-grow 1
            {font_16_m_150}
        &-desc
          {font_16_r_150}
      &--radio-wrapper
        position relative
        label
          display block
          padding ptr(1) 0 ptr(2) ptr(30)
          line-height inherit
          cursor pointer
          margin-top ptr(-1)
        input
          height ptr(20)
          width ptr(20)
          position absolute
          top 0
          left 0
          opacity 0
          cursor pointer
        input + label::before
          content ''
          height ptr(20)
          width ptr(20)
          background-color #fff
          border ptr(1.5) solid #000
          position absolute
          top 0
          left 0
          border-radius 50%
        input + label::after
          content ''
          width ptr(12)
          height ptr(12)
          position absolute
          top ptr(5.2)
          left ptr(5.3)
          border-radius 50%
          background-color #fff
        input:checked + label::after
          background-color #ff6b00
          transition 0.2s ease-in-out
    &--content.info
      ul
        padding-left 0
        list-style none
      .omp__form--content--left
        margin-top: -1rem
      .omp__form--content--left
        margin-top: -1rem
        {font_20_m_160}
    &--content.form-wrapper
      padding-top 0
    &--content:has(> .omp__form--file-wrapper)
      border none
      padding-top 0
    &--required
      display flex
      gap ptr(4)
      margin-top ptr(16)
      {font_16_m_100}
      &-oval
        background-color #FF6B00
        width ptr(4)
        height ptr(4)
        border-radius 50%
    &--form-list
      display flex
      flex-direction column
      width 100%
    &--form-group
      display flex
      flex-wrap wrap
      padding ptr(18)
    &--form-label
      display flex
      {font_16_m_150}
      flex-basis ptr(200)
      min-width ptr(200)
      flex-grow 1
      flex-shrink 0
      gap ptr(4)
    &--form-field
      flex 99999 0 20em
      max-width 100%
      input,
      textarea
        width 100%
        border none
        {font_16_r_150}
        outline-style none
      input::placeholder,
      textarea::placeholder
        color #858585
        {font_16_r_150}
      textarea
        height ptr(60)
        resize vertical
      input[type='date']
        cursor pointer
      input[type='date']::before
        content attr(data-placeholder)
        width 100%
        color: #858585
        {font_16_r_150}
      input[type='date']:focus::before
        display none
      input[type='date']:valid::before
        display none
    &--form-field:has(> .omp__form--content--radio-box-button)
      display flex
      {font_16_m_150}
    &--form-group:not(:last-child)
      border-bottom 1px solid #D8D8D8
    &--file-wrapper
      display flex
      align-items center
      justify-content space-between
      width 100%
      background-color #F6F6F6
      border-radius ptr(16)
      padding ptr(24)
      label
        flex-basis ptr(200)
        {font_16_m_150}
      label.omp__form--file-button
        height ptr(20)
        width ptr(20)
        margin-left auto
        flex-basis ptr(40)
        cursor pointer
        path
          stroke-width 1
      input
        opacity 0
        width 0
    &--file-wrapper.attached
      background-color #D8D8D8
    &--file-desc
      {font_16_r_150}
      color #858585
    &--privacy-wrapper
      display flex
      flex-direction column
      ul
        {font_14_r_160}
        margin-top 0
        margin-bottom ptr(40)
        padding-left ptr(8)
        list-style-position inside
    &--privacy-description
        {font_14_r_160}
        margin-bottom 0
    &--button
      width ptr(400)
      {font_16_m_100}
      padding ptr(12) ptr(40)
      color #fff
      cursor pointer
      background #000
      border-radius ptr(50)
      border none
    &--checkbox-wrapper
      position relative
      label
        display flex
        padding ptr(4) 0 ptr(2) ptr(32)
        {font_16_m_100}
        gap ptr(4)
      input
        height ptr(20)
        width ptr(20)
        position absolute
        top 0
        left 0
        opacity 0
      input + label::before
        content ''
        height ptr(20)
        width ptr(20)
        background-color #fff
        border ptr(2) solid #000
        border-radius ptr(1.5)
        position absolute
        top 0
        left 0
      input + label::after
        content ''
        border ptr(2) solid #000
        border-left 0
        border-top 0
        border-radius ptr(1.5)
        height ptr(10)
        width ptr(6)
        transform rotate(45deg)
        position absolute
        top ptr(4)
        left ptr(8)
        opacity 0
        transition opacity 0.2s ease-in-out
      input:checked + label::after
        opacity 1
      input:focus + label::before
        box-shadow 0 0 0 ptr(3) #000
        outline: ptr(3) solid transparent
