html.omp.product-list
  .omp-product-list__cate-lists
    display none

// 상품 필터
.omp-product-filter
  margin-top ptr(40px)
  width ptr(170px)
  &__header
    { font_14_b_100 }
    display flex
    justify-content space-between
    align-items center
    padding-bottom ptr(16px)
    border-bottom 1px solid BK

  &__reset-icon
    width ptr(14px)
    height ptr(14px)
    background none
    border none
    padding 0
    margin 0
    cursor pointer
    position relative

.omp-product-filter-list
  &__list
    border-bottom 1px solid GY_50
    padding ptr(4px) 0
    &[data-is_open="true"]
      .omp-product-filter-item__list
        display block
      .omp-product-filter-list__arrow
        svg
          transform rotate(90deg)
  &__name
    { font_14_m_100 }
    padding ptr(12px) 0
    display flex
    justify-content space-between
    align-items center
    cursor pointer
  &__select-count[data-count="0"]
    display none
  &__arrow
    width ptr(10px)
    height ptr(10px)
.omp-product-filter-item
  &__list
    display none
  &__item
    padding ptr(4px) 0
    margin-bottom ptr(12px)
    { font_14_m_100 }
  &__checkbox
    display flex
    align-items center
    position relative
  &__input-checkbox
    cursor pointer
    margin 0
    opacity 0
    width 100%
    height 100%
    position absolute
    z-index 1
  &__checkbox-box
    position relative
    display block
    width ptr(12px)
    height ptr(12px)
    margin-right ptr(8px)
    border-radius ptr(3px)
    border 1px solid BK
    box-shadow 0 0 1px BK
    &[data-checkbox-checked="true"]
      background-repeat no-repeat
      background-size ptr(8px) ptr(8px)
      background-position center
      background-image url("//s3.marpple.co/files/u_29089/2023/7/original/e0193dc81715eb4e315aa1b84d249963331069931.svg")
  &__color-item
    { font_14_m_100 }
    display inline-block
    vertical-align top
    width ptr(50px)
    overflow hidden
    margin-right ptr(10px)
    margin-bottom ptr(12px)
    position relative
    &:nth-child(3n)
      margin-right 0
  &__color-checkbox-box-wrapper
    width ptr(16px)
    height ptr(16px)
    margin 0 auto ptr(8px)
    flex-shrink 0
    border-radius 100%
    display flex
    align-items center
    justify-content center
    border 1px solid transparent
    &[data-checkbox-checked="true"]
      border-color OG
  &__color-checkbox-box
    width ptr(10px)
    height ptr(10px)
    flex-shrink 0
    border-radius 100%
    border 1px solid transparent
    &[data-color-code="#fff"]
      border-color GY !important
  &__color-checkbox-name
    width 100%
    text-overflow ellipsis
    overflow hidden
    white-space nowrap
    text-align center

// 상품 리스트쪽
.omp-product-list
  &__cate-lists
    margin-bottom ptr(24px)
  &__select
    { font_26_m_100 }
    display flex
    align-items center
  &__checked-cate-list
    { font_26_m_100 }
    background transparent
    border none
    padding 0
    margin 0
    display flex
    align-items center
    cursor pointer
  &__checked-cate-list-arrow
    width ptr(20px)
    height ptr(20px)
    margin 0 8px
  &__flex-space-between
    display flex
    justify-content space-between
    align-items flex-start
  &__cate-items
    display flex
    align-items center
    padding-top ptr(5)
    { font_16_m_100 }
  &__cate-item
    margin-right ptr(24px)
    margin-bottom ptr(24px)
    padding-bottom ptr(5)
    border-bottom ptr(2) solid transparent
    &--checked
      { font_16_b_100 }
      position relative
      color OG !important
      border-bottom ptr(2) solid OG

  &__checked-filter-items
    margin-bottom ptr(28)
    display flex
    flex-wrap wrap
    &[data-list_show="false"]
      display none
  &__checked-filter-item-init
  &__checked-filter-item
    background none
    border none
    padding 0
    margin 0 ptr(16) ptr(4) 0
    display flex
    align-items center
    justify-content space-between
    { font_14_m_100 }
    color GY_80
    cursor pointer
  &__checked-filter-item-x-icon-wrapper
    padding ptr(3)
    border-radius 100%
    background-color GY_80
    margin-left ptr(3)
    display flex
    align-items center
    justify-content center
  &__checked-filter-item-x-icon
    width ptr(7)
    height ptr(7)
    display flex
    align-items center
    justify-content center
  &__checked-filter-item-reset-icon
    width ptr(16)
    height ptr(16)
    margin-right ptr(4)
    path
      stroke GY_80

  &__sort
    position relative
    padding 0 0 ptr(20px)
    &:hover
      .omp-product-list__sort-selector
        display block
  &__checked-sort
    { font_16_b_100 }
    display flex
    align-items center
    cursor pointer
  &__checked-sort-triangle
    width ptr(9px)
    height ptr(9px)
    margin-left ptr(8px)
    display flex
    align-items center
  &__sort-selector
    display none
    position absolute
    left ptr(-16px)
    bottom ptr(-100px)
    padding ptr(8px) ptr(16px)
    border-radius ptr(16px)
    border 1px solid GY_50
    background WHT
    z-index 1
  &__sort-item
    { font_16_m_100 }
    display inline-block
    padding 0
    margin ptr(8px) 0
    background transparent
    border none
    white-space nowrap
    cursor pointer
  &__body
    min-height 80vh
  &__list
    display grid
    grid-template-columns repeat(4, minmax(0, 1fr))
    grid-gap ptr(40) ptr(8)
  &__no-list
    { font_26_m_100 }
    padding ptr(120) 0


.omp-product-cate-selector
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background rgba(0,0,0,0.5)
  display flex
  align-items center
  justify-content center
  z-index 2
  &__body
    display flex
    flex-direction column
    padding ptr(30px)
    border-radius ptr(20px)
    width fit-content
    height  fit-content
    background WHT
  &__title
    display flex
    align-items center
    justify-content space-between
    { font_26_m_130 }
    margin-bottom ptr(24)
  &__x-btn
    width ptr(20)
    height ptr(20)
    cursor pointer
    background transparent
    border none
    margin 0
    padding 0
  &__cate-lists
    overflow-x hidden
    overflow-y auto
    margin-bottom ptr(8)
    height ptr(820)
  &__cate-list
    margin-bottom ptr(22)
  &__cate-list-name
    { font_16_m_100 }
    margin-bottom ptr(16)
  &__cate-items
    display flex
    align-items center
    flex-wrap wrap
    margin-right ptr(-8)
  &__cate-item
    margin-right ptr(8)
    margin-bottom ptr(16)
    display flex
    position relative
    flex-shrink 0
  &__cate-item-radio
    cursor pointer
    margin 0
    opacity 0
    width 100%
    height 100%
    position absolute
    z-index 1
  &__cate-item-name
    border-radius ptr(50)
    border 1px solid GY_50
    padding ptr(10) ptr(14)
    { font_14_m_100 }
    &[data-radio-checked="true"]
      background-color OG
      border-color OG

@import "./type.styl"
