.dormant-return-wrapper
  display flex
  flex-direction column
  min-height 50vh
  .dormant-return
    flex 1 1 auto
    display flex
    flex-direction column
    align-items center
    justify-content center
    margin 0 auto
    width ptr(450)
    &__title
      width 100%
      padding 0
      margin 0
      {font_26_m_130}
    &__info
      width 100%
      display flex
      flex-direction column
      padding-bottom ptr(24)
    &__privacy
      box-sizing border-box
      width 100%
      display flex
      flex-direction column
      padding ptr(24)
      border-radius ptr(20)
      background-color BG_PRODUCT
      &__data
        display flex
        gap ptr(24)
      &__key
        width ptr(110)
        {font_14_m_140}
      &__value
        {font_14_r_140}
    &__password
      width 100%
      display flex
      flex-direction column
      gap ptr(16)
      &__title
        flex-shrink 0
        width ptr(100)
        {font_16_m_150}
      &__form-title
        {font_16_b_100}
    &__buttons
      margin-top ptr(14)
      display flex
      width 100%