// modules/NewMaker/ConditionAgreeText/F/Style/Pc/index.styl
.condition_agree_text
  width 100%
  box-sizing border-box
  background #fff
  //&.has_padding
  //  padding 0 40px 0
  //  html[marpplizer_mode="short"] &
  //    padding 0 30px 0
.condition_agree_text_wrapper
  position relative

  & p
    margin 8px 0

    &:last-child
      margin-bottom 16px

  & .text_wrapper
    display flex
    justify-content space-between

  & .warning_text
    color var(--BK, var(--kakao-logo, #000))
    font-family Pretendard
    font-size 14px
    font-style normal
    font-weight 700
    line-height 100%

  & .view_more
    color var(--OG, #FF6B00)
    font-family Pretendard
    font-size 14px
    font-style normal
    font-weight 500
    line-height 100%
    cursor pointer

.condition_agree_text_checkbox_wrapper
  border-top 1px solid #E8E8E8
  transform translateX(0%)
  padding-top 16px


@media screen and (max-width 1397px)
  .condition_agree_text_wrapper
    position relative

    & p
      margin 2px 0

    & .text_wrapper
      flex-wrap wrap

    & .view_more
      text-align right
      flex auto


//@media screen and (max-height 768px)
//  html.maker_page &
//    #maker_frame
//      #marpplizer
//        .activity
//          >.body
//            overflow-y scroll
//            scrollbar-width none
//            -ms-overflow-style none
//            &::-webkit-scrollbar
//              display none
