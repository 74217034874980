.don_frame[frame_name="mp.product.share.modal"]
  >.don_wrapper
    width ptr(480)
    display table
    height auto

  .don_back_page
    display none

  .share_product_modal_wrap
    padding ptr(30)
    display flex
    flex-direction column
    gap ptr(50)
    .share_product_modal
      font-weight 500
      &__title
        font-size ptr(26)
        line-height 130%
      p
        color GY
        font-size ptr(16)
        line-height 150%
        margin-bottom 0
      .go_to_DF
        color BK
        cursor pointer
    .share_product_modal_input
      &__title
        font-size ptr(14)
        font-weight 500
        line-height 140%
        svg
          vertical-align text-top
          padding-top ptr(3)
      &__wrap
        display flex
        height ptr(50)
        padding-right ptr(16)
        align-self stretch
        border-bottom 1px solid black
        align-items center
        img
          width 12.8px
          height 12.8px
        input
          width 100%
          outline none
          border none
          font-size ptr(16)
    .share_product_modal_buttons
      display flex
      gap ptr(8)
      justify-content space-between
      button
        font-size ptr(16)
        display flex
        height ptr(50)
        padding ptr(6) ptr(16)
        justify-content center
        align-items center
        flex 1 0 0
        border-radius 999px
        border 1px solid BK
      .cancel
        background WHT
      .create
        background #D8D8D8
        color WHT
        border none
        pointer-events none
      .active
        pointer-events auto
        background BK
      .done
        color WHT
  .input_detail
    margin-bottom 24px
    > input
      color #858585

