
body.my_review
  #body
    .don_tab[tab_name="mp.my_review"]
    .don_tab[tab_name="mp.review.list"]
      font-weight normal
      >.don_wrapper >.body
        .header_title
          font-weight bold
          line-height 1.2
          color color_black
        .header_tab
          display flex
          align-items center
          .tab
            text-align center
            width 50%
            border-bottom 1px solid #d5dbe0
            font-weight bold
            padding-bottom 16px
            a
              color color_gray3
            &.selected
              border-bottom 1px solid #000
        .header_my_review
          background-color #f5f5f5
          .info1
            font-size 13px
            line-height 1.08
            margin-bottom 8px
            span
              color #ea291f
          .info2
            font-size 12px
            color color_gray3
        .up_c_list[total_count="0"]
          .up_cs
            display none
          #not_found_results
            display block
            padding 80px 0
            margin 0 auto
            &:before
              background-image url(//s3.marpple.co/files/u_29089/2018/11/original/f_12446_1541496885022_M8F6LaVQO0hT1f3fg5glJ.png)
        .up_cs
          .up_c
            border-bottom 1px solid #e9ecef
            margin-top 24px
            padding-bottom 16px
            position relative
            .ordered_at
              margin-bottom 16px
              color color_gray3
              html[lang="en"] &
                font-family CircularStd
              span:before
                content ''
                width 1px
                height 10px
                margin 4px 8px
                display inline-block
                vertical-align top
                background-color #d5dbe0
            .img
              vertical-align top
              display inline-block
            .bp_info
              vertical-align top
              display inline-block
              .bp_name
                word-break keep-all
                word-wrap break-word
      @media screen and (min-width 1025px)
        position relative
        >.don_wrapper >.body
          max-width 992px
          margin auto
          padding 40px 0 120px 0
          min-height 424px
          .header_title
            font-size 24px
            margin 40px 0
          .header_tab
            .tab
              font-size 16px
          .header_my_review
            padding 24px
            margin-bottom 24px
          .up_cs
            .up_c
              .bp_info
                position absolute
                width auto
                left 124px
                top 0
                right 0
                .bp_name
                  position absolute
                  left 0
                  top 56px
                .review_info
                  float right
                  .write_a_review
                    position absolute
                    right 0
                    top 40px
                    padding 8px 39px
                    margin 0
                  div
                    position absolute
                    right 0
                    top 56px
      @media screen and (max-width 1024px)
        position relative
        >.don_wrapper >.body
          padding 0
          margin 40px 0 80px
          .header_title
            font-size 16px
            margin-bottom 24px
            text-align center
          .header_tab
            border-bottom 1px solid #d5dbe0
            box-sizing border-box
            .tab
              box-sizing border-box
              margin-bottom -1px
          .header_my_review
            padding 18px 16px
            margin-bottom 24px
            .info1
              margin-bottom 16px
            .info2
              line-height 1.83
          .up_cs
          .reviews_wrap
            padding 0 16px
          .bp_info
            position absolute
            bottom 24px
            right 0
            left 124px
            .bp_name
              margin-bottom 16px
            .review_info
              .write_a_review
                margin 0
