// Pc
.omp-search
  &__empty-result
    display flex
    flex-direction column
    gap ptr(16)
    margin-bottom ptr(38)
    &__title
      {font_26_m_100}
    &__description
      {font_16_m_100}
      color GY_80

  &__result-empty
    margin ptr(120) 0
  &__result-empty__title
    {font_26_m_100}

  &__result-title
    {font_26_m_100}

  /* 탭 네비게이션 */
  &__result-nav
    margin ptr(24) 0
  &__result-nav__items
    margin 0
    padding 0
    display flex
    gap ptr(24)
    list-style-type none
  &__result-nav__item
    > a, a:link, a:visited
      {font_16_m_100}
      color GY
  &__result-nav__item--active
    > a, a:link, a:visited
      {font_16_b_100}
      color BK

  &__result-wrap
    display flex
    flex-direction column
    gap ptr(64)

  /* 상품 */
  &__result-products
    display flex
    flex-direction column
    gap ptr(40)
  &__result-products__more-product
    text-align center
    {font_16_m_100}
    align-self center
    width ptr(400)
    height ptr(50)
    display flex
    justify-content center
    align-items center
    padding ptr(6) ptr(32) ptr(7) ptr(32)
    border ptr(1) solid BK
    border-radius ptr(999)
    box-sizing border-box
    background BK
    color WHT !important


  /* 컨텐츠 */
  &__result-contents
    display flex
    flex-direction column
    gap ptr(16)
  &__result-contents__header
    width 100%
    display flex
    justify-content space-between
    align-items center
  &__result-contents__title
    {font_26_m_100}
  &__result-contents__view-more
    {font_14_r_140}
  &__result-contents-tab
    .omp-search__result-contents__header
      display none

  /* 리뷰 */
  &__result-reviews
    display flex
    flex-direction column
    gap ptr(16)
  &__result-reviews__header
    width 100%
    display flex
    justify-content space-between
    align-items center
  &__result-reviews__title
    {font_26_m_100}
  &__result-reviews__view-more
    {font_14_r_140}
  &__result-reviews__body--non-infi
    .omp-review__contents
      gridColumns(2)
      grid-column-gap ptr(12)
      grid-row-gap ptr(8)

  &__result-reviews-tab
    .omp-search__result-reviews__header
      display none
