.don_frame[frame_name="/modules/OMP/Auth/ResetPassword/S/Mui/frame.js"]
  .omp-reset-password
    display flex
    flex-direction column
    padding-top ptr(24)
    box-sizing border-box
    gap ptr(40)
    width ptr(440)
    &__form
      display flex
      flex-direction column
      gap ptr(40)
    &__form-data
      display flex
      flex-direction column
      gap ptr(16)
    &__form-value
      display flex
      align-items center
      gap ptr(24)
      {font_16_m_150}
      border-bottom 1px solid BK
    &__form-key
      flex-shrink 0
    &__button
      width 100%
    &__button-wrapper
      display flex

