.mp_maker
  &[data-is_vector="true"]
    canvas.upper-canvas
      display none

.maker-product-info__bp-option-groups
  *
    box-sizing border-box
  span.head
    display block
    font-weight 500
    margin-bottom 6px
  .bp_option_group
    &:not(:first-child)
      margin-top 32px
  .bp_option_group[data-ui_type_pc="SELECT"]
  .bp_option_group[data-ui_type_pc="IMAGE_SELECT"]
    select
      &:focus-visible
        outline none
      width 100%
      font-size 15px
      cursor pointer
      padding 0 0 8px
      border none
      border-bottom 1px solid BK
      background no-repeat url(//s3.marpple.co/files/u_193535/2023/7/original/dcba01bcdbefff186a378596095099b9631d75d71.svg) right 0 bottom 40%
      background-size 12px 12px
      appearance none
      -moz-appearance none
      -webkit-appearance none
    select
      display block !important
    .select_box
      display none !important
  .bp_option_group[data-ui_type_pc="BUTTON"]
    select
      display none !important
    .select_box
      display flex !important
  .bp_option_group[data-ui_type_pc="CHECKBOX"]
    margin-top 16px
    .title
      margin-bottom 0
      label
        display flex
        gap 4px
        align-items center
        cursor pointer
        span
          display flex
  .bp_option_groups
    &[data-is_designed="true"]
      .bp_option_group
        &[data-is_lockable="true"]
          select
            opacity 0.3
            background transparent no-repeat url(//s3.marpple.co/files/u_193535/2021/5/original/93604cbd49a7593ed268f972cbc8a94e9208664d1.png) right 16px bottom 50%
  .bp_basic_info
    *
      font-size 20px
    margin-bottom 43px
    .name
      margin-bottom 8px
    .original_price
      margin-bottom 8px
      color OG
    .delivery_price
      font-size 14px
      *
        font-size 14px
  .bp_option_groups
    &[data-is_designed="true"]
      .bp_option_group[data-is_lockable="true"]
        .option:not(.selected)
          opacity 0.3
    .title
      font-size 14px
      font-weight 500
      margin-bottom 16px
      display flex
      align-items center
      justify-content start
      gap 4px
      label
      span
        display flex
    .select_box
      display flex
      flex-wrap wrap
      .option
        cursor pointer
        height 40px
        display flex
        justify-content center
        align-items center
        border 1px solid BK
        margin-right 8px
        border-radius 8px
        text-align center
        html[marpplizer_mode="short"] &
          width 80px
          &:last-child
            margin-right 0
        html[marpplizer_mode="basic"] &
          width 89px
          &:last-child
            margin-right 0
        html[marpplizer_mode="basic"] &
        html[lang="en"] &
          width 126px
          &:last-child
            margin-right 0
        &.selected
          color OG
          border 1px solid OG
    select
      display none
    .bp_option_group[data-is_visibility="true"]
      &:last-child
        margin-bottom 0
    .bp_option_group[data-is_visibility="false"]
      display none
  .additional-information
    display flex
    align-items center
    gap 4px
    span
      display flex
    .question_mark
      cursor pointer
