.omp-email-verify-request
  display flex
  flex-direction column
  align-items center
  gap ptr(24)
  margin-top ptr(24)
  width ptr(440)
  &__desc
    text-align center
    {font_16_m_150}
  &__notice
    text-align center
    {font_16_m_150}