// modules/OMP/GroupAndBizProduct/F/Style/Pc/index.styl
.omp-group-biz-products
  .omp-cell__product
    .omp-atom__badge[data-type="custom_badge"]
    .omp-atom__badge[data-type="moq"]
    .omp-atom__badge[data-type="bp_first"]
    .omp-atom__badge[data-type="sale"]
    .omp-atom__badge[data-type="new"]
    .omp-atom__badge[data-type="order_quantity"]
      display flex

.omp-group-biz-products
  padding 0
  .curation-keyword-item__image
    img
      border-radius 50%

  .omp-home__section-header > h3
    {font_26_m_100}
    scroll-margin-top ptr(150)
  section
    & > h3
      {font_26_m_130}
      margin-top 0
      margin-bottom ptr(16)
  .margin-top-60
    margin-top ptr(60)
  .swiper-wrapper
    .swiper-slide
      margin-right ptr(8)

  .omp-group-biz-products__sub-title
    position relative
    margin 0 0 ptr(16)
    {font_26_m_130}
    scroll-margin-top ptr(150)
    .hash
      position absolute
      top ptr(-190)

  .omp-group-biz-products__filters
    ul
    li
      list-style none
      margin 0
      padding 0
    .filter-groups
      margin 0
      padding ptr(24)
      border-radius ptr(20)
      background-color BG_PRODUCT
      {font_16_m_100}
      color BK
      display flex
      flex-direction column
      gap ptr(12)
      .filter-group-item
        display flex
        align-items center
        gap ptr(24)
        .title
          font-weight bold
          min-width ptr(140)
        ul.filter-items
          display flex
          gap ptr(8)
          overflow-x scroll
          -webkit-overflow-scrolling touch
          scrollbar-width none
          &::-webkit-scrollbar
            display: none
          li
            padding ptr(12) ptr(16)
            cursor pointer
            border-radius 999px
            display flex
            align-items center
            text-align center
            &.active
              background-color BK
              color WHT

      .line
        margin 0
        border-bottom 1px solid BG_MODEL
    .omp-group-biz-products__chips-and-sort-wrapper
      display flex
      justify-content space-between
      align-items center
      .sort-wrapper
        .omp-product-list__sort
          position relative
          padding-bottom ptr(20)
          margin ptr(32) ptr(0) ptr(12)
          &:hover
            .omp-group-biz-products__sort-selector
              display block
          .omp-product-list__checked-sort
            {font_16_m_100}
            display flex
            align-items center
            cursor pointer
          .omp-group-biz-products__checked-sort-triangle
            width ptr(9px)
            height ptr(9px)
            margin-left ptr(8px)
            display flex
            align-items center
          .omp-group-biz-products__sort-selector
            display none
            position absolute
            left ptr(-16px)
            bottom ptr(-100px)
            padding ptr(8px) ptr(16px)
            border-radius ptr(16px)
            border 1px solid GY_50
            background WHT
            z-index 1
          .omp-group-biz-products___sort-item
            { font_16_m_100 }
            display inline-block
            padding 0
            margin ptr(8px) 0
            background transparent
            border none
            white-space nowrap
            cursor pointer
      .omp-group-biz-products__selected-filter-chips-wrapper
        display flex
        align-items center
        gap ptr(16)
        .selected-filter-chips
          display flex
          align-items center
          gap ptr(16)
        .omp-group-biz-products__reset-btn
          {font_14_m_100}
          height ptr(14)
          background none
          border none
          padding 0
          display flex
          justify-content space-between
          align-items center
          gap ptr(4)
          icon
            width ptr(14)
            height ptr(14)
            svg
              width ptr(14)
              height ptr(14)
        .omp-group-biz-products__selected-active-btn
          height ptr(14)
          {font_14_m_100}
          background none
          border none
          padding 0
          display flex
          justify-content space-between
          align-items center
          gap ptr(4)
          color GY_80
          svg
            width ptr(14)
            height ptr(14)
        .omp-group-biz-products__selected-active-btn-x-icon
          height ptr(14)

  .omp-group-biz-products__list-wrapper
    .omp-group-biz-products__list
      display grid
      grid-template-columns repeat(4, minmax(0, 1fr))
      grid-gap ptr(40) ptr(8)
      padding-bottom ptr(24)
      .omp-group-biz-products__no-list
        {font_26_m_130}
        padding: ptr(64) 0 ptr(56)
    .has-more-area
      display flex
      justify-content center
      align-items center
      margin-top
      .omp-group-biz-products__has-more-btn
        background none
        border 1px solid BK
        border-radius 999px
        padding ptr(6) ptr(32)
        display flex
        justify-content center
        align-items center
        height ptr(40)
        margin-top ptr(40)
        {font_16_m_100}
