.don_frame[frame_name="/modules/OMP/Auth/FindID/S/Mui/frame.js"]
  .omp-find-id
    display flex
    flex-direction column
    padding-top ptr(40)
    box-sizing border-box
    gap ptr(40)
    width ptr(440)
    &__data
      display flex
      flex-direction column
      gap ptr(16)
    &__sub-title
      {font_16_b_100}
    &__form-value
      display flex
      align-items center
      gap ptr(24)
      {font_16_m_150}
      border-bottom 1px solid BK
      &[data-requested="false"]
        display none
    &__form-key
      min-width ptr(42)
      flex-shrink 0
      white-space nowrap
    &__form-button
      flex-shrink 0
      width ptr(109)
    &__button
      width 100%
    &__button-wrapper
      display flex
    &__timer-wrapper
      color OG





