
.vector-editor__single-editor
  vector-editor-frame()
  user-select none
  position absolute
  left 0
  right 0
  top 0
  height 0
  > .don_wrapper
    position relative
    width 100%
    > .body
      position relative
      width 100%
  .vector-editor__single-editor-page.don_page
    position absolute
    width 100%
    > .don_wrapper
      position relative
      width 100%
      > .body
        position relative
        width 100%
    .don_tab
      user-select none
      position relative
      width 100%
      > .don_wrapper
        position relative
        width 100%
        height 100%
        display flex
        .left_container
          position relative
          width 0
          flex-grow 1
          height 100%
          background-color #FFFFFF
          .editor_container
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            background-color #F8F9FA
          .foreground_container
            position absolute
            top 0
            left 0
            width 0
            height 0
            overflow visible
            .size_guide_container
              position absolute
              top 0
              left 0
              width 0
              height 0
              overflow visible
              .size_guide_container_value
                visibility hidden
                position absolute
                top 0
                left 0
                display inline-flex
                width 200px
                height 20px
                align-items center
                justify-content center
                background-color #474748
                color #fff
                border-radius 16px
          .top_menu_container
            position absolute
            top 0
            left 0
            right 2px
            height 66px
            background-color #FFFFFF
            top-menus()
          .left_menu
            position absolute
            right 22px
            display inline-flex
            flex-direction column
            width 64px
            height 73px
            align-items center
            justify-content space-between
            transition left ease-in-out 200ms
            &:hover
              .icon
                background-color OG
            &[data-is_show="false"]
              left -130px
            &[data-icon_type="upload_image"]
              top calc(50% + 33px - 42px - 16px - 84px - 16px - 84px)
              .icon
                mp-editor-icon-upload()
            &[data-icon_type="my_image"]
              top calc(50% + 33px - 42px - 16px - 84px)
              .icon
                mp-editor-icon-my-image()
            &[data-icon_type="shape"]
              top calc(50% + 33px - 42px)
              .icon
                mp-editor-icon-design()
            &[data-icon_type="path"]
              top calc(50% + 33px - 42px + 84px + 16px)
              .icon
                mp-editor-icon-path()
            &[data-icon_type="text"]
              top calc(50% + 33px - 42px + 84px + 16px + 84px + 16px)
              .icon
                mp-editor-icon-text()
            .icon
              display inline-flex
              width 50px
              height 50px
              align-items center
              justify-content center
              border-radius 50%
              border 0 none
              background-color #FFFFFF
              background-size 28px 28px
              background-repeat no-repeat
              background-position center center
              padding 0
              margin 0
              cursor pointer
              &:active
                background-color OG
            .label_text
              overflow visible
              font-size 12px
              white-space nowrap
          .layer_container
            width 288px
            height 476px
            display flex
            flex-direction column
            position absolute
            top calc(50% - 185px)
            left 0
            background-color #FFFFFF
            box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.1)
            border-radius 10px
            overflow hidden
            &[data-is_show="false"]
              display none
            .header
              width 100%
              height 46px
              display flex
              align-items center
              justify-content space-between
              padding 0 16px
              box-sizing border-box
              border-bottom 1px solid #E9ECEF
              .title
                font-size 14px
                font-weight bold
              .close_layer
                width 15px
                height 15px
                margin 0
                padding 0
                border 0 none
                background-color transparent
                background-repeat no-repeat
                background-size 15px 15px
                background-position center center
                background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-close-15-px@2x.png")
                display inline-flex
                justify-content center
                align-items center
                cursor pointer
            .list_container
              position relative
              width 100%
              height 0
              flex-grow 1
              box-sizing border-box
              padding-top 16px
              padding-bottom 16px
              overflow auto
              &[data-count="0"]
                display none
              .layer_item
                position relative
                width 100%
                height 48px
                box-sizing border-box
                background-color #FFFFFF
                display flex
                align-items center
                cursor pointer
                .image_container
                  width 40px
                  height 40px
                  box-sizing border-box
                  border-radius 2px
                  border solid 1px #d5dbe0
                  margin-right 8px
                  margin-left 16px
                  background-color #FFFFFF
                  background-repeat no-repeat
                  background-size 40px 40px
                  background-position center center
                  background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/img-layer@2x.png")
                .label_container
                  width 0
                  height 100%
                  flex-grow 1
                  display flex
                  align-items center
                .button_container
                  width 50px
                  height 100%
                  display flex
                  align-items center
                  justify-content space-between
                  margin-right 16px
                  button
                    width 25px
                    height 25px
                    padding 0
                    margin 0
                    border 0 none
                    background-color transparent
                    background-repeat no-repeat
                    background-size 12px 12px
                    background-position center center
                    cursor pointer
                &[data-is_selected="true"]
                  vector-editor-light-selected()
                &[data-is_selected="false"]
                  background-color #ffffff
                &[data-is_visible="true"]
                  .button_container
                    .visible
                      background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-visible@2x.png")
                &[data-is_visible="false"]
                  .button_container
                    .visible
                      background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-hide@2x.png")
                &[data-is_locked="true"]
                  .button_container
                    .lock
                      background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-locked@2x.png")
                &[data-is_locked="false"]
                  .button_container
                    .lock
                      background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-unlocked@2x.png")
      .right_container
        @keyframes hide_to_right {
          from {
            opacity 1
            transform translateX(0)
            visibility visible
          }
          to {
            opacity 0
            transform translateX(20%)
            visibility hidden
          }
        }
        @keyframes hide_to_left {
          from {
            opacity 1
            transform translateX(0)
            visibility visible
          }
          to {
            opacity 0
            transform translateX(-20%)
            visibility hidden
          }
        }
        @keyframes show_to_right {
          from {
            opacity 0
            transform translateX(-20%)
          }
          to {
            opacity 1
            transform translateX(0)
          }
        }
        @keyframes show_to_left {
          from {
            opacity 0
            transform translateX(20%)
          }
          to {
            opacity 1
            transform translateX(0)
          }
        }
        vector-editor-right_container()
        overflow hidden
        &[data-show_panel_wrapper="left"]
          .panel_wrapper[data-panel_wrapper="left"]
            animation-name show_to_right
          .panel_wrapper[data-panel_wrapper="right"]
            animation-name hide_to_right
        &[data-show_panel_wrapper="right"]
          .panel_wrapper[data-panel_wrapper="left"]
            animation-name hide_to_left
          .panel_wrapper[data-panel_wrapper="right"]
            animation-name show_to_left
        .right_wrapper
          width 100%
          height 100%
          display flex
          flex-direction column
          box-sizing border-box
          padding 40px 0 32px 0
          background-color #FFFFFF
          overflow hidden
          .panel_container
            width 100%
            height 0
            flex-grow 1
            position relative
            .panel_wrapper
              position absolute
              width 100%
              height 100%
              overflow hidden
              animation-duration 200ms
              animation-timing-function ease-in-out
              animation-iteration-count 1
              animation-fill-mode forwards
              display flex
              flex-direction column
              .inner_wrapper
                width 100%
                height 0
                flex-grow 1
                position relative
                @media screen and (max-width vector-editor-short-version-width)
                  setting-background-right-panel(24px, 30px)
                @media screen and (min-width vector-editor-short-version-min-width)
                  setting-background-right-panel(28px, 40px)
                .right_panel_home
                  position absolute
                  width 100%
                  height 100%
                  background-color #FFFFFF
                  display flex
                  flex-direction column
                  box-sizing border-box
                  padding 0 40px 0 40px
                  .header
                    width 100%
                    height 56px
                    display flex
                    flex-direction column
                    align-items flex-start
                    justify-content space-between
                    font-size 20px
                    font-weight normal
              .button_container
                position relative
                width 100%
                height 56px
                display flex
                justify-content space-between
                box-sizing border-box
                padding 0 40px 0 40px
                button
                  height 100%
                  margin 0
                  padding 0
                  border 0 none
                  width 100%
                  margin-right 8px
                  &:last-child
                    margin-right 0
                  &[data-is_show="false"]
                    display none
                  &.cancel
                    background-color #FFFFFF
                    box-sizing border-box
                    border 1px solid #D5DBE0
                    color #000000
                  &.next
                    background-color #000000
                    color #FFFFFF

