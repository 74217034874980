.omp-brand-item
  display flex
  background BG_PRODUCT
  border-radius ptr(20)
  overflow hidden
.omp-brand-img
  width 100%

.omp-home
  &-wrapper
    max-width 100%
  .swiper-slide
    margin-right var(--gap-8px)
    &:last-child
      margin-right 0
  &__section
    position relative
    margin-bottom ptr(60)
  &__section-header
    display flex
    justify-content space-between
    align-items center
    margin-bottom ptr(16)
    h3
      margin 0
      {font_26_m_100}
      color #000
    a
      margin 0
      {font_14_r_140}
      color #000
  //&__section-body

  &__curation-pod
    .swiper-slide
      width calc((100% - (var(--gap-8px) * 3)) / 4)
  &__curation-keyword
    display flex
    justify-content flex-start
    align-items flex-start
    gap ptr(32)
    > div
      flex-basis ptr(140)
  &__curation-biz
    .swiper-slide
      width calc((100% - (var(--gap-8px) * 2)) / 3)
  &__curation-products
    .swiper-container
      margin 0 -4px
    .swiper-slide
      width 25%
      margin-right 0
      .omp-cell__product
        width auto
        margin 0 4px
    .omp-swiper-prev, .omp-swiper-next
      top calc(var(--product-height) / 2)
  &__reviews
    .swiper-slide
      width calc((100% - (var(--gap-8px) * 3)) / 4)
    .omp-swiper-prev, .omp-swiper-next
      top calc(var(--content-height) / 2)
  &__contents
    .content-item
      width calc((100% - (var(--gap-8px) * 3)) / 4)
    .omp-swiper-prev, .omp-swiper-next
      top calc(var(--content-height) / 2)
  &__brand
    .brand-item
      width calc((100% - (var(--gap-8px) * 9)) / 10)
    .omp-swiper-prev, .omp-swiper-next
      top 50%
  &__categories
    position relative
    height ptr(250)
    margin-bottom ptr(80)
    .swiper-wrapper
      position absolute
    .categories-item
      width fit-content
      margin-right var(--gap-8px)
  &__keyword
    margin-bottom ptr(80)
    { font_26_m_100 }
    display flex
    align-items center
    justify-content flex-start
    gap ptr(16)
    flex-wrap wrap
    row-gap ptr(20)
  &__keyword-item
    color BK
    &:last-child
      .comma
        display none

  &__end
    opacity 1

  &__product-view-more
    display flex
    flex-direction column
    align-items center
    justify-content center
    aspect-ratio 3/4
    border-radius ptr(20)
    border 1px solid GY_50
    { font_20_m_140 }
    color OG !important
    margin 0 4px
  &__product-view-more-atom
    width ptr(28)
    height ptr(28)
    margin-bottom ptr(8)
    svg path
      stroke-width 1


:root {
  --gap-8px ptr(8)
  --content-height ptr(400)
  --product-height ptr(500)
}
