button =
  {font_16_m_100}
  justify-content center
  align-items center
  padding ptr(12) ptr(40)
  color #fff
  cursor pointer
  background #000000
  border-radius ptr(50)
  border none

br[data-mobile-only]
  display none

.omp-aboutKitHTML
  .omp
    &__intro
      display flex
      justify-content flex-start
      align-items center
      .omp__image
        width 50%
        aspect-ratio 810 / 500
        margin-right ptr(40)
        flex-shrink 0
        img
          width 100%
      .omp__content
        h3
          {font_26_m_130}
          margin 0 0 ptr(16)
        p
          {font_16_r_150}
          min-height ptr(120)
          margin 0 0 ptr(16)
          strong
            {font_16_m_150}
        a
          {font_16_m_100}
          {button}
    &__section
      margin-top ptr(80)
      img
        max-width 100%
    &__section-header
      {font_26_m_130}
      margin 0 0 ptr(16)
      &--between
        display flex
        justify-content space-between
        align-items center
      &--center
        text-align center
      &--more
        {font_14_r_140}
    &__section-sub-header
      {font_16_m_150}
    &__flex-start
      display flex
      justify-content flex-start
      align-items flex-start
    &__image
      position relative
      font-size 0
      border-radius ptr(40)
      overflow hidden
      img
        width 100%
      span
        {font_16_m_100}
        {button}
        text-align center
        position absolute
        bottom ptr(20)
        left ptr(20)
        right ptr(20)
        display none
        z-index 2
      &:hover span
        display block
      & + omp__text
        margin-top ptr(10)
    &__video
      border-radius ptr(40)
      overflow hidden
      aspect-ratio 806 / 450
      video
        width 100%
    &__text
      margin 0
      {font_16_r_150}
      &--strong
        margin 0
        {font_16_m_150}
        span
          margin-right ptr(16)
      &--num
        flex-shrink 0
        width ptr(24)
    &__grid
      &.grid--2
        gridColumns(2)
        column-gap ptr(10)
        &.between-end
          align-items flex-end
        .omp__section-header
          limitLine(2)
        .omp__image
          aspect-ratio 806 / 450
        .omp__text
          margin ptr(10) 0 0
        .omp__text--strong
          margin ptr(10) ptr(16) 0 0
          white-space nowrap
      &.grid--3
        gridColumns(3)
        column-gap ptr(8)
        .omp__image
          aspect-ratio 535 / 350
        .omp__text
          display flex
          justify-content flex-start
          align-items flex-start
          margin ptr(8) 0 0
          {font_16_m_150}
          span
            width ptr(80)
            white-space nowrap
            margin-right ptr(16)
      &.grid--4
        gridColumns(4)
        column-gap ptr(8)
        .omp__image
          aspect-ratio 1/1
        .omp__text--strong
          margin ptr(10) 0 0
        .omp__text
          margin ptr(8) 0 0
    &__marquee
      margin-top ptr(40)
      &-group
        display flex
        justify-content center
        margin 0 0 ptr(8)
        font-size 0
        column-gap ptr(8)
        img
          width ptr(154)
          height ptr(154)
          object-fit cover
      &.companies
        .company
          display flex
          justify-content center
          align-items center
          width 100%
          max-width ptr(154)
          aspect-ratio 1/1
          background #FFFFFF
          border 1px solid #E8E8E8
          border-radius ptr(20)
          overflow hidden
          img
            width 110% !important
            aspect-ratio 1/1
            max-width none !important
            height auto !important
            flex-shrink 0
            object-fit contain
            overflow visible
    &__apply.container
      display grid
      position relative
    &__apply
      margin-top ptr(60)
      .content
        display flex
        align-items center
        justify-content center
        flex-direction column
        gap ptr(24)
        text-align center
        {font_26_m_130}
        grid-area 1/2
        button
          {font_16_m_100}
          {button}
      img
        border-radius ptr(20)
        width 100%
        grid-area 1/2
    &__goods
      &--buttons
        display flex
        align-items center
        justify-content center
        margin-top ptr(40)
        column-gap ptr(16)
        .btn
          {button}
          text-align center
          min-width ptr(260)
          box-sizing border-box
        .btn.mail
          box-sizing border-box
          white-space nowrap
          color #000
          background #fff
          border 1px solid #000
      .omp__section-sub-header
        text-align center
        margin-bottom ptr(40)
        //height ptr(72)
    &__faq
      &-list
        margin-top ptr(40)
        padding 0
        list-style none
      &-item
        position relative
        list-style-type none
        margin 0
        padding ptr(32) 0
        border-top 1px solid #000
      &-q
        {font_20_m_140}
        list-style none
        cursor pointer
        display flex
        align-items center
        justify-content space-between
        .icon
          display block
          width ptr(20)
          height ptr(20)
          background-position center center
          background-repeat no-repeat
          background-image url(//s3.marpple.co/files/u_1504988/2023/6/original/ca44478a4312c23f3216b9f610d9a20a9562cfd01.png)
      &-a
        list-style none
        margin ptr(16) 0 0
        p, ul
          list-style-type '- '
          {font_16_r_150}
          margin 0
      [data-details][open]
        .omp__faq-q
          {font_20_b_140}
          .icon
            background-image url(//s3.marpple.co/files/u_1504988/2023/6/original/7d298b1afc7f8d3e1f78bb90ed0b6516542e853d2.png)
      details summary::-webkit-details-marker
        display none
    &__group-items
      gridColumns(3)
      gap ptr(16) ptr(8)
    &__recommend
      &-list
        display grid
        gridColumns(2)
        gap ptr(10)
      &-info
        display flex
        margin-top ptr(16)
      &-left
        flex-basis 20%
      &-title
        font-weight 700
      &-right
        display flex
        flex-direction column
      &-summary
        {font_16_m_150}
      &-desc
        {font_16_r_150}
        margin-top ptr(8)
      &-point
        {font_16_m_150}
        margin-top ptr(24)
        ol
          list-style-position inside
          {font_16_r_150}
          margin-top ptr(8)
          padding-left 0
    &__kit-intro
      display flex
      justify-content flex-start
      align-items center
      .omp__image
        width 50%
        aspect-ratio 810 / 500
        margin-right ptr(40)
        flex-shrink 0
        img
          width 100%
      .omp__content
        h3
          {font_26_m_130}
          margin 0 0 ptr(16)
        p
          {font_16_r_150}
          min-height ptr(120)
          margin 0 0 ptr(16)
          strong
            {font_16_m_150}
        a
          {font_16_m_100}
          {button}

  // about 페이지
  &.about
    .omp__section--marquee
      padding ptr(60) 0
    .omp__reviews
      .review-item
        width calc((100%-(8px*3))/4)
        margin-right 8px
      .omp-swiper-prev, .omp-swiper-next
        top ptr(179)



  // kit 페이지
  &.kit
    .omp__section--marquee
      padding ptr(40) 0

  // 퀄리티 페이지
  &.quality
    .omp__intro
      .omp__content
        height auto
    .omp__section.brand
      text-align center
      .omp__section-desc
        {font_16_m_150}
        margin 0 0 ptr(16)
      .brand-item
        width calc((100%-(8px*9))/10)
        margin-right 8px
    .contents
      .omp__grid
        gap ptr(40) ptr(8)
      .omp__text
        display flex
        justify-content flex-start
        align-items flex-start
        > span {
          min-width ptr(80)
          flex-shrink 0
          margin-right ptr(16)
          white-space break-spaces
        }
        > div
          {font_16_r_150}
          p
            margin 0
            min-height ptr(100)
          ol
            list-style none
            margin 0
            padding 0
          li
            list-style-type none
            margin 0
            &.label
              {font_16_m_150}
              margin-bottom ptr(8)

  .group-order-item
    display flex
    align-items flex-start
    gap ptr(16)
    .image
      width ptr(260)
      aspect-ratio 260 / 325
      flex-shrink 0
      img
        height 100%
        width 100%
        object-fit cover
    .text
      h4
        {font_20_m_140}
        margin 0 0 ptr(8)
      p
        {font_14_r_140}
        margin 0 0 ptr(16)
      a
        {font_16_m_100}
        display inline-flex
        align-items center
        height ptr(38)
        border-radius ptr(40)
        border 1px solid #000
        padding ptr(5) ptr(32) ptr(6)
        box-sizing border-box

  .group-banner
    margin-top ptr(80)
    position relative
    height ptr(200)
    display flex
    justify-content center
    align-items center
    .image
      position absolute
      inset 0
      text-align center
      border-radius ptr(20)
      overflow hidden
      z-index 1
      &:after
        content: ''
        position absolute
        inset 0
        background rgba(0,0,0,0.5)
        z-index: 2
      img
        position relative
        height 100%
        user-select none
        z-index: 1
    .text
      position relative
      text-align center
      color #fff
      z-index 2
      h4
        {font_26_m_130}
        margin 0 0 ptr(8)
      a
        {font_15_m_150}
        display inline-flex
        align-items center
        height ptr(32)
        padding 0 ptr(24)
        background #FF6B00
        border-radius ptr(20)
        margin-bottom ptr(8)
      p
        {font_14_r_140}
        margin 0


