html.shinhanbrand
  #don_up
  #ch-plugin
    display none !important

.shinhan-brand-event
  width 100%
  img
    vertical-align top
  &__img
    img
      width 100%
      max-width 100%
    &--mt-4
      margin-top ptr(-4)
  &__youtube
    position relative
    padding-bottom 56.25%
    height 0
    overflow hidden
    iframe
    object
    embed
      position absolute
      top 0
      left 0
      width 100%
      height 100%
  &__btns
    position fixed
    left 0
    right 0
    text-align center
    bottom ptr(100)
  &__btn
    display inline-block
    resetButton()
    img
      width ptr(357)




.shinhan-brand-event------end
  width 100%