html[lang="kr"]
  .help_center_area .help_center_wrap
    .help_tab
      ul li
        width 33.3%
    &[type="order_pay_kr"]
    &[type="lf_order_pay_kr"]
      .help_tab li:nth-child(1)
        border-bottom-color #000
        a
          color color_black
      .hg_list.make_group
      .hg_list.image_edit
        display none
    &[type="make_group_kr"]
    &[type="lf_make_group_kr"]
      .help_tab li:nth-child(2)
        border-bottom-color #000
        a
          color color_black
      .hg_list.order_pay
      .hg_list.image_edit
        display none
    &[type="image_edit_kr"]
    &[type="lf_image_edit_kr"]
      .help_tab li:nth-child(3)
        border-bottom-color #000
        a
          color color_black
      .hg_list.order_pay
      .hg_list.make_group
        display none
html[lang="en"]
  .help_center_area .help_center_wrap
    &[type="buying_shipping"]
      .help_tab li:nth-child(1)
        border-bottom-color #000
        a
          color color_black
      .hg_list.creating
        display none
    &[type="creating_selling"]
      .help_tab li:nth-child(2)
        border-bottom-color #000
        a
          color color_black
      .hg_list.buying
        display none
html[lang="jp"]
  .help_center_area .help_center_wrap
    .help_tab
      ul li
        width 33.3%
        a
          height ptr(25)
    &[type="order_pay_jp"]
      .help_tab li:nth-child(1)
        border-bottom-color #000
        a
          color color_black
      .hg_list.make_group
      .hg_list.image_edit
      .hg_list.image_copy
        display none
    &[type="make_group_jp"]
      .help_tab li:nth-child(2)
        border-bottom-color #000
        a
          color color_black
      .hg_list.order_pay
      .hg_list.image_edit
      .hg_list.image_copy
        display none
    &[type="image_edit_jp"]
      .help_tab li:nth-child(3)
        border-bottom-color #000
        a
          color color_black
      .hg_list.order_pay
      .hg_list.make_group
      .hg_list.image_copy
        display none
    &[type="image_copy_jp"]
      .help_tab li:nth-child(4)
        border-bottom-color #000
        a
          color color_black
      .hg_list.order_pay
      .hg_list.make_group
      .hg_list.image_edit
        display none

.help_center_area .help_center_wrap
  position relative
  width ptr(992)
  margin ptr(80) auto 0
  h2
    margin 0
    padding 0
    font-size ptr(32)
    font-weight normal
    line-height 1.19
    color color_black
    text-align center
    html[lang="en"] &
      font-family CircularStd
  .help_tab
    ul
      margin ptr(12) 0 0
      padding 0
      list-style none
      li
        display inline-block
        width 50%
        border-bottom 1px solid #d5dbe0
        a
          display inline-block
          padding ptr(22) 0
          width 100%
          text-align center
          font-size ptr(20)
          color color_gray3
  .help_center_cont
    position relative
    padding-top ptr(80)
    padding-left ptr(280)
    width ptr(992)
    min-height ptr(500)
    margin 0 auto ptr(100)
    box-sizing border-box
  .help_center_menu
    position absolute
    top ptr(80)
    left 0
    width ptr(200)
    color color_black
    h2
      margin 0 0 ptr(10)
      padding 0 0 ptr(7)
      font-size ptr(16)
      line-height 1.5
      border-bottom ptr(2) solid #000
    .hg_list
      margin 0 0 ptr(38)
      padding 0
      list-style none
      li
        font-size ptr(14)
        line-height 2.29
        margin-bottom ptr(2)
        a
          display inline-block
          padding-right ptr(10)
        &[selected="true"]
          font-weight bold
          a
            background url(//s3.marpple.co/files/u_14355/2018/10/original/f_12272_1540822501866_MELM4ZFniW3ASf2nUa.png) no-repeat 100% 52%
            background-size ptr(4) auto
    .send_email
      a
        display block
        padding-left ptr(50)
        box-sizing border-box
        background #fafafa url(//s3.marpple.co/files/u_14355/2018/10/original/f_12324_1540871092967_pIbO0pQ2k2Eq0xP3pCcB.png) no-repeat ptr(16) ptr(17)
        background-size ptr(18) auto
        {font_14_r_140}
        height ptr(48)
        line-height ptr(48)
    .live_chat
      margin-top ptr(8)
      span
        display block
        padding-left ptr(50)
        box-sizing border-box
        cursor pointer
        background #fafafa url(//s3.marpple.co/files/u_14355/2018/10/original/f_12323_1540871092967_FR9N8WBsmDDD7T7u6dg9z.png) no-repeat ptr(16) ptr(17)
        background-size ptr(18) auto
        {font_14_r_140}
        height ptr(48)
        line-height ptr(48)
  .help_center
    .visual
    .help_group_type
      display none
    .pc_title
      h2
        margin 0 0 ptr(30)
        padding 0
        font-size ptr(20)
        font-weight bold
        color color_black
    .helps
      .back
        display none
      .name
        margin-bottom ptr(14)
        line-height 1.71
        font-size ptr(20)
        font-weight normal
        color color_black
      .help_list
        margin 0 0 ptr(50)
        padding 0
        list-style none
        .help_item
          border-bottom 1px solid #d5dbe0
          &[selected="true"]
            .question
              background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_5968_1539181296743_L6VWH5tH7d7E5s2u2a0TR0mA.png)
            .answer
              display block
          .question
            padding ptr(20) ptr(16) ptr(19)
            background url(//s3.marpple.co/files/u_18053/2018/10/original/f_5967_1539181292187_BzObFQ8cH7bFS4X1vL6F.png) no-repeat
            background-size ptr(8) ptr(8)
            background-position 0 ptr(25)
            cursor pointer
            font-size ptr(14)
            font-weight normal
            color color_black
            p
              margin 0
              padding 0
          .answer
            display none
            font-size ptr(14)
            font-weight normal
            font-style normal
            font-stretch normal
            line-height 1.71
            color color_black !important
            padding ptr(20) ptr(16) ptr(40)
            table
              margin ptr(20) 0
              width 100%
              table-layout fixed
              border-collapse collapse
              text-align center
              th
              td
                padding ptr(11) 0
                color #333
                font-size ptr(12)
                border ptr(1) solid #e9ecef
                font-weight 200
              th
                font-weight bold
                text-align center
                color color_black
                background-color #fafafa
              td
                color color_black
            img
              max-width 100%
            img[alt="ship_internationally"]
              max-width 100%
            img[alt="ship_internationally_m"]
              display none
            img[alt="help_pc_img"]
              max-width 100%
            img[alt="help_m_img"]
              display none
            *
              color color_black !important
            ul
            ol
              margin 0
              padding 0 0 0 ptr(17)
              li
                padding-left ptr(13)
            h1
            h2
            h3
            h4
            h5
            h6
            p
            ol
            ul
              margin-top 0
              margin-bottom ptr(10)
            ul
              padding 0 0 0 ptr(8)
              list-style none
              li
                padding-left ptr(8)
                text-indent ptr(-8)
                &:before
                  content '∙ '
                  display inline-block
                  font-size ptr(12)
                  vertical-align top
                  line-height ptr(22)
    .other_questions
      h3
        margin 0 0 ptr(16)
        padding 0
        font-size ptr(14)
        font-weight bold
        line-height 1.71
        letter-spacing normal
        color color_black
      .image
        margin-top ptr(25)
        img
          max-width 100%
          &.is_mobile
            display none
      p
        margin 0
        padding 0
        font-size ptr(14)
        font-weight normal
        line-height 1.71
        color color_black
        a
          color color_active

html.marpple.omp
  .help_center_area .help_center_wrap
    margin-top 0
  @media screen and (max-width 768px)
    .help_center_area .helps
      padding 0 ptr(16) ptr(20)
    .help_center_area .helps .back
      top ptr(4)
      left ptr(16)