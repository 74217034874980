product_detail_tabs_container_active = {
  color: #111,
  border-bottom: ptr(1) solid #111,
  font-weight: 600
}

.product_detail_tabs_container
  max-width ptr(1440)
  margin ptr(100) auto 0
  .product_detail_tab_body:not([data-tab_name="STORY_TAB"])
    margin ptr(40) auto 0
  .product_detail_tab_body[data-tab_name="STORY_TAB"]
    margin ptr(8) auto 0
  html.creator &
    padding-bottom ptr(60)
  .review_header
    border-bottom none !important
  .product_detail_tabs
    height ptr(45)
    background #fff
    .wrapper
      display flex
      justify-content space-between
      background #fff
      height ptr(45)
      align-items center
      width 100%
      max-width ptr(1440)
      margin 0 auto
  .product_detail_tab
    cursor pointer
    margin 0
    height 100%
    background none
    border-top none
    border-bottom ptr(1) solid #d5dbe0
    border-right none
    border-left none
    color #828282
    font-size ptr(15)
    flex 1
    &[data-is_hidden="true"]
      display none !important
  .product_detail_tab_body
    .mp_review_list
      width 100%
    .bp_info_pop_buttons
      html.design_collection_page &
        display none
    &[data-is_hidden="true"]
      display none !important
  &[data-current_tab="REVIEW_TAB"]
    .product_detail_tab[data-tab_name="REVIEW_TAB"]
      {product_detail_tabs_container_active}
  &[data-current_tab="BP_DETAIL_TAB"]
    .product_detail_tab[data-tab_name="BP_DETAIL_TAB"]
      {product_detail_tabs_container_active}
  &[data-current_tab="MANUFACTURE_TAB"]
    .product_detail_tab[data-tab_name="MANUFACTURE_TAB"]
      {product_detail_tabs_container_active}
  &[data-current_tab="STORY_TAB"]
    .product_detail_tab[data-tab_name="STORY_TAB"]
      {product_detail_tabs_container_active}
  .product_detail_tab_body
    &[data-tab_name="STORY_TAB"]
      margin-top ptr(50)
  .product_detail_tab_body[data-tab_name="BP_DETAIL_TAB"]
  .product_detail_tab_body[data-tab_name="MANUFACTURE_TAB"]
    padding ptr(40) ptr(40) 0
  .product_detail_tab_body[data-tab_name="REVIEW_TAB"]
    padding ptr(40) 0
  .product_detail_tab_body[data-tab_name="STORY_TAB"]
    padding ptr(80) 0 ptr(160)
  .toggle_cont
    border-top none !important
  .product_detail_tab_body
    .toggle_cont
      margin 0 !important
    .toggle_cont:first-child
      padding-top 0 !important
  .bp_info_pop_buttons
    margin-bottom 0 !important
  .product_detail_tab_body
    .toggle_cont
      margin-top ptr(8)
      &:first-child
        margin-top 0
      &:last-child
        border-bottom none !important
        .tg_body
          padding-bottom 0 !important
    &:first-child
      margin-top 0 !important
      .toggle_cont:first-child
        margin-top 0 !important
    margin-top ptr(40)
    border-top ptr(1) solid #000
    &:first-child
      border-top none
      margin-top 0
  &[data-is_header_headroom="true"]
    &[data-is_top_fixed="true"]
      .product_detail_tabs
        .wrapper
          transition transform 0.25s ease-in-out
          &.headroom--pinned
            position fixed
            //top 0
            //left 0
            //right 0
            z-index 10000
            transform translateY(ptr(60))
          &.headroom--unpinned
            position fixed
            //top 0
            //left 0
            //right 0
            z-index 10000
            transform translateY(0)
  &[data-is_header_headroom="false"]
    &[data-is_top_fixed="true"]
      .product_detail_tabs
        .wrapper
          position fixed
          //top 0
          //left 0
          //right 0
          z-index 10000
