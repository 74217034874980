body.mp-checkout-kr
  .additional_shipping_price
    display none !important
    &[data-has_additional_shipping_price="true"]
      display flex !important
  .mobile
    display none
  .bank_info
    display flex
    flex-direction column
    width 100%
  .mp-select-wrapper
    &.memo2-selected
      input[name="memo2"]
        flex 1
      select
        opacity 0
        width ptr(100)
    &.bank_info
      select
        background-color transparent
        background-image url(//s3.marpple.co/files/u_193535/2023/7/original/5ba584f0fea9dc032cb254d63c9511b7023ead4a2.svg)
        background-repeat no-repeat
        background-size ptr(20) ptr(20)
        background-position 100% 50%
    .checkout-row-1__body
      position relative
    select
      width 100%
      background-color transparent
      z-index 1
    &__btn
      background none
      outline none
      color OG
      font-size ptr(16)
      position absolute
      right 0
      top 50%
      transform translateY(-50%)
  .checkout-label-checkbox
    display flex
    align-items center
    > span:first-child
      margin-right ptr(8)
    *
      font-size ptr(16)
    .mp_input
      cursor pointer
      display flex
      align-items center
      span
        display inline-block
        width ptr(20)
        height ptr(20)
        border ptr(1) solid BK
        border-radius ptr(3)
    input[type="checkbox"]
      display none
      &:checked
        ~span
          background url("//s3.marpple.co/files/u_29089/2023/7/original/e0193dc81715eb4e315aa1b84d249963331069931.svg") no-repeat center
          background-size ptr(13)
  .checkout-label-radio
    display flex
    align-items center
    .mp_input
      margin-right ptr(8)
      width ptr(20)
      height ptr(20)
      border-radius ptr(100)
      cursor pointer
      position relative
      border ptr(1) solid BK
    input[type="radio"]
      display none
      &:checked
        ~span
          position absolute
          top 50%
          left 50%
          width 60%
          height 60%
          transform translate(-50%, -50%)
          border-radius ptr(100)
          background-color BK
  .receipt_info
    .receipt_body
      display flex
      flex-direction column
      width 100%
  .cash.receipt_body
  .company.receipt_body
  .bank_info
    select
      border none
      outline none
      margin-bottom ptr(8)
      border-bottom ptr(1) solid GY_50
      padding ptr(16) 0
      font-size ptr(16)
    input
      border none
      border-bottom ptr(1) solid GY_50
      padding ptr(16) 0
      font-size ptr(16)
      &:focus
      &:placeholder
        font-size ptr(16)
        font-weight 400
        border none !important
        outline none !important
      outline none
  .radio_box
    display flex
    flex-wrap wrap
    .checkout-label-radio
      margin-right ptr(16)
  .don_frame[frame_name="mp.quick_shipping_price"]
    .don_page
      >.don_wrapper
        >.header
          height ptr(64)
          display flex
          background #fff
          align-items center
          .title
            padding-left 0
            margin 0 auto
            font-size ptr(16)
            font-weight bold
        .don_tab >.don_wrapper
          >.body
            display flex
            margin-bottom ptr(16)
        padding 0 ptr(16) ptr(24)
        table:first-child
          border-right none
          padding-left ptr(1)
        .last
          border-right none
        table, th, td
          border ptr(1) solid #e9ecef
          border-collapse collapse
          width 50%
        th
          background #fafafa
        th,td
          text-align center
          padding ptr(16) 0
        p
          margin 0
          padding 0
          color GY
          font-size ptr(14)

  .don_page.mp_checkout
    font-weight normal
    span
    label
      font-size ptr(14)
    .msg
      display none
    .omp_msg
      color GY
      font-size ptr(14) !important
      font-weight 400
      margin-top ptr(16) !important
    .make_request
      display flex
      border none !important
      outline none !important
      border-bottom ptr(1) solid GY !important
      padding ptr(24) 0 !important
      height ptr(195)
      //html[data-applied_promo_length = "1"] &
      //  height ptr(195+60)
      //html[data-applied_promo_length = "2"] &
      //  height ptr(195+60+60)
    .shipping_picker
      display flex
    .check_all
      display flex
      align-items center
    .checkout-row-1
      input
      select
      button
        &:focus
        &:placeholder
          font-size ptr(16)
          font-weight 400
          border none !important
          outline none !important
        border none !important
        outline none !important
        font-size ptr(16)
      input
        padding 0
        background none
      padding ptr(18) 0
      border-bottom ptr(1) solid GY_50
      display flex
      position relative
      *
        font-size ptr(16) !important
      &__body
        display flex
        justify-content space-between
        align-items center
        > *:nth-child(1)
          flex 1
      &__option
        background-color transparent
        color OG !important
        font-weight 500
        height 100%
        border none
        outline none
      > *:nth-child(1)
        font-weight 500
        width ptr(200)
        text-align left
      ::placeholder
        font-weight 400
        color GY
      > *:not(label):not(.name)
        flex 1
        font-weight 400
    .header_title
      margin-bottom ptr(16)
      .title_text
        font-size ptr(26)
        font-weight 500
        margin-bottom ptr(16)
      .status_bar
        span
          &.arrow
            margin auto ptr(16)
            height ptr(10)
            min-width ptr(10)
            background svg-right-arrow no-repeat center
          font-size ptr(16)
          font-weight 500
          color GY
          &.active
            font-weight 700
            color BK
    .to_map
      img
        max-width 100%
    >.don_wrapper .checkout_body
      input.hidden
        display none !important
      .input_wrap
        .hidden.done_upload_text
          display none
      .input_wrap.done_upload
        .done_upload_text
          display block !important
      select
        -webkit-appearance none
        -moz-appearance none
        cursor pointer
      textarea.make_request
        line-height 150%
        width 100%
        border ptr(1) solid GY
        resize none
        overflow auto
        box-sizing border-box
        padding ptr(16)
        font-size ptr(14)
        outline none
        -webkit-appearance none
        border-radius 0
      .section
        margin-bottom ptr(8)
        .header_summary
          font-size ptr(12)
          line-height 1.17
          -webkit-appearance none
          -moz-appearance none
          letter-spacing normal
          color #212529
        .section_header
          line-height 1.14
          border-bottom ptr(1) solid BK
          padding-bottom ptr(24)
          //&:nth-child(3)
          //  margin-top ptr(16)
          box-sizing border-box
          color BK
          {flex-center-between}
          .txt
            font-size ptr(16)
            font-weight bold
          .btn
            font-size ptr(12)
            text-decoration underline
            cursor pointer
        .section_body
          p
            color GY
            margin-bottom 0
          input[type="text"]
          input[type="password"]
            display inline-block
          .input
          select
            display inline-block
          .input
            color #878E96
          input[type="text"]
            &[name="memo2"]
              margin-bottom 0
          .input_wrap
            label
              display inline-block
          .msg
            font-size ptr(12)
            line-height 1.67
            color GY
          .has_not_email
            .msg
              position relative
              left ptr(106)
              top ptr(-10)
          .price_detail
            .detail
              .discount
                color OG !important
          .total_price
            .info
              font-size ptr(26)
              line-height 1
              color OG
          .up_item
            &:not(:last-child)
              margin-bottom ptr(40)
          .up_c_item
            .up_c_info
              width 100%
              .pc_name
                display none
              .quantity:after
                content '개'
              .title
                margin-bottom ptr(16)
      .payment_type.alipay
        ul
          padding-left ptr(18)
          font-size ptr(13)
      .we_accept
        position relative
        height ptr(30)
        margin ptr(14) 0
        .txt
          position absolute
          top ptr(6)
          left 0
          font-size ptr(14)
          font-weight bold
          color color_black
        .logos
          position absolute
          top 0
          right 0
          > img
            width ptr(48)
            height ptr(30)
      .promotion_description:empty
        display none
      .my_point
      .promotion_description
        display inline-block
        color GY
      .input_wrap2
        display flex
        .txt
          display inline-block
          width ptr(106)
        .promotion_description
          margin-bottom ptr(16)
        .my_point
        .promotion_description
          margin-left ptr(106)
        .apply
          font-size ptr(12)
          background-color #ffffff
          margin-left ptr(8)
          vertical-align top
          border solid ptr(1) #000000
          color color_black
          &:after
            content '쿠폰 적용하기'
          &[applied="true"]
            color GY !important
            &:after
              content '쿠폰 취소'
        button
          margin-left ptr(8)
          font-size ptr(14)
          vertical-align top
          border ptr(1) solid #d5dbe0
      .payment_type.without_bank
        .radio_box .row.last
          label
            margin-bottom ptr(8)
      .receipt_info
        padding-top ptr(24)
        .receipt_body
          display none
        &[receipt_type='cash']
          .cash.receipt_body
            display flex
        &[receipt_type='company']
          .company.receipt_body
            display flex
        &[receipt_type='taxBill']
          .taxBill.receipt_body
            display flex
        .receipt_body
          margin-top ptr(24)
        >.header
          margin-bottom ptr(16)
        >.option
          display none
          margin-top ptr(24)
        .company
        .cash
          select
          input
          .input
            margin-bottom ptr(8) !important
            &:last-child
              margin-bottom 0 !important
          select
            margin-bottom ptr(8)
        .taxBill
          .wrapper
            display flex
            > button
              flex-grow 1
              width 50%
              font-size ptr(14)
              padding ptr(12) 0
              text-align center
              &:nth-child(1)
                margin-right ptr(8)
      .agreement
        padding-top 0 !important
        .checkout-label-checkbox
          height ptr(68)
          display flex
          align-items center
        .description
          font-size ptr(14)
          color BK
          line-height 160%
          margin-bottom ptr(24)
        .check_all
          font-size ptr(14)
          font-weight bold
        .check_item
          border-bottom ptr(1) solid BK
          svg
            margin-left ptr(8)
        a
          color BK
          text-decoration underline
      .submit
        margin-top ptr(16)
        width 100%
        border-radius 999px
        height ptr(56)
        border none
        background-color #212529
        color #ffffff
        {flex-center-center}
        {font_16_m_150}
      .shipping_type
        table
          li
            font-size ptr(14)
        .txt2
          font-size ptr(14)
          color #666
        h4
          margin 0
          font-weight normal
          margin-bottom ptr(16)
        .shipping_type_tab
          padding ptr(18) 0
          &[has_before_deliver_projections="true"]
            label
              &:nth-child(2)
                margin-right ptr(78)
              &:nth-child(1)
              &:nth-child(3)
                margin-right ptr(68)
        .shipping_type
          background BG_PRODUCT
          padding ptr(24)
          &.radio_target
            border-radius ptr(20)
          .quick_table
            position relative
            text-decoration underline
          th
            width ptr(60)
            font-size ptr(14)
            color BK
            font-weight normal
            text-align left
            vertical-align initial
            line-height 1.67
          td
            padding-bottom ptr(12)
            font-size ptr(14)
            line-height 1.67
          tr:last-child
            td
              padding-bottom 0
          ul
            margin 0
            margin-top ptr(16)
            padding ptr(16) 0 0
            list-style none
            position relative
            border-top ptr(1) solid #d5dbe0
            li
              font-size ptr(14)
              line-height 1.83
        .shipping_type.combined
          table
            width 100%
          tr
            th
              width ptr(80)
              text-align left
              padding-right ptr(4)
              word-break break-all
            th
            td
              padding 0 0 ptr(12)
              vertical-align top
            td:first-child
              label
                position relative
                top ptr(-1)
            td
              label
                display inline-block
            td:nth-child(3n)
              padding-right ptr(8)
            td:last-child
              text-decoration underline
      .shipping_picker
        padding ptr(16) 0
        position relative
        font-size ptr(16)
        font-weight 500
        >label
          margin-right ptr(16)
        button.open_list
          display none
          position absolute
          padding ptr(5) ptr(8)
          border ptr(1) solid #d5dbe0
          font-weight normal
          background #fff
          top ptr(-4)
          right 0

  .don_page.mp_checkout
    .don_tab >.don_wrapper .checkout_body
      position static
    .none_br
      br
        display none
    .quick_table
      padding-left ptr(8)
    .section
      padding ptr(24) 0
      box-sizing border-box
      > *
        margin 0
        .input_wrap
          position relative
        .for_desktop_wrap
          display flex
          justify-content space-between
        .for_desktop
          &.city_state
            width ptr(284)
          &.postcode_country
            width ptr(284)
            >*
              max-width ptr(284)
          .country
            margin-bottom 0
          &.mobile_number
            display flex
            justify-content space-between
            select.dialing_code
              width ptr(294)
              margin-right ptr(8)
      &.product
        .header_summary
          display none
        >.section_header
          border-bottom ptr(1) solid #d5dbe0
          padding-bottom ptr(16)
        .up_list
          margin 0
          padding ptr(16) 0 0
          .thumb
            border ptr(1) solid GY_50
            border-radius ptr(8)
            .expiration
              width 100%
              height 100%
              display flex
              background-color BG_PRODUCT
              align-items center
              justify-content center
              color GY
              text-align center
              border-radius ptr(8)
              font-size ptr(12)
          .item_header
            font-size ptr(16)
            color BK
          .up_c_info
            .title,
              font-size ptr(16)
              font-weight 400
              color BK
            .sub_title, .face_list, .size, .quantity
              font-size ptr(14)
              font-weight 400
              color GY
            .quantity:after
              content '개'
            .product_price
              margin-top ptr(4)
          .item_body_wrap1
            margin 0
    .bar_wrapper
      display flex
      justify-content space-between
      gap ptr(16)
    .left_bar
      flex 1
      order 1
    .right_bar
      position sticky
      top ptr(205)
      order 2
      .section
        width ptr(602)
    .payment_method
      width ptr(625)
      padding ptr(24)
      box-sizing border-box
      {flex-center-between}
      background-color #fafafa
      .logos
        >img
          margin-left ptr(8)
    .submit
      margin-top ptr(40)

  .don_frame[frame_name="mp.checkout.change_email"][is_modal="true"]
    >.don_wrapper
      width ptr(407) !important
      height ptr(452) !important
      overflow hidden
      .don_page >.don_wrapper
        >.header
          height ptr(65)
          z-index 10
        .page_body
          padding 0 ptr(40)

  //.don_page[page_name="mp.checkout.change_email"]
  //  background-color #fff
  //  .don_wrapper >.page_body
  //    padding 0 ptr(24)
  //    .header_title
  //      font-size ptr(16)
  //      font-weight bold
  //      color color_black
  //    .current_data
  //      margin ptr(24) 0
  //      .title
  //        font-size ptr(14)
  //        color color_black
  //        margin-bottom ptr(16)
  //      .email_text
  //        font-size ptr(14)
  //        color color_black
  //        background-color #fafafa
  //        padding ptr(12) ptr(16)
  //    .notice
  //      margin-top ptr(8)
  //      font-size ptr(14)
  //      line-height 1.5
  //      color GY
  //    .inputs
  //      //margin-bottom ptr(24)
  //      input
  //      .input
  //        box-sizing border-box
  //        padding ptr(12) ptr(16) ptr(12) ptr(36)
  //        width 100%
  //        border solid ptr(1) #d5dbe0
  //        background-color #ffffff
  //        outline none
  //        -webkit-appearance none
  //        border-radius 0
  //        &::placeholder
  //          font-size ptr(14)
  //          color GY
  //        &.new_email
  //          margin-bottom ptr(8)
  //          background png-email no-repeat
  //          background-size ptr(12) ptr(12)
  //          background-position left ptr(16) top ptr(14)
  //        &.password
  //          background png-password no-repeat
  //          background-size ptr(12) ptr(12)
  //          background-position left ptr(16) top ptr(14)
  //    .submit_btn
  //      {flex-center}
  //      height ptr(48)
  //      font-size ptr(14)
  //      color #ffffff
  //      background-color #000000
  //      cursor pointer

  #body
    .payment_type_container
      padding-bottom ptr(16)
      taxBill.receipt_body
        input
          margin-bottom 0
      .payment_types
        margin-top ptr(16)
        .callout_naver_pay
          display none
        .bank_info
          margin ptr(24) 0 !important
          select
            margin-bottom ptr(8) !important
          input
          .input
            margin-bottom 0 !important
        .payment_type
          display none
        .selector
          .group
            display grid
            grid-template-columns repeat(5, 1fr)
            gap 0 ptr(8)
          //flex-wrap wrap
          button
            width 100%
            //min-width ptr(190)
            border ptr(1) solid GY_50
            border-radius ptr(10)
            height ptr(56)
            background #fff
            cursor pointer
            font-size ptr(16)
            margin-right ptr(8)
            box-sizing border-box
            outline none
            margin-bottom ptr(8)
            display flex
            align-items center
            justify-content center
            gap ptr(8)
            &[payment_type="naverpay"]
              position relative
            .badge
              {font_12_b_100}
              position absolute
              top ptr(-12)
              border-radius 999px
              height ptr(24)
              padding ptr(0) ptr(8)
              display flex
              align-items center
              justify-content center
              background-color OG
              color WHT
            > svg
              width ptr(24)
              height ptr(24)
              border-radius 999px
        &[type="card"] > .card
        &[type="without_bank"] > .without_bank
        &[type="trans"] > .trans
        &[type="paypal"] > .paypal
        &[type="alipay"] > .alipay
          display block
        &[type="card"] button[payment_type="card"]
        &[type="without_bank"] button[payment_type="without_bank"]
        &[type="trans"] button[payment_type="trans"]
        &[type="offline_card"] button[payment_type="offline_card"]
        &[type="offline_cash"] button[payment_type="offline_cash"]
        &[type="samsung"] button[payment_type="samsung"]
        &[type="payco"] button[payment_type="payco"]
        &[type="naverpay"] button[payment_type="naverpay"]
        &[type="global_port_one"] button[payment_type="global_port_one"]
        &[type="ssgpay"] button[payment_type="ssgpay"]
        &[type="lpay"] button[payment_type="lpay"]
        &[type="paypal"] button[payment_type="paypal"]
        &[type="alipay"] button[payment_type="alipay"]
        &[type="vbank"] button[payment_type="vbank"]
        &[type="tosspay"] button[payment_type="tosspay"]
        &[type="kakaopay"] button[payment_type="kakaopay"]
          border-color BK
          background-color BK
          color WHT
          &:not(.third_party)
            > svg > path
              fill WHT
        &[type="naverpay"]
          .callout_naver_pay
            display flex
            align-items flex-start
            gap ptr(8)
            background-color #FFF8F2
            border-radius ptr(8)
            padding ptr(16)
            {font_12_m_100}
            line-height 17px
            margin-top ptr(16)
            > .title
              color OG
              flex-basis ptr(64)




  .don_page.mp_checkout .checkout_body
    .postcode_layer
      box-sizing border-box
      margin-bottom ptr(16)
      display none
      height ptr(470)
      width 100%
      overflow hidden
      z-index 100
      border ptr(5) solid #000
      padding-top ptr(16)
      position relative
      .postcode_close
        cursor pointer
        position absolute
        right ptr(-3)
        top ptr(-3)
        z-index 1
    .agreement
      .check_box
        margin-right ptr(8)
      .check_all
        border-bottom ptr(1) solid BK
        width 100%
        .mp_input
          margin-right ptr(8)
        *
          font-size ptr(16)
          font-weight 700
      .check_item:not(.with_description)
        display flex
        align-items center
        .check
          margin-right ptr(8)
      .check
        font-size ptr(16)
        font-weight 500
        display flex
        align-items center
      .with_description
        .check
          padding 0
