.don_frame[frame_name="/modules/OMP/Auth/S/Mui/frame.js"]
  > .don_wrapper
    > .body
      > .don_page
        > .don_wrapper
          > .header
            display flex
            flex-direction row
            align-items center
            justify-content space-between
            .title
              display none
            > .tab_buttons
              display flex
              gap ptr(16)
              .tab_button
                {font_26_m_130}
                background-color transparent
                box-sizing border-box
                &[selected="false"]
                  color GY_80

  .omp-auth-page
    width 100%
    min-height 50vh
    display flex
    flex-direction column
    align-items center
    justify-content center
    &__title
      display flex
      flex-direction row
      align-items center
      justify-content center
      gap ptr(16)
      & > a
        {font_26_m_130}
        &[data-active="false"]
          color GY_80 !important
  .omp-auth-login
    display flex
    justify-self center
    flex-direction column
    padding-top ptr(40)
    box-sizing border-box
    gap ptr(16)
    width ptr(440)
    &__button-wrapper
      display flex
      flex-direction column
      align-items center
      gap ptr(16)
    &__or
      color GY_80
      {font_16_m_100}
    &__data
      display flex
      flex-direction column
      gap ptr(40)
    &__inputs
      display flex
      flex-direction column
      gap ptr(16)
    &__find
      display flex
      gap ptr(16)
      align-items center
      justify-content center
      margin-bottom ptr(30)
      {font_14_m_100}
      span
        cursor pointer
  .omp-auth-signup
    display flex
    flex-direction column
    padding-top ptr(40)
    box-sizing border-box
    gap ptr(40)
    width ptr(440)
    &__button-wrapper
      display flex
      flex-direction column
      align-items center
      gap ptr(16)
    &__or
      color GY_80
      {font_16_m_100}
    &__data
      display flex
      flex-direction column
      gap ptr(40)
    &__inputs
      display flex
      flex-direction column
      gap ptr(16)
    &__term-wrapper
      display flex
      flex-direction column
      gap ptr(8)
      hr
        margin ptr(8) 0
        border 1px solid BG_MODEL
    &__checkbox-wrapper
      display flex
      align-items center
      gap ptr(8)
      a
        text-decoration underline
        {font_14_m_140}
      .omp-atom__empty-checkbox-title
        > a
          padding-left ptr(8)

/* iOS 앱에서 페이스북 로그인 있으면 Apple 로그인도 있어야 심사에 통과할 수 있어 숨김 처리 */
html[is_marpple_app="true"][is_ios="true"]
  .omp-auth-login__or
  .omp-auth-signup__or
  .omp-auth__facebook
    display none
