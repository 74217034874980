// modules/OMP/ShareProduct/Expired/F/Style/Pc/index.styl

.don_tab[tab_name="/modules/OMP/ShareProduct/Expired/S/Mui/tab.js"]
  .don_wrapper
    .omp-layout-body
      align-items center
      justify-content center
      padding-top 0
    .shared_product_expired
      width ptr(440)
      display flex
      flex-direction column
      gap ptr(50)
      align-items center
      font-weight 500
      img
        width 100%
      &__desc
        text-align center
        line-height 100%
        p
          margin 0
        &__title
          font-size ptr(20)
          font-weight 700
          padding-bottom ptr(24)
        &__info
          padding-bottom ptr(8)
          line-height 150%
        a
          padding ptr(8)
          text-decoration underline
          display inline-block
      button
        color white
        width ptr(160)
        height ptr(50)
        padding ptr(6) ptr(17)
        background BK
        border none
        border-radius 999px


