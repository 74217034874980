// maker 의 위치 조정 버튼 PC / MO 공통 처리
.mp_maker
  .circle_icon
    &.loc_optimization_btn
      display none
      .icon
        box-sizing border-box
        mp-editor-icon-optimize-position()
  &[data-is_enable_loc_optimization='true']
    .circle_icon
      &.loc_optimization_btn
        display flex
  &[data-is_activate_loc_optimization='true']
    .circle_icon
      &.loc_optimization_btn
        .icon
          border 1px solid var(--OG, #FF6B00)
          background-image url(//s3.marpple.co/files/u_1187078/2024/5/original/f77fc95f88cc601901485312714a2070a0bde1041.svg)
          &:hover
            background-image url(//s3.marpple.co/files/u_1187078/2024/5/original/f3a2aded89bef34ba0c3df6b5fdde633131fe6831.svg)
        .text
          color var(--OG, #FF6B00)
  &.unable_undo_redo
    .undo
    .redo
    .reset
      opacity 0.3 !important
